import { SWITH_MODE_ACTION } from '../action/type';

const INIT_STATE = {
    switchModeState: 'testMode', // 智驾模式切换
    switchMvizState: 'left', // Mviz布局切换
    switchSpeedAudio: 'OFF', // 车速调整语音开关 0905产品逻辑默认改为OFF localStorage.getItem("switch_speed_audio")
    switchUnpState: false, // Mtour开关
    switchEventState: false, // event默认关闭， 没有缓存
    switchWorkBench: false, // workbench开关
    switchFollowCarState: false, // 跟车时距开关
    recordTimes: 15, //录音按钮默认时间
    isRecording: false, // 是否正在录音中
    switchDrivingBroadcast: 'Alert', // 智驾播报模式切换: 默认简洁,（1228产品需求变动添加缓存）
    switchTFLState: 'CLOSE',
};

export default function switchModeAction(state = INIT_STATE, action) {
    switch (action.type) {
        case SWITH_MODE_ACTION.switchModeState:
            return { ...state, switchModeState: action.data };
        case SWITH_MODE_ACTION.switchMvizState:
            return { ...state, switchMvizState: action.data };
        case SWITH_MODE_ACTION.switchSpeedAudio:
            return { ...state, switchSpeedAudio: action.data };
        case SWITH_MODE_ACTION.switchUnpState:
            return { ...state, switchUnpState: action.data };
        case SWITH_MODE_ACTION.switchWorkBench:
            return { ...state, switchWorkBench: action.data };
        case SWITH_MODE_ACTION.switchEventState:
            return { ...state, switchEventState: action.data };
        case SWITH_MODE_ACTION.switchFollowCarState:
            return { ...state, switchFollowCarState: action.data };
        case SWITH_MODE_ACTION.recordTimes:
            return { ...state, recordTimes: action.data };
        case SWITH_MODE_ACTION.isRecording:
            return { ...state, isRecording: action.data };
        case SWITH_MODE_ACTION.switchDrivingBroadcast:
            return { ...state, switchDrivingBroadcast: action.data };
        case SWITH_MODE_ACTION.switchTFLState:
            return { ...state, switchTFLState: action.data };
        default:
            return state;
    }
}
