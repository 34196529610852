import React, {useState, useEffect} from "react";
import {useTranslation} from 'react-i18next';
import {AppFn} from '@/utils/native';
import "./index.less";

let playModeOptions = [
    {
        label: '静音',
        value: 'closeBroadcast',
    },
    {
        label: '简洁',
        value: 'simpleBroadcast',
    },
    {
        label: '详细',
        value: 'detailBroadcast',
    }
];

const {jsButtonCallBack, getNaviSettings} = AppFn

export default function NavigationTips() {
    const {t: translate} = useTranslation();
    const [checkedPlay, setCheckedPlay] = useState(null);

    useEffect(() => {
        getNaviSettings('naviSettingCallback')
        window.naviSettingCallback = function (data) {
            const {broadcastMode} = data;
            let playArr = [];
            playModeOptions.map(item => {
                playArr.push(item.value);
            })
            if (playArr.includes(broadcastMode)) {
                handleTap('playMode', broadcastMode);
            }
        }
    }, [])

    const handleTap = (flag, value) => {
        if ((flag === 'playMode' && value === checkedPlay)) {
            return
        }

        if (flag === 'playMode') {
            setCheckedPlay(value)
        }

        jsButtonCallBack(value);
    }


    return (
        <div className={"navigation_tips_card_wrap"}>
            <div className={"title_wrap"}>导航提示</div>

            <div className={"sensitivity_wrap"}>
                <div className={"select_box"}>
                    {
                        playModeOptions.length && playModeOptions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${checkedPlay === item.value ? 'active' : ''}`}
                                    onClick={() => handleTap('playMode', item.value)}
                                >
                                    {item.label}
                                </span>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
