import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from 'react-i18next';
import {Switch} from "antd-mobile";
import CardLayout from "../../components/singleCard";
import lpnpImg from '@/assets/parking/lpnpsetting.png';
import {cusToast} from '@/components/customToast';
import {useSelector} from "react-redux";
import {SocketMap} from '@/constant/socketMap';
import FM from "@/utils/nativeSubAndPub";
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import "@/components/slider/smartDrivingSetting/components/cardStyle/index.less";

let timer = null;
export default function LpnpCard() {
    const {t: translate} = useTranslation();
    const lpnp_switch = useSelector(state => state?.featureModelDriving?.switch?.lpnp?.lpnp_switch);
    const [checkSwitch, setCheckSwitch] = useState(lpnp_switch === "OPEN");

    useEffect(() => {
        lpnp_switch && setCheckSwitch(lpnp_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer)
            timer = null
        }
    }, [lpnp_switch])

    // swtich: AEB
    const switchLpnpChange = (value) => {

        reportDataFunc(value)

        clearTimeout(timer)
        timer = setTimeout(() => {
            setCheckSwitch(lpnp_switch === "OPEN")
        }, 2 * 1000)
        LpnpControl(value);
    }

    // 数据上报
    const reportDataFunc = (v) =>{
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData:{
                LPNP: v==='OPEN'? 'ON' : 'OFF',
            },
        })
    }

    // 通知底层：LPNP
    const LpnpControl = async (value) => {
        FM.sendToHMIDriving(SocketMap.lpnp_switch_control, {
            cmd: value ? 'OPEN' : 'CLOSE',
        },'drive_node_res_fail_cb'); 
    }

    const dom = 
        (<div className={"left_dom_wrap"}>
            <div className={"switch_wrap"}>
                <div className={"content_wrap"}>
                    <div className={"text_wrap"}>LPNP</div>
                    <div className={"text_wrap_detail"} style={{whiteSpace: "pre-wrap"}}>
                        {translate('settings.lpnpSwitch')}
                    </div>
                </div>
                <Switch
                    checked={checkSwitch}
                    onChange={(checked) => switchLpnpChange(checked)}
                    className={"customSwitch"}
                />
            </div>
        </div>)

    return (
        <div className="wap-content">
            <CardLayout
                dom={dom}
            />
            <img className="lpnp-img" src={lpnpImg} />
        </div>
    )
}
