import {SocketMap} from '@/constant/socketMap';
import FM from "@/utils/nativeSubAndPub";
import {cusToast} from '@/components/customToast';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function useBSDDOWRCWCommon(props) {
    const {t: translate} = useTranslation();
    const rcw_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.rcw_switch);
    const lca_bsd_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.lca_bsd_switch);
    const dow_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.dow_switch);

    // switch：BSD/LCA、DOW、RCW
    const switchChange = (value,flag, setCallback) =>{
        let controlParams = '';
        if(flag === 'BSD'){
            props?.timer && clearTimeout(props?.timer)
            const timer = setTimeout(() => {
                setCallback(lca_bsd_switch === 'OPEN');
            }, 2 * 1000)
            props?.setTimer(timer)
            controlParams = SocketMap.lcaBsdControl;
        }else if(flag === 'DOW'){
            props?.timer && clearTimeout(props?.timer)
            const timer = setTimeout(() => {
                setCallback(dow_switch === 'OPEN');
            }, 2 * 1000)
            props?.setTimer(timer)
            controlParams = SocketMap.dowControl;
        }else if(flag === 'RCW'){
            props?.timer && clearTimeout(props?.timer)
            const timer = setTimeout(() => {
                setCallback(rcw_switch === 'OPEN');
            }, 2 * 1000)
            props?.setTimer(timer)
            controlParams = SocketMap.rcwControl;
        }
        noticeControl({value,controlParams});
        // 数据上报
        reportDataFunc({value,flag});
    }

    // 通知底层：BSD/LCA、DOW、RCW
    const noticeControl = async (params) =>{
        const {value,controlParams} = params
       FM.sendToHMIDriving(controlParams, {
            switch: value ? 'OPEN' : 'CLOSE',
        },'drive_node_res_fail_cb');
      
    }
    // 数据上报
    const reportDataFunc = (params) =>{
        const {value,flag} = params;
        let dataObj = {};
        let repName = '';
        if(flag === 'BSD'){
            repName = reportEventName.HMI_BSD_SWITCH_CLICK
        }else if(flag === 'DOW'){
            repName = reportEventName.HMI_DOW_SWITCH_CLICK
        }else if(flag === 'RCW'){
            repName = reportEventName.HMI_RCW_SWITCH_CLICK
        }
        dataObj[flag] = value ? 'OPEN' : 'CLOSE';
        repName && singelPointReport({
            eventName: repName,
            timestamp: new Date().getTime(),
            reportData: dataObj,
        })
    }

    return {
        switchChange
    }
}
