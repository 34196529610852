import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppFn } from '@/utils/native';
import FM from '@/utils/nativeSubAndPub';
import { SocketMap, ParkingSocketMap } from '@/constant/socketMap';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { HOT_AREA_TYPE } from '@/store/action/type';
import SmartDrivingSetting from './smartDrivingSetting/index';
import TestAssistant from './testAssistant/index';
import FollowDistance from './../drivingContainer/followDistance/index';
import SystemTime from './systemTime/index';
import Reconnection from './reconnection/index';
import WorkBench from './../workbench/index';
import workBenchEventName from '@/constant/workBenchEventName';
import { widgetQueue, widgetType } from '@/utils/widgetQueue';
import logSend from '@/utils/aLog';
import isCN from '@/utils/isCN';
import { cusToast } from '@/components/customToast';
import switchStateMap from '@/constant/switchStateMap';
import localCache from '@/utils/localCache';

import './index.less';

const { jsButtonCallBack, getData } = AppFn;
let routeModalCancel = false;
export default function Slider() {
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    const switchModeState = useSelector((state) => state?.switchModeAction?.switchModeState);
    const position_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.position_state);
    const outHand = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.hands_off_drive_mode?.hands_off_switch,
    );
    const outHandShow = useSelector((state) => state?.featureModelDriving?.switch?.driving?.hands_off_drive_mode?.mode);
    const area = useSelector((state) => state?.layout?.Area);
    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);
    const Mtour = useSelector((state) => state?.switchModeAction?.switchUnpState);
    const [siderBarData, setSlider] = useState([]);
    const [checkObj, setChecked] = useState(null);
    // const i18nLang = window.localStorage.getItem('i18nLang');
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    const dispatch = useDispatch();
    // 热区
    const sliderDom = useRef(null);
    const sceneChangeRef = useRef(null);

    const { t: translate } = useTranslation();

    // 上报热区
    useEffect(() => {
        const sliderDomArea = sliderDom?.current?.getBoundingClientRect();
        const sliderDomInfo = `${sliderDomArea?.x}, ${sliderDomArea?.y}, ${sliderDomArea?.width}, ${sliderDomArea?.height}`;
        if (typeof sliderDomInfo !== 'undefined' && sliderDomArea?.current?.getBoundingClientRect()?.width != 0) {
            dispatch({
                type: HOT_AREA_TYPE.sliderDom,
                data: sliderDomInfo,
            });
        }
    }, [sliderDom?.current?.getBoundingClientRect()?.width, area?.isHorizontal]);

    window.pilot_kind_control_cb = (res) => {
        logSend({
            action: 'pilot_kind_control_cb',
            data: {
                ...res,
            },
        });
        if (res?.data?.code === 0) {
            //进入parking上报
            singelPointReport({
                eventName: reportEventName.HMI_PARKING_ENTER_CLICK,
                timestamp: new Date().getTime(),
                text: 'enter parking',
            });
            // FM.registerFeatureModel(['parking'], "recevieFeatueModelParkingData")
            // navigate(`/${"home/parking"}`);
        }
    };
    const lpnp_switch = useSelector((state) => state?.featureModelDriving?.switch?.lpnp?.lpnp_switch);
    // const apa_switch = useSelector((state) => state?.featureModelDriving?.switch?.apa?.apa_switch);
    // const apa_switch = switchStateMap.close;
    const isRecording = useSelector((state) => state?.switchModeAction?.isRecording);

    const handleTap = async (component) => {
        console.log('click----', component, checkObj);
        if (component == 'parking' && automatic_state != 'ACTIVE') {
            // if (![lpnp_switch, apa_switch].includes(switchStateMap.open)) {
            //     cusToast.show({
            //         // TODO: apa暂时下掉
            //         // content: 'Please switch on the  APA or LPNP first',
            //         content: 'Please switch on the LPNP first',
            //         duration: 3000,
            //     });
            //     return;
            // }
            if (isRecording) {
                cusToast.show({
                    content: 'Wait for recording to complete',
                    duration: 3000,
                });
                return;
            }
            FM.sendToHMIDriving(
                ParkingSocketMap.pilot_kind_control,
                {
                    cmd: 'PARKING',
                },
                'pilot_kind_control_cb',
            );
        } else if (component == 'parking') {
            if (!sceneChangeRef.current) {
                // 前端hack widget
                widgetQueue({
                    widgetNumber: 2,
                    type: widgetType.SCENE_CHANGE,
                    widgetContent: {
                        icon: '',
                        content: `<span style="white-space: nowrap;">${translate('sceneChange.tipText')}</span>`,
                    },
                });
                sceneChangeRef.current = setTimeout(() => {
                    widgetQueue({ widgetNumber: 2, type: widgetType.SCENE_CHANGE, widgetContent: null });
                    clearInterval(sceneChangeRef.current);
                    sceneChangeRef.current = null;
                }, 3000);
            }
            return;
        }

        if (component == 'workbench') {
            singelPointReport({
                eventName: workBenchEventName.WB_home_click,
                timestamp: new Date().getTime(),
                reportData: {
                    openWorkbench: 'open',
                },
            });
        }
        if (component === 'routeTestAssistant') {
            if (component === checkObj) {
                cancelHotReport();
            } else {
                singelPointReport({
                    text: 'helpBuddy',
                    eventName: reportEventName.HMI_HOME_CHECKCLINIC_CLICK,
                    timestamp: new Date().getTime(),
                });
            }
        } else if (component === 'IntelliDriving' && component === checkObj) {
            cancelHotReport();
        } else if (component === 'routeChoose') {
            jsButtonCallBack(component);
            cancelHotReport();
        }
        setChecked(component === checkObj ? null : component);
    };
    const handleCancel = (item) => {
        if (item === 'close') {
            cancelHotReport();
            setChecked(null);
        }
    };
    window.routeCancelCallback = function () {
        routeModalCancel = true;
        handleCancel('close');
    };

    // 取消热区上报
    const cancelHotReport = () => {
        dispatch({
            type: HOT_AREA_TYPE.settingModalDom,
            data: '',
        });
        dispatch({
            type: HOT_AREA_TYPE.testAssistantDom,
            data: '',
        });
    };

    useEffect(() => {
        switchModeState && siderBarMethods(switchModeState);
    }, [switchModeState]);

    const siderBarMethods = (item) => {
        let data;
        if (item === 'userMode') {
            data = [
                {
                    IconFont: '\ue670',
                    component: 'IntelliDriving',
                },
            ];
            setSlider(data);
        } else {
            data = [
                {
                    IconFont: '\ue671',
                    component: 'parking',
                },
                {
                    IconFont: '\ue631',
                    component: 'workbench',
                },
                // {
                //   IconFont: '\ue66e',
                //   component: 'routeChoose',
                // },
                {
                    IconFont: '\ue670',
                    component: 'IntelliDriving',
                },
                {
                    IconFont: '\ue66d',
                    component: 'routeTestAssistant',
                },
            ];
            setSlider(data);
        }
    };

    const setOutHand = async (v) => {
        FM.sendToHMIDriving(
            SocketMap.handsOffDriveMode,
            {
                state: v === 'ACTIVE' ? 'OFF' : 'ACTIVE',
            },
            'hands_off_drive_mode',
        );
    };

    return (
        <div className="slider" ref={sliderDom}>
            <div className="slider-top">
                <SystemTime />
                {switchModeState === 'testMode' && (
                    <>
                        <div className="slider-item">
                            <p className="position-num">{position_state || '--'}</p>
                            <p className="position-title">Accuracy</p>
                        </div>
                        {/*<div className={`slider-item ${checkObj=== "routeChoose" ?'active':''}`} onClick={()=> handleTap("routeChoose")}>
                                <span className='iconfont iconfont-slider'>{'\ue668'}</span>
                            </div>*/}
                    </>
                )}
                <Reconnection />
                {outHandShow === 'ACTIVE' && (
                    <div
                        className={`slider-item line hands-off ${outHand === 'ACTIVE' ? 'active' : ''}`}
                        onClick={() => setOutHand(outHand)}
                    >
                        <span className="iconfont iconfont-slider">{'\ue667'}</span>
                    </div>
                )}
            </div>
            <div className="slider-center">
                <div className="slider-item follow-distance">
                    <FollowDistance />
                </div>
            </div>
            <div id="slider-bottom-icon" className="slider-bottom">
                {siderBarData.length &&
                    siderBarData.map((item, index) => {
                        if ((Mtour || !switchWorkBench || !isCN()) && 'workbench' === item.component) {
                            return null;
                        }
                        if (!isCN() && item.component === 'parking') return null;
                        return (
                            <div
                                key={index}
                                className={`slider-item ${checkObj === item.component ? 'active' : ''}`}
                                onClick={() => handleTap(item.component)}
                            >
                                <span className="iconfont iconfont-slider">{item.IconFont}</span>
                            </div>
                        );
                    })}
            </div>
            {checkObj === 'workbench' && <WorkBench onCancel={handleCancel}></WorkBench>}

            {checkObj === 'IntelliDriving' && <SmartDrivingSetting onCancel={handleCancel}></SmartDrivingSetting>}
            {checkObj === 'routeTestAssistant' && <TestAssistant onCancel={handleCancel}></TestAssistant>}
        </div>
    );
}
