const INIT_STATE = {
    currentState: null,
    carPosition: null,
 };
 
 export default function func(state = INIT_STATE, action) {
    // console.log('duan_test', action);
     switch (action.type) {  
        case 'currentState': 
            return {
                ...state,
                ...{
                    currentState: action.data
                }
            }
        case 'drivingModeChange':
            return {
                ...state,
                ...{
                     drivingModeChange: action.data
                }
            }
        case 'carPosition': 
            return {
                ...state,
                ...{
                    carPosition: action.data
                }
            }     
        default:
             return state;
     }
 }