import {useSelector} from 'react-redux';
import {SocketMap} from '@/constant/socketMap';
import FM from "@/utils/nativeSubAndPub";
import {cusToast} from '@/components/customToast';
import {useTranslation} from 'react-i18next';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function useLKASettingCommon(props) {
    const {t: translate} = useTranslation();
    const ldw_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.lka_switch?.ldw_switch);
    const ldp_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.lka_switch?.ldp_switch);
    const sensitive_level = useSelector(state => state?.featureModelDriving?.switch?.adas?.lka_switch?.sensitive_level) || '2';
    const warning_type = useSelector(state => state?.featureModelDriving?.switch?.adas?.lka_switch?.warning_type);

    /*const judgeVibrationAndWarning = (warning_type) => {
        let VibrationSwitch = false
        let WarningSwitch = false
        if (warning_type) {
            if (warning_type === 'BOTH') {
                VibrationSwitch = true
                WarningSwitch = true
            } else if (warning_type === 'AUDIO') {
                VibrationSwitch = false
                WarningSwitch = true
            } else if (warning_type === 'HAPTIC') {
                VibrationSwitch = true
                WarningSwitch = false
            } else {
                VibrationSwitch = false
                WarningSwitch = false
            }
        }
        return {
            VibrationSwitch,
            WarningSwitch
        }
    }*/

    // switchChange: 方向盘震动、声音警告、LDW、LDP、ELK  trueValue代表的是方向盘警告的值，或者是声音警告的值
    const switchChange = (flag, value, setCallback, trueValue) => {
        if (flag === 'LDW') {
            props?.timer && clearTimeout(props?.timer)
            props?.setLoading && props?.setLoading(true)
            const timer = setTimeout(() => {
                props?.setLoading(false)
                setCallback(ldw_switch === 'OPEN')
            }, 2 * 1000)
            props?.setTimer(timer)
            adasControl({
                LDWSwitch: value ? "OPEN" : "CLOSE",
                LDPSwitch: ldp_switch,
                sensiChange: sensitive_level,
                warningType: warning_type,
            });
            // 数据上报
            reportDataFunc({
                value: value ? "OPEN" : "CLOSE",
                repName:reportEventName.HMI_LDW_SWITCH_CLICK,
                flag,
            })
        } else if (flag === 'LDP') {
            props?.timer && clearTimeout(props?.timer)
            const timer = setTimeout(() => {
                setCallback(ldp_switch === "OPEN");
            }, 2 * 1000)
            props?.setTimer(timer)
            adasControl({
                LDWSwitch: ldw_switch,
                LDPSwitch: value ? "OPEN" : "CLOSE",
                sensiChange: sensitive_level,
                warningType: warning_type,
            });
            // 数据上报
            reportDataFunc({
                value: value ? "OPEN" : "CLOSE",
                flag,
                repName:reportEventName.HMI_LDP_SWITCH_CLICK,
            })
        }
    }

    // adasControl: 方向盘震动、声音警告、LDW、LDP 通知底层
    const adasControl = async (params) => {
        const {LDWSwitch, LDPSwitch, sensiChange, warningType} = params;
        FM.sendToHMIDriving(SocketMap.adasControl, {
            ldw_switch: LDWSwitch,
            ldp_switch: LDPSwitch,
            sensitive_level: sensiChange,
            warning_type: warningType,

        },'drive_node_res_fail_cb');
    }
    // 数据上报
    const reportDataFunc = (params) =>{
        const {value, repName, flag} = params;
        let dataObj = {};
        dataObj[flag] = value;
        singelPointReport({
            eventName: repName,
            timestamp: new Date().getTime(),
            reportData: dataObj,
        })
    }


    return {
       /* judgeVibrationAndWarning,*/
        switchChange,
        adasControl
    }

}
