import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import store from '@/store/index';
import { PARKING_LPNP_EXIT } from '@/store/action/parkingType';
import ControllTips from '@/assets/parking/get_control_tips.png';
import Loading from '../loading/index';
import Modal from '../modal/index';
import './index.less';
class QuitStatusClass {
    constructor(curS) {
        this.curStatus = curS;
        this.preStatus = '';
    }
    changeStatus(s) {
        this.preStatus = this.curStatus;
        this.curStatus = s;
    }
}

export default function ErrorTips() {
    const lpnpExit = useSelector((state) => state?.parking?.lpnpExit);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const [isShow, setIsShow] = useState(false);
    const objRef = useRef(null);
    const exitRef = useRef(null);

    useEffect(() => {
        objRef.current = new QuitStatusClass();
    }, []);

    useEffect(() => {
        let statusObj = objRef?.current;
        statusObj.changeStatus(status);
    }, [status]);

    useEffect(() => {
        if (!lpnpExit) return;
        // setIsShow(true);
        Loading.hide();
        Modal.clear();
        setTimeout(() => {
            exitRef.current = false;
            setIsShow(false);
            store.dispatch({
                type: PARKING_LPNP_EXIT.lpnpExit,
                data: false,
            });
        }, 2000);
    }, [lpnpExit]);

    return (
        <>
            {isShow ? (
                <div className="tipsItem">
                    <img src={ControllTips}></img>
                    <p>Please take over immediately</p>
                </div>
            ) : (
                ''
            )}
        </>
    );
}
