import { WIDGET_ACTION } from '../action/type';

const INIT_STATE_WIDGET2 = {
    level: null,
    list: []
};

export default function widget2Data(state = INIT_STATE_WIDGET2, action) {
    switch (action.type) {
        case WIDGET_ACTION.widget2Action:
            return { ...state, ...action.data };
        default:
            return state;
    }
}