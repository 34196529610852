import { useEffect } from 'react';
import store from '@/store/index';
import { MVIZ_APA_PARKING_DATA } from '@/store/action/parkingType';
import localAudioNames from '@/constant/localAudioName';
import logSend from '@/utils/aLog';

import { AppFn } from '@/utils/native';
const { playLocalAudio } = AppFn;

export default function useSetMvizSelect() {
    const notHandleMsg = (obj) => {
        return !obj?.hasOwnProperty('mviz_msg');
    };

    useEffect(() => {
        let momenta_mviz_msg = function (data) {
            if (data instanceof MessageEvent) {
                // 浏览器端处理逻辑
                if (notHandleMsg(data?.data)) return;
                store.dispatch({
                    type: MVIZ_APA_PARKING_DATA.mvizData,
                    data: data?.data,
                });
            } else {
                // mviz消息处理逻辑
                logSend({
                    action: 'mviz_to_hmi_msg',
                    data,
                });
                try {
                    let MvizMsg = JSON.parse(data?.mviz_msg);
                    store.dispatch({
                        type: MVIZ_APA_PARKING_DATA.mvizData,
                        data: MvizMsg,
                    });
                    if (MvizMsg?.is_auto_selected)
                        return playLocalAudio(localAudioNames.LPNP_CAR_SITE_HAS_SELECTED, 'Normal');
                    playLocalAudio(localAudioNames.SELECT_CAR_SITE_D, 'Normal');
                } catch (error) {
                    store.dispatch({
                        type: MVIZ_APA_PARKING_DATA.mvizData,
                        data: {},
                    });
                }
            }
        };
        window.notifyPickedParkingSpace = function (data) {
            logSend({
                action: 'selectCarSite',
                data,
            });
            store.dispatch({
                type: MVIZ_APA_PARKING_DATA.mvizData,
                data: {
                    num: data.code,
                    track_id: data.track_id,
                },
            });
            playLocalAudio(localAudioNames.SELECT_CAR_SITE_D, 'Normal');
        };
        window['momenta_mviz_msg'] = momenta_mviz_msg;
        window.addEventListener('message', momenta_mviz_msg, false);
    }, []);
}
