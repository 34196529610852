import React, {useState, useEffect} from "react";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import useFcwSettingCommon from "../FCWSettingCommon";
import "./index.less";

let timer = null;
export default function CustomFcwCard() {
    const dispatch = useDispatch();
    const flag = useSelector(state => state?.smartDrivingSettingMsg?.flag);
    const {t: translate} = useTranslation();
    let sensiOptions = translate('AEBFCW.sensiOptions', {returnObjects: true});
    const [sensiFCWChange, setSensiFCWChange] = useState(null);
    const fcw_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.fcw?.fcw_switch);
    const fcw_sensitive_level = useSelector(state => state?.featureModelDriving?.switch?.adas?.fcw?.fcw_sensitive_level);

    const {FCWControl} = useFcwSettingCommon()

    useEffect(() => {
        let fcw_level = '';
        if (['1', '2', '3'].indexOf(fcw_sensitive_level) > -1) {
            fcw_level = fcw_sensitive_level;
        } else {
            fcw_level = '2';
            handleSensiFCWMode(fcw_level)
        }
        fcw_level && setSensiFCWChange(fcw_level);
        if (timer) {
            clearTimeout(timer)
            timer = null
        }
    }, [fcw_sensitive_level])

    // change: FCW 灵敏度
    const handleSensiFCWMode = (value, isClick) => {
        if (fcw_switch !== "OPEN") {
            if (isClick && isClick === "click") {
                const text = translate('LKASetting.onFCWFirst')
                dispatch({
                    type: "setUpMsg",
                    data: {
                        tooltipMsg: text,
                        flag: !flag
                    }
                })
            }
            return
        }
        clearTimeout(timer)
        timer = setTimeout(() => {
            let fcw_level = '';
            if (['1', '2', '3'].indexOf(fcw_sensitive_level) > -1) {
                fcw_level = fcw_sensitive_level;
            } else {
                fcw_level = '2';
            }
            setSensiFCWChange(fcw_level);
        }, 2 * 1000)
        FCWControl({
            'fcw_switch': fcw_switch,
            'fcw_sensitive_level': value,
        });
    }

    return (
        <div className={`custom_fcw_card_wrap ${fcw_switch === "OPEN" ? "" : "custom_fcw_not_active"}`}>
            <div className={"title_wrap"}>{translate('AEBFCW.customFcw')}</div>

            <div className={"sensitivity_wrap"}>
                <p className='subtitle_wrap'>{translate('AEBFCW.sensiFCWChange')}</p>
                <div className={"select_box"}>
                    {
                        sensiOptions.length && sensiOptions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${sensiFCWChange === item.value ? 'active' : ''}`}
                                    onClick={() => handleSensiFCWMode(item.value, "click")}
                                >
                                    {item.label}
                                </span>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
