/**
 * 变道相关
 * */
import {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux'
import localAudioNames from '@/constant/localAudioName';
import WidgetComponent from '@/components/widget/index';
import {widgetQueue, widgetType} from '@/utils/widgetQueue';
import {AppFn} from '@/utils/native';
import {useTranslation} from 'react-i18next';
import {batchReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
const { playLocalAudio } = AppFn

// 变道向左向右icon
const directionEum = {
    'LEFT': 'blue_left',
    'RIGHT': 'blue_right',
}
// 确认式语音
const confirmAudioEum = {
    // "LEFT": localAudioNames.DEMO_CONFIRM_LANE_CHANGE_LEFT,
    // "RIGHT": localAudioNames.DEMO_CONFIRM_LANE_CHANGE_RIGHT,
    "LEFT": "",
    "RIGHT": "",
}
// trigger Reason 语音
const triggerAudioEum = {
    "ON_RAMP": localAudioNames.ON_RAMP, // 0：进匝道
    "OFF_RAMP": localAudioNames.OFF_RAMP, // 1：出匝道汇入主路
    "AVOID_CONFLUENCE_AREA": localAudioNames.AVOID_CONFLUENCE_AREA, // 2：避开汇流区
    "OVERTAKING_LANE_CHANGE": localAudioNames.OVERTAKING_LANE_CHANGE, //3：超车变道
    "DRIVE_BETTER_LANE": localAudioNames.DRIVE_BETTER_LANE, // 4：驶入更优车道
};

export default function LaneChangeComponet() {
    // 正在运行的智驾状态
    const running_mode = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const widget_turn_light = useSelector(state => state?.featureModelDriving?.laneChange);
    const turn_light_notice = useSelector(state => state?.featureModelDriving?.laneChange?.notice);
    const lane_change_type = turn_light_notice?.data?.change_type;
    const lane_change_direction = turn_light_notice?.data?.direction;
    const lane_change_state = turn_light_notice?.data?.state;
    // running_mode为CP时才有值
    const cp_autonomous_switch = useSelector(state => state?.featureModelDriving?.switch?.driving?.lane_change_mode?.cp_autonomous_switch);
    const np_confirm_switch = useSelector(state => state?.featureModelDriving?.switch?.driving?.lane_change_mode?.np_confirm_switch);
    const lane_change_trigger_reason = turn_light_notice?.data?.trigger_reason_v2;
    const lane_change_on_ramp_count = turn_light_notice?.data?.on_ramp_count;
    // release_platform (MAIN HNP UNP CP)
    const release_platform = useSelector(state => state?.featureModelDriving?.baseData?.hmi?.release_platform);
    const box_id = useSelector(state => state?.featureModelDriving?.baseData?.body?.boxid);
    const lane_change_type_pre = useRef(lane_change_type);
    let warnningTimer = useRef(null);
    let pre_lane_change_on_ramp_count = null;
    let pre_lane_change_on_ramp_time = null;

    /**
     * M7.1 新消息结构体（变道、分汇流点、进入匝道口特殊情况）兼容2.2.0处理
     */
    // const widget = useSelector(state => state?.func?.currentState?.widget);
    // const widget_turn_light = widget?.turn_light;
    const widget_reason_type = widget_turn_light?.reason?.type;
    const widget_task_id = widget_turn_light?.taskId;
    const widget_change_type = widget_turn_light?.reason?.detail?.change_type;
    const widget_change_direction = widget_turn_light?.direction;
    const widget_change_state = widget_turn_light?.reason?.detail?.state;
    const widget_change_trigger_reason = widget_turn_light?.reason?.detail?.trigger_reason_v2;
    const widget_change_on_ramp_count = widget_turn_light?.reason?.detail?.on_ramp_count;
    const widget_change_confirm = widget_turn_light?.reason?.detail?.confirm?.has_confirmed;
    const widget_change_warning = widget_turn_light?.reason?.detail?.confirm?.warning;

    const pre_widget_turn_light = useRef({});
    // CP模式下推荐变道
    const widget_turn_light_recommend_direction = widget_turn_light?.turn_light_recommend?.direction;

    // 国际化处理
    const {t: translate} = useTranslation();

    const triggerReasonEum = translate("laneChangeWidget.triggerReasonEum",{returnObjects: true });
    /*const triggerReasonEum = {
        "ON_RAMP": "即将进入匝道",
        "OFF_RAMP": "即将汇入主路",
        "DRIVE_BETTER_LANE": "正在驶入更快车道",
        "AVOID_CART": "正在避开大车",
        "AVOID_CONFLUENCE_AREA": "正在避开汇流区"
    }*/

    // 上报变道信息
    useEffect(() => {
        if (typeof(widget_task_id) === 'undefined') {
            if (typeof(pre_widget_turn_light?.current?.taskId) === 'undefined') return;
            batchReport({
                eventName: widget_reason_type=== "TURN_LIGHT_LANE_MERGE_SPLIT" ? reportEventName.HMI_MERGE_SIGNAL_VIEW : reportEventName.HMI_LANE_CHANGE_VIEW,
                timestamp: new Date().getTime(),
                text: 'laneChangeAbort',
                reportData: {
                    task_id: box_id + '_' + pre_widget_turn_light?.current?.taskId
                }
            })
        }
        else {
            if(widget_reason_type=== "TURN_LIGHT_LANE_MERGE_SPLIT"){
                // HMI_merge_signal_view
                batchReport({
                    eventName: reportEventName.HMI_MERGE_SIGNAL_VIEW,
                    timestamp: new Date().getTime(),
                    reportData: {
                        direction: widget_change_direction,
                        task_id: box_id + '_' + widget_turn_light?.taskId
                    }
                })
            } else{
                batchReport({
                    eventName: reportEventName.HMI_LANE_CHANGE_VIEW,
                    timestamp: new Date().getTime(),
                    reportData: {
                        ...widget_turn_light,
                        task_id: box_id + '_' + widget_turn_light?.taskId
                    }
                })
            }
        }
        pre_widget_turn_light.current = {...widget_turn_light};
    },[
        box_id,
        widget_task_id,
        widget_turn_light
    ])

    // 变道相关 语音处理
    useEffect(() => {
        if (release_platform === 'UNP') return
        if(typeof(lane_change_type) === 'undefined'
            && typeof(lane_change_direction) === 'undefined'
            && typeof(lane_change_state) === 'undefined'
            && typeof(lane_change_trigger_reason) === 'undefined'
            && typeof(lane_change_on_ramp_count) === 'undefined'
        ){
            return;
        }

        let audioFile = '';
        // 加锁
        // if(lane_change_pre.current === `${lane_change_type}+${lane_change_direction}+${lane_change_state}+${lane_change_trigger_reason}+${lane_change_on_ramp_count}`) return
        let trigger_audio = '', confirm_audio = '';
        // trigger reason audio
        trigger_audio = triggerAudioEum?.[lane_change_trigger_reason] || '';
        // 确认式语音
        confirm_audio = confirmAudioEum?.[lane_change_direction] || '';
        audioFile = triggerReasonAudioFunc({
            trigger_audio,
            'type': lane_change_type,
            confirm_audio
        });

        // 变道audio
        // if((lane_change_type_pre.current !== lane_change_type) && (lane_change_state === 'READY')){
        if(lane_change_state === 'READY'){
            audioFile && playLocalAudio(audioFile,'Normal','voice');
            // 0823 产品让去掉音效
            // playLocalAudio(localAudioNames.LANE_CHANGE_BEEP,'Normal')
            /*if(audioFile){
                setTimeout(()=>{
                    playLocalAudio(audioFile,'Normal','voice');
                },600)
            }*/

            // 0823产品变更逻辑只有确认式有音效
            if(np_confirm_switch === 'CONFIRM'){
                playLocalAudio(localAudioNames.LANE_CHANGE_BEEP,'Normal');
            }

            // CP主动超车语音提示
            if(cp_autonomous_switch === 'AUTONOMOUS_INITIALTIVE' && running_mode === 'CP' && lane_change_type === 'CAR_SELF' && audioFile){
                setTimeout(()=>{
                    playLocalAudio(audioFile,'Normal','voice');
                },600)
            }

        }else if(['MANUAL','NAVI','CAR_SELF'].indexOf(lane_change_type) === -1
            && ['ON_RAMP','OFF_RAMP'].includes(lane_change_trigger_reason) && audioFile){
            // 0823 产品让去掉音效
            // playLocalAudio(localAudioNames.LANE_CHANGE_BEEP,'Normal');
            /* setTimeout(()=>{
                 audioFile && playLocalAudio(audioFile,'Normal','voice');
             },600)*/
        }
        // lane_change_pre.current = `${lane_change_type}+${lane_change_direction}+${lane_change_state}+${lane_change_trigger_reason}+${lane_change_on_ramp_count}`;
        lane_change_type_pre.current = lane_change_type;
    }, [ lane_change_type,
        lane_change_direction,
        lane_change_state,
        np_confirm_switch,
        lane_change_trigger_reason,
        // lane_change_on_ramp_count,
        release_platform ]);


    // 确认式变道音效处理
    useEffect(() => {
        if (release_platform === 'UNP') return
        if (np_confirm_switch === 'CONFIRM' && widget_change_state !== 'DONE' && widget_change_type !== 'MANUAL') {
            clearInterval(warnningTimer.current);
            warnningTimer.current = null;
            if (widget_change_warning) {
                if (warnningTimer.current) return;
                warnningTimer.current = setInterval(() => {
                    playLocalAudio(localAudioNames.LANE_CHANGE_WARNING, 'Normal');
                }, 1000);
            }
        }
        if (
            ((typeof(widget_change_state) === 'undefined' && typeof(widget_change_type) === 'undefined')
                || widget_change_state === 'CHANGING'
                || widget_change_state === 'DONE') && warnningTimer.current
        ) {
            clearInterval(warnningTimer.current);
            warnningTimer.current = null;
        }
    }, [np_confirm_switch, widget_change_warning, widget_change_confirm, widget_change_state, widget_change_type, release_platform]);

    // CP模式下推荐变道展示widget和一次语音
    useEffect(() => {
        if (running_mode === 'CP' && cp_autonomous_switch === 'AUTONOMOUS_RECOMMEND' && widget_turn_light_recommend_direction) {
            // 方向图标：direction icon
            let directionIcon = directionEum?.[widget_turn_light_recommend_direction] || '';
            // 文案
            let recommendText = (`
                <div class="custom_toast-title">${translate("autoLaneChange.autoLaneChangeTitle")}</div>
                <div class="custom_toast-subtitle">${translate("laneChangeWidget.confirmText")}</div>
                `);
            let recommendContent =  {'icon': directionIcon, 'content': recommendText};
            widgetQueue({widgetNumber: 1, type: widgetType.SELF_CONFIRM, widgetContent: recommendContent})
            playLocalAudio(localAudioNames.LANE_CHANGE_BEEP,'Normal');
            batchReport({
                eventName: reportEventName.HMI_SUGGESTED_LANECHANGE_VIEW,
                timestamp: new Date().getTime(),
                reportData: {
                    direction: widget_turn_light_recommend_direction
                }
            })
        }
        else {
            widgetQueue({widgetNumber: 1, type: widgetType.SELF_CONFIRM, widgetContent: null})
        }
    }, [widget_turn_light_recommend_direction, running_mode, cp_autonomous_switch])

    // 变道相关 文案处理
    useEffect(() => {
        // 加锁
        // if(lane_change_pre.current === `${lane_change_type}+${lane_change_direction}+${lane_change_state}+${lane_change_trigger_reason}+${lane_change_on_ramp_count}`) return
        let all_text = '', directionIcon = '', trigger_reason_text = '',confirm_text = '', connect_text = '';
        // 方向图标：direction icon
        directionIcon = directionEum?.[widget_change_direction] || '';
        // trigger reason text
        trigger_reason_text = triggerReasonEum?.[widget_change_trigger_reason] || '';
        // 确认文案：confirm Text
        confirm_text = (np_confirm_switch === 'CONFIRM' && !widget_change_confirm) ? translate("laneChangeWidget.confirmText"): '';

        // 汇总文案：connect_text
        connect_text = connectTextFunc({
            trigger_reason_text,
            change_type: widget_change_type,
            reason_type: widget_reason_type,
            confirm_text
        });

        // 变道text
        if(directionIcon || connect_text){
            all_text =  {'icon': directionIcon, 'content': connect_text, 'priority':'highest'};
        }
        // 只有打灯原因为TURN_LIGHT_LANE_CHANGE的时候才处理undefined情况
        if (widget_reason_type && widget_reason_type === 'TURN_LIGHT_LANE_CHANGE') {
            if(typeof(widget_change_type) === 'undefined'
                && typeof(widget_change_direction) === 'undefined'
                && typeof(widget_change_state) === 'undefined'
                && typeof(widget_change_trigger_reason) === 'undefined'
                && typeof(widget_change_on_ramp_count) === 'undefined'
            ){
                all_text = '';
            }
        }
        else {
            if(typeof(widget_change_type) === 'undefined'
                && typeof(widget_change_direction) === 'undefined'
                && typeof(widget_change_state) === 'undefined'
                && typeof(widget_change_trigger_reason) === 'undefined'
                && typeof(widget_change_on_ramp_count) === 'undefined'
            ){
                all_text = '';
            }
        }
        // console.log(widget_reason_type, widget_change_type, widget_change_direction, widget_change_state, widget_change_trigger_reason, widget_change_on_ramp_count);
        widgetQueue({widgetNumber: 1, type: widgetType.LANE_CHANGE, widgetContent: all_text})

    }, [ widget_reason_type,
        widget_change_type,
        widget_change_direction,
        widget_change_state,
        widget_change_confirm,
        widget_change_trigger_reason,
        widget_change_on_ramp_count,
        np_confirm_switch
    ]);


    // 兼容2.2.0处理变道信息
    const laneChangeContext = (type, trigger_reason_text, confirm_text) => {
        let changeContext = '';
        if (['NAVI','CAR_SELF'].includes(type)) {
            // 默认的原因
            changeContext = trigger_reason_text;

            // NP模式下才有trigger_reason，如果是匝道的更换文案
            if (widget_change_trigger_reason === 'ON_RAMP') {
                changeContext = (`
                    <div class="custom_toast-title">${trigger_reason_text}</div>`
                );
            }

            // CP模式下如果开启了主动变道超车提示,CP下没有trigger_reason
            if (!widget_change_trigger_reason && type === 'CAR_SELF' && running_mode === 'CP' && cp_autonomous_switch === 'AUTONOMOUS_INITIALTIVE') {
                changeContext = translate("autoLaneChange.autoLaneChangeWidget");
            }

            // 如果是确认式变道
            if(np_confirm_switch === 'CONFIRM' && !widget_change_confirm){
                changeContext
                    ? (changeContext = (`
                <div class="custom_toast-title">${changeContext}</div>
                <div class="custom_toast-subtitle">${confirm_text}</div>`))
                    : changeContext = translate("laneChangeWidget.recommendLane");
            }
        }
        else {
            if(['ON_RAMP','OFF_RAMP'].includes(widget_change_trigger_reason)){
                let text = triggerReasonEum?.[widget_change_trigger_reason] || '';
                changeContext = `${text}`
            }
        }
        return changeContext;
    }

    // new: trigger reason: text
    const connectTextFunc = (params) =>{
        const {trigger_reason_text, change_type, confirm_text, reason_type} = params;
        let connect_text = '';
        // 2.3.0才有reason_type
        if (reason_type) {
            // 打灯原因拆分
            switch (reason_type) {
                // 0823 产品逻辑去掉
                // 车道分汇流
                case 'TURN_LIGHT_LANE_MERGE_SPLIT':
                    connect_text = '';
                    // connect_text = translate("laneChangeWidget.reasonMergeSplit");
                    break;
                /*// 进入匝道口时,特殊处理
                case 'TURN_LIGHT_ENTER_RAMP':
                    connect_text = translate("laneChangeWidget.reasonEnterRamp");
                    break;
                // 出匝道口
                case 'TURN_LIGHT_OUT_RAMP':
                    connect_text = translate("laneChangeWidget.reasonOutRamp");
                    break;*/
                // 正常变道打灯
                case 'TURN_LIGHT_LANE_CHANGE':
                    connect_text = laneChangeContext(change_type, trigger_reason_text, confirm_text);
                    break;
                default :
                    break;
            }
        }
        else {
            connect_text = laneChangeContext(change_type, trigger_reason_text, confirm_text);
        }
        return connect_text;
    };

    //new trigger reason: audio
    const triggerReasonAudioFunc = (params) =>{
        const { type, trigger_audio, confirm_audio} = params;
        let audioFile = '';
        if(np_confirm_switch === 'CONFIRM'){
            audioFile = confirm_audio;
        }else if(type === 'NAVI'){
            audioFile = trigger_audio;
            /*if(lane_change_trigger_reason === 'ON_RAMP'){
                if (lane_change_on_ramp_count > 2) {
                    audioFile = localAudioNames.LANE_CHANG_TRIGGER_REASON_MANY;
                    /!*let now = new Date().getTime();
                    // 7s 内只播一次
                    if (pre_lane_change_on_ramp_time === null){
                        pre_lane_change_on_ramp_time = new Date().getTime();
                        audioFile = localAudioNames.LANE_CHANG_TRIGGER_REASON_MANY;
                    }
                    if ( now - pre_lane_change_on_ramp_time < 7000) {
                        if(pre_lane_change_on_ramp_count > lane_change_on_ramp_count) audioFile = '' // audioFile
                    } else {
                        audioFile = localAudioNames.LANE_CHANG_TRIGGER_REASON_MANY;
                    }
                    if(lane_change_on_ramp_count >= pre_lane_change_on_ramp_count)  audioFile = localAudioNames.LANE_CHANG_TRIGGER_REASON_MANY;*!/

                } else if (lane_change_on_ramp_count === 2) {
                    audioFile = localAudioNames.LANE_CHANG_TRIGGER_REASON;
                }
                pre_lane_change_on_ramp_count = lane_change_on_ramp_count;
            }else if(lane_change_trigger_reason === 'OFF_RAMP'){
                audioFile = trigger_audio;
            }*/
        }else if(type === 'CAR_SELF'){
            // CP模式下如果开启了主动变道超车提示
            audioFile = (running_mode === 'CP' && cp_autonomous_switch === 'AUTONOMOUS_INITIALTIVE') ? localAudioNames.CP_AUTONOMOUS_SWITCH : trigger_audio;
        }else{
            /*if(['ON_RAMP','OFF_RAMP'].includes(lane_change_trigger_reason)){
                audioFile = trigger_audio;
            }*/
        }
        return audioFile;
    };

    return (
        <>
            <WidgetComponent />
        </>)
}
