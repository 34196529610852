import { useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import {cusToast} from '@/components/customToast';
// import AdasLimit from '@/assets/hnp/adas/limit.png';
// import AdasRelieve from '@/assets/hnp/adas/relieve.png';
import {useTranslation} from 'react-i18next';
import {batchReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

import './index.less';

let timeDuration = 0;  // SLIF结束后上报SLIF持续时间
function SlifLayoutComponent() {
    const slif_state = useSelector(state => state?.featureModelDriving?.slif?.state);
    const speed_limit = useSelector(state => state?.featureModelDriving?.slif?.speedLimit);
    const notice_slif_notice = useSelector(state => state?.featureModelDriving?.slif?.notice?.data?.warning);
    const {t: translate} = useTranslation();

    /*useEffect(()=>{
        if(notice_slif_notice){
            cusToast.show({
                content:translate("SLIFTSR.controlVehicleSpeed"),
            });
        }

    },[notice_slif_notice])*/
    const SlifIcon = (status,speed) => {
        if (!speed) {
            return <></>
        }
        return (
            <div className={`adas-tips ${status}`}>
               <span>{speed || '--'}</span>
            </div>
        )
    }

    const StatusElement = (status) => {
        switch(status) {
            case 'NORMAL':
            case 'WARNING':
                return <div className={"slif_icon_wrap"}>{SlifIcon(status,speed_limit)}</div>;
            case 'RELIEVE':
            case 'CLOSE':
                return <></>;
            default:
                return <></>;
        }
    }
    // 数据上报
    const reportDataFunc = (eventName, text) =>{
        batchReport({
            eventName: eventName,
            timestamp: new Date().getTime(),
            text: text,
        })
    }
    useEffect(()=>{
        if(slif_state === "WARNING"){
            timeDuration = new Date().getTime();
            reportDataFunc(reportEventName.HMI_SLIF_VIEW, "SLIFWarning");
        } else {
            if (timeDuration > 0) {
                const currentTime = new Date().getTime();
                const duration = (currentTime - timeDuration) + "";
                reportDataFunc(reportEventName.HMI_SLIF_duration, duration);
                timeDuration = 0;
            }
        }
    },[slif_state,speed_limit])

    return (
        <div className={"slif-Container"}>
            {StatusElement(slif_state)}
        </div>
    );
}
export default memo(SlifLayoutComponent)
