import setD from '@/assets/parking/d_new/tool/set_d.png';
import set from '@/assets/parking/d_new/tool/set.png';
import debug from '@/assets/parking/d_new/tool/debug.png';
import debugD from '@/assets/parking/d_new/tool/debug_d.png';
import workbench from '@/assets/parking/d_new/tool/workbench.png';
import workbenchD from '@/assets/parking/d_new/tool/workbench_d.png';

import { useTranslation } from 'react-i18next';

export const ParkingLayoutConfig = {
    mapArea: '0.0',
    mVizArea: '100.0',
    verticalMapArea: '0.0',
    verticalMvizArea: '100.0',
    scene: 'parking',
};

export const ToolMap = () => {
    const { t: translate } = useTranslation();
    return [
        {
            name: 'set',
            iconD: setD,
            icon: set,
            txt: translate('parking.setting.setingText'),
            id: 1000,
        },
        {
            name: 'lpnp',
            icon: debug,
            iconD: debugD,
            txt: 'LPNP',
            id: 3000,
        },
        {
            name: 'apa',
            icon: debug,
            iconD: debugD,
            txt: 'APA',
            id: 4000,
        },
        {
            name: 'workbench',
            icon: workbench,
            iconD: workbenchD,
            txt: '',
            id: 5000,
        },
    ];
};

export const TOAST_TIME = 3000;
