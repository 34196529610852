import React, {useEffect, useState } from "react";
import {widgetIcon} from "@/constant/widget";
import {useSelector, useDispatch} from "react-redux";
import { WIDGET_ACTION } from '@/store/action/type';
import "./index.less";

export default function WidgetComponent() {
    const dispatch = useDispatch();
    // store中存储的widget数据
    const widgetQueueList = useSelector(state => state?.widget1Data?.list);
    // store中存储的等级
    const storeWidgetLevel = useSelector(state => state?.widget1Data?.level);
    const [showWidgetIcon, setShowWidgetIcon] = useState('');
    const [showWidgetContent, setShowWidgetContent] = useState('');

    useEffect(() => {
        if (storeWidgetLevel !== null && widgetQueueList.length !== 0) {
            const {icon, content} = Object.values(widgetQueueList.shift())[0];
            setShowWidgetIcon(icon);
            setShowWidgetContent(content);
        }
        else {
            setShowWidgetIcon('');
            setShowWidgetContent('');
        }
    }, [widgetQueueList, storeWidgetLevel])

    useEffect(() => {
        // 离开组件后，进行数据复位
        return () => {
            dispatch({
                type: WIDGET_ACTION.widget1Action,
                data: {
                    level: null,
                    list: [],
                }
            })
        }
    }, [])

    return (
        <>
            {
                (showWidgetContent || showWidgetIcon) && (
                    <div className={`widget-class`}>
                        <div className={`widget-class-wrap ${showWidgetIcon ? "icon-show" : ""}`}>
                            {widgetIcon?.[showWidgetIcon] && <img alt="" src={widgetIcon?.[showWidgetIcon]}/>}
                            {showWidgetContent && <div className="text-box" dangerouslySetInnerHTML={{__html: showWidgetContent}}></div>}
                        </div>
                    </div>
                )}
            {
                !(showWidgetContent || showWidgetIcon) &&
                (
                    <>
                    </>
                )}
        </>

    )
}
