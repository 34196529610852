import { WIDGET_ACTION } from '../action/type';

const INIT_STATE_WIDGET3 = {
    list: []
};

export default function widget3Data(state = INIT_STATE_WIDGET3, action) {
    switch (action.type) {
        case WIDGET_ACTION.widget3Action:
            return { ...state, ...action.data };
        default:
            return state;
    }
}
