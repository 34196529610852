/**
 * 变道相关
 * */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import localAudioNames from '@/constant/localAudioName';
import WidgetComponent from '@/components/widget/index';
import { widgetQueue, widgetType } from '@/utils/widgetQueue';
import { AppFn } from '@/utils/native';
import { useTranslation } from 'react-i18next';
import { batchReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
const { playLocalAudio } = AppFn;

// 变道向左向右icon
const directionEum = {
    LEFT: 'blue_left',
    RIGHT: 'blue_right',
};
// 确认式语音
const confirmAudioEum = {
    UNKNOWN_LEFT: localAudioNames.CONFIRM_UNKNOWN_LEFT, // 1：未知变道类型向左
    UNKNOWN_RIGHT: localAudioNames.CONFIRM_UNKNOWN_RIGHT, // 1：未知变道类型向右
    MAIN_ROAD_NAVI_LEFT: localAudioNames.CONFIRM_NAVI_LEFT, // 3：主路导航变道---进匝道提前变道向左
    MAIN_ROAD_NAVI_RIGHT: localAudioNames.CONFIRM_NAVI_RIGHT, // 3：主路导航变道---进匝道提前变道向右
    CONFLUENCE_NAVI_LEFT: localAudioNames.CONFIRM_CONFLUENCE_NAVI_LEFT, // 5：汇流导航变道，汇入主路--汇入主路向左
    CONFLUENCE_NAVI_RIGHT: localAudioNames.CONFIRM_CONFLUENCE_NAVI_RIGHT, // 5：汇流导航变道，汇入主路--汇入主路向右
    WITHIN_RAMP_NAVI_LEFT: localAudioNames.CONFIRM_NAVI_LEFT, // 6：匝道内导航变道-- 匝道导航变道向左
    WITHIN_RAMP_NAVI_RIGHT: localAudioNames.CONFIRM_NAVI_RIGHT, // 6：匝道内导航变道-- 匝道导航变道向右
    CONGESTION_NAVI_LEFT: localAudioNames.CONFIRM_NAVI_LEFT, // 7：拥堵导航变道 --- 拥堵变道向左
    CONGESTION_NAVI_RIGHT: localAudioNames.CONFIRM_NAVI_RIGHT, // 7：拥堵导航变道 --- 拥堵变道向右
    BIG_CAR_OVERTAKING_LEFT_LEFT: localAudioNames.CONFIRM_OVERTAKING_LEFT, // 8：大车向左超车变道---向左超越大车
    BIG_CAR_OVERTAKING_RIGHT_RIGHT: localAudioNames.CONFIRM_OVERTAKING_RIGHT, // 9：大车向右超车变道---向右超越大车
    SMALL_CAR_OVERTAKING_LEFT_LEFT: localAudioNames.CONFIRM_OVERTAKING_LEFT, // 10：小车向左超车变道--向左超车
    SMALL_CAR_OVERTAKING_RIGHT_RIGHT: localAudioNames.CONFIRM_OVERTAKING_RIGHT, // 11：小车向右超车变道---向右超车
    CONTINUATION_OVERTAKING_LEFT: localAudioNames.CONFIRM_OVERTAKING_LEFT, // 12：AL2 超车变道---向左连续超车
    CONTINUATION_OVERTAKING_RIGHT: localAudioNames.CONFIRM_OVERTAKING_RIGHT, // 12：AL2 超车变道---向右
    STATIC_OVERTAKING_LEFT: localAudioNames.CONFIRM_OVERTAKING_LEFT, // 13：静止车超车变道---避让静止车向左
    STATIC_OVERTAKING_RIGHT: localAudioNames.CONFIRM_OVERTAKING_RIGHT, // 13：静止车超车变道---避让静止车向右
    AVOID_CONFLUENCE_AREA_LEFT: localAudioNames.CONFIRM_CONFLUENCE_AREA_LEFT, // 14：避让汇流点变道 (规避汇流点、高速匝道入口)--- 提前避让汇流区
    AVOID_CONFLUENCE_AREA_RIGHT: localAudioNames.CONFIRM_CONFLUENCE_AREA_RIGHT, // 14：避让汇流点变道 (规避汇流点、高速匝道入口)--- 提前避让汇流区
};
// trigger Reason 语音
const triggerAudioEum = {
    AVOID_CART: localAudioNames.AVOID_CART, // 避开大车（triggerV2)
    OVERTAKING_LANE_CHANGE: localAudioNames.OVERTAKING_LANE_CHANGE, // 超车变道(triggerV2)
    NO_LANE_CHANGE: '', // 0：无变道
    UNKNOWN: localAudioNames.DRIVE_BETTER_LANE, // 1：未知变道类型
    MANUAL: '', // 2：交互式变道
    MAIN_ROAD_NAVI: localAudioNames.MAIN_ROAD_NAVI, // 3：主路导航变道---进匝道提前变道
    SHUNT_NAVI: localAudioNames.SHUNT_NAVI, // 4：分流导航变道，右1 分流进匝道--进匝道
    CONFLUENCE_NAVI: localAudioNames.CONFLUENCE_NAVI, // 5：汇流导航变道，汇入主路--汇入主路
    WITHIN_RAMP_NAVI: localAudioNames.WITHIN_RAMP_NAVI, // 6：匝道内导航变道-- 匝道导航变道
    CONGESTION_NAVI: localAudioNames.CONGESTION_NAVI, // 7：拥堵导航变道 --- 拥堵变道
    BIG_CAR_OVERTAKING_LEFT: localAudioNames.BIG_CAR_OVERTAKING_LEFT, // 8：大车向左超车变道---向左超越大车
    BIG_CAR_OVERTAKING_RIGHT: localAudioNames.BIG_CAR_OVERTAKING_RIGHT, // 9：大车向右超车变道---向右超越大车
    SMALL_CAR_OVERTAKING_LEFT: localAudioNames.SMALL_CAR_OVERTAKING_LEFT, // 10：小车向左超车变道--向左超车
    SMALL_CAR_OVERTAKING_RIGHT: localAudioNames.SMALL_CAR_OVERTAKING_RIGHT, // 11：小车向右超车变道---向右超车
    CONTINUATION_OVERTAKING: localAudioNames.CONTINUATION_OVERTAKING, // 12：AL2 超车变道---向左连续超车
    STATIC_OVERTAKING: localAudioNames.STATIC_OVERTAKING, // 13：静止车超车变道---避让静止车
    AVOID_CONFLUENCE_AREA: localAudioNames.ADVANCE_AVOID_CONFLUENCE_AREA, // 14：避让汇流点变道 (规避汇流点、高速匝道入口)--- 提前避让汇流区
};

export default function LaneChangeComponet() {
    // 正在运行的智驾状态
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const widget_turn_light = useSelector((state) => state?.featureModelDriving?.laneChange);
    const turn_light_notice = useSelector((state) => state?.featureModelDriving?.laneChange?.notice);
    const lane_change_type = turn_light_notice?.data?.change_type;
    const lane_change_direction = turn_light_notice?.data?.direction;
    const lane_change_state = turn_light_notice?.data?.state;
    // running_mode为CP时才有值
    const cp_autonomous_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.lane_change_mode?.cp_autonomous_switch,
    );
    const np_confirm_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.lane_change_mode?.np_confirm_switch,
    );
    const lane_change_trigger_reason = turn_light_notice?.data?.trigger_reason;
    const lane_change_on_ramp_count = turn_light_notice?.data?.on_ramp_count;
    // release_platform (MAIN HNP UNP CP)
    const release_platform = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.release_platform);
    const box_id = useSelector((state) => state?.featureModelDriving?.baseData?.body?.boxid);
    const lane_change_type_pre = useRef(lane_change_type);
    let warnningTimer = useRef(null);
    let pre_lane_change_on_ramp_count = null;
    let pre_lane_change_on_ramp_time = null;

    /**
     * M7.1 新消息结构体（变道、分汇流点、进入匝道口特殊情况）兼容2.2.0处理
     */
    // const widget = useSelector(state => state?.func?.currentState?.widget);
    // const widget_turn_light = widget?.turn_light;
    const widget_reason_type = widget_turn_light?.reason?.type;
    const widget_task_id = widget_turn_light?.taskId;
    const widget_change_type = widget_turn_light?.reason?.detail?.change_type;
    const widget_change_direction = widget_turn_light?.direction;
    const widget_change_state = widget_turn_light?.reason?.detail?.state;
    const widget_change_trigger_reason = widget_turn_light?.reason?.detail?.trigger_reason;
    const widget_change_on_ramp_count = widget_turn_light?.reason?.detail?.on_ramp_count;
    const widget_change_is_tip = widget_turn_light?.reason?.detail?.confirm?.is_tip;
    const widget_change_fail_reason = widget_turn_light?.reason?.detail?.fail_reason;

    const pre_widget_turn_light = useRef({});
    // CP模式下推荐变道
    const widget_turn_light_recommend_direction = widget_turn_light?.turn_light_recommend?.direction;
    // 智驾播报模式
    const switchDrivingBroadcast = useSelector((state) => state?.switchModeAction?.switchDrivingBroadcast);

    // 国际化处理
    const { t: translate } = useTranslation();

    const triggerReasonEum = translate('laneChangeWidget.triggerReasonV3Eum', { returnObjects: true });
    const confirmFailReasonEum = translate('laneChangeWidget.confirmFailReasonEum', { returnObjects: true });

    // 上报变道信息
    useEffect(() => {
        if (typeof widget_task_id === 'undefined') {
            if (typeof pre_widget_turn_light?.current?.taskId === 'undefined') return;
            batchReport({
                eventName:
                    widget_reason_type === 'TURN_LIGHT_LANE_MERGE_SPLIT'
                        ? reportEventName.HMI_MERGE_SIGNAL_VIEW
                        : reportEventName.HMI_LANE_CHANGE_VIEW,
                timestamp: new Date().getTime(),
                text: 'laneChangeAbort',
                reportData: {
                    task_id: box_id + '_' + pre_widget_turn_light?.current?.taskId,
                },
            });
        } else {
            if (widget_reason_type === 'TURN_LIGHT_LANE_MERGE_SPLIT') {
                // HMI_merge_signal_view
                batchReport({
                    eventName: reportEventName.HMI_MERGE_SIGNAL_VIEW,
                    timestamp: new Date().getTime(),
                    reportData: {
                        direction: widget_change_direction,
                        task_id: box_id + '_' + widget_turn_light?.taskId,
                    },
                });
            } else {
                batchReport({
                    eventName: reportEventName.HMI_LANE_CHANGE_VIEW,
                    timestamp: new Date().getTime(),
                    reportData: {
                        ...widget_turn_light,
                        task_id: box_id + '_' + widget_turn_light?.taskId,
                    },
                });
            }
        }
        pre_widget_turn_light.current = { ...widget_turn_light };
    }, [box_id, widget_task_id, widget_turn_light]);

    //new trigger reason: audio
    const triggerReasonAudioFunc = (params) => {
        const { lane_change_type, lane_change_trigger_reason, lane_change_direction } = params;
        let audioFile = '';
        // 原始变道语音
        let trigger_audio = triggerAudioEum?.[lane_change_trigger_reason] || '';
        // 确认式变道语音
        let confirm_audio =
            confirmAudioEum?.[lane_change_trigger_reason + '_' + lane_change_direction] || trigger_audio;
        if (np_confirm_switch === 'CONFIRM') {
            audioFile = confirm_audio;
        } else {
            audioFile = trigger_audio;
            if (lane_change_type === 'CAR_SELF') {
                // CP模式下如果开启了主动变道超车提示
                audioFile =
                    running_mode === 'CP' && cp_autonomous_switch === 'AUTONOMOUS_INITIALTIVE'
                        ? localAudioNames.CP_AUTONOMOUS_SWITCH
                        : trigger_audio;
            }
        }
        return audioFile;
    };

    // 变道相关 语音处理
    useEffect(() => {
        if (release_platform === 'UNP') return;
        if (
            typeof lane_change_type === 'undefined' &&
            typeof lane_change_direction === 'undefined' &&
            typeof lane_change_state === 'undefined' &&
            typeof lane_change_trigger_reason === 'undefined' &&
            typeof lane_change_on_ramp_count === 'undefined'
        ) {
            return;
        }

        let audioFile = '';
        audioFile = triggerReasonAudioFunc({ lane_change_type, lane_change_direction, lane_change_trigger_reason });

        // 变道audio
        if (lane_change_state === 'READY') {
            // 确认式变道详细模式下播放语音、其他情况只有音效
            if (np_confirm_switch === 'CONFIRM' && running_mode === 'HNP') {
                if (switchDrivingBroadcast === 'Alert') {
                    playLocalAudio(localAudioNames.LANE_CHANGE_BEEP, 'Normal');
                } else {
                    audioFile && playLocalAudio(audioFile, 'Normal', 'voice');
                }
            }
            // CP主动超车语音提示
            else if (
                cp_autonomous_switch === 'AUTONOMOUS_INITIALTIVE' &&
                running_mode === 'CP' &&
                lane_change_type === 'CAR_SELF' &&
                audioFile
            ) {
                setTimeout(() => {
                    playLocalAudio(audioFile, 'Normal', 'voice');
                }, 600);
            } else {
                audioFile && playLocalAudio(audioFile, 'Normal', 'voice');
            }
        }
        lane_change_type_pre.current = lane_change_type;
    }, [
        lane_change_type,
        lane_change_direction,
        lane_change_state,
        np_confirm_switch,
        lane_change_trigger_reason,
        release_platform,
        switchDrivingBroadcast,
    ]);

    // CP模式下推荐变道展示widget和一次语音
    useEffect(() => {
        if (
            running_mode === 'CP' &&
            cp_autonomous_switch === 'AUTONOMOUS_RECOMMEND' &&
            widget_turn_light_recommend_direction
        ) {
            // 方向图标：direction icon
            let directionIcon = directionEum?.[widget_turn_light_recommend_direction] || '';
            // 文案
            let recommendText = `
                <div class="custom_toast-title">${translate('autoLaneChange.autoLaneChangeTitle')}</div>
                <div class="custom_toast-subtitle">${translate('laneChangeWidget.confirmText')}</div>
                `;
            let recommendContent = { icon: directionIcon, content: recommendText };
            widgetQueue({ widgetNumber: 1, type: widgetType.SELF_CONFIRM, widgetContent: recommendContent });
            playLocalAudio(localAudioNames.LANE_CHANGE_BEEP, 'Normal');
            batchReport({
                eventName: reportEventName.HMI_SUGGESTED_LANECHANGE_VIEW,
                timestamp: new Date().getTime(),
                reportData: {
                    direction: widget_turn_light_recommend_direction,
                },
            });
        } else {
            widgetQueue({ widgetNumber: 1, type: widgetType.SELF_CONFIRM, widgetContent: null });
        }
    }, [widget_turn_light_recommend_direction, running_mode, cp_autonomous_switch]);

    // 变道语音特殊处理： TURN_LIGHT_ENTER_RAMP，当作trigger_reason_v3: 4处理
    useEffect(() => {
        if (release_platform === 'UNP') return;
        if (widget_reason_type === 'TURN_LIGHT_ENTER_RAMP') {
            let audioFile = triggerAudioEum['SHUNT_NAVI'];
            audioFile && playLocalAudio(audioFile, 'Normal', 'voice');
        }
    }, [widget_reason_type, release_platform]);

    // 变道相关 文案处理
    useEffect(() => {
        let all_text = '',
            directionIcon = '',
            trigger_reason_text = '',
            confirm_text = '',
            connect_text = '';
        // 方向图标：direction icon
        directionIcon = directionEum?.[widget_change_direction] || '';
        // trigger reason text
        trigger_reason_text = triggerReasonEum?.[widget_change_trigger_reason] || '';
        // 确认文案：confirm Text
        confirm_text =
            np_confirm_switch === 'CONFIRM' && widget_change_is_tip ? translate('laneChangeWidget.confirmText') : '';

        // 汇总文案：connect_text
        connect_text = connectTextFunc({
            trigger_reason_text,
            change_type: widget_change_type,
            reason_type: widget_reason_type,
            confirm_text,
        });

        // 变道text
        if (directionIcon || connect_text) {
            all_text = { icon: directionIcon, content: connect_text, priority: 'highest' };
        }
        // 只有打灯原因为TURN_LIGHT_LANE_CHANGE的时候才处理undefined情况
        if (widget_reason_type && widget_reason_type === 'TURN_LIGHT_LANE_CHANGE') {
            if (
                typeof widget_change_type === 'undefined' &&
                typeof widget_change_direction === 'undefined' &&
                typeof widget_change_state === 'undefined' &&
                typeof widget_change_trigger_reason === 'undefined' &&
                typeof widget_change_on_ramp_count === 'undefined'
            ) {
                all_text = '';
            }
        } else {
            if (
                typeof widget_change_type === 'undefined' &&
                typeof widget_change_direction === 'undefined' &&
                typeof widget_change_state === 'undefined' &&
                typeof widget_change_trigger_reason === 'undefined' &&
                typeof widget_change_on_ramp_count === 'undefined'
            ) {
                all_text = '';
            }
        }
        // console.log(widget_reason_type, widget_change_type, widget_change_direction, widget_change_state, widget_change_trigger_reason, widget_change_on_ramp_count);
        widgetQueue({ widgetNumber: 1, type: widgetType.LANE_CHANGE, widgetContent: all_text });
    }, [
        widget_reason_type,
        widget_change_type,
        widget_change_direction,
        widget_change_state,
        widget_change_trigger_reason,
        widget_change_on_ramp_count,
        np_confirm_switch,
        widget_change_is_tip,
    ]);

    // 确认式变道下处理fail_reason
    useEffect(() => {
        if (release_platform === 'UNP') return;
        let fail_reason_text = '';
        let fail_reason_widget_content = '';
        if (['NO_RIGHT', 'TIMEOUT', 'SOLID'].includes(widget_change_fail_reason)) {
            fail_reason_text = confirmFailReasonEum?.[widget_change_fail_reason] || '';
            fail_reason_widget_content = { icon: 'odd_hnp_to_manual', content: fail_reason_text };
            // 确认式变道下处理fail_reason
            if (
                running_mode === 'HNP' &&
                np_confirm_switch === 'CONFIRM' &&
                !['NO_LANE_CHANGE', 'MANUAL', 'SHUNT_NAVI'].includes(widget_change_trigger_reason)
            ) {
                widgetQueue({
                    widgetNumber: 1,
                    type: widgetType.LANE_CHANGE_CONFIRM_FAIL_REASON,
                    widgetContent: fail_reason_widget_content,
                });
                playLocalAudio(localAudioNames.CONFIRM_FAIL_REASON, 'Normal');
                setTimeout(() => {
                    widgetQueue({
                        widgetNumber: 1,
                        type: widgetType.LANE_CHANGE_CONFIRM_FAIL_REASON,
                        widgetContent: null,
                    });
                }, 1000);
            }
            // NP CP下交互式变道
            else if (['MANUAL'].includes(widget_change_type)) {
                widgetQueue({
                    widgetNumber: 1,
                    type: widgetType.LANE_CHANGE_FAIL_REASON,
                    widgetContent: fail_reason_widget_content,
                });
                playLocalAudio(localAudioNames.CONFIRM_FAIL_REASON, 'Normal');
                setTimeout(() => {
                    widgetQueue({ widgetNumber: 1, type: widgetType.LANE_CHANGE_FAIL_REASON, widgetContent: null });
                }, 3000);
            }
        }
    }, [widget_change_fail_reason, running_mode, np_confirm_switch, widget_change_trigger_reason, release_platform]);

    // 兼容2.2.0处理变道信息
    const laneChangeContext = (type, trigger_reason_text, confirm_text) => {
        let changeContext = '';
        // 默认的原因
        changeContext = trigger_reason_text;

        // CP模式下如果开启了主动变道超车提示,CP下没有trigger_reason
        if (
            !widget_change_trigger_reason &&
            type === 'CAR_SELF' &&
            running_mode === 'CP' &&
            cp_autonomous_switch === 'AUTONOMOUS_INITIALTIVE'
        ) {
            changeContext = translate('autoLaneChange.autoLaneChangeWidget');
        }

        // 如果是确认式变道
        if (np_confirm_switch === 'CONFIRM' && widget_change_is_tip) {
            changeContext =
                changeContext &&
                `
                <div class="custom_toast-title">${changeContext}</div>
                <div class="custom_toast-subtitle">${confirm_text}</div>`;
            // : changeContext = translate("laneChangeWidget.recommendLane");
        }
        return changeContext;
    };

    // new: trigger reason: text
    const connectTextFunc = (params) => {
        const { trigger_reason_text, change_type, confirm_text, reason_type } = params;
        let connect_text = '';
        // 2.3.0才有reason_type
        if (reason_type) {
            // 打灯原因拆分
            switch (reason_type) {
                // 0823 产品逻辑去掉
                // 车道分汇流
                case 'TURN_LIGHT_LANE_MERGE_SPLIT':
                    connect_text = '';
                    break;
                // 正常变道打灯
                case 'TURN_LIGHT_LANE_CHANGE':
                    connect_text = laneChangeContext(change_type, trigger_reason_text, confirm_text);
                    break;
                // 进入匝道口时,特殊处理， 按trigger_reason_v3: 4处理
                case 'TURN_LIGHT_ENTER_RAMP':
                    connect_text = laneChangeContext(change_type, triggerReasonEum?.['SHUNT_NAVI'], confirm_text);
                    break;
                default:
                    break;
            }
        }
        return connect_text;
    };

    return (
        <>
            <WidgetComponent />
        </>
    );
}
