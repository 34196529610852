import logSend from '@/utils/aLog';

let callbackId = Date.now();
const callbackHeap = new Map();

function bridgeCallback(data) {
    // console.log('dddd-response', data);
    const { callbackId, code, data: dataJson = 'null' } = data;
    if (!callbackHeap.has(callbackId)) {
        logSend({
            action: 'nativeHttpCallbackError',
            data: { callbackId },
        });
        throw new Error('callbackId异常:' + callbackId);
    }
    const [success, fail] = callbackHeap.get(callbackId);
    logSend({
        action: 'nativeHttpEnd',
        data: {
            response: data,
            callbackHeapSize: callbackHeap.size,
        },
    });
    const response = JSON.parse(dataJson);
    code ? success(response) : fail(response);
    callbackHeap.delete(callbackId);
}

window.HMI_http_callback = bridgeCallback;

class NativeHttp {
    constructor(config) {
        this.config = { ...config };
        this.configCallback = () => {};
    }
    _originBridge(parmas) {
        return new Promise((resolve, reject) => {
            callbackId++;
            const data = { callbackId, ...this.config, ...this.configCallback({ ...this.config }), ...parmas };
            // console.log('dddd-request', data);
            callbackHeap.set(callbackId, [resolve, reject]);
            window.momentaJsBridge.dispatch('commonHttpApi', 'HMI_http_callback', JSON.stringify(data));
            logSend({
                action: 'nativeHttpStart',
                data,
            });
        });
    }
    beforeRequset(cb) {
        this.configCallback = cb;
    }
    get http() {
        return {
            get: (url, { params } = {}) => this._originBridge({ method: 'GET', httpUrl: url, queries: params }),
            post: (url, body) => this._originBridge({ method: 'POST', httpUrl: url, body }),
            fsPush: (params) => {
                const FeiShuUrl = 'https://open.feishu.cn/open-apis/bot/v2/hook/25a720d2-785b-4214-82a8-6f84bf5734d2';
                const body = { msg_type: 'text', content: { text: JSON.stringify(params) } };
                return this._originBridge({ method: 'POST', httpUrl: FeiShuUrl, body });
            },
        };
    }
}

export default NativeHttp;
