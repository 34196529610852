import {SocketMap} from '@/constant/socketMap';
import FM from "@/utils/nativeSubAndPub";
import {cusToast} from '@/components/customToast';
import {useTranslation} from 'react-i18next';

export default function useFcwSettingCommon() {
    const {t: translate} = useTranslation();

    // 通知底层：FCW
    const FCWControl = async (params) => {
        const {fcw_switch, fcw_sensitive_level} = params;
        FM.sendToHMIDriving(SocketMap.adasFcwControl, {
            fcw_switch: fcw_switch ? 'OPEN' : 'CLOSE',
            fcw_sensitive_level: fcw_sensitive_level,
        },'drive_node_res_fail_cb');
        
    }

    return {
        FCWControl
    }

}
