
import {cusToast} from '@/components/customToast';
import{t as translate}from 'i18next'
  window.car_follow_control_cb = (nodeRes) =>{
    if(nodeRes?.data?.code !== 0){
        return cusToast.show({
            content: translate("distanceControll.nodeResFail"),
            duration: 2000,
        });
    }
  }

  window.driving_control_cb =  (nodeRes) =>{
    if(nodeRes?.data?.code !== 0){
        cusToast.show({content:translate("NPFail")});
    }
  }

  //操作通用回调
  window.drive_mode_common_cb =  (nodeRes) =>{
    if(nodeRes?.data?.code !== 0){
        cusToast.show({
            content: '操作失败',
        });
    }
  }

  window.drive_mode_common_cb = (nodeRes) =>{
    if(nodeRes?.data?.code !== 0){
        cusToast.show({
            content: '操作失败',
        });
    }
  }

  //通用回调 
  window.drive_node_res_fail_cb = (nodeRes)=>{
    if(nodeRes?.data?.code !== 0){
        cusToast.show({
            content: translate('nodeResFail'),
            duration: 2000,
        });
    }
  }

  // parking
  window.lpnp_switch_control_cb = (nodeRes)=>{
    if (nodeRes?.data?.code !== 0) {
      cusToast.show({
          content: "LPNP设置失败",
          duration: 2000,
      });
    }    
  }
  window.meb_switch_control_cb = (nodeRes)=>{
    if (nodeRes?.data?.code !== 0) {
      cusToast.show({
        content: "MEB设置失败!",
        duration: 2000,
      });
    }   
  }