import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HOT_AREA_TYPE } from '@/store/action/type';
// import Unlock from "@/assets/parking/paking_build_unlock.png";
import { singelPointReport } from '@/utils/report';
// import reportEventName from '@/constant/reportEventName';
import { getStudyStatus, statusMap } from '../../operateArea/operateBtns/util';
import FM from '@/utils/nativeSubAndPub';
import { ParkingSocketMap } from '@/constant/socketMap';
import { AppFn } from '@/utils/native';
import OpenLpnp from './openLpnp';
import GuideStep from './guideStep';
import guide_close from '@/assets/parking/guide/guide_close.png';
import guide_close_d from '@/assets/parking/guide/guide_close_d.png';

// import guide_left_bg from "@/assets/parking/guide_left_bg.png";
import bgGround from '@/assets/parking/guide/guide_ground.png';
import bgBasement from '@/assets/parking/guide/guide_basement.png';
import bgStartPoint from '@/assets/parking/guide/guide_start_point.png';
import classNames from 'classnames';

import './index.less';

const bgMap = {
    step1: bgGround,
    step2: bgBasement,
    step3: bgStartPoint,
    step4: bgStartPoint,
};

export default function StudyGuide() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const isShowGuide = useSelector((state) => state?.parking?.isShowGuide);
    const isD = userMode === 'D';
    const [currentRuning, SetCurrentRuning] = useState('step1'); // 当前在什么状态下
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const noticeType = useSelector((state) => state?.featureModelParking?.alert?.type); //消息类型判定第几步
    const condition = useSelector((state) => state?.featureModelParking?.lpnp?.condition);
    const dispatch = useDispatch();
    const backHomeBtnRef = useRef(null);
    const BtnRect = backHomeBtnRef?.current?.getBoundingClientRect();
    const handleClose = () => {
        singelPointReport({
            eventName: 'HMI_lpnp_exit_guideline_click',
            timestamp: new Date().getTime(),
            text: 'HMI_lpnp_exit_guideline_click',
        });
        dispatch({
            type: 'ISSHOWGUIDE',
            data: false,
        });
    };
    useEffect(() => {
        //驶出停车场，步骤一完成
        if (noticeType === 'lpnp_exit_park') {
            singelPointReport({
                eventName: 'HMI_lpnp_guideline_step2_start_view',
                timestamp: new Date().getTime(),
                text: 'HMI_lpnp_guideline_step2_start_view',
            });
            playAudio('lpnp_guide_back_park.mp3'); //播报返回停车场
            SetCurrentRuning('step2');
        } else if (noticeType === 'lpnp_drive_to_map_starting_point') {
            console.log('学习学习', 222);
            singelPointReport({
                eventName: 'HMI_lpnp_guideline_step3_start_view',
                timestamp: new Date().getTime(),
                text: 'HMI_lpnp_guideline_step3_start_view',
            });
            playAudio('lpnp_drive_to_map_starting_point.mp3'); //播报回路线学习起点，使用lpnp
            SetCurrentRuning('step3'); //驶出停车场，步骤一完成
        } else if (noticeType === 'lpnp_ready') {
            singelPointReport({
                eventName: 'HMI_lpnp_guideline_complete_view',
                timestamp: new Date().getTime(),
                text: 'HMI_lpnp_guideline_complete_view',
            });
            playAudio('lpnp_ready.mp3'); //播报lpnp可用了。
            SetCurrentRuning('step4');
        }
    }, [noticeType]);
    useEffect(() => {
        //进入到页面就播报,返回停车场回到地面
        singelPointReport({
            eventName: 'HMI_lpnp_guideline_step1_start_view',
            timestamp: new Date().getTime(),
            text: 'HMI_lpnp_guideline_step1_start_view',
        });
        playAudio('lpnp_guide_back_ground.mp3');
        //test用，默认c版本
        // window?.momentaJsBridge?.dispatch("switchToScenario","",JSON.stringify({scenario: "sr"}))
    }, []);

    //获取当前状态是否在进行中
    const getStaus = (currentRuning, step) => {
        const num = currentRuning.slice(4, 5);
        if (num >= step) {
            return 'success';
        } else {
            return 'fail';
        }
    };

    // 点击开启lpnp命令触发完成后，关闭引导页面
    window.startParkingCallBack = (res) => {
        console.log('start_res', res);
        handleClose();
    };
    const handleParking = async () => {
        //启动LPNP[开始LPNP]
        singelPointReport({
            eventName: 'HMI_lpnp_guideline_cruise_start_click',
            timestamp: new Date().getTime(),
            text: 'HMI_lpnp_guideline_cruise_start_click',
        });
        FM.sendToHMIParking(
            ParkingSocketMap.lpnp_cmd,
            {
                cmd: 'START_PARKING',
            },
            'startParkingCallBack',
        );
    };
    const canParkingUse = (condition) => {
        return condition?.isMapBuilt && condition?.isOnRoute && condition?.isThroughMappingStartPos;
    };
    useEffect(() => {
        // 如果已经巡航且还在展示，直接关闭
        if (status === 'CRUISING' && isShowGuide) {
            dispatch({
                type: 'ISSHOWGUIDE',
                data: false,
            });
        }
    }, [status]);
    const playAudio = (audioName) => {
        AppFn.playLocalAudio(audioName, 'Highest');
    };
    useEffect(() => {
        const BtnInfo = `${BtnRect?.x}, ${BtnRect?.y}, ${BtnRect?.width}, ${BtnRect?.height}`;
        dispatch({
            type: HOT_AREA_TYPE.backHomeBtn,
            data: BtnInfo,
        });
    }, [status, BtnRect?.x, BtnRect?.y, BtnRect?.width, BtnRect?.height]);

    return (
        <div className={classNames('study-guide', isD ? 'd' : 'sr')}>
            <div className="opration-top">
                <div ref={backHomeBtnRef} onClick={() => handleClose()} className={`guide-close-content`}>
                    <img className="guide-close-icon" src={isD ? guide_close_d : guide_close} alt="cancel" />
                </div>
                <OpenLpnp
                    handler={handleParking}
                    name={'LPNP_OPEN'}
                    disabled={!(getStudyStatus(status) === statusMap.STANDBY && canParkingUse(condition))}
                />
                <div className="header-title">Follow the guidelines to use LPNP please</div>
            </div>
            <div className="guide-content">
                <div className={`guide-left`}>
                    <img src={bgMap[currentRuning]} alt="bg"></img>
                </div>
                <div className={`guide-right`}>
                    <GuideStep
                        step={'step1'}
                        status={getStaus(currentRuning, '2')}
                        running={currentRuning === 'step1'}
                    />
                    <GuideStep
                        step={'step2'}
                        status={getStaus(currentRuning, '3')}
                        running={currentRuning === 'step2'}
                    />
                    <GuideStep
                        step={'step3'}
                        status={getStaus(currentRuning, '4')}
                        running={currentRuning === 'step3'}
                    />
                </div>
            </div>
        </div>
    );
}
