let callbackKey = Date.now();
const bridge = ({ name, cbName = null, data }) => window.momentaJsBridge.dispatch(name, cbName, data);

export const get = (key, cb) => {
    const cbName = `HMI_bridge_callback_${callbackKey++}`;
    window[cbName] = (data) => {
        try {
            cb(JSON.parse(data));
        } catch {
            cb(data);
        }
        delete window[cbName];
    };
    bridge({ name: 'getData', cbName, data: key });
};

export const set = (key, value) => bridge({ name: 'saveData', data: JSON.stringify({ key, value }) });

export const remove = (key) => bridge({ name: 'removeData', data: key });
