import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import logSend from '@/utils/aLog';

export default function useSetUserGuide() {
    const dispatch = useDispatch();

    useEffect(() => {
        //学习完成后，点击马上试试跳转引导页面的回调
        window.notifyButtonClick = function (rdata) {
            console.log('showParkingGuideCallBack', JSON.stringify(rdata));
            logSend({
                action: 'showParkingGuideCallBack',
                data: rdata,
            });
            if (rdata.buttonName === 'lpnp_complete_try') {
                dispatch({
                    type: 'ISSHOWGUIDE',
                    data: true,
                });
            }
        };
    }, []);
}
