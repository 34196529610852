import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import { cusToast } from '@/components/customToast';
import FM from "@/utils/nativeSubAndPub";
import {useTranslation} from 'react-i18next';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function DriveStyle ({isDisabled}) {
    const driving_style_switch = useSelector(state => state?.featureModelDriving?.switch?.driving?.driving_style?.driving_style_switch);
    const [checkedDriving, setCheckedDriving] =  useState(driving_style_switch ? driving_style_switch : 'NORMAL');
    const {t: translate} = useTranslation();

    const styleOptions = translate("driving.styleOptions",{returnObjects: true })

    useEffect(() => {
        if(!driving_style_switch) {
            handleClick("NORMAL")
            return
        }
    }, [driving_style_switch])

    const handleClick = async (v) => {
        if (isDisabled) return;
        if (!v || v === checkedDriving) return
        // 数据上报
        reportDataFunc(v)
        setCheckedDriving(v)
        await operateDrivingStyle(v)
    }

    const operateDrivingStyle = async (state) => {
        FM.sendToHMIDriving(SocketMap.drivingStyle, {
            state
        },'drive_node_res_fail_cb');
       
    }

    // 数据上报
    const reportDataFunc = (v) =>{
        singelPointReport({
            eventName: reportEventName.HMI_DREIVING_STYLE_CLICK,
            timestamp: new Date().getTime(),
            text: v ,
        })
    }

    return (
        <div className='select-group-item'>
            <p className='select-group-item-name name'>{translate("driving.checkedDriving")}</p>
            <div className={`select-box`}>
                {
                    styleOptions.map((item, index) => {
                        return(
                            <span
                                key={index}
                                className={`select-box-item ${checkedDriving === item.value ? 'active' : ''}`}
                                onClick={()=> handleClick(item.value) }
                            >
                            {item.label}
                        </span>
                        )
                    })
                }
            </div>
        </div>
    )
}
