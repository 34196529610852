import { TAKE_OVER_ACTION } from '../action/type';

const INIT_STATE_MODAL = {
    takerOverState: false,
    takeroverContinueState: false,
    img: '',
    text: ''
};
export default function modalData(state = INIT_STATE_MODAL, action) {
    switch (action.type) {
        case TAKE_OVER_ACTION.takerOverState:
            return { ...state, ...action.data};
        case TAKE_OVER_ACTION.takeroverContinueState:
            return { ...state, ...action.data};
        default:
            return state;
    }
}
