import React from "react";
import {Grid} from "antd-mobile";
import "./index.less"

export default function CardLayout(props) {
    return (
        <div className={"card_wrap"}>
            <Grid.Item>
                {props?.dom}
            </Grid.Item>
        </div>
    )
}
