import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from 'react-i18next';
import {Switch} from "antd-mobile";
import CardLayout from "../../../components/cardLayout";
import {cusToast} from '@/components/customToast';
import {useSelector} from "react-redux";
import {SocketMap} from '@/constant/socketMap';
import FM from "@/utils/nativeSubAndPub";
import "@/components/slider/smartDrivingSetting/components/cardStyle/index.less";
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

let timer = null;
export default function CswCard() {
    const {t: translate} = useTranslation();
    const csw_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.csw_switch);
    const [cswSwitch, setCswSwitch] = useState(csw_switch === "OPEN");

    useEffect(() => {
        csw_switch && setCswSwitch(csw_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer)
            timer = null
        }
    }, [csw_switch])

    // 数据上报
    const reportDataFunc = (v) =>{
        singelPointReport({
            eventName: reportEventName.HMI_CSW_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            text: v ,
        })
    }

    // swtich: CSW
    const switchCSWChange = (value) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            setCswSwitch(csw_switch === "OPEN")
        }, 2 * 1000)
        reportDataFunc(value ? 'ON' : 'OFF')
        cswControl(value);
    }

    // 通知底层：csw
    const cswControl = async (value) => {
        FM.sendToHMIDriving(SocketMap.cswControl, {
            switch: value ? 'OPEN' : 'CLOSE',
        },'drive_node_res_fail_cb');
       
    }

    const leftDom = (
        <div className={"left_dom_wrap"}>
            <div className={"switch_wrap"}>
                <div className={"content_wrap"}>
                    <div className={"text_wrap"}>CSW</div>
                    <div className={"text_wrap_detail"} style={{whiteSpace: "pre-wrap"}}>
                        {translate('settings.CSWSwitch')}
                    </div>
                </div>
                <Switch
                    checked={cswSwitch}
                    onChange={(checked) => switchCSWChange(checked)}
                    className={"customSwitch"}
                />
            </div>
        </div>
    )

    const rightDom = (
        <div className={"right_dom_wrap"}/>
    )

    return (
        <>
            <CardLayout
                leftDom={leftDom}
                rightDom={rightDom}
            />
        </>
    )
}
