import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import roadCollection from '@/assets/settingComNew/otherSettings/roadCollection.png';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { useSelector, useDispatch } from 'react-redux';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import localCache from '@/utils/localCache';

export default function RoadTestCollection() {
    const dispatch = useDispatch();
    const { t: translate } = useTranslation();
    // const warning_automatic = localStorage.getItem('warning_automatic') || 'OFF';
    // const [remindSwitch, setRemindSwitch] = useState(warning_automatic === 'ON' ? true : false);
    const takeOverSwitch = useSelector((state) =>
        state?.warningAutomatic?.takeOverSwitchStatus === 'ON' ? true : false,
    );

    const switchRemind = (value) => {
        if (value) {
            singelPointReport({
                eventName: reportEventName.HMI_HOME_15SNOTICE_CLICK,
                text: value,
                timestamp: new Date().getTime(),
            });
            // localStorage.setItem('warning_automatic', 'ON')
            localCache.set('warning_automatic', 'ON');
            dispatch({
                type: 'switchStatus',
                data: 'ON',
            });
        } else {
            // localStorage.setItem('warning_automatic', 'OFF')
            localCache.set('warning_automatic', 'OFF');
            dispatch({
                type: 'switchStatus',
                data: 'OFF',
            });
        }
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'} style={{ whiteSpace: 'pre-wrap' }}>
                        {translate('settings.remindSwitch')}
                    </div>
                </div>
                <Switch
                    checked={takeOverSwitch}
                    onChange={(checked) => switchRemind(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = (
        <div className={'right_dom_wrap'}>
            <img src={roadCollection} />
        </div>
    );

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} />
        </>
    );
}
