import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd-mobile';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { cusToast } from '@/components/customToast';
import { useTranslation } from 'react-i18next';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import localCache from '@/utils/localCache';
import { SWITH_MODE_ACTION } from '@/store/action/type';

let timer = null;
export default function TFLSwitch({ isDisabled }) {
    const dispatch = useDispatch();
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    // 当前智驾
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // OPEN/CLOSE
    const tfl_switch = useSelector((state) => state?.featureModelDriving?.switch?.driving?.cp_tfl_stop_control?.switch);
    const [tflSwitch, setTflSwitch] = useState(tfl_switch === 'OPEN');
    const switchTFLState = useSelector((state) => state?.switchModeAction?.switchTFLState);
    const { t: translate } = useTranslation();

    useEffect(() => {
        tfl_switch && setTflSwitch(tfl_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [tfl_switch]);

    const switchChange = async (v) => {
        // CP开关关闭时，此时若用户点击TFL开关，智驾页弹窗提示“请先开启CP再使用”
        if (isDisabled) {
            const text = translate('settings.pleaseEnableCp');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }

        // 若此时用户智驾运行时，点击TFL开关，智驾页弹窗提示“请先处于手动驾驶时再更改”
        if (['HNP_PLUS', 'HNP', 'CP', 'ACC'].indexOf(running_mode) > -1 && automatic_state === 'ACTIVE') {
            const text = translate('settings.manualDrivingMode');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }

        clearTimeout(timer);
        timer = setTimeout(() => {
            setTflSwitch(tfl_switch === 'OPEN');
        }, 2 * 1000);
        reportDataFunc(v ? 'OPEN' : 'CLOSE');
        await tflSwitchControl(v);
    };

    useEffect(async () => {
        // CP开关关闭时，如果TFL开关是打开状态，则将TFL开关关闭，且开关置灰
        if (isDisabled && tfl_switch === 'OPEN') {
            // 记录用户状态
            // localStorage.setItem("is_cp_tfl_switch_state", "OPEN")
            localCache.set('is_cp_tfl_switch_state', 'OPEN');
            dispatch({
                type: SWITH_MODE_ACTION.switchTFLState,
                data: 'OPEN',
            });
            await tflSwitchControl(false);
        } else {
            // CP开关打开了, 恢复用户状态
            if (!isDisabled) {
                // const is_cp_tfl_switch_state = localStorage.getItem("is_cp_tfl_switch_state");
                if (switchTFLState === 'OPEN') {
                    await tflSwitchControl(true);
                    localCache.set('is_cp_tfl_switch_state', 'CLOSE');
                    dispatch({
                        type: SWITH_MODE_ACTION.switchTFLState,
                        data: 'CLOSE',
                    });
                    // localStorage.setItem("is_cp_tfl_switch_state", "CLOSE")
                }
            }
        }
    }, [isDisabled]);

    // 通知底层
    const tflSwitchControl = async (value) => {
        FM.sendToHMIDriving(
            SocketMap.tfl_stop_control,
            {
                switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };

    // 数据上报
    const reportDataFunc = (v) => {
        singelPointReport({
            eventName: reportEventName.HMI_TFL_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                TFL: v,
            },
        });
    };

    return (
        <div className="select-group-item">
            <div className="select-group-switch">
                <Switch
                    checked={tflSwitch}
                    // disabled={isDisabled}
                    onChange={(checked) => switchChange(checked)}
                    className="custom-switch"
                />
                <span className="switch-name">TFL</span>
            </div>
        </div>
    );
}
