import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import localAudioNames from '@/constant/localAudioName';
import {AppFn} from "@/utils/native";
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function LaneChangeLampRemind() {
    // turnLightState === ON 立即播报语音
    const turnLightState = useSelector(state => state?.featureModelDriving?.laneChange?.notice?.data?.turn_light?.state);

    // 数据上报
    const reportDataFunc = () => {
        singelPointReport({
            eventName: reportEventName.HMI_SIGNAL_TURN_OFF_AUDIO_VIEW,
            timestamp: new Date().getTime(),
        })
    }

    useEffect(() => {
        // console.log(turnLightState, "log turnLightState")
        if(turnLightState === "ON") {
            // console.log("log 播放转向杆回正语音")
            reportDataFunc();
            AppFn.playLocalAudio(localAudioNames.TURN_SIGNAL_STILL_ON, 'Low', 'voice');
        }
    }, [turnLightState])

    return <></>
}
