// highest
import ban_red from "@/assets/widget/highest/ban_red.png";
// import highest_left_red from "@/assets/widget/highest/highest_left_red.png";
import highest_left_red from "@/assets/widget/highest/hightest_left.gif";
import highest_remind from "@/assets/widget/highest/highest_remind.png";
// import highest_right_red from "@/assets/widget/highest/highest_right_red.png";
import highest_right_red from "@/assets/widget/highest/highest_right.gif";

// important
import important_remind from "@/assets/widget/important/important_remind.png";
// import yellow_left from "@/assets/widget/important/yellow_left.png";
import yellow_left from "@/assets/widget/important/important_left.gif";
// import yellow_right from "@/assets/widget/important/yellow_right.png";
import yellow_right from "@/assets/widget/important/important_right.gif";

// normal
// import blue_left from "@/assets/widget/normal/blue_left.png";
import blue_left from "@/assets/widget/normal/normal_left.gif";
// import blue_right from "@/assets/widget/normal/blue_right.png";
import blue_right from "@/assets/widget/normal/normal_right.gif";
import done from "@/assets/widget/normal/done.png";
import give_way_left from "@/assets/widget/normal/give_way_left.png";
import give_way_right from "@/assets/widget/normal/give_way_right.png";

// odd
import odd_hnp_to_manual from "@/assets/widget/odd/odd_hnp_to_manual.png";
import left_img from "@/assets/collisionAvoid/car_left_black.png";
import right_img from "@/assets/collisionAvoid/car_right_black.png";
import warn from "@/assets/collisionAvoid/warning.png";
import quit_icon from "@/assets/MRC/quit_icon.png";

// cpTFL
import cp_tfl from "@/assets/hnp/cp_tfl.png";

// last_lane_change
import last_lane_change from "@/assets/widget/normal/last_lane_change.png";

// speed_button_evetn
import max_warning from "@/assets/drivingLevel/max_warning.png";

const highest = {
    ban_red: ban_red,  // 红色 禁止
    highest_left_red: highest_left_red,  // 红色 左转
    highest_remind: highest_remind, // 红色 强提醒
    highest_right_red: highest_right_red,  // 红色 右转
}

const important = {
    important_remind: important_remind,  // 黄色 提醒
    yellow_left: yellow_left,  // 黄色 左转
    yellow_right: yellow_right,  // 黄色 右转
}

const normal = {
    blue_left: blue_left,  // 蓝色 左转
    blue_right: blue_right,  // 蓝色 右转
    done: done,  // 蓝色 变道完成
    give_way_left: give_way_left,  // 蓝色 向左避让大车
    give_way_right: give_way_right,  // 蓝色 向右避让大车
}

const odd = {
    odd_hnp_to_manual,  // odd接管
}


export const widgetIcon = {
    ...highest,
    ...important,
    ...normal,
    ...odd,
    left_img,
    right_img,
    warn,
    quit_icon,
    cp_tfl,
    last_lane_change,
    max_warning
}

