import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

// import {cusToast} from '@/components/customToast';

export default function useLPNPSettingCommon() {
    // 通知底层：LPNP开关
    const LPNPControl = async (lpnp_switch) => {
        FM.sendToHMIParking(
            ParkingSocketMap.lpnp_switch_control,
            {
                cmd: lpnp_switch,
            },
            'lpnp_switch_control_cb',
        );
    };

    return {
        LPNPControl,
    };
}
