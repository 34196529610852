import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import {widgetPush, widgetType} from '@/utils/widgetPush';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
import {modalQueue, modalType} from '@/utils/modalQueue';
import {useTranslation} from 'react-i18next';
import {batchReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { STATUS, FcwState } from "./common"
import "./css/fcw.less"
import FCW_OPEN from "../aebAndFcwComponent/img/fcw_open.png"
import FCW_CLOSE from "../aebAndFcwComponent/img/fcw_close.png"
import FCW_WARN from "../aebAndFcwComponent/img/fcw_warning.png"
import fcw_front_car from "./img/fcw_front_car.png"

const { playLocalAudio } = AppFn

export default function FCW(){
    // const widget = useSelector(state => state?.func?.currentState?.widget); 废弃了
    const fcw_fcn_state = useSelector(state => state?.featureModelDriving?.fcw?.notice?.data?.isFcwWarning);
    const fcwRef = useRef()
    // const fcw_state = widget?.adas?.fcw_state; 废弃了
    let warningTimer = useRef(null);
    let endingTimer = useRef(null);
    const {t: translate} = useTranslation();

    useEffect(() => {
       fcwRef.current = new FcwState()
    }, [])
    /**
     * 0915 M7.2改为音频展示
     */
    useEffect(()=>{
        if(fcw_fcn_state) {
            clearTimeout(endingTimer.current);
            endingTimer.current = null;
            modalQueue({type: modalType.FCW, modalData:{"img":fcw_front_car, "text": translate('AEBFCWCom.frontCar')} })
            reportDataFunc();
            playLocalAudio(localAudioNames.BIBIBI_WARNING, "Highest");
            (!warningTimer.current) && (warningTimer.current = setInterval(()=>{
                playLocalAudio(localAudioNames.BIBIBI_WARNING, "Highest")
            },1000))
        }else {
            clearInterval(warningTimer.current);
            warningTimer.current = null;
            endingTimer.current = setTimeout(() => {
                modalQueue({type: modalType.FCW, modalData: null})
            }, 500)
        }
    }, [fcw_fcn_state])

    // 数据上报
    const reportDataFunc = () =>{
        batchReport({
            eventName: reportEventName.HMI_FCW_VIEW,
            timestamp: new Date().getTime(),
            text: "FCWWarning",
        })
    }



    /*useEffect(() => {
        fcwRef?.current?.changeFcnType(fcw_fcn_state)
    }, [fcw_fcn_state])*/

    /**
     * 0914M7.2去掉 FCW ICON
     */
    /*useEffect(()=>{
        let imgSrc = "";
        switch(fcw_state) {
            case STATUS.WARNING:
                imgSrc = FCW_WARN;
                break;
            default:
                imgSrc = "";
                break;
        }
        let currentText =
            `<div class="fcw ${fcw_state}">
                <img src=${imgSrc} alt="fcw"/>
            </div>`;

        let content = {"content": currentText };
        if(imgSrc){
            widgetPush({widgetNumber: 3, type: widgetType.FCW, widgetContent: content});
        }else{
            widgetPush({widgetNumber: 3, type: widgetType.FCW, widgetContent: null});
        }
    },[fcw_state])*/

   /* const StatusElement = (status) => {
        switch(status) {
            case STATUS.CLOSE:
                return <img src={FCW_CLOSE} alt="fcw close" />
            case STATUS.OPEN:
                return <img src={FCW_OPEN} alt="fcw open" />
            case STATUS.WARNING:
                return <img src={FCW_WARN} alt="fcw warning" />
            default:
                return <></>
        }
    }*/
    return (
        <>
        </>
        /*<div className="fcw-wrapper">{StatusElement(fcw_state)}</div>*/
    )
}
