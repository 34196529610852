import { Area } from '@/constant/config'

const INIT_STATE = {
   Area: Area,
   showButtonView: "true"  // "true"显示  "false"隐藏
};

export default function layout(state = INIT_STATE, action) {
    switch (action.type) {
        case 'resetArea':
            return {
                ...state,
                ...{
                    Area: action.data
                },
            }
        case 'showView':
            return {
                ...state,
                ...{
                    showButtonView: action.data
                }
            }
        default:
            return state;
    }
}
