import { MAP_TYPE } from '../action/type';
const INIT_STATE = {
    // 定位信息
    locateInfo: {},
};

export default function map(state = INIT_STATE, action) {
    switch (action.type) {
        case MAP_TYPE.locateInfo:
            return { ...state, locateInfo: action.data };
        default:
            return state;
    }
}
