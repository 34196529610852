import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider } from 'antd-mobile';
import { useSelector, useDispatch } from 'react-redux';

import { SWITH_MODE_ACTION } from '@/store/action/type';
import localCache from '@/utils/localCache';

const marks = (function () {
    let start = 3,
        end = 20;
    let obj = {};
    for (let i = start; i <= end; i++) {
        obj[i] = i;
    }
    return obj;
})();

export default function RecordCard() {
    const { t: translate } = useTranslation();
    const recordTimes = useSelector((state) => state?.switchModeAction?.recordTimes);
    const isRecording = useSelector((state) => state?.switchModeAction?.isRecording);
    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);
    const switchUnpState = useSelector((state) => state?.switchModeAction?.switchUnpState);

    const themeColorState = useSelector((state) => state?.themeAction?.themeColorState);
    const dispatch = useDispatch();
    const handleChange = (value) => {
        localCache.set('workbench_reocrd_times', value);
        dispatch({
            type: SWITH_MODE_ACTION.recordTimes,
            data: value,
        });
    };

    return (
        <div className="slider-content">
            <div className={themeColorState === 'black' ? 'slider-black text_wrap' : 'slider-white text_wrap'}>
                {translate('settings.recordTimes')}
            </div>
            <Slider
                //  style={{ '--fill-color': '#2A69E2' }}
                className={themeColorState === 'black' ? 'slider-black' : 'slider-white'}
                disabled={isRecording || !switchWorkBench || switchUnpState}
                marks={marks}
                value={recordTimes}
                onChange={handleChange}
                ticks={true}
                max={20}
                min={3}
                step={1}
            />
        </div>
    );
}
