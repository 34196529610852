const workBenchEventName = {
    // 工作台启用状态
    WB_active_view: "WB_active_view", 
    // 工作台首页打开、关闭
    WB_home_click: "WB_home_click",
    // 工作台触发录制
    WB_event_record_click: "WB_event_record_click",
    // 录音截图上报结果
    WB_record_result_view: "WB_record_result_view",
    // 上报管理中播放录音
    WB_record_play_click: "WB_record_play_click",
    // 上报管理中查看截图
    WB_picture_play_click: "WB_picture_play_click",
    //录音打断事件
    WB_record_break_event: "WB_record_break_event"

}

export default workBenchEventName;