import { useEffect, useState } from 'react';
import store from '../store';
import { MODAL_ACTION } from '@/store/action/type';

// MODAL_ACTION.modalAction
export const modalType = {
    RCW: 'RCW',
    FCW: 'FCW',
    LKA: 'LKA',
    AMAP: 'AMAP',
    NRP: 'NRP',
};
export const modalQueue = ({ type, modalData }) => {
    // modal 的type
    let storeModalType = MODAL_ACTION.modalAction;
    const state = store?.getState();
    // store中存储的list消息队列
    const storeModalQueue = state?.modalData?.list;
    const contentObj = {};
    contentObj[type] = modalData;
    let arr = [];
    modalData ? arr.push({ ...contentObj }) : (arr = []);
    if (!modalData && storeModalQueue.length === 0) return;
    if (storeModalQueue.length === 0) {
        // 1: store里没有值

        // 1-1: 如果传进来的数据没有值，return
        if (!modalData) return;

        // 1-2: modalData有值直接存储
        store.dispatch({
            type: storeModalType,
            data: {
                list: [...arr],
            },
        });
    } else {
        // 2: store里有值
        let equalIndex = null;
        storeModalQueue.map((item, index) => {
            if (Object.keys(item)[0] === type) {
                equalIndex = index;
            }
        });
        if (!modalData) {
            // 2-1: 如果传进来的没有值，判断key是否在store里存在
            // 2-1-1: key存在: 将store里的值赋空(key可能等于0， 用!=null来判断)
            // 2-1-2: key不存在：return

            if (equalIndex !== null) {
                store.dispatch({
                    type: storeModalType,
                    data: {
                        list: [],
                    },
                });
            } else {
                return;
            }
        } else {
            // 2-2: 如果传进来的有值
            // 2-2-1: key存在： 将store里直接赋值
            // 2-2-2: key不存在: 也直接赋值
            store.dispatch({
                type: storeModalType,
                data: {
                    list: [...arr],
                },
            });
        }
    }
};
