
import {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux'
import OldLaneChange from './oldIndex';
import NewLaneChange from './newIndex';
import V3LaneChange from './v3Index';
import {laneChangeFilterVersion, laneChangeV2FilterVersion} from '@/constant/filterVersionMap';

export default function LaneChange() {
    const hmi_version = useSelector(state => state?.featureModelDriving?.baseData?.hmi?.hmi_version);

    const laneChangeCom = () =>{
        // if(!laneChangeFilterVersion.includes(hmi_version) &&  !laneChangeV2FilterVersion.includes(hmi_version)){
            return <V3LaneChange />
        // }else if(!laneChangeFilterVersion.includes(hmi_version)){
        //     return <NewLaneChange/>
        // }else{
        //     return <OldLaneChange />
        // }

    }
    return (
        // !laneChangeFilterVersion.includes(hmi_version) ? <NewLaneChange/> : <OldLaneChange/>
        <>
            {laneChangeCom()}
        </>


    )
}