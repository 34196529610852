import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import localAudioNames from '@/constant/localAudioName';
import { AppFn } from '@/utils/native';

const { playLocalAudio } = AppFn;

export default function IsaWarning() {
    const isaWarning = useSelector((state) => state?.featureModelDriving?.isa?.isaWarning);
    let isaWarningTimer = useRef(null);

    useEffect(() => {
        if (isaWarning) {
            playLocalAudio(localAudioNames.BIBIBI_WARNING, 'Important');
            !isaWarningTimer.current &&
                (isaWarningTimer.current = setInterval(() => {
                    playLocalAudio(localAudioNames.BIBIBI_WARNING, 'Important');
                }, 1000));
        } else {
            clearInterval(isaWarningTimer.current);
            isaWarningTimer.current = null;
            return;
        }
    }, [isaWarning]);

    return <></>;
}
