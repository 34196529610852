import React, {useEffect} from "react";
import {useSelector} from 'react-redux';
import CLOSE from "@/assets/hnp/adas/ESS/ess_close.png";
import OPEN from "@/assets/hnp/adas/ESS/ess_normal.png";
import WARNING from "@/assets/hnp/adas/ESS/ess_warn.gif";
import {widgetPush, widgetType} from '@/utils/widgetPush';
import './index.less';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function EssIcon() {
    // widget 展示  CLOSE/OPEN/WARNING
    const ess_icon_status = useSelector(state => state?.featureModelDriving?.ess?.state);

    const showWidget = () => {
        if (ess_icon_status && ess_icon_status !== "" && ess_icon_status === "WARNING") {
            /**
             * ESS icon为常驻显示 icon
             * ESS功能关闭，icon显示：黄色
             * ESS功能开启(未触发报警)，icon显示：绿色
             * ESS触发报警，icon显示：红色，且持续闪烁
             *
             * 2023-02-01 M11.2 Vector 更新展示逻辑
             *
             * 当开启时，当前显示绿色，修改后不显示，
             - 当关闭时，当前显示黄色，修改后不显示，
             - 当报警时，当前显示红，修改红色，
             - 当失效时，当前没展示，则显示黄色。（当前暂时拿不到失效状态）
             * */
            const ImageMap = { WARNING }
            let contentText = `
                <div class="ess_icon_wrap">
                  <img class="img_wrap"  src=${ImageMap[ess_icon_status]} alt="ESS"/>
                </div>
            `
            let content = {"content": contentText}
            widgetPush({widgetNumber: 3, type: widgetType.ESS, widgetContent: content});
        } else {
            widgetPush({widgetNumber: 3, type: widgetType.ESS, widgetContent: null});
        }
    }

    // 数据上报
    const reportDataFunc = (v) => {
        singelPointReport({
            eventName: reportEventName.HMI_ESS_VIEW,
            timestamp: new Date().getTime(),
            text: v,
        })
    }

    useEffect(() => {
        showWidget()
        ess_icon_status === "WARNING" && reportDataFunc("true")
    }, [ess_icon_status])

    return (
        <></>
    )
}
