import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux';
import { SocketMap} from '@/constant/socketMap';
import { cusToast } from '@/components/customToast';
import FM from "@/utils/nativeSubAndPub";
import {useTranslation} from 'react-i18next';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function LaneChangeStyle ({isDisabled}) {
    const lane_change_style_switch = useSelector(state => state?.featureModelDriving?.switch?.driving?.driving_style?.lane_change_style_switch);
    const [checkedLaneChange, setCheckedLaneChange] =  useState(lane_change_style_switch ? lane_change_style_switch : "NORMAL")
    const {t: translate} = useTranslation();
    // 驾驶/变道风格
    const styleOptions = translate("laneChange.styleOptions",{returnObjects: true })
    const laneChangeStyleTips = translate("laneChange.laneChangeStyleTips", {returnObjects: true})

    useEffect(() => {
        if(!lane_change_style_switch) {
            handleClick("NORMAL")
            return
        }
    }, [lane_change_style_switch])

    const handleClick = async (v)=> {
        if (isDisabled) return;
        if (!v || v === checkedLaneChange) return
        // 数据上报
        reportDataFunc(v)
        setCheckedLaneChange(v)
        await operateLaneChangeStyle(v)
    }

    const operateLaneChangeStyle = async (state) => {
        FM.sendToHMIDriving(SocketMap.laneChangeStyle, {
            state
        },'drive_node_res_fail_cb');
    }
    // 数据上报
    const reportDataFunc = (v) =>{
        singelPointReport({
            eventName: reportEventName.HMI_LANECHANGE_STYLE_CLICK,
            timestamp: new Date().getTime(),
            text: v ,
        })
    }

    return <div className='select-group-item'>
        <p className='select-group-item-name name'>{translate("laneChange.checkedLaneChange")}</p>
        <div className={`select-box`}>
            {
                styleOptions.map((item, index) => {
                    return(
                        <span
                            key={index}
                            className={`select-box-item ${checkedLaneChange === item.value ? 'active' : ''}`}
                            onClick={()=> handleClick(item.value) }
                        >
                        {item.label}
                    </span>
                    )
                })
            }
        </div>
        {laneChangeStyleTips[checkedLaneChange] && <p className="auto-lanechange-tip">{laneChangeStyleTips[checkedLaneChange]}</p>}
    </div>
}
