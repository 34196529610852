import {useSelector} from 'react-redux';
import {useState, useEffect, useRef} from 'react';
import {useDispatch} from "react-redux";
import {SWITH_MODE_ACTION, HOT_AREA_TYPE} from '@/store/action/type';
import logSend from '@/utils/aLog';
import "./index.less";

let setInterval_time = null; // 定时器句柄
export default function InforTips() {
    const trigger_reason = useSelector(state => state?.featureModelDriving?.eventCollector?.triggerReason);
    const switchEventState = useSelector(state => state?.switchModeAction?.switchEventState);
    const [isShow, setIsShow] = useState(false);
    const [reasonState, setReasonState] = useState([]);
    const dispatch = useDispatch();
    // 热区
    const eventInfoDom = useRef(null);
    const switchMvizState = useSelector(state => state?.switchModeAction?.switchMvizState);
    const area = useSelector(state => state?.layout?.Area);

    const triggerArray = useRef([])


    // 点击关闭按钮,关闭弹窗
    const handleCancel = () => {
        setIsShow(false);
        dispatch({
            type: SWITH_MODE_ACTION.switchEventState,
            data: false,
        });
    }
    useEffect(() => {
        let result = [...reasonState]
        if (trigger_reason) {
            setIsShow(true);
            result.push(trigger_reason)
            setReasonState(result)
        }
        triggerArray.current = [...result]

        function fn() {
            const msgArray = [...triggerArray.current]
            msgArray.shift()
            triggerArray.current = msgArray
            setReasonState([...msgArray])
            if (msgArray.length < 1) {
                clearInterval(setInterval_time)
                setInterval_time = null
            }
        }

        if (result.length > 0 && !setInterval_time) {
            setInterval_time = setInterval(fn, 3000)
        }
    }, [trigger_reason])
    // 上报热区
    useEffect(() => {
        const eventInfoDomArea = eventInfoDom?.current?.getBoundingClientRect();
        const eventInfoDomInfo = `${eventInfoDomArea?.x}, ${eventInfoDomArea?.y}, ${eventInfoDomArea?.width}, ${eventInfoDomArea?.height}`;
        // icon存在的情况下连续变化，防止layout热区监控不到变化，导致热区上报有问题
        dispatch({
            type: HOT_AREA_TYPE.eventInfoDom,
            data: ''
        });
        if (typeof (eventInfoDomInfo) !== 'undefined' && eventInfoDomInfo?.current?.getBoundingClientRect()?.width !== 0) {
            dispatch({
                type: HOT_AREA_TYPE.eventInfoDom,
                data: eventInfoDomInfo
            });
        }
        logSend({
            action: "eventInfoHotArea",
            data: {
                width: eventInfoDom?.current?.getBoundingClientRect()?.width,
                eventInfoDomInfo: eventInfoDomInfo
            }
        });
    }, [eventInfoDom?.current?.getBoundingClientRect()?.width,
        area?.isHorizontal,
        switchMvizState,
        eventInfoDom?.current?.getBoundingClientRect().x,
        eventInfoDom?.current?.getBoundingClientRect().y,
    ])
    return (
        <>
            {
                switchEventState && isShow && reasonState.length > 0 && (
                    <div className="event_info" ref={eventInfoDom}>
                        <p className="event_info_box">
                            <span className="event_info_title">Event</span>
                            <span className='iconfont close' onClick={() => handleCancel()}>
                                {'\ue621'}
                            </span>
                        </p>
                        {
                            reasonState.map((item, index) => {
                                return (
                                    <p key={index} className={"item_wrap"}>{item}</p>
                                )
                            })
                        }
                    </div>
                )
            }
        </>
    )
}
