import StudyComplete from './studyComplete';
import ParkingComplete from './parkingComplete';
import RouteLearningFailed from './RouteLearningFailed';
import './index.less';

export default function LPNPComplete() {
    return (
        <>
            <StudyComplete />
            <ParkingComplete />
            <RouteLearningFailed />
        </>
    );
}
