import React, { useEffect, useState } from "react";
import {widgetIcon} from "@/constant/widget";
import {useSelector, useDispatch} from "react-redux";
import { WIDGET_ACTION } from '@/store/action/type';
import "./index.less";

export default function WidgetThreeComponent(props) {
    const dispatch = useDispatch();
    // store中存储的widget数据
    const widgetQueueList = useSelector(state => state?.widget3Data?.list);

    useEffect(() => {
        // 离开组件后，进行数据复位
        return () => {
            dispatch({
                type: WIDGET_ACTION.widget3Action,
                data: {
                    level: null,
                    list: [],
                }
            })
        }
    }, [])

    return (
        <>
            { (widgetQueueList.length>0 )
            && (
                <div className={`widget-three-class`}>
                    { widgetQueueList.map(item=>{
                        return(
                            <div className={`widget-three-class-wrap`} key={Object.keys(item)[0]}>
                                <div className="text-box" dangerouslySetInnerHTML={{__html: Object.values(item)[0]['content']}}></div>
                            </div>
                        )
                    })}

                </div>
            )}
            { (widgetQueueList.length===0 )
            && (
                <></>
            )}
        </>

    )
}
