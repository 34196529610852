import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HOT_AREA_TYPE } from '@/store/action/type';
import Finish from '@/assets/parking/paking_crusing_finish.png';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import Icon from '@/assets/parking/d_new/complete_icon.png';
import Modal from '@/components/parkingContainer/globalComponent/modal/index';

export default function ParkingComplete() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const statistic = useSelector((state) => state?.featureModelParking?.lpnp?.statistic);
    const parking_fail_reason = useSelector((state) => state?.featureModelParking?.lpnp?.failReason?.parkingFailReason);
    const area = useSelector((state) => state?.layout?.Area);
    const preStatus = useRef(null);
    const curStatus = useRef(null);
    const [isShow, setIsShow] = useState(false);
    const comfirmBtnRef = useRef(null);
    const dispatch = useDispatch();

    const comfirmBtn = comfirmBtnRef?.current?.getBoundingClientRect();

    const hide = () => {
        setIsShow(false);
    };

    const invokeComplete = () => {
        let obj = {
            pageType: 2,
            cruisingDistance: parseInt(statistic?.cruisingDistance || 0),
            avoidPeople: parseInt(statistic?.avoidPeopleCount || 0),
            avoidCar: parseInt(statistic?.avoidVehiclesCount || 0),
        };
        window?.momentaJsBridge?.dispatch('showLPNP', '', JSON.stringify(obj));
    };
    useEffect(() => {
        // 触发条件: MAP_BEING_VERIFIED -> PASSIVE && mapping_fail_reason为空
        if (
            preStatus?.current?.current == 'PARKING' &&
            (status == 'STANDBY' || status == 'PASSIVE') &&
            !parking_fail_reason
        ) {
            singelPointReport({
                eventName: reportEventName.HMI_LPNP_PARKING_SUCC_VIEW,
                timestamp: new Date().getTime(),
                text: String(parseInt(statistic?.cruisingDistance || 0)),
            });
            //LPNP完成
            if (userMode === 'D') {
                setIsShow(true);
            } else {
                setIsShow(false);
                invokeComplete();
            }
            Modal.clear();
        }
        if (status != curStatus) {
            preStatus.current = curStatus;
            curStatus.current = status;
        }
    }, [status]);

    useEffect(() => {
        const comfirmBtnInfo = `${comfirmBtn?.x}, ${comfirmBtn?.y}, ${comfirmBtn?.width}, ${comfirmBtn?.height}`;
        comfirmBtn &&
            dispatch({
                type: HOT_AREA_TYPE.studyComplete,
                data: comfirmBtn ? comfirmBtnInfo : [],
            });
    }, [isShow, comfirmBtn?.x, comfirmBtn?.y, comfirmBtn?.width, comfirmBtn?.height]);

    return (
        <div>
            {isShow ? (
                <div className={`study-status ${userMode == 'D' ? '' : 'sr'}`}>
                    <div className="study-status-left">
                        <h3>
                            <img className="p-study-complete" src={Icon} />
                            LPNP Complete
                        </h3>
                        <label>Route Info:</label>
                        <p>
                            Cruising Distance <i>{parseInt(statistic?.cruisingDistance || 0)}</i>m
                        </p>
                        <p>
                            Pedestrian Avoidance <i>{parseInt(statistic?.speedBumpsCount || 0)}</i>
                        </p>
                        <p>
                            Vehicle Avoidance <i>{parseInt(statistic?.speedBumpsCount || 0)}</i>
                        </p>
                        <p ref={comfirmBtnRef} onClick={() => hide()} className="study-btn">
                            Complete
                        </p>
                    </div>
                    <div className="study-status-right">
                        <img src={Finish} alt="parking unlocked" />
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
