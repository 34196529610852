import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd-mobile';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { useTranslation } from 'react-i18next';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function TFLSwitch({ isDisabled }) {
    const dispatch = useDispatch();
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    // CP告警 OPEN/CLOSE
    const cp_warning_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.cp_warning_control?.switch,
    );
    const [cpWarningSwitch, setCpWarningSwitch] = useState(cp_warning_switch === 'OPEN');
    const { t: translate } = useTranslation();
    const changeTimer = useRef(null);

    useEffect(() => {
        cp_warning_switch && setCpWarningSwitch(cp_warning_switch === 'OPEN');
        if (changeTimer.current) {
            clearTimeout(changeTimer.current);
            changeTimer.current = null;
        }
    }, [cp_warning_switch]);

    useEffect(() => {
        const disableCpWarningSwitch = async () => {
            // CP开关关闭时，如果CpWarning开关是打开状态，则将CpWarning开关关闭，且开关置灰
            if (isDisabled && cp_warning_switch === 'OPEN') {
                await cpWarningSwitchControl(false);
            }
        };
        disableCpWarningSwitch();
    }, [isDisabled, cp_warning_switch]);

    const switchChange = async (v) => {
        // CP开关关闭时，此时若用户点击Cpwarning开关，智驾页弹窗提示“请先开启CP再使用”
        if (isDisabled) {
            const text = translate('settings.pleaseEnableCp');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }

        clearTimeout(changeTimer);
        changeTimer.current = setTimeout(() => {
            setCpWarningSwitch(cp_warning_switch === 'OPEN');
        }, 2 * 1000);
        reportDataFunc(v ? 'OPEN' : 'CLOSE');

        await cpWarningSwitchControl(v);
    };

    // 通知底层
    const cpWarningSwitchControl = async (value) => {
        // todo 换成新的cpwarning信号
        FM.sendToHMIDriving(
            SocketMap.cp_warning_control,
            {
                switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };

    // 数据上报
    const reportDataFunc = (v) => {
        singelPointReport({
            eventName: reportEventName.HMI_CPWARNING_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                TFL: v,
            },
        });
    };

    return (
        <div className="select-group-item">
            <div className="select-group-switch">
                <Switch
                    checked={cpWarningSwitch}
                    // disabled={isDisabled}
                    onChange={(checked) => switchChange(checked)}
                    className="custom-switch"
                />
                <span className="switch-name">CP Warning</span>
            </div>
        </div>
    );
}
