import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
import localCache from '@/utils/localCache';
import store from '@/store';
import dialog_zh_us from '../img/dialog_zh_us.png';
import dialog_en_us from '../img/dialog_en_us.png';
import style from '../css/dialog.module.less';

const { playTTSAudio, playLocalAudio } = AppFn;
const state = store?.getState();
const i18nLang = state?.themeAction?.i18nState;

export const STATUS = {
    CLOSE: 'CLOSE',
    OPEN: 'OPEN',
    WARNING: 'WARNING',
};

const FCW_FCN_STATE = {
    NORMAL: false,
    WARNING: true,
};

class ADAS_BASE {
    constructor(type) {
        this.cur_fcn_type = type;
    }
}

class FcwDialog {
    constructor(dom) {
        this.root = dom || document.body;
        this.dialogDom = null;
    }

    show() {
        this.close();
        let div = document.createElement('div');
        let img = document.createElement('img');
        // let i18nLang = window.localStorage.getItem("i18nLang");
        img.src = i18nLang !== 'en-US' ? dialog_zh_us : dialog_en_us;
        img.alt = '请注意前车';
        div.setAttribute('data-name', 'fcw-dialog');
        div.className = style['fcw-dialog-wrap'];
        div.classList.add(style['fadeIn']);
        div.classList.add(style['animated']);
        div.classList.add(style['infinite']);
        div.appendChild(img);
        try {
            this.root.appendChild(div);
            this.dialogDom = div;
            this.closeAutoDelay();
        } catch (err) {
            console.log(err);
        }
    }

    close() {
        this.dialogDom && this.root.removeChild(this.dialogDom);
        this.dialogDom = null;
    }

    closeAutoDelay() {
        setTimeout(() => {
            this.close();
        }, 10000);
    }
}

class FcwState extends ADAS_BASE {
    constructor() {
        super();
        this.fcwD = new FcwDialog(document.getElementsByClassName('mvizArea')[0]);
    }

    changeFcnType(type) {
        if (type === this.cur_fcn_type) return;
        this.cur_fcn_type = type;
        if (FCW_FCN_STATE.WARNING === type) {
            this.showDialog();
            this.playAudio();
        } else {
            this.close();
        }
    }

    showDialog() {
        this.fcwD.show();
    }

    close() {
        this.fcwD.close();
    }

    playAudio() {
        // let i18nLang = window.localStorage.getItem("i18nLang");
        if (i18nLang !== 'en-US') {
            playTTSAudio('请注意前车', 'Highest');
        } else {
            playTTSAudio('Forward Collision Warning', 'Highest');
        }
    }
}

export { FcwState };
