import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Space, Button, SpinLoading, Modal, Grid } from 'antd-mobile';
import { InformationCircleOutline, LeftOutline } from 'antd-mobile-icons';

import { TASK_STATUS_MAP, ROUTE_TYPE } from '../const';
import dayjs from 'dayjs';
import { getSoftwareStatus, downloadAndDeploy, getTaskList, startTask, endTask, getTaskMileage } from '../../api';
import { StatusLabel } from './fn';
import Toast from '@/components/workbench/Toast';

import eventBus from '@/utils/eventBus';
import eventMap from '../../eventMap';
import { useIsParking } from '../../hook';

import './index.less';

export default function TaskDetail({ taskInfo = {}, visbale, onCancel }) {
    const isParking = useIsParking();
    const box_id = useSelector((state) =>
        isParking ? state?.featureModelParking?.body?.boxId : state?.featureModelDriving?.baseData?.body?.boxid,
    );
    // not_download-未下载
    // downloading-下载中
    // downloaded-已下载
    // deploying-部署中
    // running-运行中
    // fail-下载或部署失败
    const [status, setStatus] = useState('');

    // start or end staus
    // const [real_task_status,setReal_task_status] = useState(taskInfo.real_task_status)

    //手动开始结束的状态
    const [manual_task_status, setManual_task_status] = useState(taskInfo.manual_task_status);

    const [totalMile, setTotalMile] = useState(undefined);

    //是否有正在执行中的任务
    const [isRunningTask, setIsRunningTask] = useState(false);

    const [mileList, setMileList] = useState([]); //mile真实数据

    const [modalVisible, setModalVisible] = useState(false);

    const [loading, setLoading] = useState(false);
    const [startloading, setStartloading] = useState(false);

    const rollUpTimer = useRef();
    const request = useRef(false);
    let {
        driver,
        owner,
        task_name,
        task_function,
        task_form,
        estimate_end_time,
        estimate_start_time,
        real_task_status,
        informer,
        description,
        task_require,
        task_routes,
        software_version,
        _id,
    } = taskInfo;

    const taskRoute = useMemo(() => {
        const { name, route_names } = task_routes || {};
        if (route_names) return route_names.join();
        return ROUTE_TYPE.find(({ value }) => value === name)?.label;
    }, [task_routes]);

    const getSoftwareStatusFn = async () => {
        let res = await getSoftwareStatus({
            deviceBoxId: box_id,
            softwareName: software_version,
        });
        if (res.code === 0) {
            setStatus(res.data);
        }
    };

    //获取里程
    const getTaskMileageFun = () => {
        if (_id) {
            getTaskMileage({ task_id: _id, device_box_id: box_id })
                .then((res) => {
                    if (res.code === 200) {
                        setMileList(res?.data?.task_laps_arr || []);
                        //   setMileList([{route_name:'Suzhou_EUS081', mileage: 331,},
                        //   {route_name:'Suzhou_EUS02', mileage: 332,},
                        //   {route_name:'Suzhou_EUS03', mileage: 333,},
                        //   {route_name:'Suzhou_EUS04', mileage: 334,},
                        //   {route_name:'Suzhou_EUS08', mileage: 333,},
                        //   {route_name:'Suzhou_EUS09', mileage: 334,},
                        //  ])
                        setTotalMile(res?.data?.task_total);
                    } else {
                        Toast.info('获取里程信息失败:' + res.message, 2000);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };
    useEffect(() => {
        if (!_id) return;
        getTaskMileageFun();
        if (real_task_status === 4) {
            eventBus.emit(eventMap.refreshMileageDataByTaskDetail);
            eventBus.on(eventMap.refreshMileageDataByDistanceInfo, getTaskMileageFun);
        }
        return () => {
            eventBus.off(eventMap.refreshMileageDataByDistanceInfo);
        };
    }, [_id]);

    //获取当前任务最新状态
    const getNewTaskStatus = () => {
        let params = { box_id: box_id, date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') };
        getTaskList(params).then(({ code, data, message }) => {
            if (code === 200) {
                const tasks = data?.tasks || [];

                tasks.map((item) => {
                    if (item._id === _id) {
                        // setReal_task_status(item.real_task_status)
                        setManual_task_status(item.manual_task_status);
                    }
                });
                setModalVisible(false);
            }
        });
    };

    useEffect(async () => {
        if (!software_version) return;
        await getSoftwareStatusFn();
        clearInterval(rollUpTimer.current);
        rollUpTimer.current = setInterval(async () => {
            await getSoftwareStatusFn();
        }, 5000);
        return () => {
            clearInterval(rollUpTimer.current);
        };
    }, [software_version]);

    useEffect(() => {
        setManual_task_status(taskInfo.manual_task_status);
    }, [taskInfo.manual_task_status]);

    // 返回操作
    const backButton = () => {
        clearInterval(rollUpTimer.current);
        onCancel();
    };
    // 确定按钮
    const handleConfim = async () => {
        // 如果有未结束的任务，必须先手动结束才能继续。
        if (isRunningTask) {
            setModalVisible(false);
            return;
        }
        if (manual_task_status === 0) {
            startTask({
                device_box_id: box_id,
                task_id: _id,
            })
                .then((res) => {
                    if (res.code === 200) {
                        getNewTaskStatus();
                    } else {
                        Toast.info('开始失败：' + res.message, 2000);
                    }
                })
                .catch((e) => {
                    Toast.info('开始任务失败', 2000);
                });
        } else if (manual_task_status === 1) {
            endTask({
                device_box_id: box_id,
                task_id: _id,
            })
                .then((res) => {
                    if (res.code === 200) {
                        getNewTaskStatus();
                    } else {
                        Toast.info('结束失败：' + res.message, 2000);
                    }
                })
                .catch((e) => {
                    Toast.info('结束任务失败', 2000);
                });
        }
    };

    const startOrEndButton = () => {
        //执行展示开始
        if (manual_task_status === 0) {
            return (
                <Button
                    fill="outline"
                    loading={loading}
                    style={{
                        '--border-color': '#fff',
                        '--background-color': '#00D8A4',
                        '--text-color': '#fff',
                        opacity: startloading ? 0.4 : 1,
                    }}
                    onClick={async () => {
                        if (startloading) {
                            return;
                        }
                        let params = { box_id, date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') };
                        setStartloading(true);
                        getTaskList(params)
                            .then(({ code, data, message }) => {
                                setStartloading(false);
                                if (code === 200) {
                                    const tasks = data?.tasks || [];
                                    //状态为5
                                    let ishas = false;
                                    for (let i = 0; i < tasks.length; i++) {
                                        //如果找到当前任务依旧没有找到，则符合开始条件，直接退出
                                        if (tasks[i]._id === _id) {
                                            break;
                                        }
                                        ishas = tasks[i].real_task_status !== 5;
                                        //如果在任务列表之前就找到了，直接退出
                                        if (ishas) {
                                            break;
                                        }
                                    }
                                    setIsRunningTask(ishas);
                                    setModalVisible(true);
                                } else {
                                    Toast.info('获取任务列表状态失败：', 2000);
                                }
                            })
                            .catch((e) => {
                                setStartloading(false);
                                Toast.info('任务列表接口调用失败', 2000);
                            });
                    }}
                >
                    手动开始任务
                </Button>
            );
        } else if (manual_task_status === 1)
            // 执行中
            return (
                <Button
                    fill="outline"
                    style={{
                        '--background-color': 'rgba(42, 105, 226, 1)',
                        '--text-color': '#fff',
                        opacity: loading ? 0.4 : 1,
                    }}
                    onClick={() => {
                        setModalVisible(true);
                        setIsRunningTask(false);
                    }}
                >
                    手动结束任务
                </Button>
            );
    };

    const setFlag = (v) => {
        request.current = v;
        setLoading(v);
    };

    const startDownloadAndDeploy = async (type) => {
        if (!box_id || !software_version || !type) return;
        if (request.current) return;
        setFlag(true);
        let res = await downloadAndDeploy({
            deviceBoxId: box_id,
            softwareName: software_version,
            type,
        });
        if (res.code === 0) {
            return await getSoftwareStatusFn();
        }

        Toast.info(res?.message || '接口调用失败', 2000);

        setFlag(false);
    };

    const ButtonStatus = useMemo(() => {
        return (
            <Space className="btn-box" justify={'between'}>
                {startOrEndButton()}
            </Space>
        );
        // 未存在/
        /* 隐藏掉下载部署软件版本逻辑
    if(!software_version) {
      return <Space className="btn-box" justify={"between"}>
        {
        // backButton()
         startOrEndButton()
        }
      </Space>
    }
    switch (status) {
      case "not_download":
      case "downloaded":
        return <Space className="btn-box" justify={software_version ? "between":"center"}>
            <Button
              onClick={()=>{ startDownloadAndDeploy(status == "not_download" ? "download": "deploy")}}
              fill="solid"
              style={{ '--background-color': 'rgba(42, 105, 226, 1)', '--text-color': '#fff', opacity: loading ? .4 : 1 }}
            >
              <div style={{display: "flex", justifyContent: "center", alignContent:"center"}}>
                <span style={{paddingRight: ".1rem"}}>
                  { status == "not_download" ? "下载软件包" : "部署软件包"}
                </span>
                { loading ? <SpinLoading color='white' style={{'--size': '0.3rem'}}/> : null}
              </div>
            </Button>
            {startOrEndButton()}
        </Space>;
      case "downloading":
      case "deploying":
        setFlag(false);
        return <Space className="btn-box" justify={"between"}>
            <Button
              fill="solid"
              style={{ '--background-color': 'rgba(42, 105, 226, 1)', '--text-color': '#fff', 'opacity': 0.4 }}
            >
              { status == "downloading" ? "软件包下载中" : "软件包部署中"}...
            </Button>
            {startOrEndButton()}
        </Space>;
      case "download_fail":
        return <Space className="btn-box" justify={"between"}>
            <Button
              onClick={()=>{startDownloadAndDeploy("download")}}
              fill="solid"
              style={{ '--background-color': 'rgba(42, 105, 226, 1)', '--text-color': '#fff'}}
            >
              下载失败，点击重试
            </Button>
            {startOrEndButton()}
        </Space>;
      case "deploy_fail":
        return <Space className="btn-box" justify={"between"}>
            <Button
              onClick={()=>{startDownloadAndDeploy("deploy")}}
              fill="solid"
              style={{ '--background-color': 'rgba(42, 105, 226, 1)', '--text-color': '#fff'}}
            >
              部署失败，点击重新部署
            </Button>
            {startOrEndButton()}
        </Space>;
      default:
        return <Space className="btn-box" justify={"center"}>
          {startOrEndButton()}
        </Space>;
    }
    */
    }, [software_version, status, startloading, manual_task_status, _id]);

    if (!visbale) return null;

    return (
        <div className="task-detail-container">
            <h4 className="title">当前任务</h4>
            <div className="task-info">
                <div className="back-content" onClick={backButton}>
                    <span>
                        <LeftOutline /> 返回
                    </span>
                </div>
                <h5 className="task-title">任务信息</h5>
                <ul className="task-info-list">
                    <li className="task-info-item">任务名称：{task_name ? task_name : '--'}</li>
                    <li className="task-info-item">
                        安全员：{driver?.driver_name ? `${driver?.driver_name} | ${driver?.phone}` : '未分配'}
                    </li>
                    <li className="task-info-item">任务类型：{task_form}</li>
                    <li className="task-info-item">任务所属功能：{task_function}</li>
                    <li className="task-info-item">
                        任务时间：{dayjs(estimate_start_time).format('YYYY-MM-DD HH:mm:ss')}至
                        {dayjs(estimate_end_time).format('YYYY-MM-DD HH:mm:ss')}
                    </li>
                    <li className="task-info-item">
                        任务状态：{(TASK_STATUS_MAP[real_task_status] || TASK_STATUS_MAP.default).text}
                    </li>
                    <li className="task-info-item">任务要求：{task_require}</li>
                    <li className="task-info-item">任务路线：{taskRoute}</li>
                    <li className="task-info-item">
                        任务知会人：{Array.isArray(informer) ? informer.map((item) => item?.user_name).join() : ''}
                    </li>
                    <li className="task-info-item">
                        路测员：{owner?.user_name} | {owner?.phone}
                    </li>
                    <li className="task-info-item">备注：{description || '无'}</li>
                    <li className="task-info-item">
                        软件包：{software_version || '无'} {StatusLabel(status)}
                    </li>
                </ul>
                <div className="tasks-contains">
                    <Grid columns={2} className="task-content">
                        <Grid.Item>
                            <p className="t-title">本次任务里程明细</p>
                        </Grid.Item>
                        <Grid.Item>
                            <p className="t-miles">
                                本次任务已行驶总里程 <span>{totalMile ? totalMile.toFixed(2) + 'KM' : '--'}</span>
                            </p>
                        </Grid.Item>
                    </Grid>
                    {new Array(Math.ceil(mileList.length / 2)).fill('').map((item, idx) => {
                        let values1 = {};
                        let values2 = {};
                        if (idx === 0) {
                            values1 = mileList[0];
                            values2 = mileList[1];
                        } else {
                            values1 = mileList[2 * idx];
                            values2 = mileList[2 * idx + 1];
                        }
                        return (
                            <Grid key={idx} columns={2} className="table-content">
                                <Grid.Item>
                                    <p className="t-route">{values1?.route_name}</p>
                                    <p className="route-miles">{values1?.mileage?.toFixed(2) + 'KM'}</p>
                                </Grid.Item>
                                <Grid.Item>
                                    <p className="t-route">
                                        {values2?.route_name}
                                        <span className="route-miles">
                                            {values2?.mileage ? values2.mileage.toFixed(2) + 'KM' : ''}
                                        </span>
                                    </p>
                                </Grid.Item>
                            </Grid>
                        );
                    })}
                </div>
                {ButtonStatus}
            </div>
            <Modal
                bodyClassName="modal-content"
                visible={modalVisible}
                content={
                    <div>
                        <span className="title-content">
                            <InformationCircleOutline
                                style={{ marginRight: '.1rem', fontSize: '.30rem', color: '#FFBD0A' }}
                            />
                            {isRunningTask
                                ? '无法开始该任务，请先手动结束之前任务。'
                                : `确定${manual_task_status === 0 ? '开始' : '结束'}该任务吗？`}
                        </span>
                        <Grid columns={2} style={{ textAlign: 'center', marginBottom: '.27rem' }}>
                            <Grid.Item>
                                <Button
                                    fill="outline"
                                    style={{
                                        '--border-color': 'rgba(137, 145, 160, 1)',
                                        '--text-color': 'rgba(137, 145, 160, 1)',
                                        width: '2.72rem',
                                        height: '.78rem',
                                        borderRadius: '.08rem',
                                    }}
                                    onClick={() => {
                                        setModalVisible(false);
                                        setIsRunningTask(false);
                                    }}
                                >
                                    <span className="cancel">取消</span>
                                </Button>
                            </Grid.Item>
                            <Grid.Item>
                                <Button
                                    fill="outline"
                                    style={{
                                        '--background-color': 'rgba(42, 105, 226, 1)',
                                        '--text-color': '#fff',
                                        width: '2.72rem',
                                        height: '.78rem',
                                        borderRadius: '.08rem',
                                    }}
                                    onClick={handleConfim}
                                >
                                    <span className="comfirm">确认</span>
                                </Button>
                            </Grid.Item>
                        </Grid>
                    </div>
                }
                closeOnAction
            />
        </div>
    );
}
