import { useSelector } from 'react-redux';
import { getCookie } from '@/utils/tools';
import { isOfflinePack, isNativeLoad } from '@/utils/isEnv';
import './index.less';

const packageInfo = require('./../../../package.json');

export default function InforTips() {
    const current_scene = useSelector((state) => state?.scene?.current_scene);
    const box_id = useSelector((state) => state?.featureModelDriving?.baseData?.body?.boxid);
    const ip = useSelector((state) => state?.featureModelDriving?.baseData?.body?.ip);
    const carType =
        useSelector(
            (state) => state?.featureModelDriving?.baseData?.hmi?.car_type || state?.featureModelCommon?.carType,
        ) || 'Devcar';
    // const carType = getCookie('carType') || 'Devcar';
    // const maf_version_state = useSelector(state => state?.featureModelDriving?.baseData?.hmi?.maf_version);
    // ua信息
    const userAgent = window.navigator.userAgent;
    // appVersion信息
    const regVersion = /hmi_appVersion\(([^\)]+)\)/;
    const appVersion = regVersion.exec(userAgent) ? regVersion.exec(userAgent)[1] : '';
    const hmi_version = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.hmi_version);
    const ota_version = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.ota_version);
    // release_platform (MAIN HNP UNP CP)
    const release_platform = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.release_platform);

    const ip_pnp = useSelector((state) => state?.featureModelParking?.body?.ip);
    const box_id_pnp = useSelector((state) => state?.featureModelParking?.body?.boxId);
    const release_platform_pnp = useSelector((state) => state?.featureModelParking?.hmi?.releasePlatform);
    const hmi_version_pnp = useSelector((state) => state?.featureModelParking?.hmi?.hmiVersion);

    // 设备信息
    const regDevice = /hmi_uniqueId\(([^\)]+)\)/;
    const deviceID = regDevice.exec(userAgent) ? regDevice.exec(userAgent)[1] : '';

    // 车端版本号
    const otaVersion = ota_version ? `${ota_version?.split('-')[1]}-${ota_version?.split('-')[2]}` : '';

    return (
        <div className="infor_tips">
            <p className="infor_tips_title">Version</p>
            {release_platform === 'UNP' && <p>{release_platform}</p>}
            {box_id && <p>{box_id}</p>}
            {carType && <p>{carType}</p>}
            {appVersion && (
                <p>
                    {appVersion} {packageInfo?.version} {isOfflinePack ? '' : 'cloud'}
                </p>
            )}
            <p>{ip && ip !== 'Failed to get IP of vpn' ? ip : 'Searching IP...'}</p>
            {/* {maf_version_state && <p>{maf_version_state}</p>} */}
            {/* {hmi_version && <p>{hmi_version}</p>} */}
            {otaVersion && <p>{otaVersion}</p>}
            {deviceID && <p>{deviceID}</p>}
        </div>
    );
}
