/**
 * M6.2 CP下增加自主变道开关（https://momenta.feishu.cn/wiki/wikcnCH4L2snoWfcsZhCMkf3eef）
 * auth: @he.gao
 */
import {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import {Switch} from 'antd-mobile';
import {SocketMap} from '@/constant/socketMap';
import {emit} from '@/utils/robonode';
import {cusToast} from '@/components/customToast';
import FM from "@/utils/nativeSubAndPub";
import {useTranslation} from 'react-i18next';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function AutoLaneChangeSwitch({isDisabled}) {
    const cp_autonomous_switch = useSelector(state => state?.featureModelDriving?.switch?.driving?.lane_change_mode?.cp_autonomous_switch);
    const [checkedAutoLaneChange, setCheckAutoLaneChange] = useState(cp_autonomous_switch ? cp_autonomous_switch : "AUTONOMOUS_CLOSE");
    const {t: translate} = useTranslation();

    // 主动变道开关文案枚举
    const autoLaneChangeOptions = translate("autoLaneChange.autoLaneChangeOptions",{returnObjects: true })
    const autoLaneChangeTips = translate("autoLaneChange.autoLaneChangeTips",{returnObjects: true })

    useEffect(() => {
        if(!cp_autonomous_switch) {
            handleClick("AUTONOMOUS_CLOSE")
            return
        }
        // if(checkedAutoLaneChange === cp_autonomous_switch) return;
        // 设置开关
        // setCheckAutoLaneChange(cp_autonomous_switch);
        // cp_autonomous_switch && setAutoLaneChangeSwitch(cp_autonomous_switch === 'OPEN' ? true : false);
    }, [cp_autonomous_switch]);

    const handleClick = async (v) => {
        console.log(v);
        if (isDisabled) return;
        if (!v || v === checkedAutoLaneChange) return
        // 数据上报
        reportDataFunc(v);
        // 设置开关
        setCheckAutoLaneChange(v);
        await laneChangeControl(v)
    }

    // 通知底层
    const laneChangeControl = async (mode) => {
        FM.sendToHMIDriving(SocketMap.autoLaneChangeControl, {
            mode
        },'drive_node_res_fail_cb');
    }
    // 数据上报
    const reportDataFunc = (v) =>{
        singelPointReport({
            eventName: reportEventName.HMI_SPEED_lANECHANGE_CLICK,
            timestamp: new Date().getTime(),
            text: v ,
        })
    }


    return (
        <div className='select-group-item'>
            <p className='select-group-item-name name'>{translate("autoLaneChange.checkedAutoLaneChange")}</p>
            <div className={`select-box`}>
                {
                    autoLaneChangeOptions.map((item, index) => {
                        return(
                            <span
                                key={index}
                                className={`select-box-item ${checkedAutoLaneChange === item.value ? 'active' : ''}`}
                                onClick={()=> handleClick(item.value) }
                            >
                            {item.label}
                        </span>
                        )
                    })
                }
            </div>
            {autoLaneChangeTips[checkedAutoLaneChange] && <p className="auto-lanechange-tip">{autoLaneChangeTips[checkedAutoLaneChange]}</p>}
        </div>
    )
}