import React from "react";
import {useTranslation} from 'react-i18next';
import OtherSettings from "./otherSettings";
import BroadcastMode from "../drivingAssistSetting/broadcastMode";
import './index.less';

export default function RoadTestComponent() {
    const {t: translate} = useTranslation();
    return (
        <div className="road-test-setting">
            {/* 播报方式 */}
            <div className="road-test-setting-item">
                <div className="road-test-setting-item-title">{translate('settings.broadcastMode')}</div>
                <div className="road-test-setting-item-content">
                    <div className="safety_module_broadcast">
                        <BroadcastMode/>
                    </div>
                </div>
            </div>
            {/* 显示设置 */}
            <div className="road-test-setting-item" style={{paddingTop: ".6rem"}}>
                <div className="road-test-setting-item-title">{translate('settings.additionalSettings')}</div>
                <div className="road-test-setting-item-content">
                    <div className="safety_module_broadcast">
                        <OtherSettings/>
                    </div>
                </div>
            </div>
        </div>
    )
}
