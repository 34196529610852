import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import WidgetComponent from '@/components/widget/index';
import { AppFn } from '@/utils/native';
import { widgetQueue, widgetType } from '@/utils/widgetQueue';
import { useTranslation } from 'react-i18next';
import localAudioNames from '@/constant/localAudioName';
const { playLocalAudio } = AppFn;

export default function CpWarning() {
    const { t: translate } = useTranslation();
    const cp_road_boundary_collision_warn_level = useSelector(
        (state) => state?.featureModelDriving?.cpRoadBoundaryCollision?.notice?.data?.warn_level,
    );
    const cp_rear_car_collision_warn_level = useSelector(
        (state) => state?.featureModelDriving?.cpRearCarCollision?.notice?.data?.warn_level,
    );
    const cp_cross_boundary_warn_level = useSelector(
        (state) => state?.featureModelDriving?.cpCrossBoundary?.notice?.data?.warn_level,
    );

    useEffect(() => {
        let type = '';
        let widgetContent = '';
        let audio = '';
        // CP道路边缘碰撞提醒
        if (cp_road_boundary_collision_warn_level) {
            widgetContent = `${translate(
                `cpWarning.cp_road_boundary_collision.${cp_road_boundary_collision_warn_level?.toString()}`,
            )}`;
            type = widgetType.CP_ROAD_BOUNDARY_COLLISION;
            audio =
                cp_road_boundary_collision_warn_level === 1
                    ? localAudioNames.CP_ROAD_BOUNDARY_COLLISION_VOICE_1
                    : localAudioNames.CP_ROAD_BOUNDARY_COLLISION_VOICE_2;
        }
        // CP后车碰撞报警和加速提示
        if (cp_rear_car_collision_warn_level) {
            widgetContent = translate('cpWarning.cp_rear_car_collision');
            type = widgetType.CP_REAR_CAR_COLLISION;
            audio = localAudioNames.CP_REAR_CAR_COLLISION;
        }
        // CP车道线压线提醒
        if (cp_cross_boundary_warn_level) {
            widgetContent = `${translate(`cpWarning.cp_cross_boundary.${cp_cross_boundary_warn_level?.toString()}`)}`;
            type = widgetType.CP_CROSS_BOUNDARY;
            audio =
                cp_cross_boundary_warn_level === 1
                    ? localAudioNames.CP_CROSS_BOUNDARY_AUDIO
                    : localAudioNames.CP_CROSS_BOUNDARY_VOICE;
        }
        if (type === '') {
            widgetQueue({ widgetNumber: 1, type: widgetType.CP_ROAD_BOUNDARY_COLLISION, widgetContent: null });
            widgetQueue({ widgetNumber: 1, type: widgetType.CP_REAR_CAR_COLLISION, widgetContent: null });
            widgetQueue({ widgetNumber: 1, type: widgetType.CP_CROSS_BOUNDARY, widgetContent: null });
            return;
        }
        let contentText = { icon: 'highest_remind', content: widgetContent };
        widgetQueue({ widgetNumber: 1, type: type, widgetContent: contentText });
        audio !== '' && playLocalAudio(audio, 'Important');
    }, [cp_road_boundary_collision_warn_level, cp_rear_car_collision_warn_level, cp_cross_boundary_warn_level]);

    return (
        <React.Fragment>
            <WidgetComponent />
        </React.Fragment>
    );
}
