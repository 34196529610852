import { MODAL_ACTION } from '../action/type';

const INIT_STATE_MODAL = {
    list: []
};
export default function modalData(state = INIT_STATE_MODAL, action) {
    switch (action.type) {
        case MODAL_ACTION.modalAction:
            return { ...state, ...action.data };
        default:
            return state;
    }
}
