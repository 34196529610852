import React, {useState, useEffect} from "react";
import {useTranslation} from 'react-i18next';
import {Switch} from "antd-mobile";
import CardLayout from "../../../components/cardLayout";
import rcw from '@/assets/settingComNew/activeSafety/rcw.png';
import useBSDDOWRCWCommon from "../BSDDOWRCWCommon";
import {useSelector} from "react-redux";
import "@/components/slider/smartDrivingSetting/components/cardStyle/index.less";

export default function RcwCard() {
    const {t: translate} = useTranslation();
    const rcw_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.rcw_switch);
    const [RCWSwitch, setRCWSwitch] = useState(rcw_switch === 'OPEN');

    const [timer, setTimer] = useState(null)  // 存放定时器句柄

    const {switchChange} = useBSDDOWRCWCommon({timer: timer, setTimer: setTimer});

    useEffect(() => {
        rcw_switch && setRCWSwitch(rcw_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
    }, [rcw_switch])


    const leftDom = (
        <div className={"left_dom_wrap"}>
            <div className={"switch_wrap"}>
                <div className={"content_wrap"}>
                    <div className={"text_wrap"}>RCW</div>
                    <div className={"text_wrap_detail"}>{translate('BSDLCADOWRCW.RCWSwitch')}</div>
                </div>
                <Switch
                    checked={RCWSwitch}
                    onChange={(checked) => switchChange(checked, "RCW", setRCWSwitch)}
                    className={"customSwitch"}
                />
            </div>
        </div>
    )

    const rightDom = (
        <div className={"right_dom_wrap"}>
            <img src={rcw}/>
        </div>
    )

    return (
        <>
            <CardLayout
                leftDom={leftDom}
                rightDom={rightDom}
            />
        </>
    )
}
