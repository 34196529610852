import store from '@/store';

const ua = window.navigator.userAgent;
const reg = /language\(([^\)]+)\)/;
const isCN = () => {
    const state = store?.getState();
    const i18nLang = state?.themeAction?.i18nState;
    return i18nLang !== 'en-US' || reg.exec(ua)?.[1] !== 'en-US';
};

export default isCN;
