import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { modalQueue, modalType } from '@/utils/modalQueue';
import HEIGHT_UNPASSABLE from '@/assets/hnp/adas/NRP/height_unpassable.png';
import WIDTH_UNPASSABLE from '@/assets/hnp/adas/NRP/width_unpassable.png';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
import { useTranslation } from 'react-i18next';

// 前方路况变化，语音提醒用户谨慎驾驶
const reminder = {
    WIDTH_PASSABLE: 'WIDTH_PASSABLE',
    HEIGHT_PASSABLE: 'HEIGHT_PASSABLE',
    WIDTH_PASSABLE_UNCONTROLLABLE: 'WIDTH_PASSABLE_UNCONTROLLABLE',
};
// 危险警告 ---> 既有语音提醒，又有弹窗提醒
const dangerWarningImg = {
    WIDTH_UNPASSABLE: WIDTH_UNPASSABLE, // 前方狭窄路段不可通行
    HEIGHT_UNPASSABLE: HEIGHT_UNPASSABLE, // 请注意前方高度不可通行
};

export function useNRP() {
    const { t: translate } = useTranslation();
    const NRPDangerWarningText = translate('settings.NRPDangerWarningText', { returnObjects: true });
    const nrp_notice_mode = useSelector((state) => state?.featureModelParking?.alert?.data?.mode);
    const timer = useRef(null);
    useEffect(() => {
        const reminderList = Object.keys(reminder);
        if (reminderList.includes(nrp_notice_mode)) {
            // 语音播放逻辑
            AppFn.playLocalAudio(localAudioNames?.[nrp_notice_mode], 'Important');
            return;
        }

        const dangerWarningList = Object.keys(dangerWarningImg);
        if (dangerWarningList.includes(nrp_notice_mode)) {
            clearTimeout(timer.current);
            // 语音播放逻辑
            AppFn.playLocalAudio(localAudioNames?.[nrp_notice_mode], 'Important');
            // 弹窗展示逻辑
            modalQueue({
                type: modalType.NRP,
                modalData: { img: dangerWarningImg[nrp_notice_mode], text: NRPDangerWarningText[nrp_notice_mode] },
            });
        } else {
            // 弹窗销毁逻辑
            timer.current = setTimeout(() => {
                modalQueue({ type: modalType.NRP, modalData: null });
            }, 500);
        }
    }, [nrp_notice_mode]);
}
