import openLplp from '@/assets/parking/icons/icon_open_lpnp.png';

import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HOT_AREA_TYPE } from '@/store/action/type';
import './index.less';

const operate_icon_map = {
    LPNP_OPEN: {
        iconD: openLplp,
        icon: openLplp,
        txt: 'Start LPNP', //"开启LPNP"
    },
};

export default function OperateBtns(props) {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);

    const lpnpBtnRef = useRef(null);
    const lpnpRect = lpnpBtnRef?.current?.getBoundingClientRect();

    const dispatch = useDispatch();

    useEffect(() => {
        const lpnpBtnInfo = `${lpnpRect?.x}, ${lpnpRect?.y}, ${lpnpRect?.width}, ${lpnpRect?.height}`;
        console.log('lpnpBtnInfo', lpnpBtnInfo);
        dispatch({
            type: HOT_AREA_TYPE.guideLpnpBtn,
            data: lpnpBtnInfo,
        });
    }, [status, lpnpRect?.x, lpnpRect?.y, lpnpRect?.width, lpnpRect?.height]);
    return (
        <>
            {
                <button
                    ref={lpnpBtnRef}
                    disabled={props?.disabled ? 'disabled' : ''}
                    onClick={props?.handler}
                    className={`guide-operate-btn ${userMode == 'D' ? 'd' : 'sr'} ${
                        props?.disabled ? 'disabled' : 'active'
                    } ${operate_icon_map[props?.name]?.icon ? 'highlight' : ''} ${
                        operate_icon_map[props?.name]?.class ? 'restart' : ''
                    }`}
                >
                    {operate_icon_map[props?.name]?.icon ? (
                        <img
                            src={
                                userMode === 'D'
                                    ? operate_icon_map[props?.name]?.iconD
                                    : operate_icon_map[props?.name]?.icon
                            }
                            alt="btn_icon"
                        />
                    ) : (
                        ''
                    )}
                    <span>{operate_icon_map[props?.name]?.txt}</span>
                </button>
            }
        </>
    );
}
