import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ParkingSocketMap } from '@/constant/socketMap';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import Modal from '../../../globalComponent/modal';
import FM from '@/utils/nativeSubAndPub';
import cancelIconD from '@/assets/parking/d_new/cancel_d.png';
import { HOT_AREA_TYPE } from '@/store/action/type';
import './index.less';

export default function Cancel() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);

    const dispatch = useDispatch();

    const cancelBtnRef = useRef(null);
    const cancelBtn = cancelBtnRef?.current?.getBoundingClientRect();

    const handleCancelStartStudy = async () => {
        console.log('xxxx-点击了取消');
        //取消学习
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_CANCEL_LEARNING_CLICK,
            timestamp: new Date().getTime(),
            text: 'lpnp cancel learning',
        });

        Modal.info({
            onOk: async () => {
                FM.sendToHMIParking(ParkingSocketMap.lpnp_cmd, {
                    cmd: 'CANCEL_LEARNING',
                });
            },
            okText: 'EXIT',
            cancelText: 'CONTINUE',
            content: 'Terminate the route learning will end the learning process and not save the route.',
            centered: true,
            isD: userMode === 'D',
        });
    };

    useEffect(() => {
        const cancelBtnInfo = `${cancelBtn?.x}, ${cancelBtn?.y}, ${cancelBtn?.width}, ${cancelBtn?.height}`;
        console.log('cancelBtnInfo', cancelBtnInfo);
        dispatch({
            type: HOT_AREA_TYPE.cancelBtn,
            data: cancelBtnInfo,
        });
    }, [status, cancelBtn?.x, cancelBtn?.y, cancelBtn?.width, cancelBtn?.height]);

    return (
        <>
            {status === 'LEARNING' ? (
                <div
                    ref={cancelBtnRef}
                    onClick={() => handleCancelStartStudy()}
                    className={`p-learning-cancel ${userMode == 'D' ? 'd' : 'sr'}`}
                >
                    <img className="p-cancel-icon" src={userMode == 'D' ? cancelIconD : cancelIconD} alt="cancel" />
                </div>
            ) : (
                ''
            )}
        </>
    );
}
