import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import store from '@/store';
import { HOT_AREA_TYPE } from '@/store/action/type';

import PNPDebug from '../debug/pnpDebug';
import { ToolMap } from '../config';
import './index.less';

export default function Tool() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const SetTab = useSelector((state) => state?.parking?.SetTab);
    const lpnpMtour = useSelector((state) => state?.switchModeAction?.switchUnpState);
    const [curTab, setTab] = useState('');
    const [showPNPDebug, setShowPNPDebug] = useState(false);
    const switchRef = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);

    const tool_map = ToolMap();

    const switchArea = switchRef?.current?.getBoundingClientRect();

    const handlerSetTab = (t) => {
        setTab(t);
        store.dispatch({
            type: 'SetTab',
            data: t,
        });
    };

    const tabChange = (tab) => {
        handlerSetTab(tab);
        switch (tab) {
            case 'set':
                setShowPNPDebug(false);
                break;
            case 'route':
                window?.momentaJsBridge?.dispatch('showLPNPHistoriesInMap', '', '');
                break;
            case 'apa':
                navigate(`/home/debug${location?.search}`);
                break;
            case 'lpnp':
                if (tab == curTab) {
                    handlerSetTab('');
                }
                togglePNPDebug();
                break;
            default:
                break;
        }
    };

    const togglePNPDebug = () => {
        if (showPNPDebug) {
            handlerSetTab('');
        }
        setShowPNPDebug(!showPNPDebug);
    };

    useEffect(() => {
        if (!SetTab) {
            setTab('');
        }
    }, [SetTab]);

    useEffect(() => {
        const switchAreaInfo = `${switchArea?.x}, ${switchArea?.y}, ${switchArea?.width}, ${switchArea?.height}`;
        dispatch({
            type: HOT_AREA_TYPE.pnpSoftSwitch,
            data: switchAreaInfo,
        });
    }, [switchArea?.x, switchArea?.y, switchArea?.width, switchArea?.height]);

    return (
        <div ref={switchRef} className={`p-tool-area ${userMode == 'D' ? 'd' : 'sr'}`}>
            <ul>
                {tool_map.map((item) => {
                    if ((lpnpMtour || userMode === 'C') && ['apa', 'lpnp'].includes(item.name)) {
                        return null;
                    }
                    if ((lpnpMtour || !switchWorkBench) && item.name === 'workbench') return null;
                    return (
                        <li
                            key={item.id}
                            onClick={() => tabChange(item?.name)}
                            className={curTab == item?.name ? 'active' : ''}
                        >
                            <img src={userMode == 'D' ? item?.iconD : item?.icon} alt="icon" />
                            {item?.txt && <span>{item?.txt}</span>}
                        </li>
                    );
                })}
            </ul>

            {showPNPDebug ? <PNPDebug close={() => togglePNPDebug()} /> : ''}
        </div>
    );
}
