import React from 'react';
import ReactDOM from 'react-dom';
import store from '@/store/index';
import { HOT_AREA_TYPE } from '@/store/action/type';

import Dialog from './dialog/index';

let modalNode = [],
    Modal = {};
// 移除节点
const removeNode = (fn) => {
    modalNode.forEach((node) => {
        try {
            React.isValidElement(node) && ReactDOM.unmountComponentAtNode(node);
            document.body.removeChild(node);
        } catch (err) {}
    });
    modalNode = [];
    if (typeof fn === 'function') {
        fn();
    }
};

const show = (props) => {
    let timer = null;
    removeNode();
    const div_wrap = document.createElement('div');
    modalNode.push(div_wrap);
    document.body.appendChild(div_wrap);

    const removeHotAll = () => {
        store.dispatch({
            type: HOT_AREA_TYPE.isHotAll,
            data: false,
        });
    };
    const _cancel = () => {
        // timer = setTimeout(() => {
        //   removeNode(props.onCancel);
        //   clearTimeout(timer);
        //   removeHotAll();
        // }, 350);
        removeNode(props.onCancel);
        removeHotAll();
    };

    const _ok = () => {
        removeNode(props.onOk);
        removeHotAll();
        // timer = setTimeout(() => {
        //   removeNode(props.onOk);
        //   removeHotAll();
        //   clearTimeout(timer);
        // }, 350);
    };

    const _props = {
        visible: true,
        onOk: _ok,
        onCancel: _cancel,
        mask: true,
        maskCloseable: false,
        centered: true,
        ...{
            title: props.title,
            okText: props.okText,
            cancelText: props.cancelText,
            maskCloseable: props.maskCloseable,
            centered: props.centered,
            style: props.style,
            isD: props.isD,
        },
    };

    try {
        store.dispatch({
            type: HOT_AREA_TYPE.isHotAll,
            data: true,
        });
        ReactDOM.render(<Dialog {..._props}>{props.content}</Dialog>, div_wrap);
    } catch (error) {}

    return null;
};

Modal.info = function info(content) {
    common(content, 'info');
};

Modal.clear = function () {
    removeNode();
};

// 合并参数为对象
function common(content, type) {
    let obj = {};
    if (typeof content === 'object') {
        obj = {
            ...content,
        };
    } else {
        obj = {
            content,
            _type: type,
        };
    }
    return show(obj);
}

export default Modal;
