// 左侧信号灯
import left_green_flash from "@/assets/TLA/gif/left_green_flash.gif";
import left_red_flash from "@/assets/TLA/gif/left_red_flash.gif";
import left_yellow_flash from "@/assets/TLA/gif/left_yellow_flash.gif";
import left_green from "@/assets/TLA/left_green.png";
import left_red from "@/assets/TLA/left_red.png";
import left_yellow from "@/assets/TLA/left_yellow.png";
// 中间信号灯
import mid_green_flash from "@/assets/TLA/gif/mid_green_flash.gif";
import mid_red_flash from "@/assets/TLA/gif/mid_red_flash.gif";
import mid_yellow_flash from "@/assets/TLA/gif/mid_yellow_flash.gif";
import mid_green from "@/assets/TLA/mid_green.png";
import mid_red from "@/assets/TLA/mid_red.png";
import mid_yellow from "@/assets/TLA/mid_yellow.png";
import INVALID from "@/assets/TLA/INVALID.png";
// 右侧信号灯
import right_green_flash from "@/assets/TLA/gif/right_green_flash.gif";
import right_red_flash from "@/assets/TLA/gif/right_red_flash.gif";
import right_yellow_flash from "@/assets/TLA/gif/right_yellow_flash.gif";
import right_green from "@/assets/TLA/right_green.png";
import right_red from "@/assets/TLA/right_red.png";
import right_yellow from "@/assets/TLA/right_yellow.png";

// TAL 交通信号灯 中间状态
export const lightColorMid = {
    INVALID: INVALID,  // 识别无效
    RED: mid_red, // 红色
    GREEN: mid_green, // 绿色
    YELLOW: mid_yellow, // 黄色
    RED_FLASH: mid_red_flash, // 红色闪烁
    YELLOW_FLASH: mid_yellow_flash, // 黄色闪烁
    GREEN_FLASH: mid_green_flash,  // 绿色闪烁
}

// TAL 交通信号灯 右侧状态
export const lightColorRight = {
    INVALID: INVALID,  // 识别无效
    RED: right_red, // 红色
    GREEN: right_green, // 绿色
    YELLOW: right_yellow, // 黄色
    RED_FLASH: right_red_flash, // 红色闪烁
    YELLOW_FLASH: right_yellow_flash, // 黄色闪烁
    GREEN_FLASH: right_green_flash,  // 绿色闪烁
}

// TAL 交通信号灯 左侧状态
export const lightColorLeft = {
    INVALID: INVALID,  // 识别无效
    RED: left_red, // 红色
    GREEN: left_green, // 绿色
    YELLOW: left_yellow, // 黄色
    RED_FLASH: left_red_flash, // 红色闪烁
    YELLOW_FLASH: left_yellow_flash, // 黄色闪烁
    GREEN_FLASH: left_green_flash,  // 绿色闪烁
}
