import { SCENE_TYPE } from "../action/parkingType";
const INIT_STATE = {
    // 当前场景
   current_scene: "",
};

export default function scene(state = INIT_STATE, action) {
    switch (action.type) {
        case SCENE_TYPE.currentScene:
            return { ...state, current_scene: action.data };
        default:
            return state;
    }
}