import React from 'react';
import StopSign from "../adas/StopSign";
import SlifLayoutComponent from "../adas/slifComponent";
import ETSAComponent from "../adas/EtsaComponent";
import VcruiseComponent from '../VcruiseComponent';
import CswShow from "../adas/cswIcon";
import './index.less';

export default function RightInfoShow() {
    return (
        <div className={"right_info_wrap"}>
            <VcruiseComponent/>
            <SlifLayoutComponent/>
            <StopSign/>
            <CswShow/>
            <ETSAComponent/>
        </div>
    )
}
