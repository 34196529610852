import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { SocketMap} from '@/constant/socketMap';
import { cusToast } from '@/components/customToast';
import FM from "@/utils/nativeSubAndPub";
import {useTranslation} from 'react-i18next';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function LaneChangeConfirm({isDisabled}) {
    const dispatch = useDispatch();
    const flag = useSelector(state => state?.smartDrivingSettingMsg?.flag);
    const running_mode = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const automatic_state = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    const np_confirm_switch = useSelector(state => state?.featureModelDriving?.switch?.driving?.lane_change_mode?.np_confirm_switch);
    const release_platform = useSelector(state => state?.featureModelDriving?.baseData?.hmi?.release_platform);
    const [laneChange, setLaneChange] = useState(np_confirm_switch ? np_confirm_switch : 'NORMAL');
    const {t: translate} = useTranslation();
    // 确认式变道开关
    const laneChangeOptions = translate('settings.laneChangeOptions',{returnObjects: true });

    // 变道确认开关
    const handleLaneChangeMode = (value) => {
        console.log(value);
        if (value === laneChange) return;
        if (release_platform === 'UNP') return;
        if (isDisabled) {
            const text = translate('settings.laneChangeToast')
            dispatch({
                type: "setUpMsg",
                data: {
                    tooltipMsg: text,
                    flag: !flag
                }
            })
            return;
        };
        // NP运行时也可以操作开关
        // if (automatic_state === 'ACTIVE') {
        //     const text = translate('settings.manualDrivingMode')
        //     dispatch({
        //         type: "setUpMsg",
        //         data: {
        //             tooltipMsg: text,
        //             flag: !flag
        //         }
        //     })
        //     return;
        // }
        // 数据上报
        reportDataFunc(value);
        setLaneChange(value);
        value && operateLaneChangeMode(value);
    }

    // 通知底层
    const operateLaneChangeMode = async (value) => {
        FM.sendToHMIDriving(SocketMap.operateLaneChangeMode, {
            mode: value,
        },'drive_node_res_fail_cb');
       
    }
    // 数据上报
    const reportDataFunc = (value) =>{
        singelPointReport({
            eventName: reportEventName.HMI_LANE_CONFIRM_CLICK,
            timestamp: new Date().getTime(),
            text: value ,
        })
    }

    // useEffect(() => {
    //     handleLaneChangeMode(np_confirm_switch ? np_confirm_switch : 'NORMAL');
    // }, [])

    return (
        // { ${checkedDriving !== 'HNP' && 'item-display-none'}}
        <div className={`select-group-item`}>
            <p className='select-group-item-name'>{translate('settings.laneChangeSwitch')}</p>
            <div className={`select-box ${(release_platform === 'UNP') ? 'disabled' : ''}`}>
                {
                    laneChangeOptions.length && laneChangeOptions.map((item, index)=>{
                        return(
                            <span
                                key={index}
                                className={`select-box-item ${laneChange === item.value ? 'active' : ''}`}
                                onClick={()=> handleLaneChangeMode(item.value)}
                            >
                        {item.label}
                    </span>)
                    })
                }
            </div>
        </div>
    )
}
