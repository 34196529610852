import { Space, Button } from 'antd-mobile';

export function StatusLabel (v) {
    switch (v) {
        case "not_download":
            return <span className="status_label not_download">未下载</span>
        case "downloading":
            return <span className="status_label downloading">下载中</span> 
        case "downloaded":
            return <span className="status_label downloaded">已下载</span>
        case "deploying":
            return <span className="status_label deploying">部署中</span>  
        case "running":
            return <span className="status_label running">运行中</span>        
        default:
            return <></>;
    }
}

