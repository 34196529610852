import React from 'react';
import { useTranslation } from 'react-i18next';
import DrivingSwitchModule from './drivingSwitchModule';
import SafetyModule from './activeSafety';
import ParkingModule from './parkingSetting';
import isCN from '@/utils/isCN';

// import BroadcastMode from "./broadcastMode";
import './index.less';

export default function DrivingAssistSetting() {
    const { t: translate } = useTranslation();
    return (
        <div className="driving-assist-setting">
            {/* 辅助驾驶 */}
            <div className="driving-assist-setting-item">
                <div className="driving-assist-setting-item-title">{translate('settings.drivingMode')}</div>
                <div className="driving-assist-setting-item-content">
                    <DrivingSwitchModule />
                </div>
            </div>
            {/* 主动安全 */}
            <div className="driving-assist-setting-item">
                <div className="driving-assist-setting-item-title">{translate('settings.activeSafety')}</div>
                <div className="driving-assist-setting-item-content">
                    <div className="safety_module_broadcast">
                        <SafetyModule />
                    </div>
                </div>
            </div>
            {/* 辅助泊车 */}
            {isCN() && (
                <div className="driving-assist-setting-item">
                    <div className="driving-assist-setting-item-title">{translate('settings.parkingSetting')}</div>
                    <div className="driving-assist-setting-item-content">
                        <div className="safety_module_broadcast">
                            <ParkingModule />
                        </div>
                    </div>
                </div>
            )}
            {/* 播报方式 */}
            {/* <div className="driving-assist-setting-item" style={{paddingTop: ".6rem"}}>
                <div className="driving-assist-setting-item-title">{translate('settings.broadcastMode')}</div>
                <div className="driving-assist-setting-item-content">
                    <div className="safety_module_broadcast">
                        <BroadcastMode/>
                    </div>
                </div>
            </div> */}
        </div>
    );
}
