import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import give_way_img from '@/assets/hnp/adas/ETSA/give_way.png';
import no_overtaking_img from '@/assets/hnp/adas/ETSA/no_overtaking.png';
import end_of_no_overtaking_img from '@/assets/hnp/adas/ETSA/end_of_no_overtaking.png';
import no_entrance_img from '@/assets/hnp/adas/ETSA/no_entrance.png';
import no_vehicle_entrance_img from '@/assets/hnp/adas/ETSA/no_vehicle_entrance.png';
import end_speed_overtaking_img from '@/assets/hnp/adas/ETSA/end_speed_overtaking.png';
// 国内版交通指示牌
import zh_give_way_img from '@/assets/hnp/adas/ETSADomestic/give_way.png';
import zh_no_overtaking_img from '@/assets/hnp/adas/ETSADomestic/no_overtaking.png';
import zh_end_of_no_overtaking_img from '@/assets/hnp/adas/ETSADomestic/end_of_no_overtaking.png';
import zh_no_vehicle_entrance_img from '@/assets/hnp/adas/ETSADomestic/no_vehicle_entrance.png';
import localCache from '@/utils/localCache';
import './index.less';

export default function ETSAComponent() {
    // let i18nLang = window.localStorage.getItem("i18nLang");
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    // OPEN/CLOSE
    const give_way = useSelector((state) => state?.featureModelDriving?.tsr?.giveWay);
    // OPEN/CLOSE
    const no_overtaking = useSelector((state) => state?.featureModelDriving?.tsr?.noOvertaking);
    // OPEN/CLOSE
    const end_of_no_overtaking = useSelector((state) => state?.featureModelDriving?.tsr?.endOfNoOvertaking);
    // OPEN/CLOSE
    const no_entrance = useSelector((state) => state?.featureModelDriving?.tsr?.noEntrance);
    // OPEN/CLOSE
    const no_vehicle_entrance = useSelector((state) => state?.featureModelDriving?.tsr?.noVehicleEntrance);
    // OPEN/CLOSE
    const end_speed_overtaking = useSelector(
        (state) => state?.featureModelDriving?.tsr?.endOfSpeedAndOvertakingRestrictions,
    );
    // speed
    const despeed = useSelector((state) => state?.featureModelDriving?.tsr?.despeed);

    return (
        <div className={'etsa_wrap'}>
            {give_way === 'OPEN' && <img src={i18nLang !== 'en-US' ? zh_give_way_img : give_way_img} alt="give_way" />}

            {no_overtaking === 'OPEN' && (
                <img src={i18nLang !== 'en-US' ? zh_no_overtaking_img : no_overtaking_img} alt="no_overtaking" />
            )}

            {end_of_no_overtaking === 'OPEN' && (
                <img
                    src={i18nLang !== 'en-US' ? zh_end_of_no_overtaking_img : end_of_no_overtaking_img}
                    alt="end_of_no_overtaking"
                />
            )}

            {no_entrance === 'OPEN' && <img src={no_entrance_img} alt="no_entrance" />}

            {no_vehicle_entrance === 'OPEN' && (
                <img
                    src={i18nLang !== 'en-US' ? zh_no_vehicle_entrance_img : no_vehicle_entrance_img}
                    alt="no_vehicle_entrance"
                />
            )}

            {end_speed_overtaking === 'OPEN' && <img src={end_speed_overtaking_img} alt="end_speed_overtaking" />}

            {!isNaN(despeed) && despeed !== 0 && (
                <div className={'end_speed_overtaking_wrap'}>
                    <div className={'speed_content'}>{despeed}</div>
                    <img src={end_speed_overtaking_img} alt="end_speed_overtaking" />
                </div>
            )}
        </div>
    );
}
