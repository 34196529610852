/**
 * Mviz
 * @returns
 */
import { useEffect } from 'react';
import { useSelector} from 'react-redux'
import { AppFn } from '@/utils/native';
import './index.less';
// import { MVIZ_URL } from '@/constant/config';

export default function Mviz(props) {
  const area = useSelector(state => state?.layout?.Area);
  // console.log('123 mviz', area);
  /*useEffect(() => {
    console.log('123 SetMviz', area.isHorizontal)
    if(!(area.isHorizontal=== undefined)){
      let data;
      if(area.isHorizontal){
        data = {}
      }else{
        data = {
          'verticalMapArea':40,
          'verticalMvizArea':60,
        }
      }
        AppFn.setMviz();

    }
  },[area.isHorizontal]);*/
 
  return (
    <div className="Mviz-Container">
      {/* <iframe
        title="mviz"
        src={MVIZ_URL}
        scrolling="no"
        rameborder="0"
        frameBorder="0"
        width="100%"
        height="100%"
      ></iframe> */}
    </div>
  );
}
 