import React, {useState, useEffect} from "react";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {Switch} from "antd-mobile";
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import useLKASettingCommon from "../LKASettingCommon";
import "./index.less";

let sensiTimer = null;
export default function CustomLdwCard() {
    const dispatch = useDispatch();
    const flag = useSelector(state => state?.smartDrivingSettingMsg?.flag);
    const {t: translate} = useTranslation();
    let sensiOptions = translate('LKASetting.sensiOptions', {returnObjects: true});
    let warningTypeOptions = translate('LKASetting.warningTypeOptions', {returnObjects: true});
    const ldw_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.lka_switch?.ldw_switch);
    const ldp_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.lka_switch?.ldp_switch);
    const sensitive_level = useSelector(state => state?.featureModelDriving?.switch?.adas?.lka_switch?.sensitive_level) || '2';
    const warning_type = useSelector(state => state?.featureModelDriving?.switch?.adas?.lka_switch?.warning_type);
    const [sensiChange, setSensiChange] = useState(null);
    /*const [vibrationSwitch, setVibrationSwitch] = useState(false);
    const [warningSwitch, setWarningSwitch] = useState(false);*/
    const [warningTypeSwitch, setWarningTypeSwitch] = useState(["CLOSE","HAPTIC","AUDIO","BOTH"].includes(warning_type) ? warning_type:"CLOSE");

    const {switchChange, adasControl} = useLKASettingCommon()

    useEffect(() => {
        // 设置灵敏度初始值
        sensitive_level && setSensiChange(sensitive_level);
        if (sensiTimer) {
            clearTimeout(sensiTimer)
            sensiTimer = null
        }
    }, [sensitive_level])

    useEffect(() => {
       /* const {VibrationSwitch, WarningSwitch} = judgeVibrationAndWarning(warning_type)
        // 设置方向盘震动
        setVibrationSwitch(VibrationSwitch)
        // 设置声音警告
        setWarningSwitch(WarningSwitch)*/
        if(["CLOSE","HAPTIC","AUDIO","BOTH"].includes(warning_type)){
            setWarningTypeSwitch(warning_type);
        }else{
            setWarningTypeSwitch("CLOSE");
            handleWarningType("CLOSE");
        }
    }, [warning_type])

    const handleSensiMode = (value) => {
        if (ldw_switch !== "OPEN") {
            const text = translate('LKASetting.onLDWFirst')
            dispatch({
                type: "setUpMsg",
                data: {
                    tooltipMsg: text,
                    flag: !flag
                }
            })
            return
        }
        clearTimeout(sensiTimer)
        sensiTimer = setTimeout(() => {
            setSensiChange(sensitive_level);
        }, 2 * 1000)
        adasControl({
            LDWSwitch: ldw_switch,
            LDPSwitch: ldp_switch,
            sensiChange: value,
            warningType: warning_type,
        });
        reportDataFunc({flag: "ldw_sensitivity",value,repName:reportEventName.HMI_LDW_SENSITIVITY_CLICK});
    }
    const handleWarningType = (value, isClick) =>{
        if (ldw_switch !== "OPEN") {
            if (isClick && isClick === "click") {
                const text = translate('LKASetting.onLDWFirst')
                dispatch({
                    type: "setUpMsg",
                    data: {
                        tooltipMsg: text,
                        flag: !flag
                    }
                })
            }
            return
        }
        adasControl({
            LDWSwitch: ldw_switch,
            LDPSwitch: ldp_switch,
            sensiChange: sensitive_level,
            warningType: value,
        });
        reportDataFunc({flag: "ldw_warning_type_click",value,repName:reportEventName.HMI_LDW_WARNINF_TYPE_CLICK});
    }

    // 数据上报
    const reportDataFunc = (params) =>{
        const {flag,value,repName} = params;
        let dataObj = {}
        dataObj[flag] = value
        singelPointReport({
            eventName: repName,
            timestamp: new Date().getTime(),
            reportData: dataObj,
        })
    }

    return (
        <div className={`custom_ldw_card_wrap ${ldw_switch === "OPEN" ? "" : "custom_ldw_not_active"}`}>
            <div className={"title_wrap"}>{translate('LKASetting.customLDW')}</div>

            {/*灵敏度*/}
            <div className={"sensitivity_wrap"}>
                <p className='subtitle_wrap'>{translate('LKASetting.sensiChange')}</p>
                <div className={"select_box"}>
                    {
                        sensiOptions.length && sensiOptions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${sensiChange === item.value ? 'active' : ''}`}
                                    onClick={() => handleSensiMode(item.value)}
                                >
                                    {item.label}
                                </span>
                            )
                        })
                    }
                </div>
            </div>
           {/* 方向盘震动、声音警告*/}
            <div className={"ldw_warning_wrap"}>
                <p className='subtitle_wrap'>{translate('LKASetting.ldw_warning_type')}</p>
                <div className={"select_box"}>
                    {
                        warningTypeOptions.length && warningTypeOptions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${warningTypeSwitch === item.value ? 'active' : ''}`}
                                    onClick={() => handleWarningType(item.value, "click")}
                                >
                                    {item.label}
                                </span>
                            )
                        })
                    }
                </div>
            </div>


            {/*方向盘震动*/}
           {/* <div className={"switch_wrap"}>
                <Switch
                    disabled={ldw_switch !== "OPEN"}
                    checked={vibrationSwitch}
                    onChange={(checked) =>
                        switchChange('steeringWheelVibration', checked, setVibrationSwitch, warningSwitch)}
                    className='customSwitch'
                />
                <span className='text_wrap'>{translate('LKASetting.vibrationSwitch')}</span>
            </div>*/}

            {/*声音警告*/}
            {/*<div className={"switch_wrap"}>
                <Switch
                    disabled={ldw_switch !== "OPEN"}
                    checked={warningSwitch}
                    onChange={(checked) =>
                        switchChange('voiceWarning', checked, setWarningSwitch, vibrationSwitch)}
                    className='customSwitch'
                />
                <span className='text_wrap'>{translate('LKASetting.warningSwitch')}</span>
            </div>*/}
        </div>
    )
}
