import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import logSend from '@/utils/aLog';

/**
 * @description 设置热区
 * @param {string} dispatchName 派发名字
 * @param {object} config 配置
 * @param {'' | 'custom' | 'watch'} config.mode 模式
 * custom: 手动更新，子节点比较多时使用该模式，外部调用更新
 * watch: 监听属性变化更新，适用dom结构比较简单，自动监听改变更新
 * ''(不传): 挂载、卸载更新，适用纯展示，不会改变位置
 * @returns {{nodeRef: () => void, updateHotArea: () => void}}
 */
export function useSetHotArea(dispatchName = '', { mode = '' } = {}) {
    const dispatch = useDispatch();
    const target = useRef();
    const observer = useRef();

    function getAreaInfo(node) {
        const { x, y, width, height } = node.getBoundingClientRect();
        return `${x}, ${y}, ${width}, ${height}`;
    }

    function updateHotArea() {
        if (mode !== 'custom') return;
        const dispatchInfo = { type: dispatchName, data: getAreaInfo(target.current) };
        dispatch(dispatchInfo);
        logSend({
            action: 'useSetHotArea_updateHotArea',
            data: dispatchInfo,
        });
    }

    const nodeRef = useCallback((node) => {
        if (!dispatchName) throw new Error('热区上报name不能为空');
        const isMount = !!node;
        const dispatchInfo = { type: dispatchName, data: isMount ? getAreaInfo(node) : '' };
        dispatch(dispatchInfo);
        logSend({
            action: 'useSetHotArea_mountOrDestory',
            data: { ...dispatchInfo, isMount, mode },
        });
        if (isMount) {
            target.current = node;
            if (mode !== 'watch') return;
            // TODO: 层级较深增加提示
            // 监听属性变化、子节点变化重新获取位置
            observer.current = new MutationObserver(() => {
                const dispatchInfo = { type: dispatchName, data: getAreaInfo(node) };
                dispatch(dispatchInfo);
                logSend({
                    action: 'useSetHotArea_observer',
                    data: dispatchInfo,
                });
            });
            observer.current.observe(node, {
                attributeFilter: ['class', 'style'],
                childList: true,
                subtree: true,
            });
        } else {
            // 节点卸载，取消监听
            observer.current?.disconnect();
            observer.current = null;
        }
    }, []);
    return { nodeRef, updateHotArea };
}
