import SlsWebLogger from 'js-sls-logger';
import store from '@/store';
import { getCookie } from '@/utils/tools';
import { isOfflinePack } from '@/utils/isEnv';
const project = 'k8s-log-ce77fee6584cd46b9a1e8a98e5c305e79';
const logstore = 'web-hmi-prod';
const packageInfo = require('./../../package.json');

// const ua = window.navigator.userAgent
// const reg = /hmi_appVersion\(([^\)]+)\)/
// const app_version_exec = reg.exec(ua)

const getInfoObj = () => {
    const state = store?.getState();
    // ua信息
    const userAgent = window.navigator.userAgent;
    // appVersion信息
    const regVersion = /hmi_appVersion\(([^\)]+)\)/;
    const appVersion = regVersion.exec(userAgent) ? regVersion.exec(userAgent)[1] : '';
    return {
        maf_version_state: state?.featureModelDriving?.baseData?.hmi?.maf_version,
        box_id: state?.featureModelDriving?.baseData?.body?.boxid || state?.featureModelParking?.body?.boxId,
        ip: state?.featureModelDriving?.baseData?.body?.ip,
        h5Version: packageInfo?.version,
        appVersion,
        carType: state?.featureModelDriving?.baseData?.hmi?.car_type || state?.featureModelCommon?.carType || 'Devcar',
        isOfflinePack: isOfflinePack,
    };
};

let baseOpts = {
    project,
    logstore,
};
const opts = {
    host: 'cn-beijing.log.aliyuncs.com',
    ...baseOpts,
    time: 3,
    count: 10,
};
const logger = new SlsWebLogger(opts);
const logSend = (param) => {
    try {
        logger.send({
            timestamp: new Date().getTime(),
            ...getInfoObj(),
            ...param,
        });
    } catch (err) {
        console.log(err);
    }
};
export default logSend;
