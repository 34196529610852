import { useEffect } from "react"
import { useSelector } from "react-redux"
import {widgetPush, widgetType} from '@/utils/widgetPush';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { STATUS } from "./common"
import "./css/aeb.less"
import AEB_RED from "../aebAndFcwComponent/img/aeb_red.png";

export default function AEB() {
    // const widget = useSelector(state => state?.func?.currentState?.widget);
    // const aeb_state = widget?.adas?.aeb_state;
    const aeb_state = useSelector(state => state?.featureModelDriving?.aeb?.state);
    const switchUnpState = useSelector(state => state?.switchModeAction?.switchUnpState);

    useEffect(()=>{
        let imgSrc = "";
        switch(aeb_state) {
            case STATUS.CLOSE:
                break;
            case STATUS.OPEN:
                break;
            case STATUS.WARNING:
                imgSrc = AEB_RED;
                //  数据上报
                reportDataFunc();
                break;
            default:
                imgSrc = "";
                break;
        }
        let currentText =
            `<div class="aeb ${aeb_state}">
                <img src=${imgSrc} alt="aeb"/>
            </div>`;

        let content = {"content": currentText };
        if(imgSrc && !switchUnpState){
            widgetPush({widgetNumber: 3, type: widgetType.AEB, widgetContent: content});
        }else{
            widgetPush({widgetNumber: 3, type: widgetType.AEB, widgetContent: null});
        }
    },[aeb_state, switchUnpState])
    // 数据上报
    const reportDataFunc = () =>{
        singelPointReport({
            eventName: reportEventName.HMI_AEB_VIEW,
            timestamp: new Date().getTime(),
            text: "AEBWarning",
        })
    }

   /* const StatusElement = (status) => {
        switch(status) {
            case  STATUS.CLOSE:
                return <img src={AEB_CLOSE} alt="aeb open" />
            case STATUS.OPEN:
                return <img src={AEB_OPEN} alt="aeb close" />
            case STATUS.WARNING:
                return <img src={AEB_WARN} alt="aeb warning" />
            default:
                return <></>
        }
    }*/
    return (
        <></>
       /* <div className="aeb-wrapper">{StatusElement(aeb_state)}</div>*/
    )
}
