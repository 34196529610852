import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import layout from './reducers/layout';
import func from './reducers/func';
import map from './reducers/map';
import diskDataCollection from './reducers/diskDataCollection';
import hotArea from './reducers/hotArea';
import warningAutomatic from './reducers/warningAutomatic';
import nativeAction from './reducers/nativeAction';
import reportSwitch from './reducers/reportSwitch';
import themeAction from './reducers/theme';
import scene from './reducers/secen';
import parking from './reducers/parking';
import switchModeAction from './reducers/switchMode';
import robonode from './reducers/robonode';
import showWidgetAction from './reducers/showWidget';
import widget1Data from './reducers/widget1Data';
import widget2Data from './reducers/widget2Data';
import widget3Data from './reducers/widget3Data';
import showWidgetTwoAction from './reducers/showWidgetTwo';
import modalData from './reducers/modalData';
import routerInfo from './reducers/routerInfo';
import warningData from './reducers/warningData';
import widget0Data from './reducers/widget0Data';
import smartDrivingSettingMsg from './reducers/smartDrivingSettingMsg';
import showVcruise from './reducers/vcruise';
import featureModelDriving from './reducers/featModelDriving';
import featureModelParking from './reducers/featModelParking';
import featureModelCommon from './reducers/featModelCommon';

export default createStore(
    combineReducers({
        layout,
        diskDataCollection,
        func,
        map,
        hotArea,
        nativeAction,
        warningAutomatic,
        reportSwitch,
        themeAction,
        scene,
        parking,
        switchModeAction,
        robonode,
        showWidgetAction,
        widget1Data,
        widget2Data,
        showWidgetTwoAction,
        widget3Data,
        modalData,
        routerInfo,
        warningData,
        widget0Data,
        smartDrivingSettingMsg,
        showVcruise,
        featureModelDriving,
        featureModelParking,
        featureModelCommon,
    }),
    composeWithDevTools(),
);
