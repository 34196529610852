import { useEffect, useState } from 'react';
import store from "../store";
import { WIDGET_ACTION } from '@/store/action/type';

const widgetStoreTypeEnum = {
    3: WIDGET_ACTION.widget3Action,
}
export const widgetType = {
    AEB: 'AEB',
    FCW: 'FCW',
    BSDLCADOW: 'BSDLCADOW',
    HARDDISK: 'HARDDISK',
    ESS: "ESS",
    IHC: "IHC",
}
export const widgetPush = ({widgetNumber, type, widgetContent}) => {
    // widget的type
    let storeWidgetType = widgetStoreTypeEnum[widgetNumber];
    const state = store?.getState();
    // store中存储的list消息队列
    const storeWidgetQueue = state?.widget3Data?.list;
    let oldStoreWidgetQueue = [...storeWidgetQueue] || [];

    // 差集：过滤出来type不相等的
    let oldStoreWidgetQueueFilter = oldStoreWidgetQueue?.filter((widgetItem) => {
        return Object.keys(widgetItem)[0] !== type;
    })
    let currentWidgetQueue = [...oldStoreWidgetQueueFilter];
    let equalIndex = null;
     oldStoreWidgetQueue.map((item,index)=>{
        if(Object.keys(item)[0] === type){
            equalIndex = index;
        }
    })
    // 如果widget有值
    if(widgetContent){
        // 如果widget有值,
        // 1、这个type之前在这个数组里不存在，直接添加
        // 2、如果存在，找到对应的下标进行替换
        let contentObj = {};
        contentObj[type] = widgetContent;
        equalIndex === null ? oldStoreWidgetQueue?.push(contentObj): ((oldStoreWidgetQueue[equalIndex]) = contentObj);
        store.dispatch({
            type: storeWidgetType,
            data: {
                list: oldStoreWidgetQueue
            },
        });
    }else{
        if(oldStoreWidgetQueue.length === 0){
            return;
        }
        // 如果widget值为null, 并且在oldStoreWidgetQueue差集为空
        if(currentWidgetQueue.length === 0){
            store.dispatch({
                type: storeWidgetType,
                data: {
                    list: currentWidgetQueue
                },
            });
        }else{
            // 如果widget值为null, 并且在oldStoreWidgetQueue差集有值
            store.dispatch({
                type: storeWidgetType,
                data: {
                    list: currentWidgetQueue
                },
            });
        }
    }

}
