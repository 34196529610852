import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetHotArea } from '@/hook';
import { HOT_AREA_TYPE } from '@/store/action/type';
import Icon from '@/assets/parking/d_new/icon_fail.png';
import closeIcon from '@/assets/parking/guide/guide_close_d.png';
import fail_img from '@/assets/parking/route_learning_failed.png';
import { AppFn } from '@/utils/native';
import { singelPointReport } from '@/utils/report';
import Modal from '@/components/parkingContainer/globalComponent/modal/index';
import reportEventName from '@/constant/reportEventName';

const FAIL_REASON = {
    // 没有泊入车位
    FAILURE_NOT_PARKED_IN_PARKING_SLOT: 'Not Parking in',
    // 路线存在交叉或重合
    FAILURE_ROUTE_REPEAT: 'Route is overlapped',
    // Unknown reason
    OTHER: 'Unknown reason',
    default: 'Unknown reason',
};

export default function RouteLearningFailed() {
    const [showPage, setShowPage] = useState(false);
    const userMode = useSelector((state) => state?.parking?.userMode);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const mapping_fail_reason = useSelector((state) => state?.featureModelParking?.lpnp?.failReason?.mappingFailReason);
    const reason = FAIL_REASON[mapping_fail_reason] || FAIL_REASON.default;
    const preStatus = useRef('');

    const idD = userMode === 'D';

    useEffect(() => {
        if (
            status === 'STANDBY' &&
            ['LEARNING', 'MAP_BEING_VERIFIED'].includes(preStatus.current) &&
            mapping_fail_reason
        ) {
            singelPointReport({
                timestamp: Date.now(),
                eventName: reportEventName.HMI_lpnp_map_build_fail_view,
                text: reason,
            });
            AppFn.playLocalAudio('routeLearningFailed.mp3', 'Highest');
            if (idD) {
                setShowPage(true);
            } else {
                AppFn.showLPNP({ pageType: 3, reason });
            }
            Modal.clear();
        }
        preStatus.current = status;
    }, [status, mapping_fail_reason]);

    const { nodeRef: closeRef } = useSetHotArea(HOT_AREA_TYPE.studyComplete);

    if (!showPage) return null;
    return (
        <div className="study-status">
            <div className="study-status-left">
                <div
                    className="close-btn"
                    ref={closeRef}
                    onClick={() => {
                        setShowPage(false);
                    }}
                >
                    <img src={closeIcon} alt="" />
                </div>
                <h3>
                    <img className="p-study-complete" src={Icon} alt="" />
                    Route Learning Failed
                </h3>
                <label>Failure Reason:</label>
                <p>{reason}</p>
            </div>
            <div className="study-status-right">
                <img src={fail_img} alt="" />
            </div>
        </div>
    );
}
