import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MathRoundFn } from '@/utils/common';
import { batchReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import np_active from '@/assets/resume/np_active.png';
import loading_icon from '@/assets/resume/loading_icon.png';
import FM from '@/utils/nativeSubAndPub';

import { SocketMap } from '@/constant/socketMap';
import { cusToast } from '@/components/customToast';
import { useDebounceFn } from '@/utils/common';
import { HOT_AREA_TYPE, WIDGET_ZERO_ACTION } from '@/store/action/type';
import logSend from '@/utils/aLog';
import DrivingIcon from '../../drivingIcon';

// odd类型
const noticeOddType = {
    // 0、50、100: 领航路段结束
    ODD_UNKNOWN: 'ODD_UNKNOWN',
    // 1: 道路施工
    ODD_ROAD_CONSTRUCTION: 'ODD_ROAD_CONSTRUCTION',
    // 2: 收费站
    ODD_TOLL: 'ODD_TOLL',
    // 3: 闸机
    ODD_GATE_MACHINE: 'ODD_GATE_MACHINE',
    // 4: 地图采集专用 1121新增
    ODD_MAP_COLLECT: 'ODD_MAP_COLLECT',
    // 5: 地图数据MPD问题 1121新增
    ODD_MAP_ERROR: 'ODD_MAP_ERROR',
    // 6: 非地图模块的其他模块问题 1121新增
    ODD_NONE_MAP_ERROR: 'ODD_NONE_MAP_ERROR',
    // 7: 动态事件 1121新增
    ODD_DYNAMIC_EVENT: 'ODD_DYNAMIC_EVENT',
    // 领航路段终点
    // ODD_DESTINATION: 'ODD_DESTINATION',
    // 进匝道（处理1000，500）
    ODD_RAMP_ENTER: 'ODD_RAMP_ENTER',
    // 出匝道
    ODD_RAMP_OUT: 'ODD_RAMP_OUT',
    // 101: SD未匹配的区域
    ODD_UNMATCH: 'ODD_UNMATCH',
    // 102: 路线终点
    ODD_TOUR_END: 'ODD_TOUR_END',
    // 103: 道路类型变化
    ODD_DDLD_ROAD_TYPE_CHANGE: 'ODD_DDLD_ROAD_TYPE_CHANGE',
    // 121: 道路施工&道路变化的一种细化类型
    NO_HD_MAP: 'NO_HD_MAP',
};
export default function OddWidgetComponent() {
    const dispatch = useDispatch();
    // odd是否展示
    const oddComState = useSelector((state) => state?.widget0Data?.oddComState);
    const hnp_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 正在运行状态
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    // widget odd distance
    // const widget_route_info_odd_distance = useSelector(state => state?.func?.currentState?.widget?.route_info?.odd?.distance || state?.func?.currentState?.widget?.route_info?.ramp_info?.distance);
    const widget_route_info_odd_distance = useSelector((state) => state?.featureModelDriving?.routeOdd?.distance);
    // widget odd type
    // const widget_route_info_odd_type = useSelector(state => state?.func?.currentState?.widget?.route_info?.odd?.type || state?.func?.currentState?.widget?.route_info?.ramp_info?.type);
    const widget_route_info_odd_type = useSelector((state) => state?.featureModelDriving?.routeOdd?.type);
    // 新增的 widget odd type
    const new_widget_route_info_odd_type = useSelector((state) => state?.featureModelDriving?.routeOdd?.oddClose?.type);
    // 代表当前可进入的智驾等级
    const widget_highest_running_mode = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.highestRunningMode,
    );
    // odd类型
    // const notice_route_odd_type = useSelector(state => state?.func?.currentState?.notice_list?.notice_route_odd?.data?.odd_type);

    const [currentState, setCurrentState] = useState('');
    // 是否显示icon后面的文案
    const [isShowText, setShowText] = useState('');
    // odd text
    const [oddText, setOddText] = useState('');
    // 是否点击了切换至CP
    const [isClickSwitch, setClickSwitch] = useState('');
    // widget是否显示warning颜色
    const [warningClass, setWarningClass] = useState('');
    // 距离取值
    const [localOddDistance, setLocalOddDistance] = useState();
    // 200米后一直显示
    const [isAlawShow, setIsAlawShow] = useState(false);

    // 进行展示计时
    const [count, setCount] = useState(0);
    const timer = useRef(null);
    // 热区
    const area = useSelector((state) => state?.layout?.Area);
    const switchMvizState = useSelector((state) => state?.switchModeAction?.switchMvizState);
    const oddComDom = useRef(null);

    const { t: translate } = useTranslation();
    const driver_state = {
        HNP_ACTIVE: np_active,
    };
    const oddTextList = translate('oddComList.oddTextList', { returnObjects: true });

    const newOddTextList = translate('oddComList.newOddTextList', { returnObjects: true });
    // 判断是否包含旧的widget odd type
    const haveOldOddType = Object.values(noticeOddType)
        .filter((pre) => pre !== 'ODD_RAMP_ENTER' && pre !== 'ODD_RAMP_OUT')
        .includes(widget_route_info_odd_type);
    // 判断是否包含新的widget odd type
    const haveNewOddType = Object.values(noticeOddType).includes(new_widget_route_info_odd_type);
    // 判断当前是否可以降级到CP
    const lowerToCp = widget_highest_running_mode === 'CP';
    // 在新的widget odd type下，是否点击了智驾Icon
    const [newTypeIconClick, setNewTypeIconClick] = useState(false);

    // 上报热区
    useEffect(() => {
        const oddComDomArea = oddComDom?.current?.getBoundingClientRect();
        const oddComDomInfo = `${oddComDomArea?.x}, ${oddComDomArea?.y}, ${oddComDomArea?.width}, ${oddComDomArea?.height}`;
        // icon存在的情况下连续变化，防止layout热区监控不到变化，导致热区上报有问题
        dispatch({
            type: HOT_AREA_TYPE.oddComDom,
            data: '',
        });
        if (typeof oddComDomInfo !== 'undefined' && oddComDomInfo?.current?.getBoundingClientRect()?.width !== 0) {
            dispatch({
                type: HOT_AREA_TYPE.oddComDom,
                data: oddComDomInfo,
            });
        }
        logSend({
            action: 'setODDStateHotArea',
            data: {
                width: oddComDom?.current?.getBoundingClientRect()?.width,
                currentState: currentState,
                oddComDomInfo: oddComDomInfo,
            },
        });
    }, [
        oddComDom?.current?.getBoundingClientRect()?.width,
        area?.isHorizontal,
        switchMvizState,
        oddComDom?.current?.getBoundingClientRect().x,
        oddComDom?.current?.getBoundingClientRect().y,
        currentState,
        oddText,
        widget_route_info_odd_distance,
        widget_route_info_odd_type,
    ]);

    useEffect(() => {
        if (hnp_state === 'ACTIVE' && running_mode === 'HNP') {
            // NP active
            setCurrentState(`${running_mode}_ACTIVE`);
        }
        clearIntervalFunc();
        setClickSwitch(false);
        setShowText(true);
    }, [running_mode, hnp_state]);

    // 点击智驾状态ICON
    const clickDriveIcon = (params) => {
        console.log('params', params, isShowText);
        function trigger() {
            // clearIntervalFunc();
            // if(params === isShowText) return;
            let text = '';
            if (isShowText) {
                text = '';
            } else {
                text = oddText;
            }
            setShowText(text);
        }
        if (haveOldOddType) {
            trigger();
        } else {
            if (haveNewOddType) {
                clearIntervalFunc();
                if (!lowerToCp) {
                    // 不满足降CP条件
                    trigger();
                } else {
                    // 满足降CP条件
                    if (isShowText) {
                        if (newTypeIconClick) {
                            // setShowText(false)
                            setNewTypeIconClick(false);
                        } else {
                            setNewTypeIconClick(true);
                        }
                    } else {
                        setShowText(true);
                        setNewTypeIconClick(false);
                    }
                }
            }
        }
    };

    // 新增监听事件, 旧的odd type不存在，且新的odd type存在，此时判断newTypeIconClick是否为false
    // 如果为false，则持续显示，如果为true，则五秒后再次显示 "暂无高精地图"
    useEffect(() => {
        if (!newTypeIconClick && !isShowText && haveNewOddType && !haveOldOddType) {
            setOddText(newOddTextList?.['hdMapUnavailable']);
            setShowText(true);
        }
        if (!haveNewOddType && !haveOldOddType) {
            setOddText('');
        }
    }, [newTypeIconClick, isShowText, widget_route_info_odd_type, new_widget_route_info_odd_type]);

    // odd widget
    useEffect(() => {
        let oddDistance;
        if (typeof widget_route_info_odd_distance !== 'undefined') {
            if (widget_route_info_odd_distance > 1049) return;
            // 1013 进出匝道：不做处理（si 进出匝道不属于odd）
            if ([noticeOddType.ODD_RAMP_ENTER, noticeOddType.ODD_RAMP_OUT].includes(widget_route_info_odd_type)) return;
            // 1121 新增，防止未知的类型被处理
            if (
                ![
                    noticeOddType.ODD_UNKNOWN,
                    noticeOddType.ODD_ROAD_CONSTRUCTION,
                    noticeOddType.ODD_TOLL,
                    noticeOddType.ODD_GATE_MACHINE,
                    noticeOddType.ODD_MAP_COLLECT,
                    noticeOddType.ODD_MAP_ERROR,
                    noticeOddType.ODD_NONE_MAP_ERROR,
                    noticeOddType.ODD_DYNAMIC_EVENT,
                    noticeOddType.ODD_UNMATCH,
                    noticeOddType.ODD_TOUR_END,
                    noticeOddType.ODD_DDLD_ROAD_TYPE_CHANGE,
                ].includes(widget_route_info_odd_type)
            )
                return;
            if (widget_route_info_odd_distance <= 1049 && widget_route_info_odd_distance > 950) {
                oddDistance = 1000;
            } else if (widget_route_info_odd_distance <= 549 && widget_route_info_odd_distance > 450) {
                oddDistance = 500;
            } else if (widget_route_info_odd_distance <= 249 && widget_route_info_odd_distance > 150) {
                oddDistance = 200;
                setIsAlawShow(true);
            } else if (widget_route_info_odd_distance <= 150 && widget_route_info_odd_distance >= 94) {
                oddDistance = 150;
                setIsAlawShow(true);
            } else if (widget_route_info_odd_distance <= 95 && widget_route_info_odd_distance >= 74) {
                oddDistance = 100;
                setIsAlawShow(true);
            } else if (widget_route_info_odd_distance <= 75 && widget_route_info_odd_distance >= 44) {
                oddDistance = 75;
                setIsAlawShow(true);
            } else if (widget_route_info_odd_distance <= 45 && widget_route_info_odd_distance >= 26) {
                oddDistance = 50;
                setIsAlawShow(true);
            } else if (widget_route_info_odd_distance <= 25 && widget_route_info_odd_distance >= 0) {
                oddDistance = 25;
                setIsAlawShow(true);
            } else {
                oddDistance = '';
            }
            if (widget_route_info_odd_distance <= 200 && widget_route_info_odd_distance > 0) {
                setWarningClass('warningClass');
            }
        }
        setLocalOddDistance(oddDistance);
    }, [widget_route_info_odd_distance, widget_route_info_odd_type]);

    useEffect(() => {
        setOddText(oddTextList?.[localOddDistance] ? oddTextList?.[localOddDistance] : '');
        setShowText(oddTextList?.[localOddDistance] ? oddTextList?.[localOddDistance] : '');
        if (!localOddDistance) {
            clearIntervalFunc();
        }
    }, [localOddDistance]);

    // 通知底层进入智驾状态
    const operateDriveStateMode = async () => {
        function send() {
            clearIntervalFunc();
            setClickSwitch(true);
            let params = {},
                wsName = null;
            if (hnp_state === 'ACTIVE' && running_mode === 'HNP') {
                params['trans_running_mode'] = 'CP';
                wsName = SocketMap.transDriveMode;
            }
            FM.sendToHMIDriving(wsName, params, 'drive_mode_common_cb');
        }
        if (haveOldOddType) {
            send();
        } else {
            if (haveNewOddType) {
                if (newTypeIconClick) {
                    setNewTypeIconClick(false);
                    send();
                }
            }
        }
    };

    const clickDriveText = useDebounceFn(operateDriveStateMode, 300, false);
    const newClickDriveText = useDebounceFn(operateDriveStateMode, 300, false);

    // 清除定时器
    const clearIntervalFunc = () => {
        clearInterval(timer.current);
        timer.current = null;
        setCount(() => {
            return 0;
        });
    };

    // 计时5s
    useEffect(() => {
        if (isAlawShow || (!haveOldOddType && haveNewOddType && !newTypeIconClick)) return;
        if (count === 0) {
            setCount((prev) => {
                return prev + 1;
            });
        }

        !timer.current &&
            (isShowText || isClickSwitch) &&
            oddText &&
            (timer.current = setInterval(() => {
                setCount((prev) => {
                    return prev + 1;
                });
                if (count === 6) {
                    if (isClickSwitch) {
                        setShowText(true);
                    } else {
                        setShowText(false);
                    }
                    clearIntervalFunc();
                    setClickSwitch(false);
                }
            }, 1000));
        return () => {
            clearInterval(timer.current);
            timer.current = null;
        };
    }, [isShowText, count, isClickSwitch, isAlawShow, newTypeIconClick, haveNewOddType, haveOldOddType, oddText]);

    useEffect(() => {
        let showState = (currentState !== '' && !isClickSwitch && oddText) || isClickSwitch ? true : false;
        dispatch({
            type: WIDGET_ZERO_ACTION.oddComState,
            data: showState,
        });
    }, [currentState, isClickSwitch, oddText]);

    useEffect(() => {
        return () => {
            clearIntervalFunc();
        };
    }, []);

    return (
        <React.Fragment>
            {currentState !== '' && !isClickSwitch && oddText && oddComState && (
                <div className={`driving_state ${warningClass} driving_odd_widget`} ref={oddComDom}>
                    <div
                        className={`driving_state_left ${isShowText ? 'right_isshow' : ''}`}
                        onClick={() => clickDriveIcon(currentState)}
                    >
                        <DrivingIcon currentState={currentState} />
                    </div>
                    {
                        // 之前旧的odd type 还走之前的逻辑
                        haveOldOddType && (
                            <div
                                onClick={() => clickDriveText()}
                                className={`driving_state_right ${isShowText ? 'show_right' : 'hide-right'}`}
                            >
                                <p className={`reason_text ${isShowText ? 'show_right_text' : 'hide_right_text'}`}>
                                    {oddText}
                                </p>
                                <p className={`enter_text ${isShowText ? 'show_right_text' : 'hide_right_text'}`}>
                                    {translate('oddComList.changStateText')}
                                </p>
                            </div>
                        )
                    }
                    {
                        // 新的odd type (旧的odd type 优先级 > 新的odd type)
                        !haveOldOddType && haveNewOddType && (
                            <div
                                className={`driving_state_right ${isShowText ? 'show_right' : 'hide-right'}`}
                                onClick={() => newClickDriveText()}
                            >
                                {newTypeIconClick && (
                                    <p
                                        className={`enter_text ${isShowText ? 'show_right_text' : 'hide_right_text'}`}
                                        style={{ lineHeight: '0.64rem' }}
                                    >
                                        {translate('oddComList.changStateText')}
                                    </p>
                                )}
                                {!newTypeIconClick && (
                                    <>
                                        <p
                                            className={`reason_text ${
                                                isShowText ? 'show_right_text' : 'hide_right_text'
                                            }`}
                                        >
                                            {oddText}
                                        </p>
                                        <p
                                            className={`sub_reason_text ${
                                                isShowText ? 'show_right_text' : 'hide_right_text'
                                            }`}
                                        >
                                            {newOddTextList?.['willResumeLater']}
                                        </p>
                                    </>
                                )}
                            </div>
                        )
                    }
                </div>
            )}
            {isClickSwitch && (
                <div className="driving_state_loading">
                    <div className="plus_item loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        {/* <img alt="" className={`imgState ${currentState}`} src={loading_icon}/>*/}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
