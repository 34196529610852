import React, {useState, useEffect} from "react";
import {Switch} from "antd-mobile";
import CardLayout from "../../../components/cardLayout";
import bsd from '@/assets/settingComNew/activeSafety/bsd.png';
import useBSDDOWRCWCommon from "../BSDDOWRCWCommon";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import "@/components/slider/smartDrivingSetting/components/cardStyle/index.less";


export default function BsdCard() {
    const {t: translate} = useTranslation();
    const lca_bsd_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.lca_bsd_switch);
    const [BSDSwitch, setBSDSwitch] = useState(lca_bsd_switch === 'OPEN');

    const [timer, setTimer] = useState(null)  // 存放定时器句柄

    const {switchChange} = useBSDDOWRCWCommon({timer: timer, setTimer: setTimer});

    useEffect(() => {
        lca_bsd_switch && setBSDSwitch(lca_bsd_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
    }, [lca_bsd_switch])


    const leftDom = (
        <div className={"left_dom_wrap"}>
            <div className={"switch_wrap"}>
                <div className={"content_wrap"} style={{marginBottom: ".14rem"}}>
                    <div className={"text_wrap"} style={{paddingTop: "0"}}>BSD</div>
                    <div className={"text_wrap_detail"}>{translate('BSDLCADOWRCW.BSDText')}</div>
                    <div className={"text_wrap"}>LCA</div>
                    <div className={"text_wrap_detail"}>{translate('BSDLCADOWRCW.LCAText')}</div>
                </div>
                <Switch
                    checked={BSDSwitch}
                    onChange={(checked) => switchChange(checked, "BSD", setBSDSwitch)}
                    className={"customSwitch"}
                />
            </div>
        </div>
    )

    const rightDom = (
        <div className={"right_dom_wrap"}>
            <img src={bsd}/>
        </div>
    )

    return (
        <>
            <CardLayout
                leftDom={leftDom}
                rightDom={rightDom}
            />
        </>
    )
}
