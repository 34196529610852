import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.less';

export default function HeadToast() {
    const dispatch = useDispatch();
    const tooltipMsg = useSelector((state) => state?.smartDrivingSettingMsg?.tooltipMsg);
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    // 是否展示toast
    const [showToast, setShowToast] = useState(false);
    const toastTimer = useRef(null);

    const toShowToast = () => {
        setShowToast(true);
        if (toastTimer.current) {
            clearTimeout(toastTimer.current);
            toastTimer.current = null;
        }
        toastTimer.current = setTimeout(() => {
            setShowToast(false);
        }, 2000);
    };

    useEffect(() => {
        toShowToast();
    }, [tooltipMsg, flag]);

    // 组件销毁，清空tooltipMsg
    useEffect(() => {
        return () => {
            if (toastTimer.current) {
                clearTimeout(toastTimer.current);
                toastTimer.current = null;
            }
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: '',
                    flag: !flag,
                },
            });
        };
    }, []);

    return (
        <React.Fragment>
            {showToast && tooltipMsg !== '' && (
                <div className="switch-toast">
                    <div className="switch-toast-text">{tooltipMsg}</div>
                </div>
            )}
        </React.Fragment>
    );
}
