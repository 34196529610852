import { useEffect,  useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { AppFn } from '@/utils/native';

// import SteeringWheel from '@/assets/hnp/adas/LKA/steering_wheel.gif';
// import LeftLaneStateIntervention from '@/assets/hnp/adas/LKA/left_lane_state_intervention.gif';
// import RightLaneStateIntervention from '@/assets/hnp/adas/LKA/right_lane_state_intervention.gif';
// import LeftRightLaneStateIntervention from '@/assets/hnp/adas/LKA/left_right_lane_state_intervention.gif';
// import LeftLaneStateWarning from '@/assets/hnp/adas/LKA/left_lane_state_warning.gif';
// import RightLaneStateWarning from '@/assets/hnp/adas/LKA/right_lane_state_warning.gif';
// import LeftRightLaneStateWarning from '@/assets/hnp/adas/LKA/left_right_lane_state_warning.gif';
import SteeringWheel from '@/assets/hnp/adas/LKA/steering_wheel.png';
import LeftLaneStateIntervention from '@/assets/hnp/adas/LKA/left_lane_state_intervention1.png';
import RightLaneStateIntervention from '@/assets/hnp/adas/LKA/right_lane_state_intervention1.png';
// TODO: 0907 LeftRightLaneStateIntervention 这张图片app端预加载的不对，暂时不使用app端的预加载，先改用h5自己来预加载
import LeftRightLaneStateIntervention from '@/assets/hnp/adas/LKA/left_right_local_lane_state_intervention1.png';
import LeftLaneStateWarning from '@/assets/hnp/adas/LKA/left_lane_state_warning.png';
import RightLaneStateWarning from '@/assets/hnp/adas/LKA/right_lane_state_warning.png';
import LeftRightLaneStateWarning from '@/assets/hnp/adas/LKA/left_right_lane_state_warning.png';

import LeftRightLaneStateNormal from '@/assets/hnp/adas/LKA/left_right_lane_state_normal.png';
import localAudioNames from '@/constant/localAudioName';
import {useTranslation} from 'react-i18next';
import AdasModal from '@/components/adasModal/index';
import {modalQueue, modalType} from '@/utils/modalQueue';
import {batchReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

const { playTTSAudio, playLocalAudio } = AppFn

export default function LKAComponent() {
    // LDW语音提示
    const notice_lane_departure = useSelector(state => state?.featureModelDriving?.lka?.notice?.data?.laneDeparture);
    // LDW/LDP方向盘震动提示
    const notice_steering_wheel_vibration = useSelector(state => state?.featureModelDriving?.lka?.notice?.data?.steeringWheelVibration);
    // LDW/LDP左侧车道线状态
    const left_lane_state = useSelector(state => state?.featureModelDriving?.lka?.notice?.data?.leftLaneState);
    // LDW/LDP右侧车道线状态
    const right_lane_state = useSelector(state => state?.featureModelDriving?.lka?.notice?.data?.rightLaneState);
    const lane_departure_pre = useRef(notice_lane_departure);
    const {t: translate} = useTranslation();
    let warningTimer = useRef(null);

    // state 存储值做弹框延迟消失用
    // const [isShowDelay, setIsShowDelay] = useState(false);

   /* useEffect(()=>{
        if(left_lane_state || right_lane_state){
            setIsShowDelay(true)
        }else{
            if(isShowDelay){
                setTimeout(()=>{
                    setIsShowDelay(false);
                },1500)
            }
        }

    },[left_lane_state, right_lane_state])*/

    useEffect(()=>{
        if(lane_departure_pre.current === notice_lane_departure) return;
        // notice_lane_departure && playTTSAudio(translate("LKASetting.noticeLaneDeparture"), 'Important');
        /**
         *0915 M7.2：改为音效提示
         */
        if(notice_lane_departure){
            playLocalAudio(localAudioNames.BIBIBI_WARNING, 'Important');
            (!warningTimer.current) && (warningTimer.current = setInterval(() => {
                playLocalAudio(localAudioNames.BIBIBI_WARNING, 'Important');
            }, 1000));
        }else{
            clearInterval(warningTimer.current);
            warningTimer.current = null;
        }
        // notice_lane_departure && playLocalAudio(localAudioNames.OFF_TRACK, 'Important');
        lane_departure_pre.current = notice_lane_departure
    },[notice_lane_departure])

   /* // 只对双warning、双intervention处理，一旦左侧是warning或intervention只显示左车道图
    const LdwNoticeItem = (leftLaneState) => {
        let ldwNoticeGif = '';
        if (leftLaneState === 'WARNING') {
            if (right_lane_state === 'WARNING') {
                ldwNoticeGif = LeftRightLaneStateWarning;
            }
            else {
                ldwNoticeGif = LeftLaneStateWarning;
            }
        }
        else if (leftLaneState === 'INTERVENTION') {
            if (right_lane_state === 'INTERVENTION') {
                ldwNoticeGif = LeftRightLaneStateIntervention;
            }
            else {
                ldwNoticeGif = LeftLaneStateIntervention;
            }
        }
        else if (leftLaneState === 'NORMAL')  {
            if (right_lane_state === 'WARNING') {
                ldwNoticeGif = RightLaneStateWarning;
            }
            else if (right_lane_state === 'INTERVENTION') {
                ldwNoticeGif = RightLaneStateIntervention;
            }
            else if (right_lane_state === 'NORMAL') {
                ldwNoticeGif = LeftRightLaneStateNormal;
            }
        }
        return (
            <div className='tipsItemGif'>
                <p>{translate("LKASetting.noticeLaneDepartureText")}</p>
                {ldwNoticeGif && <img src={ ldwNoticeGif } alt=''></img>}
                {
                    notice_steering_wheel_vibration
                    && <img className='steeringwheel' src={ SteeringWheel } alt=''></img>
                }
            </div>
        )
    }*/
    // 只对双warning、双intervention处理，一旦左侧是warning或intervention只显示左车道图
    const LdwNoticeItem = (leftLaneState) => {
        let ldwNoticeGif = '';
        if (leftLaneState === 'WARNING') {
            if (right_lane_state === 'WARNING') {
                ldwNoticeGif = LeftRightLaneStateWarning;
            }
            else {
                ldwNoticeGif = LeftLaneStateWarning;
            }
        }
        else if (leftLaneState === 'INTERVENTION') {
            if (right_lane_state === 'INTERVENTION') {
                ldwNoticeGif = LeftRightLaneStateIntervention;
            }
            else {
                ldwNoticeGif = LeftLaneStateIntervention;
            }
        }
        else if (leftLaneState === 'NORMAL')  {
            if (right_lane_state === 'WARNING') {
                ldwNoticeGif = RightLaneStateWarning;
            }
            else if (right_lane_state === 'INTERVENTION') {
                ldwNoticeGif = RightLaneStateIntervention;
            }
            else if (right_lane_state === 'NORMAL') {
                ldwNoticeGif = LeftRightLaneStateNormal;
            }
        }
        if(ldwNoticeGif || notice_steering_wheel_vibration){
            const imgData = {};
            if(ldwNoticeGif && notice_steering_wheel_vibration){
                imgData["img"] = ldwNoticeGif;
                imgData["imgSub"] = SteeringWheel;
            }else if(ldwNoticeGif){
                imgData["img"] = ldwNoticeGif;
            }else if(notice_steering_wheel_vibration){
                imgData["imgSub"] = SteeringWheel;
            }
            modalQueue({
                type: modalType.LKA,
                modalData:{
                    ...imgData,
                    "text": translate("LKASetting.noticeLaneDepartureText")
                }
            })
            reportDataFunc("LDPWarning");
        }else{
            modalQueue({type: modalType.LKA, modalData: null})
        }
    }
    useEffect(()=>{
        LdwNoticeItem(left_lane_state)
    },[left_lane_state, right_lane_state, notice_steering_wheel_vibration])

    // 数据上报
    const reportDataFunc = (value) =>{
        batchReport({
            eventName: reportEventName.HMI_LDP_view,
            timestamp: new Date().getTime(),
            text: value,
        })
    }
    return(
        <>
            <AdasModal />
           {/* { (left_lane_state || right_lane_state) && LdwNoticeItem(left_lane_state)}*/}
        </>
    )

}
