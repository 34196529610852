import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import localAudioNames from '@/constant/localAudioName';
import { AppFn } from '@/utils/native';

const { playLocalAudio } = AppFn

export default function FollowCarWarning() {
    // 开关状态
    const switchFlowCarState = useSelector(state => state?.switchModeAction?.switchFollowCarState);
    // 跟车时距大于目标时距
    const greaterThanFollow = useSelector(state => state?.featureModelDriving?.carFollow?.greaterThanFollow);
    // 跟车时距小于目标时距
    const lessThanFollow = useSelector(state => state?.featureModelDriving?.carFollow?.lessThanFollow);
    // 大于目标定时器
    const greaterThanTimer = useRef(null);

    const [greaterAudioLock, setGreaterAudioLock] = useState(false);

    const tenSecondGreaterThanTimer = useRef(null);

    // 小于目标定时器
    const lessThanTimer = useRef(null);

    const [lessAudioLock, setLessAudioLock] = useState(false);

    const tenSecondLessThanTimer = useRef(null);


    // 清除 跟车过大语音 定时器
    const clearGreaterThanTimer = () => {
        clearTimeout(greaterThanTimer.current); 
        greaterThanTimer.current = null;
    }
    // 清除 跟车过大的十秒 定时器
    const clearTenSecondGreaterThanTimer = () => {
        clearTimeout(tenSecondGreaterThanTimer.current); 
        tenSecondGreaterThanTimer.current = null; 
    }
    // 清除 跟车过小语音 定时器
    const clearTenSecondLessThanTimer = () => {
        clearTimeout(tenSecondLessThanTimer.current); 
        tenSecondLessThanTimer.current = null; 
    }
    // 清除 跟车过小的十秒 定时器
    const clearLessThanTimer = () => {
        clearTimeout(lessThanTimer.current); 
        lessThanTimer.current = null;
    }

    // 
    useEffect(() => {
        if (switchFlowCarState && greaterThanFollow) {
            // 10s锁定
            if (greaterAudioLock) return;
            // 已有定时器不需要重新启动
            if (greaterThanTimer.current) return;
            // 10s解锁清除10s定时
            if (tenSecondGreaterThanTimer.current) {
                clearTenSecondGreaterThanTimer();
            }
            greaterThanTimer.current = setTimeout(() => {
                playLocalAudio(localAudioNames.GREATER_THAN_FOLLOW_CAR, 'Normal');
                setGreaterAudioLock(true);
            }, 5000);
        }
        else {
            clearGreaterThanTimer();
            clearTenSecondGreaterThanTimer();
            setGreaterAudioLock(false);
        }
    }, [switchFlowCarState, greaterThanFollow, greaterAudioLock])

    useEffect(() => {
        if (greaterAudioLock) {
            if (tenSecondGreaterThanTimer.current) return;
            tenSecondGreaterThanTimer.current = setTimeout(() => {
                setGreaterAudioLock(false);
                clearGreaterThanTimer();
            }, 10000);
        }
    }, [greaterAudioLock])

    // 小于目标车距
    useEffect(() => {
        if (switchFlowCarState && lessThanFollow) {
            // 10s锁定
            if (lessAudioLock) return;
            if (lessThanTimer.current) return;
            if (tenSecondLessThanTimer.current) {
                clearTenSecondLessThanTimer();
            } 
            lessThanTimer.current = setTimeout(() => {
                playLocalAudio(localAudioNames.LESS_THAN_FOLLOW_CAR, 'Normal');
                setLessAudioLock(true);
            }, 3000);
        }
        else {
            clearLessThanTimer()
            clearTenSecondLessThanTimer();
            setLessAudioLock(false);
        }
    }, [switchFlowCarState, lessThanFollow, lessAudioLock])

    // 小于目标车距锁
    useEffect(() => {
        console.log(lessAudioLock);
        if (lessAudioLock) {
            if (tenSecondLessThanTimer.current) return;
            tenSecondLessThanTimer.current = setTimeout(() => {
                setLessAudioLock(false);
                clearLessThanTimer();
            }, 10000);
        }
    }, [lessAudioLock])

    return null;

}