const INIT_STATE = {
    icon: undefined,
    content: undefined,
    priority: undefined
};

export default function showWidget(state = INIT_STATE, action) {
    switch (action.type) {
        case 'updateWidgetInfo':
            return {
                ...state,
                ...{
                    icon: action.data.icon,
                    content: action.data.content,
                    priority: action.data.priority
                }
            }
        default:
            return state;
    }
}
