import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import store from '@/store';
import takeOverImg from '@/assets/hnp/take_over_img.png';
import { TAKE_OVER_ACTION } from '@/store/action/type';

import './index.less';
export default function DrivingLevelNotice() {
    const {t: translate} = useTranslation();
    const takerOverState = useSelector(state => state?.warningData?.takerOverState);
    const takeroverContinueState = useSelector(state => state?.warningData?.takeroverContinueState);
    const imgSrc = useSelector(state => state?.warningData?.img) || takeOverImg;
    const text = useSelector(state => state?.warningData?.text) || translate("takeOverTips");
    const dispatch = useDispatch();
    const hideTimer = useRef(null);
    useEffect(()=>{
        if(takerOverState){
            // 图片替换时重启定时器
            clearTimeout(hideTimer.current);
            hideTimer.current = null;
            hideTimer.current = setTimeout(()=>{
                dispatch({
                    type: TAKE_OVER_ACTION.takerOverState,
                    data: {
                        takerOverState: false,
                        img: '',
                        text: ''
                    },
                });
            },3000)
        }
    },[takerOverState, imgSrc, text])


    return (
        <React.Fragment>
            {(takerOverState || takeroverContinueState) && <div className='takeOverBox'>
                {imgSrc && <img src={ imgSrc }></img>}
                <p>{text}</p>
            </div>}
        </React.Fragment>

    )
}
