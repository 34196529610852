import store from '@/store';
import localCache from '@/utils/localCache';

export function getReportEventAtt() {
    const state = store?.getState();
    /**
     * 智驾类
     * */
    // 当前智驾运行状态
    const CurrentAD = state?.featureModelDriving?.baseData?.os?.driving?.running_mode;
    // 最高可用运行状态
    const HighestAD = state?.featureModelDriving?.switch?.driving?.running_mode?.switch_running_mode;
    // 当前可进入的运行状态
    // const HighestWidgetAD = state?.func?.currentState?.widget?.running_mode?.highest_running_mode;
    const HighestWidgetAD = state?.featureModelDriving?.baseData?.os?.driving?.available_running_mode;

    /**
     * 设置类
     * */
    // 智驾设置配置 跟据HMInode字段判断
    const ADSetting = {
        CP: HighestAD === 'ACC' ? false : true,
        NP: HighestAD === 'ACC' || HighestAD === 'CP' ? false : true,
    };
    /**
     * NP
     * */
    // 变道确认开关
    const LaneChangeConfirm =
        state?.featureModelDriving?.switch?.driving?.lane_change_mode?.np_confirm_switch || 'NORMAL';
    /**
     * CP
     * */
    // 驾驶风格
    const DrivingStyle = state?.featureModelDriving?.switch?.driving?.driving_style?.driving_style_switch || 'NORMAL';
    // 变道风格
    const LaneChangeStyle =
        state?.featureModelDriving?.switch?.driving?.driving_style?.lane_change_style_switch || 'NORMAL';
    // 主动变道开关
    const CPLaneChange = state?.featureModelDriving?.switch?.driving?.lane_change_mode?.cp_autonomous_switch;
    /**
     * ADAS
     * */
    const ADAS = {
        AEB: state?.featureModelDriving?.switch?.adas?.aeb?.aeb_switch,
        FCW: state?.featureModelDriving?.switch?.adas?.fcw?.fcw_switch,
        FCW_Sensitivity: state?.featureModelDriving?.switch?.adas?.fcw?.fcw_sensitive_level,
        RCW: state?.featureModelDriving?.switch?.adas?.rcw_switch,
        BSD_LCA: state?.featureModelDriving?.switch?.adas?.lca_bsd_switch,
        DOW: state?.featureModelDriving?.switch?.adas?.dow_switch,
        TLA: state?.featureModelDriving?.switch?.adas?.tla_switch,
        SLIF: state?.featureModelDriving?.switch?.adas?.slif?.slif_switch,
        LDW: state?.featureModelDriving?.switch?.adas?.lka_switch?.ldw_switch,
        LDP: state?.featureModelDriving?.switch?.adas?.lka_switch?.ldp_switch,
        LDW_Sensitivity: state?.featureModelDriving?.switch?.adas?.lka_switch?.sensitive_level,
        /*LDW_Shake: state?.featureModelDriving?.switch?.adas?.lka_switch?.warning_type,
        LDW_Sound: state?.featureModelDriving?.switch?.adas?.lka_switch?.warning_type,*/
        LDW_warning_type: state?.featureModelDriving?.switch?.adas?.lka_switch?.warning_type,
        ELK: state?.featureModelDriving?.switch?.adas?.lka_switch?.elk_switch,
        CSW: state?.featureModelDriving?.switch?.adas?.csw_switch,
        ESS: state?.featureModelDriving?.switch?.adas?.aeb?.ess_switch,
        IHC: state?.featureModelDriving?.switch?.adas?.ihc_switch,
    };
    /**
     * 路线类
     * */
    const RouteInfo = {
        router_name: state?.routerInfo?.router_name,
        router_city: state?.routerInfo?.router_city,
        router_type: state?.routerInfo?.router_type,
        on_the_route: state?.featureModelDriving?.baseData?.os?.onroute,
    };
    /**
     * 用户路测配置
     * */
    // 车速设定(设置车速是否有语音播报，这个开关2.5.0已经下掉了)
    // const SpeedSetting = state?.func?.currentState?.notice_list?.notice_dirver_speed_limit_action?.data?.speed;
    // 15s进自动
    const AutoEnterAD = state?.warningAutomatic?.takeOverSwitchStatus;
    // 跟车时距设定
    const FollowLevelSetting = state?.featureModelDriving?.baseData?.os?.driving?.car_follow_level;
    /**
     * 路测信息
     * */
    // 定位精度
    const PositionPrecision = state?.featureModelDriving?.baseData?.os?.position_state || '--';
    /**
     * 其他设置
     * */
    const Mtour = state?.switchModeAction?.switchUnpState;
    // const MvizLayout = state?.switchModeAction?.switchMvizState || localStorage.getItem('switch_mviz_layout');
    const MvizLayout = state?.switchModeAction?.switchMvizState;

    // 智驾播报
    const SwitchDrivingBroadcast = store?.getState()?.switchModeAction?.switchDrivingBroadcast;
    return {
        CurrentAD,
        HighestAD,
        HighestWidgetAD,

        ADSetting,

        LaneChangeConfirm,

        DrivingStyle,
        LaneChangeStyle,
        CPLaneChange,

        ADAS,

        RouteInfo,

        AutoEnterAD,
        FollowLevelSetting,

        PositionPrecision,

        Mtour,
        MvizLayout,
        SwitchDrivingBroadcast,
    };
}
