import React, {memo, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import localAudioNames from '@/constant/localAudioName';
import { AppFn } from '@/utils/native';
const { playLocalAudio } = AppFn

function CollisionAlert() {
    // 碰撞风险提示信号 true/false
    const collision_alert = useSelector(state => state?.featureModelDriving?.collisionAlert?.collisionAlert);
    let warningTimer = useRef(null);

    useEffect(() => {
        if (collision_alert) {
            playLocalAudio(localAudioNames.BIBIBI_WARNING, "Highest");
            (!warningTimer.current) && (warningTimer.current = setInterval(()=>{
                playLocalAudio(localAudioNames.BIBIBI_WARNING, "Highest")
            },1000))
        } else {
            clearInterval(warningTimer.current);
            warningTimer.current = null;
        }
    }, [collision_alert])

    return <></>
}

export default memo(CollisionAlert)
