import { useSelector } from 'react-redux';
import './index.less';

const geer_map = ['P', 'R', 'N', 'D'];

export default function Gear() {
    // const robonodeReady = useSelector(state => state?.robonode?.ready);
    const gear = useSelector((state) => state?.featureModelParking?.body?.gear);
    return (
        <>
            {
                <div className="p-info-geer">
                    <ul>
                        {geer_map.map((item, index) => {
                            return (
                                <li className={gear === item ? 'active' : ''} key={index}>
                                    {item}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            }
        </>
    );
}
