import React from "react";
import {Grid} from "antd-mobile";
import "./index.less"

export default function CardLayout(props) {
    return (
        <div className={"card_wrap"}>
            <Grid columns={2}>
                <Grid.Item>
                    {props?.leftDom}
                </Grid.Item>
                <Grid.Item>
                    {props?.rightDom}
                </Grid.Item>
            </Grid>
        </div>
    )
}
