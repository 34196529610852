import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { modalQueue, modalType } from '@/utils/modalQueue';
import { AppFn } from '@/utils/native';
import amap from '@/assets/hnp/adas/AMAP/amap.png';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import localAudioNames from '@/constant/localAudioName';
import { useTranslation } from 'react-i18next';
const { playLocalAudio } = AppFn;

export default function AmapComponent() {
    const { t: translate } = useTranslation();
    const amap_notice = useSelector((state) => state?.featureModelDriving?.amap?.notice?.data?.isAmapWarning);
    let endingTimer = useRef(null);

    // 数据上报
    const reportDataFunc = () => {
        singelPointReport({
            eventName: reportEventName.HMI_AMAP_VIEW,
            timestamp: new Date().getTime(),
        });
    };

    useEffect(() => {
        if (amap_notice) {
            clearTimeout(endingTimer.current);
            endingTimer.current = null;
            // 语音播放逻辑
            playLocalAudio(localAudioNames.AMAP_BREAKING, 'Important');
            // 弹窗展示逻辑
            modalQueue({ type: modalType.AMAP, modalData: { img: amap, text: translate('settings.AMAPPressBrake') } });
            reportDataFunc();
        } else {
            // 弹窗销毁逻辑
            endingTimer.current = setTimeout(() => {
                modalQueue({ type: modalType.AMAP, modalData: null });
                clearTimeout(endingTimer.current);
                endingTimer.current = null;
            }, 500);
        }
    }, [amap_notice]);

    return <></>;
}
