import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { cusToast } from '@/components/customToast';
// import { customFilterVersion } from '@/constant/filterVersionMap';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import DriveStyle from './customiseCruise/drivingStyle';
import LaneChangeStyle from './customiseCruise/laneChangeStyle';
import LaneChangeConfirm from './customiseNavigation/laneChangeConfirm';
import AutoLaneChangeSwitch from './customiseCruise/autoLaneChangeSwitch';
import AutoLaneChangeSwitchEU from './customiseCruise/autoLaneChangeSwitchEU';
import cpWhite from '@/assets/slider/cp_white.jpg';
import npWhite from '@/assets/slider/np_white.jpg';
import cpBlack from '@/assets/slider/cp_black.jpg';
import npBlack from '@/assets/slider/np_black.jpg';
import TFLSwitch from './customiseCruise/tflSwitch';
import AccCpTakeoverSwitch from './customiseCruise/accCpTakeoverSwitch';
import CpWarningSwitch from './customiseCruise/cpWarningSwitch';
// import LaneChangeBeep from "./customiseNavigation/laneChangeBeep";
import './index.less';

export default function DrivingSwitchModule() {
    const dispatch = useDispatch();
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    const hmi_version = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.hmi_version);
    // 针对2.7.3和2.9.2的判断
    const isFilterVersion = hmi_version?.includes('HARZ') || true;
    // toast文案
    // const [switchToast, setSwitchToast] = useState('');
    // 是否展示toast
    // const [showToast, setShowToast] = useState(false);
    // 最高智驾
    const switch_running_mode_state = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.running_mode?.switch_running_mode,
    );
    // cp开关
    const [cpSwitch, setCpSwitch] = useState(switch_running_mode_state === 'ACC' ? false : true);
    // np开关
    const [npSwitch, setNpSwitch] = useState(
        switch_running_mode_state === 'ACC' || switch_running_mode_state === 'CP' ? false : true,
    );
    // 当前智驾
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 主题色
    const themeColorState = useSelector((state) => state?.themeAction?.themeColorState);
    // tfl-switch按钮
    const tfl_switch =
        useSelector((state) => state?.featureModelDriving?.switch?.driving?.cp_tfl_stop_control?.switch) || 'OPEN';
    // CP告警开关
    const cp_warning_switch =
        useSelector((state) => state?.featureModelDriving?.switch?.driving?.cp_warning_control?.switch) || 'CLOSE';
    // 碰撞风险提示按钮
    const acc_cp_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.collision_alert_control?.switch,
    );
    // featureModel返回的版本过滤
    const confirmFunction = useSelector((state) => state?.featureModelDriving?.laneChange?.confirmFunction);
    const { t: translate } = useTranslation();
    const toastTimer = useRef(null);

    // CP开关
    const cpSwitchChange = (value) => {
        if (value === cpSwitch) return;
        // 智驾运行时，NP或CP开关都不支持调节
        if (['HNP_PLUS', 'HNP', 'CP', 'ACC'].indexOf(running_mode) > -1 && automatic_state === 'ACTIVE') {
            const text = translate('settings.manualDrivingMode');
            // setSwitchToast(text)
            // toShowToast();
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }
        // 数据上报
        reportDataFunc({ type: 'CP', value });
        // 关闭CP同时关闭NP
        if (value) {
            DriveModeSend(value, npSwitch);
        } else {
            DriveModeSend(value, false);
            setNpSwitch(false);
            reportDataFunc({ type: 'NP', value: false });
        }
        setCpSwitch(value);
    };

    // NP开关
    const npSwitchChange = (value) => {
        if (value === npSwitch) return;
        // 智驾运行时，NP或CP开关都不支持调节
        if (['HNP_PLUS', 'HNP', 'CP', 'ACC'].indexOf(running_mode) > -1 && automatic_state === 'ACTIVE') {
            const text = translate('settings.manualDrivingMode');
            // setSwitchToast(text)
            // toShowToast();
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }
        // todo 提示请打开CP不可开启NP
        if (value && !cpSwitch) {
            const text = translate('settings.cruisePilot');
            // setSwitchToast(text);
            // toShowToast();
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }
        DriveModeSend(cpSwitch, value);
        setNpSwitch(value);
        // 数据上报
        reportDataFunc({ type: 'NP', value });
    };

    // 向底层传数据
    const operateDriveMode = async (v) => {
        FM.sendToHMIDriving(
            SocketMap.operateDriveMode,
            {
                switch_running_mode: v,
            },
            'drive_node_res_fail_cb',
        );
    };

    // 数据上报
    const reportDataFunc = (params) => {
        const { type, value } = params;
        singelPointReport({
            eventName: reportEventName.HMI_AD_MODE_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                CP: (type === 'CP' ? value : cpSwitch) ? 'ON' : 'OFF',
                NP: (type === 'NP' ? value : npSwitch) ? 'ON' : 'OFF',
            },
        });
    };

    const DriveModeSend = (cpValue, npValue) => {
        console.log('DriveModeSend', cpValue, npValue);
        let runningModeState = 'HNP';
        if (cpValue && npValue) {
            runningModeState = 'HNP';
        } else if (cpValue && !npValue) {
            runningModeState = 'CP';
        } else if (!cpValue && !npValue) {
            runningModeState = 'ACC';
        }
        operateDriveMode(runningModeState);
        singelPointReport({
            eventName: reportEventName.HMI_POINT_SETTINGON_CLICK,
            text: runningModeState,
            timestamp: new Date().getTime(),
        });
    };

    // const toShowToast = () => {
    //     setShowToast(true);
    //     if (toastTimer.current) {
    //         clearTimeout(toastTimer.current);
    //         toastTimer.current = null;
    //     }
    //     toastTimer.current = setTimeout(() => {
    //         setShowToast(false);
    //     }, 2000)
    // }

    return (
        <div className="switch-module-container">
            {/*{*/}
            {/*    showToast && (*/}
            {/*        <div className="switch-toast">*/}
            {/*            <div className="switch-toast-text">{switchToast}</div>*/}
            {/*        </div>*/}
            {/*    )*/}
            {/*}*/}
            <div className="container-top">
                <div
                    className="main-swtich"
                    style={{ backgroundImage: `url(${themeColorState === 'black' ? cpBlack : cpWhite})` }}
                >
                    <div className="switch-type">CP</div>
                    <div className="switch-title">{translate('settings.cruiseAssist')}</div>
                    <Switch
                        checked={cpSwitch}
                        onChange={(checked) => cpSwitchChange(checked)}
                        className="common-switch"
                    />
                </div>
                <div
                    className="main-swtich"
                    style={{ backgroundImage: `url(${themeColorState === 'black' ? npBlack : npWhite})` }}
                >
                    <div className="switch-type">NP</div>
                    <div className="switch-title">{translate('settings.navigationPilot')}</div>
                    <Switch
                        checked={npSwitch}
                        onChange={(checked) => npSwitchChange(checked)}
                        className="common-switch"
                    />
                </div>
            </div>
            <div className="container-bottom">
                <div
                    className={`customise-content ${!cpSwitch && 'is_disabled'} ${
                        isFilterVersion && 'is-filter-version'
                    }`}
                >
                    <div className="customise-title">{translate('settings.customizeCp')}</div>
                    {/* 德国2.9.2和2.7.3版本，德国版本中有四个功能存在样式功能逻辑判断 */}
                    {isFilterVersion ? (
                        <>
                            <DriveStyle isDisabled={!cpSwitch} />
                            <LaneChangeStyle isDisabled={!cpSwitch} />
                            <AutoLaneChangeSwitchEU isDisabled={!cpSwitch} />
                            {tfl_switch && <TFLSwitch isDisabled={!cpSwitch} />}
                            {cp_warning_switch && <CpWarningSwitch isDisabled={!cpSwitch} />}
                        </>
                    ) : (
                        <>
                            {/*因为当前只有碰撞提示按钮，当没有碰撞提示按钮的信号时，显示"功能开发中，敬请期待"*/}
                            {!acc_cp_switch && <div className="customist-tip">{translate('settings.tipCp')}</div>}
                            {/* 11.1下线1214 */}
                            {/* <DriveStyle isDisabled={!cpSwitch} /> */}
                            {/* 12.30共版本中暂时下掉 */}
                            {/* <AutoLaneChangeSwitch isDisabled={!cpSwitch} /> */}
                            {/* {tfl_switch && <TFLSwitch isDisabled={!cpSwitch} />} */}
                            {/* 11.1下线1214 */}
                            {/* <LaneChangeStyle isDisabled={!cpSwitch} /> */}
                        </>
                    )}
                    {/*德国和国内都有的功能*/}
                    {acc_cp_switch && <AccCpTakeoverSwitch />}
                </div>
                <div className={`customise-content ${!npSwitch && 'is_disabled'}`}>
                    <div className="customise-title">{translate('settings.customizeNp')}</div>
                    {confirmFunction !== 'hide_confirm' ? (
                        <LaneChangeConfirm isDisabled={!npSwitch} />
                    ) : (
                        <>
                            <div className="customist-tip">{translate('settings.tipCp')}</div>
                            {/* 12.30共版本中暂时下掉确认式变道功能 */}
                            {/* <LaneChangeConfirm isDisabled={!npSwitch} /> */}
                        </>
                    )}
                    {/* 隐藏变道提示音 */}
                    {/* <LaneChangeBeep /> */}
                </div>
            </div>
        </div>
    );
}
