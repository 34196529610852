export const get = (key, cb) => {
    try {
        cb(JSON.parse(localStorage?.[key]));
    } catch {
        cb(localStorage?.[key]);
    }
};

export const set = (key, value) => (localStorage[key] = JSON.stringify(value));

export const remove = (key) => localStorage.removeItem(key);
