/**
 * csw: null,
  mrc: null,
  routeOdd: null,
  positionState: null,
  safetyDiagnose: null,
  drivingState: null,
  baseData: null,
  lpnpRecommendEntrypoint: null,
  stopGo: null,
  switchModel: null,
  sharpCurve: null,
  collisionWarning: null,
  ess: null,
  aeb: null,
  ihc: null,
  tsr: null,
  tla: null,
  latestLaneChange: null,
  laneChange: null,
  blindAreaWarning: null,
  rcw: null,
  ramp: null,
  coneBucketStop: null,
  lka: null,
  fcw: null,
  carFollow: null,
  eventCollector: null,
  speedToSlif: null,
  slif: null,
 */
const INIT_STATE = {};

export default function FeatureModelDriving(state = INIT_STATE, action) {
  switch (action.type) {
    case "featureModelDriving":
      return {
        // ...state,
        ...{
          ...action.data,
        },
      };
    default:
      return state;
  }
}
