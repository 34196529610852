import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {cusToast} from '@/components/customToast';
import {useTranslation} from 'react-i18next';
import { AppFn } from '@/utils/native';
import ExclamationMark from '@/assets/toast/exclamationMark.png';

const { playTTSAudio } = AppFn

export default function MRCTakeOver() {
    const notice_shift_gear = useSelector(state => state?.featureModelDriving?.mrc?.notice?.data?.shiftGear);

    const {t: translate} = useTranslation();
    // MRC刹停后
    useEffect(()=>{
        let text = '',maskClassName = '';
        if(notice_shift_gear){
            text = (<div className='condition-content'>
                {ExclamationMark && <img className='condition-icon' src={ExclamationMark} alt='' />}
                <div>{translate("MRC.shiftGearText")}</div>
            </div>);
            maskClassName = 'toast-error';
            text !== '' && (
                cusToast.show({
                    maskClassName,
                    content: text,
                    duration: 2000,
                })
            );
            playTTSAudio(translate("MRC.shiftGearTTS"),'Highest');
        }
    },[notice_shift_gear])

    // MRC 信号
    return (
        <> </>
    );
}
