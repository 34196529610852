import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MvizLayout from './MvizLayout';
import AutoEventSwitch from './autoEventSwitch';
import WorkbenchCard from './WorkbenchCard';
import VcruiseSwitch from './vcruiseSwitch';
import HMIMode from './HMIMode';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import isCN from '@/utils/isCN';
import localCache from '@/utils/localCache';
import './index.less';

const ua = window.navigator.userAgent;
const reg = /language\(([^\)]+)\)/;

export default function OtherSettings() {
    const event_collector = useSelector((state) => state?.featureModelDriving?.eventCollector);
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    const dispatch = useDispatch();

    const [isShowWorkBench, setIsShowWorkBench] = useState(false);
    useEffect(() => {
        // reg.exec(ua)国内版本没有，所以取不到就是true
        const isShow = i18nLang !== 'en-US' || (Array.isArray(reg.exec(ua)) ? reg.exec(ua)[1] !== 'en-US' : true);
        setIsShowWorkBench(isShow);
        //兜底
        if (!isShow) {
            dispatch({
                type: SWITH_MODE_ACTION.switchWorkBench,
                data: false,
            });
        }
    }, []);
    console.log('setIsShowWorkBench', isShowWorkBench);

    return (
        <>
            <div className={'other_settings_wrap'}>
                {/*<div className={"left_wrap"}>*/}
                {/*  <MvizLayout />*/}
                {/*  {event_collector && (*/}
                {/*      <AutoEventSwitch />*/}
                {/*  )}*/}
                {/*  <VcruiseSwitch/>*/}
                {/*</div>*/}
                {/*<div className={"right_wrap"}>*/}
                {/*  {isShowWorkBench && <WorkbenchCard />}*/}
                {/*</div>*/}

                <MvizLayout />
                {isShowWorkBench && <WorkbenchCard />}
                {event_collector && <AutoEventSwitch />}
                <VcruiseSwitch />
                {isCN() && <HMIMode />}
            </div>
        </>
    );
}
