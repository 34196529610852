import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import CuriseActive from '@/assets/hnp/curise_active.png';
import CuriseMd from '@/assets/hnp/curise_md.png';
import { widgetQueue, widgetType } from '@/utils/widgetQueue';
import WidgetComponent from '@/components/widget/index';
// import max_warning from "@/assets/drivingLevel/max_warning.png";
import { useTranslation } from 'react-i18next';

import './index.less';

const ua = window.navigator.userAgent;
const reg = /language\(([^\)]+)\)/;
let languageType = reg.exec(ua);
const isEU = languageType ? (languageType[1] === 'en-US' ? true : false) : false;

const runningMode = {
    ACC: 'ACC',
    CP: 'CP',
    HNP: 'NP',
    HNP_PLUS: 'NP+',
};

const speedEventEnum = {
    // 长按加
    PLUS_LONG: 'plus_long',
    // 短按加
    PLUS_SHORT: 'plus_short',
    // 长按减
    MINUS_LONG: 'minus_long',
    // 短按减
    MINUS_SHORT: 'minus_short',
};

export default function VcruiseComponent() {
    // 开关 ON/OFF
    const showVcruise = useSelector((state) => state?.showVcruise?.vcruise);
    // 代表当前正在运行的智驾等级
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    // 当前智驾状态
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 代表当前可进入的智驾等级
    const widget_highest_running_mode = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.highestRunningMode,
    );
    // 速度
    const cruise = useSelector((state) => state?.featureModelDriving?.baseData?.body?.cruise);
    // 是否按键
    const speed_button_event = useSelector((state) => state?.featureModelDriving?.speedButtonEvent?.press);
    // 智驾状态跳变（low，UPPER，quit）
    const notice_driving_state = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.drivingModeChange?.state,
    );

    const [isMd, setIsMd] = useState(true);
    const [isShow, setIsShow] = useState(false);

    const [cruiseChange, setCruiseChange] = useState('');
    const animationTimer = useRef(null);
    const widgetTimer = useRef(null);

    const { t: translate } = useTranslation();

    useEffect(() => {
        // 手动状态
        if (!(['HNP_PLUS', 'HNP', 'CP', 'ACC'].indexOf(running_mode) > -1 && automatic_state === 'ACTIVE')) {
            setIsMd(true);
            if (['HNP_PLUS', 'HNP', 'CP', 'ACC'].indexOf(widget_highest_running_mode) > -1) {
                setIsShow(true);
            } else {
                setIsShow(false);
            }
        } else {
            setIsMd(false);
            setIsShow(true);
        }
    }, [running_mode, automatic_state, widget_highest_running_mode]);

    // 动画放大效果(暂时未考虑频繁变化场景)
    useEffect(() => {
        // 手动、打开感知开关时不展示动画
        if ((isMd && !isShow) || showVcruise !== 'OFF') return;
        // 速度变化、按键有值才有动效
        if (cruise || (speed_button_event && speed_button_event !== '')) {
            if (!cruiseChange) {
                setCruiseChange('cruise-change');
                animationTimer.current = setTimeout(() => {
                    setCruiseChange('');
                    clearTimeout(animationTimer.current);
                    animationTimer.current = null;
                }, 1300);
            }
        }
    }, [cruise, speed_button_event, isMd, isShow]);

    // 处理widget信息
    useEffect(() => {
        let widgetSpeedContent = '',
            widgetSpeedIcon = 'max_warning';
        let realCruise = Math.round(cruise * 3.6);
        // 打开感知开关的情况下，不需要处理案件widget信息
        if ((isMd && !isShow) || showVcruise !== 'OFF') {
            widgetQueue({ widgetNumber: 1, type: widgetType.VCURISE_LIMIT, widgetContent: null });
            return;
        }
        if (speed_button_event && speed_button_event !== '') {
            // 长按提示
            if (
                (speed_button_event === speedEventEnum.PLUS_LONG || speed_button_event === speedEventEnum.MINUS_LONG) &&
                automatic_state === 'ACTIVE' &&
                ['CP', 'ACC'].includes(running_mode)
            ) {
                widgetSpeedContent = translate('speedLongTip');
            }
            if (isEU) {
                // 短按加，极限值再按提示
                if (speed_button_event === speedEventEnum.PLUS_SHORT && realCruise === 150) {
                    widgetSpeedContent = `${runningMode[running_mode]} speed limit is ${realCruise} km/h`;
                }
                // 短按减，极限值再按提示
                else if (speed_button_event === speedEventEnum.MINUS_SHORT && realCruise === 10) {
                    widgetSpeedContent = `${runningMode[running_mode]} speed limit is ${realCruise} km/h`;
                }
            } else {
                // 测试改为80，上线恢复130
                if (speed_button_event === speedEventEnum.PLUS_SHORT && realCruise === 130) {
                    widgetSpeedContent = `${runningMode[running_mode]} speed limit is ${realCruise} km/h`;
                } else if (speed_button_event === speedEventEnum.MINUS_SHORT && realCruise === 10) {
                    widgetSpeedContent = `${runningMode[running_mode]} speed limit is ${realCruise} km/h`;
                }
            }
        }

        if (widgetSpeedContent) {
            widgetQueue({
                widgetNumber: 1,
                type: widgetType.VCURISE_LIMIT,
                widgetContent: { icon: widgetSpeedIcon, content: widgetSpeedContent },
            });
            // 如果之前已经有提示了，清除定时器，重启定时器进行清空处理
            if (widgetTimer.current) {
                clearTimeout(widgetTimer.current);
                widgetTimer.current = null;
            }
            widgetTimer.current = setTimeout(() => {
                widgetQueue({ widgetNumber: 1, type: widgetType.VCURISE_LIMIT, widgetContent: null });
            }, 5000);
        }
    }, [cruise, speed_button_event, isMd, isShow, showVcruise]);

    // 智驾状态改变文案消失（需求指的是降级和掉手动）
    useEffect(() => {
        if (notice_driving_state === 'QUIT' || notice_driving_state === 'LOWER') {
            widgetQueue({ widgetNumber: 1, type: widgetType.VCURISE_LIMIT, widgetContent: null });
        }
    }, [notice_driving_state]);

    return (
        <div className="vcruise-contanier">
            {showVcruise === 'OFF' && typeof cruise !== 'undefined' && isShow && (
                <div className={`vcruise-element ${isMd ? '' : 'md-element'} ${cruiseChange}`}>
                    <img alt="" src={isMd ? CuriseMd : CuriseActive} />
                    <p className="text">{Math.round(cruise * 3.6)}</p>
                </div>
            )}
            <WidgetComponent />
        </div>
    );
}
