import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import DrivingState from "../drivingContainer/drivingState";
import DrivingLevelNotice from "../drivingContainer/drivingLevelNotice";
import OddComponent from "../drivingContainer/oddComponent";
import MRCTakeOver from "../drivingContainer/MRCTakeOver"

export default function WidgetZero () {
    // 代表当前正在运行的智驾等级
    const running_mode = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    // 当前智驾状态
    const automatic_state = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 代表当前可进入的智驾等级
    const widget_highest_running_mode = useSelector(state => state?.func?.currentState?.widget?.running_mode?.highest_running_mode);
    // 代表odd的距离
    const widget_route_info_odd_distance = useSelector(state => state?.featureModelDriving?.ramp?.distance);
    // odd的类型
    const widget_route_info_odd_type = useSelector(state => state?.featureModelDriving?.ramp?.type);
    // 升降级code
    const notice_driving_code = useSelector(state => state?.func?.drivingModeChange?.data?.code);
    // MRC信号
    const notice_take_over = useSelector(state => state?.featureModelDriving?.mrc?.notice?.data?.takeOver);
    // 升降级是否展示
    const drivinglevelState = useSelector(state => state?.widget0Data?.drivinglevelState);
    // odd是否展示
    const oddComState = useSelector(state => state?.widget0Data?.oddComState);

    return (
        <div className='container-state'>
            {!drivinglevelState && !oddComState && <DrivingState/>}
            {!drivinglevelState && <OddComponent/>}
            <DrivingLevelNotice />
            <MRCTakeOver />
        </div>
    )
}
