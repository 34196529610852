import { emit } from './socket';
import store from '@/store';
import logSend from '@/utils/aLog';
const requestHmiDriving = 'requestHmiDriving';
const requestHmiParking = 'requestHmiParking';
const registerFm = 'registerFm';
const { REACT_APP_ENV } = process.env;

// 使用demo
/**
 *
    import FM from "./utils/nativeSubAndPub";
    window.pilot_kind_control_cb = function (data) {
    console.log("pilot_kind_control_cb data", data)
    }

    FM.sendToHMIParking("pilot_kind_control", {
    cmd: "DRIVING"
    }, window.pilot_kind_control_cbpilot_kind_control_cb);
 */

// 订阅对应场景的feature model数据
/**
 *
    import FM from "./utils/nativeSubAndPub";
    window.recevieFeatueModelData = (data) => {
    // dispatch到redux store中
    }
    FM.registerFeatureModel(['driving'], window.recevieFeatueModelData);
 */
class FeatureModel {
    constructor() {
        this.initFlat = false;
    }

    commonSend(cmd, cb, params) {
        console.log('common params', cmd, cb, JSON.stringify(params));
        logSend({
            action: 'cmd_h5_to_android',
            data: {
                cmd,
                cb,
                params,
            },
        });
        window?.momentaJsBridge?.dispatch(cmd, cb || null, JSON.stringify(params));
        if (REACT_APP_ENV === 'development') {
            return emit([cmd, cb, JSON.stringify(params)]);
        }
    }

    handlerParams(path, args) {
        try {
            return JSON.parse(JSON.stringify(args));
        } catch (err) {
            console.log(`FeatureModel ${path} send parama has wrong: ${err}`);
            return {};
        }
    }

    judgmentInit() {
        /**
         * 为了避免，打开app首次初始化时，featureModel还没初始化完成，此时触发send操作，导致app有一定概率崩溃，
         * 所以，我们要判断app首次初始化且收到featureModel信息后，再执行send操作
         * */
        const ready = store?.getState()?.robonode?.ready;
        if (ready && !this.initFlat) {
            this.initFlat = true;
        }
        return this.initFlat;
    }

    sendToHMIDriving(path, params, cb) {
        if (!this.judgmentInit()) return;
        this.commonSend(requestHmiDriving, cb, {
            cmd: path,
            data: this.handlerParams(path, params),
        });
    }

    sendToHMIParking(path, params, cb) {
        this.commonSend(requestHmiParking, cb, {
            cmd: path,
            data: this.handlerParams(path, params),
        });
    }

    // typeList数据枚举: driving|parking|visual|routeService
    registerFeatureModel(tl, cb) {
        this.commonSend(registerFm, cb, {
            typeList: tl || [],
        });
    }
}

export default new FeatureModel();
