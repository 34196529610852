import logSend from './aLog';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { isOfflinePack } from '@/utils/isEnv';
const ua = window.navigator.userAgent;
const reg = /hmi_appVersion\(([^\)]+)\)/;
const app_version_exec = reg.exec(ua);

export function performanceLog() {
    if (!window.performance) return;
    const speed = (navigator.connection.downlink * 1024) / 8; //单位为KB/sec
    const timing = window.performance.timing;
    const sourceTime = performance.getEntriesByType('resource');
    let data = {};
    data.netSpeed = speed; //上报网速
    data.url = window.location.href; // 当前页面url
    // DNS查找时间
    data.dns = timing.domainLookupEnd - timing.domainLookupStart;
    // TCP建连时间
    data.connect = timing.connectEnd - timing.connectStart;
    // 首字节时间
    data.ttfb = timing.responseStart - timing.navigationStart;
    // 白屏时间
    data.whiteScreenNew = isOfflinePack
        ? window.offlineEndRenderTime - window.offlineStartRenderTime
        : window.fristRenderTime - timing.navigationStart;
    data.dom = timing.domComplete - timing.domLoading;
    // 可操作时间
    data.interactiveTime = window.InteractiveTimeEnd - timing.navigationStart;

    // 页面完全加载的时间，总时长
    data.totalTime = window.TotalTimeEnd - timing.navigationStart;

    // 页面完全加载的时间，总时长
    data.allReamin = timing.loadEventEnd - timing.navigationStart;

    // 是否是离线包：isOfflinePack
    data.isOfflinePack = isOfflinePack;
    console.log('performanceEvent', { ...data, sourceTime });
    if (app_version_exec) {
        logSend({
            action: 'performanceEvent',
            data: { ...data, sourceTime },
        });
        singelPointReport({
            eventName: reportEventName.HMI_PERFORMANCE_EVENT,
            timestamp: new Date().getTime(),
            reportData: {
                ...data,
            },
        });
    }
}
