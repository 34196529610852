import { useSelector } from 'react-redux';
import React, { useEffect, memo } from 'react';
import StopSignImg from '@/assets/hnp/adas/STOP/stop_sign.png';
import zh_StopSignImg from '@/assets/hnp/adas/ETSADomestic/stop_sign.png';
import { batchReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import localCache from '@/utils/localCache';
import './index.less';

function StopSign() {
    // let i18nLang = window.localStorage.getItem("i18nLang");
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    // OPEN/CLOSE
    const stop_sign = useSelector((state) => state?.featureModelDriving?.tsr?.stopSign);

    useEffect(() => {
        if (stop_sign === 'OPEN') {
            reportDataFunc();
        }
    }, [stop_sign]);
    // 数据上报
    const reportDataFunc = () => {
        batchReport({
            eventName: reportEventName.HMI_TSR_VIEW,
            timestamp: new Date().getTime(),
            text: 'TSRView',
        });
    };
    return (
        <React.Fragment>
            {stop_sign === 'OPEN' && (
                <div className={'stop_sign_wrap'}>
                    {/* <span>STOP</span>*/}
                    <img src={i18nLang !== 'en-US' ? zh_StopSignImg : StopSignImg} alt="STOP" />
                </div>
            )}
        </React.Fragment>
    );
}
export default memo(StopSign);
