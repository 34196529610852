export const SCENE_TYPE = {
    currentScene: "currentScene"
};

export const PARKING_DATA = {
    parkingState: "parkingState"
} 

export const PARKING_NOTICE = {
    parkingNotice: "parkingNotice"
} 

export const PARKING_SWITCH = {
    parkingSwitch: "parkingSwitch"
} 

export const PARKING_LPNP_EXIT = {
    lpnpExit: "lpnpExit"
} 

export const MVIZ_APA_PARKING_DATA = {
    mvizData: "mvizData"
}

export const PARKING_WIGET = {
    parkingWiget: "parkingWiget"
}

export const USER_MODE = 'userMode'
