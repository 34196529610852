import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {cusToast} from '@/components/customToast';
import {useTranslation} from 'react-i18next';
import { AppFn } from '@/utils/native';
import { TAKE_OVER_ACTION } from '@/store/action/type';
import localAudioNames from '@/constant/localAudioName';
import { batchReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import {widgetQueue, widgetType} from '@/utils/widgetQueue';

const { playTTSAudio,playLocalAudio } = AppFn

export default function MRCTakeOver() {
    const notice_tor = useSelector(state => state?.featureModelDriving?.mrc?.notice?.data?.tor);
    let TORTimer = useRef(null);
    const {t: translate} = useTranslation();
    const dispatch = useDispatch();

    useEffect(()=>{
        let contentText = '';
        if(notice_tor){
            contentText = {'icon': 'quit_icon', 'content': `${translate("MRC.mrcTor")}`};
            playLocalAudio(localAudioNames.DENGDENG_WARNING,"Highest");
            (!TORTimer.current) && (TORTimer.current = setInterval(() => {
                playLocalAudio(localAudioNames.DENGDENG_WARNING,"Highest");
            }, 1000));
            reportDataFunc(reportEventName.HMI_HANDSON_TOR_VIEW);
        }else{
            clearInterval(TORTimer.current);
            TORTimer.current = null;
            contentText = '';
        }
        widgetQueue({widgetNumber: 2, type: widgetType.TOR, widgetContent: contentText})
    },[notice_tor])

    // 数据上报
    const reportDataFunc = (value) =>{
        batchReport({
            eventName: value,
            timestamp: Date.now(),
            text: "warning",
        })
    }
    return (
         <></>
    );
}
