import React from 'react';
import AebCard from './aebCard';
import BsdCard from './bsdCard';
import CustomFcwCard from './customFcwCard';
import CustomLdwCard from './customLdwCard';
import DowCard from './dowCard';
import ElkCard from './elkCard';
import FcwCard from './fcwCard';
import LdpCard from './ldpCard';
import LdwCard from './ldwCard';
import RcwCard from './rcwCard';
import SLifCard from './slifCard';
// import TlaCard from "./tlaCard";
import AbsmCard from './absmCard';
import CswCard from './cswCard';
import EssCard from './essCard';
import IHCCard from './IhcCard';
import CustomSlifTsrCard from './customSlifTsrCard';
import AmapCard from './amapCard';
import NrpCard from './nrpCard';
import IsaCard from './isaCard';
import './index.less';
import { useSelector } from 'react-redux';

export default function SafetyModule() {
    const ess_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.aeb?.ess_switch);
    const ihc_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.ihc_switch);
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    const amap_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.amap_switch);
    const nrp_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.nrp_switch);
    const isa_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.isa_switch);

    return (
        <React.Fragment>
            <div className={'safety_modular_wrap'}>
                <div className={'safety_modular_wrap'}>
                    <div className={'left_wrap'}>
                        <FcwCard />
                        <CustomFcwCard />
                        <RcwCard />
                    </div>
                    <div className={'right_wrap'}>
                        <LdwCard />
                        <CustomLdwCard />
                    </div>
                    <AebCard />
                    <ElkCard />
                    <DowCard />
                    <LdpCard />
                    <BsdCard />
                    {/*<TlaCard/>*/}
                    <SLifCard />
                    <AbsmCard />
                    {i18nLang === 'en-US' && <CustomSlifTsrCard />}
                    <CswCard />
                    {ess_switch && <EssCard />}
                    {ihc_switch && <IHCCard />}
                    {amap_switch && <AmapCard />}
                    {nrp_switch && <NrpCard />}
                    {isa_switch && <IsaCard />}
                </div>
            </div>
        </React.Fragment>
    );
}
