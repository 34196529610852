import { useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import './index.less';

export default function DrivingIcon (props) {

    const {currentState, startQuitAnimation = false} = props;

    // 代表当前正在运行的智驾等级
    const running_mode = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    // 当前智驾状态
    const automatic_state = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 代表当前可进入的智驾等级
    const widget_highest_running_mode = useSelector(state => state?.func?.currentState?.widget?.running_mode?.highest_running_mode);
    // 方向盘角度
    const steering_wheel_angle = useSelector(state => state?.featureModelDriving?.baseData?.body?.steering_wheel_angle);

    // 升降级是否展示
    const drivinglevelState = useSelector(state => state?.widget0Data?.drivinglevelState);
    // odd是否展示
    const oddComState = useSelector(state => state?.widget0Data?.oddComState);

    // 计算角度
    const deg = (['CP','HNP', 'HNP_PLUS'].includes(running_mode) && automatic_state === 'ACTIVE') ? (-(steering_wheel_angle || 0) * 180) / Math.PI : 0;

    return (
        <div
            className={`imgState ${currentState} ${!oddComState && automatic_state === 'ACTIVE' && 'running_mode_active'} ${automatic_state === 'STANDBY' && 'running_mode_standby'} ${startQuitAnimation && 'running_mode_quit'}`}
            key={`${running_mode}_${automatic_state}`}
            style={{transform: `rotate(${deg}deg)`}}
        >
        </div>
    )
}
