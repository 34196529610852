/**
 * 人工接管状态下，司机主动拨杆请求进自动，toast播报 && TTS语音播报
 * 0830(2.4.0)之后废弃此文件的智驾监控报警功能，只保留15s进自动提醒
 * TODO: 15s进自动提醒，不用管底层是否有报错信息上报，只需要判断是否处于STANDBY状态即可
 * */
import { AppFn } from '@/utils/native';
// import {completeList} from '@/constant/errorCodeMap';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customToast } from '@/components/toast';
import reportEventName from '@/constant/reportEventName';
import { batchReport } from '@/utils/report';
import { Feature, Channel, Group } from '@/constant/monitorKeyName';
import localAudioNames from '@/constant/localAudioName';
import { useTranslation } from 'react-i18next';
import localCache from '@/utils/localCache';

let off_audio_and_toast_15000 = false;
let timer_15000 = null;
let messageFatalLock = ''; // fatal 消息锁
let messageSevereLock = ''; // severe 消息锁
let messageAutoLock = ''; //automatic_state 消息锁
let message_error = ''; // error信息锁
let message_error_timer = 0; // error信息锁---> 时间戳

export const useDrivingLeverDetail = () => {
    // const dispatch = useDispatch();
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    const type = useSelector((state) => state?.featureModelDriving?.safetyDiagnose?.notice?.data?.type);
    const fatal_err_list =
        useSelector((state) => state?.featureModelDriving?.safetyDiagnose?.notice?.data?.fatalErrList) || [];
    const severe_err_list =
        useSelector((state) => state?.featureModelDriving?.safetyDiagnose?.notice?.data?.severeErrList) || [];
    const level = useSelector((state) => state?.featureModelDriving?.safetyDiagnose?.notice?.level);
    const takeOverSwitch = useSelector((state) => state?.warningAutomatic?.takeOverSwitchStatus);
    const switchModeState = useSelector((state) => state?.switchModeAction?.switchModeState);
    // 当前智驾等级
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const first_fatal_err = fatal_err_list.length > 0 ? fatal_err_list[0] : '';
    const first_severe_err = severe_err_list.length > 0 ? severe_err_list[0] : '';

    const fatalRef = useRef(first_fatal_err);
    const severeRef = useRef(first_severe_err);
    const automaticRef = useRef(automatic_state);
    const runningModeRef = useRef(running_mode);
    const ifAutoAudio = useRef(false);
    const { t: translate } = useTranslation();
    const completeList = translate('smartDrive.completeList', { returnObjects: true });

    // 补全语音播报&toast提示字符串
    const completionString = (text, code) => {
        if (code === '0xF002') {
            // 0xF002 数采盘未插好
            return text;
        }
        return `${translate('smartDrive.due')}${text}，${translate('smartDrive.takeOver')}`;
    };

    // 判断是否打开进自动提醒开关
    const ifOpenWarnAuto = () => {
        // const warning_automatic = localStorage.getItem('warning_automatic')
        let warning_automatic;
        localCache.get('momenta_hmi_workbench_task', (data) => {
            warning_automatic = data;
        });
        if (!warning_automatic || warning_automatic === 'OFF') {
            clearInterval(timer_15000);
            timer_15000 = null;
            off_audio_and_toast_15000 = false;
            return false;
        }
        return true;
    };

    // 监测智驾状态发生变化时，进行埋点上报
    const monitorAndReport = () => {
        if (messageAutoLock !== automatic_state) {
            messageAutoLock = automatic_state;
        }
    };

    useEffect(() => {
        // console.log(automatic_state, type, first_fatal_err, first_severe_err, 'log')
        monitorAndReport();
        fatalRef.current = first_fatal_err;
        severeRef.current = first_severe_err;
        automaticRef.current = automatic_state;
        runningModeRef.current = running_mode;
        if (!addMessageLock()) return;
        showAudioAndToast();
    }, [automatic_state, type, first_fatal_err, first_severe_err]);

    useEffect(() => {
        clearInterval(timer_15000);
        timer_15000 = null;
        off_audio_and_toast_15000 = false;

        if (takeOverSwitch === 'ON') {
            const ifNull = fatal_err_list.length < 1 && severe_err_list.length < 1;
            if (automatic_state === 'STANDBY' && (running_mode === 'HNP' || running_mode === 'CP') && ifNull) {
                timer_15000 = setTimeout(() => {
                    fifteenSecondWarn();
                }, 15 * 1000);
            }
        }
    }, [takeOverSwitch]);

    // useEffect(() => {
    //     const warning_automatic = localStorage.getItem('warning_automatic') || 'OFF'
    //     dispatch({
    //         type: 'switchStatus',
    //         data: warning_automatic
    //     });
    // }, [])

    useEffect(() => {
        return () => {
            off_audio_and_toast_15000 = false;
            clearInterval(timer_15000);
            messageFatalLock = '';
            messageSevereLock = '';
            messageAutoLock = '';
            message_error = '';
            message_error_timer = 0;
        };
    }, []);

    // 添加消息锁，防止相同消息在短时间内重复触发
    const addMessageLock = () => {
        // step: automatic_state === 'ACTIVE' 则清除所有的15s进自动提醒参数
        if (automatic_state === 'ACTIVE') {
            clearInterval(timer_15000);
            timer_15000 = null;
            off_audio_and_toast_15000 = false;
        }

        let triggered = false;
        if (fatal_err_list.length > 0) {
            // step: 当前的Fatal消息和上次Fatal消息不相同，进行触发 | 接管状态下，出现fatal_error消息，司机尝试拨杆进自动
            if (messageFatalLock !== `${automatic_state}${type}${first_fatal_err}` || type === 'enter_error') {
                triggered = true;
                messageFatalLock = `${automatic_state}${type}${first_fatal_err}`;
            }
        } else {
            // step1: 将messageFatalLock置为空
            messageFatalLock = '';
            if (severe_err_list.length > 0) {
                // step2: 将当前的Severe消息和上次的Severe消息不相同，进行触发 | 接管状态下，出现severe_error消息，司机尝试拨杆进自动
                if (messageSevereLock !== `${automatic_state}${type}${first_severe_err}` || type === 'enter_error') {
                    triggered = true;
                    messageSevereLock = `${automatic_state}${type}${first_severe_err}`;
                }
            } else {
                // step3: 将messageSevereLock置为空
                messageSevereLock = '';
                // step4: 可以进行触发（15s进自动提醒）
                if (automatic_state === 'STANDBY' || automatic_state === 'OFF') {
                    triggered = true;
                }
            }
        }
        return triggered;
    };

    // error信息优先级判断，并进行语音播报
    const priorityJudgment = (severe_err_list, fatal_err_list, type) => {
        let error_describe = '';
        let error_code = '';
        if (fatal_err_list.length < 1) {
            const detail = completeList[severe_err_list[0]]?.detail;
            const describe = completeList[severe_err_list[0]]?.describe || '';
            error_describe = detail ? detail : describe;
            error_code = severe_err_list[0];
        } else {
            const detail = completeList[fatal_err_list[0]]?.detail;
            const describe = completeList[fatal_err_list[0]]?.describe || '';
            error_describe = detail ? detail : describe;
            error_code = fatal_err_list[0];
        }
        if (!error_describe || error_describe === '') return;
        let feature = '';
        let channel_audio = '';
        let channel_toast = '';
        if (type === 'enter_error') {
            feature = Feature.autoNoEntry;
            channel_audio = Channel.voicePrompt;
            channel_toast = Channel.toast;
            message_error = error_code;
            message_error_timer = Date.now();
        }
        if (type === 'running_error') {
            if (error_code === message_error && Date.now() - message_error_timer < 10000) return;
            feature = Feature.driverTakeover;
            channel_audio = Channel.voicePrompt;
            channel_toast = Channel.toast;
            ifAutoAudio.current = true;
        }
        customToast({ className: 'custom_toast_error', text: completionString(error_describe, error_code) });
        // AppFn.playTTSAudio(completionString(error_describe, error_code), 'Highest')
        // demo语音特殊处理
        if (error_code === '0xF002') {
            AppFn.playLocalAudio(localAudioNames.DEMO_DATA_NOTINSERTED, 'Highest');
        } else {
            AppFn.playTTSAudio(completionString(error_describe, error_code), 'Highest');
        }
    };

    // 判断当前的running_mode 是HNP 还是CP，并进行不同的语音播报
    const judgeRunningMode = (running_mode) => {
        if (running_mode === 'HNP') {
            // customToast({className: 'custom_toast_normal', text: `${translate("smartDrive.openNP")}`})
            AppFn.playLocalAudio(localAudioNames.DEMO_OPEN_AUTOPILOT_NP, 'important');
        }
        if (running_mode === 'CP') {
            // customToast({className: 'custom_toast_normal', text: `${translate("smartDrive.openCP")}`})
            AppFn.playLocalAudio(localAudioNames.DEMO_OPEN_AUTOPILOT_CP, 'important');
        }
        batchReport({
            eventName: reportEventName.HMI_NOTICE_VIEW,
            timestamp: new Date().getTime(),
            text: running_mode,
        });
    };

    // 15s进自动提醒功能内部条件判断
    const fifteenSecondWarn = () => {
        if (
            automaticRef.current === 'STANDBY' &&
            !fatalRef.current &&
            !severeRef.current &&
            (runningModeRef.current === 'HNP' || runningModeRef.current === 'CP')
        ) {
            judgeRunningMode(runningModeRef.current);
            // customToast({className: 'custom_toast_normal', text: `当前可开启${running_mode}`})
            // AppFn.playTTSAudio('您可以开启自动驾驶', 'Highest')
            // AppFn.playTTSAudio(`可开启${running_mode}`)
            // AppFn.playLocalAudio(localAudioNames.DEMO_OPEN_AUTOPILOT);
            clearInterval(timer_15000);
            timer_15000 = null;
            off_audio_and_toast_15000 = false;
        } else {
            off_audio_and_toast_15000 = true;
        }
    };

    // 语音播报&Toast展示
    const showAudioAndToast = () => {
        // 处于人工接管状态
        if (automatic_state === 'STANDBY' || automatic_state === 'OFF') {
            ifAutoAudio.current = false;
            const ifNull = fatal_err_list.length < 1 && severe_err_list.length < 1;
            // 如果没有接收到error信息
            if (ifNull) {
                if (!ifOpenWarnAuto()) return;
                // step2：15s语音播报定时器已经执行，但执行瞬间不符合播报条件，等到下次符合条件时，立即执行
                if (
                    off_audio_and_toast_15000 &&
                    automatic_state === 'STANDBY' &&
                    (running_mode === 'HNP' || running_mode === 'CP')
                ) {
                    judgeRunningMode(running_mode);
                    // customToast({className: 'custom_toast_normal', text: `当前可开启${running_mode}`})
                    // AppFn.playTTSAudio('您可以开启自动驾驶', 'Highest')
                    // AppFn.playTTSAudio(`可开启${running_mode}`)
                    // AppFn.playLocalAudio(localAudioNames.DEMO_OPEN_AUTOPILOT);
                    off_audio_and_toast_15000 = false;
                    clearInterval(timer_15000);
                    timer_15000 = null;
                } else {
                    // step1: 设置15s语音播报定时器，并在执行瞬间判断是否符合播报条件，符合播报，不符合，打开锁
                    if (timer_15000) return;
                    timer_15000 = setTimeout(() => {
                        fifteenSecondWarn();
                    }, 15 * 1000);
                }
            } else {
                // 如果收到了error消息，且司机主动拨杆请求进自动  STANDBY条件下一般不会出现此种情况，主要针对OFF条件
                // step1: 根据type类型做判断  type === 'enter_error'(司机主动拨杆请求进自动，Robonode推送的error信息)
                if (switchModeState === 'userMode') return;
                if (type === 'enter_error') {
                    // step2： 根据fatal_error 和 server_error 的优先级进行判断
                    // priorityJudgment(fatal_err_list, severe_err_list, 'enter_error')
                }
            }
        }
        // 处于自动驾驶状态
        if (automatic_state === 'ACTIVE') {
            if (switchModeState === 'userMode') return;
            if (type === 'running_error' && ifAutoAudio.current) return;
            // 用户收到error信息
            if (fatal_err_list.length > 0 || severe_err_list.length > 0) {
                // step1: 根据type类型做判断  type === 'running_error'(自动驾驶状态下，Robonode推送的error信息)
                if (type === 'running_error') {
                    // priorityJudgment(fatal_err_list, severe_err_list, 'running_error')

                    // 指示安全员介入警示弹窗 数据埋点
                    if (
                        fatal_err_list.length > 0 ||
                        (fatal_err_list.length === 0 && severe_err_list.length > 0 && severe_err_list[0] !== '0xF002')
                    ) {
                        let error_code = '';
                        if (fatal_err_list.length < 1) {
                            error_code = severe_err_list[0];
                        } else {
                            error_code = fatal_err_list[0];
                        }
                    }
                }
            }
        }
    };
};
