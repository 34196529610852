import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from 'react-i18next';
import {Switch} from "antd-mobile";
import FM from "@/utils/nativeSubAndPub";
import { SocketMap } from '@/constant/socketMap';
import CardLayout from "../../../components/cardLayout";
import {cusToast} from '@/components/customToast';
import "@/components/slider/smartDrivingSetting/components/cardStyle/index.less";


export default function LastLaneChangeCard() {
    const {t: translate} = useTranslation();
    const latest_lane_change_switch = useSelector(state => state?.featureModelDriving?.switch?.driving?.latest_lane_change?.latest_lane_change_switch);
    const [laneChangeSwitch, setLaneChangeSwitch] = useState(latest_lane_change_switch === 'OPEN');

    // 最晚变道点提示开关
    const switchLastLaneChange = (value) => {
        if (value === laneChangeSwitch) return;
        operateLastLaneChange(value ? 'OPEN' : 'CLOSE');
        setLaneChangeSwitch(value);
    }
    // 向底层传数据
    const operateLastLaneChange = async (v) => {
        FM.sendToHMIDriving(SocketMap.latestLaneChangeControl, {
            switch: v,
        },'drive_node_res_fail_cb');
       
    }

    const leftDom = (
        <div className={"left_dom_wrap"}>
            <div className={"switch_wrap"}>
                <div className={"content_wrap"}>
                    <div className={"text_wrap"}>{translate('laneChange.lastLaneChangeSwitch')}</div>
                </div>
                <Switch
                    checked={laneChangeSwitch}
                    onChange={(checked) => switchLastLaneChange(checked)}
                    className={"customSwitch"}
                />
            </div>
        </div>
    )

    const rightDom = (
        <div className={"right_dom_wrap"}>
        </div>
    )

    return (
        <>
            {
                latest_lane_change_switch && (
                    <CardLayout
                        leftDom={leftDom}
                        rightDom={rightDom}
                    />
                )
            }
        </>
    )
}
