import React, {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { HOT_AREA_TYPE, WIDGET_ZERO_ACTION } from '@/store/action/type';
import logSend from '@/utils/aLog';

export default function DrivingLevelWidgetComponent (props) {
    const {textState, reasonState, imgIconState} = props;
    const dispatch = useDispatch();
    // 升降级是否展示
    const drivinglevelState = useSelector(state => state?.widget0Data?.drivinglevelState);
    const hnp_state = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 正在运行状态
    const running_mode = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const area = useSelector(state => state?.layout?.Area);
    const switchMvizState = useSelector(state => state?.switchModeAction?.switchMvizState);
    // 是否显示icon后面的文案
    const [isShowText, setShowText] = useState("");
    const oddComState = useSelector(state => state?.widget0Data?.oddComState);

    // 进行展示计时
    const [count, setCount] = useState(0);
    const timer = useRef(null);
    // 热区
    const drivingLevelDom = useRef(null);
    const {t: translate} = useTranslation();

    // 上报热区
    useEffect(()=> {
        const drivingLevelArea = drivingLevelDom?.current?.getBoundingClientRect();
        const drivingLevelDomInfo = `${drivingLevelArea?.x}, ${drivingLevelArea?.y}, ${drivingLevelArea?.width}, ${drivingLevelArea?.height}`;
        // icon存在的情况下连续变化，防止layout热区监控不到变化，导致热区上报有问题
        dispatch({
            type: HOT_AREA_TYPE.drivingLevelDom,
            data: ''
        });
        if(typeof (drivingLevelDomInfo) !== 'undefined' && drivingLevelDomInfo?.current?.getBoundingClientRect()?.width !== 0 ){
            dispatch({
                type: HOT_AREA_TYPE.drivingLevelDom,
                data: drivingLevelDomInfo
            });
        }
        logSend({
            action: "setDrivingLevelHotArea",
            data: {
                width: drivingLevelDom?.current?.getBoundingClientRect()?.width,
                drivingLevelDomInfo: drivingLevelDomInfo
            }
        });
    },[drivingLevelDom?.current?.getBoundingClientRect()?.width,
        area?.isHorizontal,
        switchMvizState,
        drivingLevelDom?.current?.getBoundingClientRect().x,
        drivingLevelDom?.current?.getBoundingClientRect().y,
        textState,
        imgIconState,
        reasonState
    ]);

    useEffect(()=>{
        // clearIntervalFunc();
        setShowText(true);
    },[running_mode, hnp_state]);

    // 点击智驾状态ICON
    const clickDriveIcon = (params) =>{
        setShowText("");
        // code发生时如果odd没销毁，就隐藏odd组件
        if (oddComState) {
            dispatch({
                type: WIDGET_ZERO_ACTION.oddComState,
                data: false,
            });
        }
        dispatch({
            type: WIDGET_ZERO_ACTION.drivinglevelState,
            data: false,
        });
    }
    useEffect(()=>{
        let showState = textState ? true : false;
        dispatch({
            type: WIDGET_ZERO_ACTION.drivinglevelState,
            data: showState,
        });
    },[textState])

    // 清除定时器
    /*const clearIntervalFunc = () =>{
        clearInterval(timer.current);
        timer.current = null;
        setCount(()=> {return 0});
    }*/

    // 计时3s
    /*useEffect(()=>{
        if(count === 0){
            setCount((prev) =>{ return prev+1 });
        }
        !timer.current && isShowText && (timer.current = setInterval(()=>{
            setCount((prev) =>{ return prev+1 });
            if(count === 4){
                setShowText(false);
                clearIntervalFunc();
            };
        },1000))
        return(()=>{
            clearInterval(timer.current);
            timer.current = null;
        })
    },[isShowText, count]);*/

    /*const clickDriveTextFunc = async () =>{
         clearIntervalFunc();
    }

    const clickDriveText = useDebounceFn(clickDriveTextFunc, 300, false);*/

    return (
        <React.Fragment>
            {
                textState && drivinglevelState && <div  className={`driving_state driving_level_widget ${isShowText?"show":"hide"}`} ref={drivingLevelDom}>
                    <div className={`plus_item`}>
                        <img alt="" className={`imgState`} src={imgIconState}  onClick={()=> clickDriveIcon(imgIconState)}/>
                        <div className={`textState show`}>
                            <p className="state_text">{textState}</p>
                            {reasonState && <p className="sub_reason_text">{reasonState}</p>}
                        </div>
                    </div>
                </div>
            }
            {
                !textState && <></>
            }
        </React.Fragment>
    )
}
