/**
 * 车辆物理按键埋点上报
 * */
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function PhysicalKeyReport() {
    /**
     * 用户长按Set +键持续2s，将巡航车速设定值提高至道路限速至 INCREASE
     * 用户长按Set -键持续2s，将巡航车速设定值降低至道路限速至 DECREASE
     * */
    const long_press_set_speed = useSelector(state => state?.featureModelDriving?.speedToSlif?.notice?.data?.changed);

    // 数据上报
    const reportDataFunc = (eventName, v) => {
        singelPointReport({
            eventName: eventName,
            timestamp: new Date().getTime(),
            text: v,
        })
    }

    useEffect(() => {
        // console.log(long_press_set_speed, "log")
        if (long_press_set_speed === "INCREASE") {
            reportDataFunc(reportEventName.HMI_INCREASE_TO_SPEED_LIMIT_CLICK, "true")
        }
        if (long_press_set_speed === "DECREASE") {
            reportDataFunc(reportEventName.HMI_DECREASE_TO_SPEED_LIMIT_CLICK, "true")
        }
    }, [long_press_set_speed])

    return (
        <></>
    )
}
