import { useSelector } from 'react-redux';
import './index.less';

export default function Speed(porps) {
    const status = useSelector((state) => state?.featureModelParking.apa?.state);
    const Lpnpstatus = useSelector((state) => state?.featureModelParking.apa?.state);
    const switch_mode = useSelector((state) => state?.featureModelParking?.switch?.apaSwitch);
    const speed = useSelector((state) => state?.featureModelParking?.body?.speed);
    const isShowTips = () => {
        const needStatusList = ['NOT_IN', 'PASSIVE', 'STANDBY'];
        const needApaStatusList = ['STANDBY', 'SEARCHING', 'SELECTING', 'PARKING', 'PARKING_PAUSE'];
        const baseRule =
            switch_mode === 'OPEN' && needStatusList.includes(Lpnpstatus) && needApaStatusList.includes(status);
        const isShow = baseRule && speed > 25;
        return isShow;
    };

    return (
        <div className={`p-info-speed ${porps?.userMode == 'D' ? 'd' : 'sr'} ${isShowTips() ? 'fast' : ''}`}>
            <span className="p-info-speed-num">{speed ? parseInt(speed) : 0}</span>
            <span className="unit">km/h</span>
        </div>
    );
}
