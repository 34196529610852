const INIT_STATE = {
    openReport: false
};

export default function reportSwitch(state = INIT_STATE, action) {
    switch (action.type) {
        case 'reportSwitchStatus':
            return {
                ...state,
                ...{
                    openReport: action.data
                }
            }
        default:
            return state;
    }
}