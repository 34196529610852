import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import { HOT_AREA_TYPE } from '@/store/action/type';
import FM from '@/utils/nativeSubAndPub';
import { SocketMap } from '@/constant/socketMap';
import { cusToast } from '@/components/customToast';
import cp_active from '@/assets/resume/cp_active.png';
import cp_standby from '@/assets/resume/cp_standby.png';
import cp_upgrade from '@/assets/resume/cp_upgrade.png';
import np_active from '@/assets/resume/np_active.png';
import np_standby from '@/assets/resume/np_standby.png';
import np_upgrade from '@/assets/resume/np_upgrade.png';
import np_plus_active from '@/assets/resume/np_plus_active.png';
import np_plus_standby from '@/assets/resume/np_plus_standby.png';
import cp_standby_toast from '@/assets/resume/cp_standby_toast.png';
import np_standby_toast from '@/assets/resume/np_standby_toast.png';
import toast_set from '@/assets/resume/toast-set.png';
import logSend from '@/utils/aLog';
// import resume_button from "@/assets/resume/resume_button.png";
import { useDebounceFn } from '@/utils/common';
import { useTranslation } from 'react-i18next';
import { singelPointReport, batchReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import DrivingIcon from '../drivingIcon';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';

import './index.less';
const { playLocalAudio } = AppFn;
export default function DrivingState() {
    const dispatch = useDispatch();
    const hmi_version = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.hmi_version);
    // 代表当前正在运行的智驾等级
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    // 当前智驾状态
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 代表当前可进入的智驾等级
    const widget_highest_running_mode = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.highestRunningMode,
    );
    // 升降级状态（兼容新老情况，老情况走widget，新版本走alert)
    const notice_driving_state = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.drivingModeChange?.state,
    );
    // 升降级时的智驾状态（兼容新老情况，老情况走widget，新版本走alert)
    const notice_driving_running_mode = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.drivingModeChange?.runningMode,
    );
    // 智驾code（兼容新老情况，老情况走widget，新版本走alert)
    const notice_driving_code = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.drivingModeChange?.code,
    );
    // 是否展示退出动画
    const isShowQuitAnimal = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.drivingModeChange?.isShowQuitAnimal,
    );
    // 当前智驾状态拼接（例：HNP_STANDBY)
    const [currentState, setCurrentState] = useState('');
    // icon右侧展示的文案信息
    const [iconRightText, setIconRightText] = useState('');
    // 点击右侧文案
    const [isClickSwitch, setClickSwitch] = useState('');
    // 退出动画开启
    const [startQuitAnimation, setStartQuitAnimation] = useState(false);
    // 点击时要进入的状态
    const [clickEnterState, setClickEnterState] = useState('');
    // 是否展示失败文案
    const [isShowFail, setIsShowFail] = useState(false);
    // 进行展示计时
    const [count, setCount] = useState(0);
    const timer = useRef(null);
    const animationTimer = useRef(null);
    const enterFailTimer = useRef(null);

    // 热区
    const area = useSelector((state) => state?.layout?.Area);
    const switchMvizState = useSelector((state) => state?.switchModeAction?.switchMvizState);
    const drivingStateDom = useRef(null);

    // 国际化
    const { t: translate } = useTranslation();

    const driver_state = {
        CP_STANDBY: cp_standby, // 进入CP 0906改为无对应进入文案
        CP_UPGRADE: cp_upgrade, // 进入NP
        CP_ACTIVE: cp_active, // 无对应进入文案
        HNP_STANDBY: np_standby, // 进入NP
        HNP_UPGRADE: np_upgrade, //进入NP+
        HNP_ACTIVE: np_active, // 无对应进入文案
        HNP_CP_ACTIVE: np_active, // 进入CP
        HNP_PLUS_STANDBY: np_plus_standby, // 进入NP+
        HNP_PLUS_ACTIVE: np_plus_active, // 进入NP
        HNP_CP_NO_STANDBY: np_active, // 无法进入CP无法进入NP+
    };
    const toast_state_img = {
        HNP_STANDBY: np_standby_toast,
        CP_STANDBY: cp_standby_toast,
        CP_ACTIVE: cp_standby_toast,
        HNP_CP_NO_STANDBY: np_standby_toast,
    };
    const click_state_text = translate('drivingState.click_state_text', { returnObjects: true });
    const toast_state_text = translate('drivingState.toast_state_text', { returnObjects: true });
    // 上报热区
    useEffect(() => {
        const drivingStateDomArea = drivingStateDom?.current?.getBoundingClientRect();
        const drivingStateDomInfo = `${drivingStateDomArea?.x}, ${drivingStateDomArea?.y}, ${drivingStateDomArea?.width}, ${drivingStateDomArea?.height}`;
        // icon存在的情况下连续变化，防止layout热区监控不到变化，导致热区上报有问题
        dispatch({
            type: HOT_AREA_TYPE.drivingStateDom,
            data: '',
        });
        if (
            typeof drivingStateDomInfo !== 'undefined' &&
            drivingStateDomInfo?.current?.getBoundingClientRect()?.width !== 0
        ) {
            dispatch({
                type: HOT_AREA_TYPE.drivingStateDom,
                data: drivingStateDomInfo,
            });
        }
        logSend({
            action: 'setDrivingStateHotArea',
            data: {
                width: drivingStateDom?.current?.getBoundingClientRect()?.width,
                currentState: currentState,
                drivingStateDomInfo: drivingStateDomInfo,
            },
        });
    }, [
        drivingStateDom?.current?.getBoundingClientRect()?.width,
        area?.isHorizontal,
        switchMvizState,
        drivingStateDom?.current?.getBoundingClientRect().x,
        drivingStateDom?.current?.getBoundingClientRect().y,
        currentState,
    ]);

    // 清除定时器
    const clearIntervalFunc = () => {
        clearInterval(timer.current);
        timer.current = null;
        setCount(() => {
            return 0;
        });
    };

    // 计时5s
    useEffect(() => {
        if (count === 0) {
            setCount((prev) => {
                return prev + 1;
            });
        }
        !timer.current &&
            (click_state_text?.[iconRightText] || isClickSwitch) &&
            (timer.current = setInterval(() => {
                setCount((prev) => {
                    return prev + 1;
                });
                if (count === 6) {
                    if (isClickSwitch) {
                        if (
                            clickEnterState &&
                            `${clickEnterState}_${automatic_state}` !== `${running_mode}_${automatic_state}`
                        ) {
                            setIsShowFail(true);
                            setIconRightText(clickEnterState);
                            playLocalAudio(localAudioNames.SELF_DRIVING, 'Important');
                        } else {
                            setIconRightText(currentState);
                        }
                        clearIntervalFunc();
                    } else {
                        closeDrivingToast();
                    }
                    setClickSwitch(false);
                    isShowFail && setIsShowFail(false);
                }
            }, 1000));
        return () => {
            clearInterval(timer.current);
            timer.current = null;
        };
    }, [click_state_text?.[iconRightText], count, isClickSwitch]);

    // useEffect(() => {
    //     if (count === 4 && clickEnterState && clickEnterState !== `${running_mode}_${automatic_state}`) {
    //         setIsShowFail(true);
    //         setTimeout(() => {
    //             console.log('12321313123');
    //             setIsShowFail(false);
    //         }, 4000)
    //     }
    // }, [count, clickEnterState]);

    // 智驾状态上报
    useEffect(() => {
        if (running_mode && automatic_state) {
            clearIntervalFunc();
            setClickSwitch(false);
            setClickEnterState('');
            setIsShowFail(false);
            batchReport({
                eventName: reportEventName.HMI_AD_STATUS_DURATION,
                timestamp: Date.now(),
                reportData: {
                    ADlevel: running_mode,
                    Status: automatic_state,
                },
            });
        }
    }, [running_mode, automatic_state]);

    useEffect(() => {
        closeDrivingToast();
        // 退至手动和退至ACC处理
        let manual_code = [6, 8, 9, 14, 15, 24].includes(notice_driving_code);
        if (isShowQuitAnimal && (notice_driving_state === 'QUIT' || notice_driving_state === 'LOWER')) {
            // 从ACTIVE状态退到手动
            if (notice_driving_state === 'QUIT') {
                if (manual_code && ['HNP', 'HNP_PLUS', 'CP'].includes(notice_driving_running_mode)) {
                    setStartQuitAnimation(true);
                    animationTimer.current = setTimeout(() => {
                        setStartQuitAnimation(false);
                    }, 500);
                    return;
                }
            } else if (notice_driving_state === 'LOWER') {
                if (manual_code && notice_driving_running_mode === 'ACC') {
                    setStartQuitAnimation(true);
                    animationTimer.current = setTimeout(() => {
                        setStartQuitAnimation(false);
                    }, 500);
                    return;
                }
            }
        }
        // 正常情况下（在播放退至手动动画时没有发生其他ACTIVE的状态）上锁
        if (startQuitAnimation) return;

        clearTimeout(animationTimer.current);
        animationTimer.current = null;

        // 手动驾驶中，其他状态（CP、NP）standby
        if (
            !(['HNP_PLUS', 'HNP', 'CP', 'ACC'].indexOf(running_mode) > -1 && automatic_state === 'ACTIVE') &&
            ['HNP_PLUS', 'HNP', 'CP'].indexOf(widget_highest_running_mode) > -1
        ) {
            // HNP_PLUS: icon(HNP_PLUS_STANDBY)
            // HNP: icon(HNP_STANDBY)
            // CP: icon(CP_STANDBY)
            setCurrentState(`${widget_highest_running_mode}_STANDBY`);
        } else if (
            automatic_state === 'ACTIVE' &&
            running_mode === 'ACC' &&
            ['HNP_PLUS', 'HNP', 'CP'].indexOf(widget_highest_running_mode) > -1
        ) {
            // 2 3: ACC active、HNP_PLUS(HNP)(CP) standby: icon(xx_STANDBY)
            setCurrentState(`${widget_highest_running_mode}_STANDBY`);
        } else if (automatic_state === 'ACTIVE' && running_mode === 'CP') {
            if (['HNP'].indexOf(widget_highest_running_mode) > -1) {
                // 2 3: CP Active、NP Standby: icon(CP_UPGRADE)，text(进入NP)
                setCurrentState(`${running_mode}_UPGRADE`);
            } else if (['HNP'].indexOf(widget_highest_running_mode) === -1) {
                // 2 3: CP Active、NP不可用: icon(CP_ACTIVE)，text(点击ICON无反应，不显示进入)
                setCurrentState(`${running_mode}_ACTIVE`);
            }
        } else if (automatic_state === 'ACTIVE' && running_mode === 'HNP') {
            if (['HNP_PLUS'].indexOf(widget_highest_running_mode) > -1) {
                // 4: NP active、HNP_PLUS standby: icon(NP_UPGRADE)，text(进入NP+)
                setCurrentState(`${running_mode}_UPGRADE`);
            } else if (['CP'].indexOf(widget_highest_running_mode) > -1) {
                // 4: NP active、CP active: icon(NP_ACTIVE)，text(进入CP)
                setCurrentState(`${running_mode}_CP_ACTIVE`);
            } else {
                // 4: NP active、cp不可用: icon(NP_ACTIVE)，不显示进入
                setCurrentState(`${running_mode}_CP_NO_STANDBY`);
            }
        } else if (automatic_state === 'ACTIVE' && running_mode === 'HNP_PLUS') {
            // HNP_PLUS actvie: icon(HNP_PLUS),text(点击ICON无反应，不显示进入)
            setCurrentState(`${running_mode}_ACTIVE`);
        } else {
            setCurrentState('');
        }
    }, [
        running_mode,
        automatic_state,
        widget_highest_running_mode,
        notice_driving_state,
        notice_driving_code,
        startQuitAnimation,
        isShowQuitAnimal,
    ]);

    useEffect(() => {
        // 如果发生智驾状态到ACTIVE，打断退至手动的动效
        if (automatic_state === 'ACTIVE') {
            setStartQuitAnimation(false);
            if (animationTimer.current) {
                clearTimeout(animationTimer.current);
                animationTimer.current = null;
            }
        }
    }, [automatic_state]);

    // 点击智驾状态ICON
    const clickDriveIcon = (params) => {
        console.log('params', params, iconRightText);
        clearIntervalFunc();
        if (isShowFail) return;
        // if(params === iconRightText) return;
        let text = '';
        if (iconRightText) {
            text = '';
        } else {
            text = params;
        }
        setIconRightText(text);
    };

    // 通知底层进入智驾状态
    const operateDriveStateMode = async (mode, highestMode) => {
        if (isShowFail) return;
        // 数据上报
        reportDataFunc();

        clearIntervalFunc();
        setClickSwitch(true);
        setIconRightText('');
        let params = {},
            wsName = null;
        if (!(['HNP_PLUS', 'HNP', 'CP', 'ACC'].indexOf(running_mode) > -1 && automatic_state === 'ACTIVE')) {
            params['state'] = 'ACTIVE';
            wsName = SocketMap.drivingControl;
        } else if (currentState === 'HNP_ACTIVE' && ['CP'].indexOf(widget_highest_running_mode) > -1) {
            params['trans_running_mode'] = 'CP';
            wsName = SocketMap.transDriveMode;
            setClickEnterState(`${highestMode}`);
        } else if (currentState === 'HNP_PLUS_ACTIVE') {
            params['trans_running_mode'] = 'HNP_PLUS_STOP';
            wsName = SocketMap.transDriveMode;
            setClickEnterState('');
        } else if (highestMode) {
            params['trans_running_mode'] = highestMode;
            wsName = SocketMap.transDriveMode;
            setClickEnterState(`${highestMode}`);
        }
        console.log(`${highestMode}`);

        FM.sendToHMIDriving(wsName, params, 'drive_mode_common_cb');
    };

    const clickDriveText = useDebounceFn(operateDriveStateMode, 300, false);

    // 数据上报
    const reportDataFunc = () => {
        let reportData = {};
        let stateList = {
            CP_UPGRADE: {
                state: 'UPPER',
                available_running_mode: 'HNP',
            },
            HNP_STANDBY: {
                state: 'UPPER',
                available_running_mode: 'HNP',
            },
            HNP_UPGRADE: {
                state: 'UPPER',
                available_running_mode: 'HNP_PLUS',
            },
            HNP_CP_ACTIVE: {
                state: 'LOWER',
                available_running_mode: 'CP',
            },
            HNP_PLUS_STANDBY: {
                state: 'UPPER',
                available_running_mode: 'HNP_PLUS',
            },
            HNP_PLUS_ACTIVE: {
                state: 'LOWER',
                available_running_mode: 'HNP',
            },
        };
        reportData['state'] = stateList[currentState]['state'];
        reportData['available_running_mode'] = stateList[currentState]['available_running_mode'];
        singelPointReport({
            eventName: reportEventName.HMI_ACTIVATE_CLICK,
            timestamp: new Date().getTime(),
            reportData,
        });
    };

    //
    const closeDrivingToast = () => {
        setIconRightText('');
        clearIntervalFunc();
    };

    // 提示弹窗(只在CP可用、CP运行NP不可用、NP可用，NP运行CP、NP+不可用)四种状态下提示
    const clickDrivingToast = (mode_state, text, state) => {
        if (
            mode_state === 'CP_STANDBY' ||
            mode_state === 'CP_ACTIVE' ||
            mode_state === 'HNP_STANDBY' ||
            mode_state === 'HNP_CP_NO_STANDBY'
        ) {
            return (
                <div ref={drivingStateDom} className="driving_toast" onClick={closeDrivingToast}>
                    <div className="toast_top">
                        <img className="top_img" alt="" src={toast_state_img?.[mode_state]} />
                        <p className="top-title">{text}</p>
                    </div>
                    <div className="toast_bottom">
                        <img className="bottom_img" alt="" src={toast_set} />
                        <p className="bottom-title">{toast_state_text?.[mode_state]}</p>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            {currentState !== '' &&
                !isClickSwitch &&
                !['CP_ACTIVE', 'CP_STANDBY', 'HNP_STANDBY', 'HNP_CP_NO_STANDBY'].includes(iconRightText) && (
                    <div ref={drivingStateDom} className="driving_state">
                        <div
                            className={`driving_state_left ${click_state_text?.[iconRightText] ? 'right_isshow' : ''}`}
                            onClick={() => clickDriveIcon(currentState)}
                        >
                            <DrivingIcon currentState={currentState} startQuitAnimation={startQuitAnimation} />
                        </div>
                        <div
                            onClick={() => clickDriveText(running_mode, widget_highest_running_mode)}
                            className={`driving_state_right ${
                                click_state_text?.[iconRightText] ? 'show_right' : 'hide-right'
                            } ${isShowFail ? 'fail-right' : ''}`}
                        >
                            <p
                                className={`right_text_state ${
                                    click_state_text?.[iconRightText] ? 'show_right_text' : 'hide_right_text'
                                } ${isShowFail ? 'fail-state' : ''}`}
                            >
                                {click_state_text?.[iconRightText]}
                                {isShowFail && <p className="fail-text">{translate('drivingState.try_again_later')}</p>}
                            </p>
                        </div>
                    </div>
                )}
            {clickDrivingToast(iconRightText, click_state_text?.[iconRightText], automatic_state)}
            {isClickSwitch && (
                <div className="driving_state_loading">
                    <div className="plus_item loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        {/* <img alt="" className={`imgState ${currentState}`} src={loading_icon}/>*/}
                    </div>
                </div>
            )}
        </>
    );
}
