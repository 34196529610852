import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from 'antd-mobile';
import store from '@/store';
import { HOT_AREA_TYPE } from '@/store/action/type';
import useLPNPSettingCommon from './hooks/lpnpHook.js';
import useMEBSettingCommon from './hooks/mebHook.js';
import closeD from '@/assets/parking/d_new/cancel_d.png';
import closeSR from '@/assets/parking/d_new/close.png';
import HMIMode from '@/components/slider/smartDrivingSetting/roadTestComponent/otherSettings/HMIMode';
import localCache from '@/utils/localCache/index.js';
import { useTranslation } from 'react-i18next';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import './index.less';

export default function LPNPSetting() {
    const { t: translate } = useTranslation();
    const userMode = useSelector((state) => state?.parking?.userMode);
    const sceneRef = useRef(null);
    const mtour = useSelector((state) => state?.switchModeAction?.switchUnpState);
    const lpnp_switch = useSelector((state) => state?.featureModelParking?.switch?.lpnpSwitch);
    const meb_switch = useSelector((state) => state?.featureModelParking?.switch?.mebSwitch);

    const [lpnpSwitchState, setLpnpSwitchState] = useState(lpnp_switch === 'OPEN');

    const [mebSwitchState, setMebSwitchState] = useState(meb_switch === 'OPEN');

    const { LPNPControl } = useLPNPSettingCommon();
    const { MEBControl } = useMEBSettingCommon();

    const dispatch = useDispatch();
    const timer = useRef(null);
    const timerMeb = useRef(null);
    const sceneArea = sceneRef?.current?.getBoundingClientRect();
    const [loading, setLoading] = useState(false);

    const [mebLoading, setMebLoading] = useState(false);

    useEffect(() => {
        lpnp_switch && setLpnpSwitchState(lpnp_switch === 'OPEN');
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        setLoading(false);
    }, [lpnp_switch]);

    const switchLPNPChange = () => {
        clearTimeout(timer.current);
        setLoading(true);
        timer.current = setTimeout(() => {
            setLoading(false);
            setLpnpSwitchState(lpnp_switch === 'OPEN');
        }, 2 * 1000);
        console.log('lpnpSwitchState', lpnpSwitchState);
        LPNPControl(lpnpSwitchState ? 'CLOSE' : 'OPEN');
    };

    useEffect(() => {
        meb_switch && setMebSwitchState(meb_switch === 'OPEN');
        if (timerMeb.current) {
            clearTimeout(timerMeb.current);
            timerMeb.current = null;
        }
        setMebLoading(false);
    }, [meb_switch]);

    const switchMEBChange = () => {
        clearTimeout(timerMeb.current);
        setMebLoading(true);
        timerMeb.current = setTimeout(() => {
            setMebLoading(false);
            setMebSwitchState(meb_switch === 'OPEN');
        }, 2 * 1000);
        console.log('mebSwitchState', mebSwitchState);
        MEBControl(mebSwitchState ? 'CLOSE' : 'OPEN');
    };

    const closeHandler = () => {
        store.dispatch({
            type: 'SetTab',
            data: '',
        });
    };

    useEffect(() => {
        const sceneAreaInfo = `${sceneArea?.x}, ${sceneArea?.y}, ${sceneArea?.width}, ${sceneArea?.height}`;
        dispatch({
            type: HOT_AREA_TYPE.sceneArea,
            data: sceneAreaInfo,
        });
        return () => {
            dispatch({
                type: HOT_AREA_TYPE.sceneArea,
                data: '',
            });
        };
    }, [sceneArea?.x, sceneArea?.y, sceneArea?.width, sceneArea?.height]);

    return (
        <div ref={sceneRef} className="lpnp-setting-page">
            <div className={`lpnp-setting-content ${userMode == 'D' ? 'd' : 'sr'}`}>
                <div className="lpnp-setting-close">
                    <img onClick={() => closeHandler()} src={userMode == 'D' ? closeD : closeSR} alt="close" />
                </div>

                <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                    <Switch
                        checked={lpnpSwitchState}
                        loading={loading}
                        onChange={(checked) => switchLPNPChange(checked)}
                        className={'customSwitch'}
                    />
                    <span>记忆泊车领航辅助驾驶 LPNP</span>
                </div>

                <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                    <Switch
                        loading={mebLoading}
                        checked={mebSwitchState}
                        onChange={(checked) => switchMEBChange(checked)}
                        className={'customSwitch'}
                    />
                    <span>{translate('parking.setting.meb')}</span>
                </div>
                {userMode === 'D' && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            checked={mtour}
                            onChange={() => {
                                localCache.set('unp_switch', !mtour);
                                store.dispatch({
                                    type: SWITH_MODE_ACTION.switchUnpState,
                                    data: !mtour,
                                });
                            }}
                            className={'customSwitch'}
                        />
                        <span>LPNP MTOUR</span>
                    </div>
                )}
                <HMIMode parking />
            </div>
        </div>
    );
}
