import { THEME_ACTION } from '../action/type';

const INIT_STATE = {
    themeColorState: '', // 主题
    i18nState: 'zh-CN', // 语言
};

export default function themeAction(state = INIT_STATE, action) {
    switch (action.type) {
        case THEME_ACTION.themeColorState:
            return { ...state, themeColorState: action.data };
        case THEME_ACTION.i18nState:
            return { ...state, i18nState: action.data };
        default:
            return state;
    }
}
