import localCache from '@/utils/localCache';

// const vcruise = localStorage.getItem('vcruise_string') || 'OFF'
const INIT_STATE = {
    vcruise: 'OFF',
};

export default function showVcruise(state = INIT_STATE, action) {
    switch (action.type) {
        case 'setVcruiseSwitch':
            return {
                ...state,
                ...{
                    vcruise: action.data,
                },
            };
        default:
            return state;
    }
}
