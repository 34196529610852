import { TipsMapConfig, TOAST_CONFIG, AudioMap, ReportMap } from './alertMap';
import store from '@/store/index';
import { PARKING_LPNP_EXIT } from '@/store/action/parkingType';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { AppFn } from '@/utils/native';
import localCache from '@/utils/localCache';

export const isShowTips = (s) => {
    const list = ['CRUISING', 'PARKING', 'LEARNING', 'APA_PARKING'];
    return list.indexOf(s) != -1;
};

export const getTipsTxt = ({ status, track_id, count }) => {
    switch (status) {
        case 'LEARNING':
            // if(learning_distance <= 5) return TipsMapConfig["LEARNING_START"];
            return TipsMapConfig['LEARNING'];
        case 'APA_SELECTING':
            if (track_id) {
                return '';
            }
            return count > 0 ? TipsMapConfig['APA_SELECTING'] : 'No parking spots, please park in manually';
        default:
            return TipsMapConfig[status];
    }
};

export const getToastAndAudioText = (t, noticeData) => {
    switch (t) {
        case 'lpnp_start_learning':
            return {
                audioName: AudioMap[t],
            };
        case 'lpnp_cruising_turn':
            if (noticeData?.direction == 'left') {
                return {
                    txt: TOAST_CONFIG[t]?.lText,
                    audioName: AudioMap[`${t}_left`],
                };
            }
            if (noticeData?.direction == 'right') {
                return {
                    txt: TOAST_CONFIG[t]?.rText,
                    audioName: AudioMap[`${t}_right`],
                };
            }
            if (noticeData?.direction === 'straight') {
                return {
                    txt: TOAST_CONFIG[t]['straight'],
                    audioName: AudioMap[`${t}_straight`],
                };
            }
            break;
        case 'lpnp_drive_to_map_starting_point':
            return {
                txt: '',
                audioName: AudioMap[t],
            };
        case 'lpnp_exit':
            // 通知接管
            store.dispatch({
                type: PARKING_LPNP_EXIT.lpnpExit,
                data: true,
            });
            return {
                txt: TOAST_CONFIG[t],
                audioName: AudioMap[t],
            };
        // 避让提醒
        case 'lpnp_interaction':
            return {
                txt: TOAST_CONFIG[t][noticeData.info],
                audioName: AudioMap[`${t}_${noticeData.info}`],
            };
        default:
            return {
                txt: TOAST_CONFIG[t],
                audioName: AudioMap[t],
            };
    }
};

export const fisrtUpper = (str) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
};

//根据消息类型，上报各阶段埋点
export const reportHandle = (noticeType) => {
    if (ReportMap[noticeType]) {
        singelPointReport({
            eventName: ReportMap[noticeType].eventName,
            timestamp: new Date().getTime(),
            text: ReportMap[noticeType].text,
        });
    }
};

export const reportHandleHack = (audioName) => {
    if (audioName === 'lpnp_cruising_turn_left.mp3') {
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_TURNING_VIEW,
            timestamp: new Date().getTime(),
            reportData: {
                '0x2F': 'Turn left',
            },
        });
    } else if (audioName === 'lpnp_cruising_turn_right.mp3') {
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_TURNING_VIEW,
            timestamp: new Date().getTime(),
            reportData: {
                '0x30': 'Turn right',
            },
        });
    }
};

export const alertAudioPlay = ({ audioName, noticeType, noticeLevel, speed_bumps_count }) => {
    switch (audioName) {
        case 'lpnp_start_learning.mp3':
        case 'lpnp_start_cruising.mp3':
            AppFn.playLocalAudio(audioName, 'Highest');
            break;
        case 'lpnp_speed_bump_inc.mp3':
            if (speed_bumps_count <= 1) {
                AppFn.playLocalAudio(audioName, 'Important');
            }
            break;
        case 'lpnp_cruising_turn_left.mp3':
        case 'lpnp_cruising_turn_right.mp3':
            AppFn.playLocalAudio(audioName, 'Important');
            break;

        default:
            if (noticeType == 'lpnp_start_apa_selecting' || noticeType == 'lpnp_apa_selecting_without_parking_slot') {
                localCache.get('lpnp_start_apa_selecting', (data) => {
                    if (!data) {
                        localCache.set('lpnp_start_apa_selecting', true);
                        audioName && AppFn.playLocalAudio(audioName, fisrtUpper(noticeLevel));
                    }
                });
            } else {
                audioName && AppFn.playLocalAudio(audioName, fisrtUpper(noticeLevel));
            }
            break;
    }
};
