import { Toast } from 'antd-mobile';
import store from '@/store';
import './index.less';
import classNames from 'classnames';
import isParking from '@/utils/isParking';

/**
 * custom_toast:
 * vertical 竖屏
 * horizontal 横屏
 * */

const show = ({ maskClassName = '', content, duration = 2000 }) => {
    const state = store?.getState();
    const area = state?.layout?.Area;
    const switch_mviz_layout = state?.switchModeAction?.switchMvizState;
    const current_scene = state?.scene?.current_scene;
    const userMode = state?.parking?.userMode;

    return Toast.show({
        maskClassName: isParking(current_scene)
            ? classNames(maskClassName, 'parking', userMode === 'D' ? 'd' : 'sr')
            : classNames(area.isHorizontal, maskClassName, switch_mviz_layout),
        content: content,
        duration,
    });
};
const close = () => {
    return Toast.close();
};
export const cusToast = {
    show: show,
    close: close,
};
