import React, { useEffect, useRef, useState } from "react";
import loading from "@/assets/workbench/default_loading.png";

const ProgressiveImg = ({ src, className, openSomeImage}) => {
  const [imgSrc, setImgSrc] = useState(loading);
  const imgRef = useRef(null);

  const getImgSizeQuality = (src, s, q) => {
    return `${src}?x-oss-process=image/resize,p_${s}/quality,q_${q}`;
  }
  
  useEffect(() => {
    let src_temp = getImgSizeQuality(src, 6, 90);
    const image = new Image();
    image.src = src_temp;
    image.onload = () => {
      setImgSrc(src_temp);
    };
  }, [src]);

  return <img 
        ref={imgRef} 
        className={className} 
        onClick={() => openSomeImage(getImgSizeQuality(src, 40, 90))} 
        src={imgSrc}
  />;
};
export default ProgressiveImg;
