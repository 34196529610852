import { WIDGET_ZERO_ACTION } from '../action/type';

const INIT_STATE = {
    drivinglevelState: false,  // 升降级
    oddComState: false, // odd
};

export default function widgetZeroAction(state = INIT_STATE, action) {
    switch (action.type) {
        case WIDGET_ZERO_ACTION.drivinglevelState:
            return {...state, drivinglevelState: action.data};
        case WIDGET_ZERO_ACTION.oddComState:
            return {...state, oddComState: action.data};
        default:
            return state;
    }
}