import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {cusToast} from '@/components/customToast';
import {useTranslation} from 'react-i18next';
import { AppFn } from '@/utils/native';
import { TAKE_OVER_ACTION } from '@/store/action/type';
import tips_img from "@/assets/MRC/tips_img.png";
import quit_img from "@/assets/drivingLevel/quit_img.png";
import localAudioNames from '@/constant/localAudioName';
import { batchReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import {widgetQueue, widgetType} from '@/utils/widgetQueue';

const { playTTSAudio,playLocalAudio } = AppFn

export default function MRCTakeOver() {
    const notice_take_over = useSelector(state => state?.featureModelDriving?.mrc?.notice?.data?.takeOver);
    let MRCTimer = useRef(null);
    const {t: translate} = useTranslation();
    const dispatch = useDispatch();
    // state 存储值做弹框延迟消失用
    // const [isShowDelay, setIsShowDelay] = useState(false);

    /*useEffect(()=>{
        if(notice_take_over){
            setIsShowDelay(true)
        }else{
            if(isShowDelay){
                setTimeout(()=>{
                    setIsShowDelay(false);
                },1500)
            }
        }
    },[notice_take_over])*/

    // MRC 信号
    useEffect(()=>{
        let text = '',maskClassName = '';
        if(notice_take_over){
            playLocalAudio(localAudioNames.BIBIBI_AUDIO,"Highest","","duplex",true,"start")
            playLocalAudio(localAudioNames.MRC_TAKE_OVER,"Highest");
            (!MRCTimer.current) && (MRCTimer.current = setInterval(() => {
                playLocalAudio(localAudioNames.MRC_TAKE_OVER,"Highest");
            }, 15000));
            reportDataFunc(reportEventName.HMI_MRC_VIEW);
        }else{
            clearInterval(MRCTimer.current);
            MRCTimer.current = null;
            playLocalAudio(localAudioNames.BIBIBI_AUDIO,"Highest","","duplex",true,"stop")
        }
        dispatch({
            type: TAKE_OVER_ACTION.takeroverContinueState,
            data: {
                takeroverContinueState: notice_take_over,
                img: '',
                text: ''
            },
        });
    },[notice_take_over]);

    // 数据上报
    const reportDataFunc = (value) =>{
        batchReport({
            eventName: value,
            timestamp: Date.now(),
            text: "warning",
        })
    }
    return (
         <>
             {
                 (notice_take_over)&& <div  className={`driving_state driving_level_widget show`}>
                     <div className={`plus_item`}>
                         <img alt="" className={`imgState`} src={tips_img} />
                         <div className={`textState show`}>
                             <p className="state_text">{translate("MRC.takeOverTTS")}</p>
                         </div>
                     </div>
                 </div>
             }
             {
                 (!notice_take_over) && <></>
             }
         </>
    );
}
