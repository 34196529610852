import { useSelector } from 'react-redux';
import { ProgressCircle } from 'antd-mobile';

import iconNotIn from '@/assets/parking/icons/icon_not_in.png';
import iconSearching from '@/assets/parking/icons/icon_searching.png';
import iconApaIng from '@/assets/parking/icons/icon_apa_ing.png';
import LPNP from '@/assets/parking/icons/icon_lpnp.png';
import route from '@/assets/parking/icons/icon_remeber_route.png';
import lpnpDone from '@/assets/parking/icons/icon_lpnp_done.png';
import process from '@/assets/parking/process.png';
import './index.less';

const parking_status_map = {
    FAIL_OVER: {
        icon: iconNotIn,
        txt: 'Route Learning Failed',
        subT: 'LPNP is Unavailable',
    },
    MAP_OVER: {
        icon: lpnpDone,
        txt: 'Route Learning Completed',
        subT: 'LPNP is Unavailable',
    },
    LPNP_OVER: {
        icon: lpnpDone,
        txt: 'LPNP Completed',
    },
    NOT_IN: {
        icon: iconNotIn,
        txt: 'Not in Parking Lot',
        subT: 'LPNP is Unavailable',
        iconSize: {
            height: '.63rem',
        },
    },
    PASSIVE: {
        icon: iconSearching,
        txt: 'Search for parking lot',
        iconSize: {
            height: '.63rem',
        },
    },
    LPNPNOTOPEN: {
        icon: lpnpDone,
        txt: 'LPNP Switch OFF',
        subT: 'LPNP is Unavailable',
        iconSize: {
            height: '.63rem',
        },
    },
    APA_PARKING: {
        iconD: iconApaIng,
        icon: iconApaIng,
        txt: 'APA is in progress',
    },
    CRUISING: {
        icon: LPNP,
        txt: 'LPNP Cruising',
    },
    PARKING: {
        icon: LPNP,
        txt: 'LPNP Parking In',
    },
    LEARNING: {
        icon: route,
        txt: 'Route Learning',
    },
    MAP_BEING_VERIFIED: {
        iconD: process,
        icon: process,
        txt: 'Route Saving',
    },
};

export default function AllStatus() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const mappingFailReason = useSelector((state) => state?.featureModelParking?.lpnp?.failReason?.mappingFailReason);
    const path_veri_rate = useSelector((state) => state?.featureModelParking?.lpnp?.statistic?.pathVerifyRate || 0);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const condition = useSelector((state) => state?.featureModelParking?.lpnp?.condition);
    const switch_mode = useSelector((state) => state?.featureModelParking?.switch?.lpnpSwitch);
    const robonodeReady = useSelector((state) => state?.robonode?.ready);
    const getReEnterStatus = (learnFlag, lpnpFlag, mapFailReason) => {
        if (learnFlag && mapFailReason) {
            return 'FAIL_OVER';
        }
        if (learnFlag) {
            return 'MAP_OVER';
        }
        if (lpnpFlag) {
            return 'LPNP_OVER';
        }
    };
    const isNeedShowGuide = (status) => {
        return ['NOT_IN', 'PASSIVE', 'STANDBY', 'MAP_BEING_VERIFIED'].indexOf(status) != -1;
    };
    const isNeedToOut = () => {
        return condition?.isLearningComplete || condition?.isLpnpComplete;
    };
    return (
        <>
            {status != 'MAP_BEING_VERIFIED' &&
            parking_status_map[status]?.txt &&
            !isNeedToOut() &&
            switch_mode === 'OPEN' ? (
                <div className="all-status">
                    <div className={`p-status-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <img
                            className="p-status-icon"
                            style={parking_status_map[status]?.iconSize}
                            src={parking_status_map[status]?.icon}
                            alt="status_icon"
                        />

                        <div className="p-status-txt-wrap">
                            <span
                                className={`p-status-txt ${userMode == 'D' ? 'd' : 'sr'} ${
                                    ['NOT_IN'].indexOf(status) !== -1 ? 'disabled' : ''
                                }`}
                            >
                                {parking_status_map[status]?.txt}
                            </span>
                            {parking_status_map[status]?.subT ? (
                                <span className="p-status-sub-txt">{parking_status_map[status]?.subT}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}

            {/*@zhongning LPNP没有打开的文案提醒 {status != 'MAP_BEING_VERIFIED' &&
            parking_status_map[status]?.txt &&
            !isNeedToOut() &&
            switch_mode === 'CLOSE' &&
            robonodeReady ? (
                <div className="all-status">
                    <div className={`p-status-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <img
                            className="p-status-icon"
                            style={parking_status_map['LPNPNOTOPEN']?.iconSize}
                            src={parking_status_map['LPNPNOTOPEN']?.icon}
                            alt="status_icon"
                        />

                        <div className="p-status-txt-wrap">
                            <span
                                className={`p-status-txt ${userMode == 'D' ? 'd' : 'sr'} ${
                                    ['NOT_IN'].indexOf(status) !== -1 ? 'disabled' : ''
                                }`}
                            >
                                {parking_status_map['LPNPNOTOPEN']?.txt}
                            </span>
                            {parking_status_map['LPNPNOTOPEN']?.subT ? (
                                <span className="p-status-sub-txt">{parking_status_map['LPNPNOTOPEN']?.subT}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )} */}

            {status == 'MAP_BEING_VERIFIED' ? (
                <div className={`p-status-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                    <div className="p-route-process-antd">
                        <ProgressCircle
                            percent={parseInt(path_veri_rate) >= 100 ? 100 : parseInt(path_veri_rate)}
                            style={{
                                '--track-width': '5px',
                                '--size': '85px',
                                '--track-color': '#fff',
                                '--fill-color': '#2A69E2',
                            }}
                        >
                            <span className="font-title">
                                {parseInt(path_veri_rate) >= 100 ? 100 : parseInt(path_veri_rate)}%
                            </span>
                        </ProgressCircle>
                    </div>
                    {/* <div
                className={`p-route-process ${userMode == "D" ? "d" : "sr"}`}
              >
                <img
                  className="animate__rotate animate__infinite animate__animated loading"
                  src={
                    userMode == "D"
                      ? parking_status_map[status]?.iconD
                      : parking_status_map[status]?.icon
                  }
                  alt="加载中"
                />
                <em>
                  {parseInt(path_veri_rate) >= 100 ? 100 : parseInt(path_veri_rate)}%
                </em>
              </div> */}
                    <span className={`p-status-txt ${userMode == 'D' ? 'd' : 'sr'}`}>
                        {parking_status_map[status]?.txt}
                    </span>
                </div>
            ) : (
                ''
            )}

            {isNeedShowGuide(status) &&
            parking_status_map[
                getReEnterStatus(condition?.isLearningComplete, condition?.isLpnpComplete, mappingFailReason)
            ]?.txt &&
            isNeedToOut() ? (
                <div className="all-status">
                    <div className={`p-status-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <img
                            className="p-status-icon"
                            src={
                                parking_status_map[
                                    getReEnterStatus(
                                        condition?.isLearningComplete,
                                        condition?.isLpnpComplete,
                                        mappingFailReason,
                                    )
                                ]?.icon
                            }
                            alt="status_icon"
                        />
                        <div className="p-status-txt-wrap">
                            <span
                                className={`p-status-txt ${userMode == 'D' ? 'd' : 'sr'} ${
                                    ['NOT_IN'].indexOf(
                                        getReEnterStatus(
                                            condition?.isLearningComplete,
                                            condition?.isLpnpComplete,
                                            mappingFailReason,
                                        ),
                                    ) !== -1
                                        ? 'disabled'
                                        : ''
                                }`}
                            >
                                {
                                    parking_status_map[
                                        getReEnterStatus(
                                            condition?.isLearningComplete,
                                            condition?.isLpnpComplete,
                                            mappingFailReason,
                                        )
                                    ]?.txt
                                }
                            </span>
                            {parking_status_map[
                                getReEnterStatus(
                                    condition?.isLearningComplete,
                                    condition?.isLpnpComplete,
                                    mappingFailReason,
                                )
                            ]?.subT ? (
                                <span className="p-status-sub-txt">
                                    {
                                        parking_status_map[
                                            getReEnterStatus(
                                                condition?.isLearningComplete,
                                                condition?.isLpnpComplete,
                                                mappingFailReason,
                                            )
                                        ]?.subT
                                    }
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
}
