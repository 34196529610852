import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BackToDriving from './backToD/index';
import Cancel from './cancel/index';
import AllStatus from './allStatus/index';
import OperateBtns from './operateBtns/index';
import { HOT_AREA_TYPE } from '@/store/action/type';
import './index.less';

export default function OperateArea() {
    const status = useSelector((state) => state?.parking?.parkingData?.os?.lpnp?.state);
    const area = useSelector((state) => state?.layout?.Area);
    const dispatch = useDispatch();
    const parkingBtnRef = useRef(null);

    const parkingBtn = parkingBtnRef?.current?.getBoundingClientRect();

    useEffect(() => {
        const parkingBtnInfo = `${parkingBtn?.x}, ${parkingBtn?.y}, ${parkingBtn?.width}, ${parkingBtn?.height}`;
        console.log('parkingBtnInfo', parkingBtnInfo);
        dispatch({
            type: HOT_AREA_TYPE.parkingBtn,
            data: parkingBtnInfo,
        });
    }, [status, parkingBtn?.x, parkingBtn?.y, parkingBtn?.width, parkingBtn?.height, area?.isHorizontal]);

    return (
        <div ref={parkingBtnRef} className="p-operate-area">
            <BackToDriving />
            <Cancel />
            <AllStatus />
            <OperateBtns />
        </div>
    );
}
