import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { getStudyStatus, statusMap } from './util';
import Modal from '../../../globalComponent/modal';
import BtnItem from './btnItem/index';
import { cusToast } from '@/components/customToast';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { MVIZ_APA_PARKING_DATA } from '@/store/action/parkingType';
import store from '@/store/index';
import { AppFn } from '@/utils/native';
import localCache from '@/utils/localCache';
import './index.less';

// const NOT_CHANGE_SCENE = ["LEARNING", "MAP_BEING_VERIFIED", "CRUISING", "PARKING", "APA_SELECTING", "APA_PARKING"];

export default function OperateBtnsSr() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const apaStatus = useSelector((state) => state?.featureModelParking?.apa?.state);
    const lpnp_switch = useSelector((state) => state?.featureModelParking?.switch?.lpnpSwitch);
    const condition = useSelector((state) => state?.featureModelParking?.lpnp?.condition);
    const mviz_msg = useSelector((state) => state?.parking?.mvizParkData);
    const dispatch = useDispatch();

    const parkingBtnRef = useRef(null);
    const parkingBtn = parkingBtnRef?.current?.getBoundingClientRect();

    const clearCarSite = () => {
        // reset
        store.dispatch({
            type: MVIZ_APA_PARKING_DATA.mvizData,
            data: null,
        });
    };
    const handleStartStudy = async () => {
        localCache.set('lpnp_start_apa_selecting', false);
        if (condition?.isMapBuilt) {
            // 重新学习
            singelPointReport({
                eventName: reportEventName.HMI_LPNP_ROUTEUPDATE_CLICK,
                timestamp: new Date().getTime(),
                text: 'lpnp routeupdate',
            });
            return Modal.info({
                onOk: async () => {
                    // 重新学习确认
                    singelPointReport({
                        eventName: reportEventName.HMI_LPNP_ROUTEUPDATE_CONFIRM_CLICK,
                        timestamp: new Date().getTime(),
                        text: 'lpnp routeupdate confirm',
                    });
                    FM.sendToHMIParking(ParkingSocketMap.lpnp_cmd, {
                        cmd: 'START_LEARNING',
                    });
                },
                okText: 'YES',
                cancelText: 'NO',
                content: 'Are you sure to re-learn a new route and replace  the old one?',
                centered: true,
                isD: userMode === 'D',
            });
        }

        // 开始学习
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_START_LEARNING_CLICK,
            timestamp: new Date().getTime(),
            text: 'lpnp start learning',
        });
        FM.sendToHMIParking(ParkingSocketMap.lpnp_cmd, {
            cmd: 'START_LEARNING',
        });
    };

    const handleParking = async () => {
        //启动LPNP[开始LPNP]
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_CRUISE_START_CLICK,
            timestamp: new Date().getTime(),
            text: 'lpnp start',
        });
        FM.sendToHMIParking(ParkingSocketMap.lpnp_cmd, {
            cmd: 'START_PARKING',
        });
    };

    window.apa_control_cb_start_parking_new = (res) => {
        if (res?.data?.code === 0) {
            clearCarSite();
        } else {
            cusToast.show({
                content: res?.msg || 'system error',
            });
        }
    };
    const handleAPAParking = async () => {
        //启动apa[开始泊车]
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_APA_START_CLICK,
            timestamp: new Date().getTime(),
            text: 'apa start',
        });
        if (!mviz_msg?.track_id) return;
        // Loading.show();
        FM.sendToHMIParking(
            ParkingSocketMap.apa_control,
            {
                cmd: 'START_PARKING',
                parking_space_id: parseInt(mviz_msg?.track_id),
            },
            'apa_control_cb_start_parking_new',
        );
    };

    const canParkingUse = (condition) => {
        return condition?.isMapBuilt && condition?.isOnRoute && condition?.isThroughMappingStartPos;
    };

    const isNeedToOut = () => {
        return condition?.isLearningComplete || condition?.isLpnpComplete;
    };

    window.pilot_kind_control_cb_driving = (res) => {
        if (res?.data?.code === 0) {
            //退出parking点击
            singelPointReport({
                eventName: reportEventName.HMI_PARKING_EXIT_CLICK,
                timestamp: new Date().getTime(),
                text: 'parking exit',
            });
        }
    };
    // const handleBack = async () => {
    //   FM.sendToHMIParking(
    //     ParkingSocketMap.pilot_kind_control,
    //     {
    //       cmd: "DRIVING",
    //     },
    //     "pilot_kind_control_cb_driving"
    //   );
    // };

    // const handleBack = async () => {
    //   FM.sendToHMIParking(ParkingSocketMap.pilot_kind_control, {
    //     cmd: "DRIVING",
    //   });
    //   // if (res.code == 0) {
    //   //   //退出parking点击
    //   //   singelPointReport({
    //   //     eventName: reportEventName.HMI_PARKING_EXIT_CLICK,
    //   //     timestamp: new Date().getTime(),
    //   //     text: "parking exit",
    //   //   });
    //   //   return navigate(`/${"home/index"}`);
    //   // }
    // };

    // 清除车位Id
    useEffect(() => {
        if (status != 'APA_SELECTING') {
            clearCarSite();
        }
    }, [status]);

    useEffect(() => {
        const parkingBtnInfo = `${parkingBtn?.x}, ${parkingBtn?.y}, ${parkingBtn?.width}, ${parkingBtn?.height}`;
        console.log('parkingBtnInfo', parkingBtnInfo);
        dispatch({
            type: HOT_AREA_TYPE.parkingBtn,
            data: parkingBtnInfo,
        });
    }, [status, parkingBtn?.x, parkingBtn?.y, parkingBtn?.width, parkingBtn?.height]);

    return (
        <div ref={parkingBtnRef} className="p-operate-btns">
            {getStudyStatus(status) != statusMap.NOT_IN && getStudyStatus(status) != undefined ? (
                <div className="p-operate-btn-group">
                    {
                        //继续泊车
                        getStudyStatus(status) == statusMap.APA_SELECTING && status === 'PARKING_PAUSE' ? (
                            <BtnItem handler={handleAPAParking} name={'PARKING_PAUSE'} disabled={false} />
                        ) : (
                            ''
                        )
                    }

                    {getStudyStatus(status) == statusMap.STANDBY && lpnp_switch == 'OPEN' && !isNeedToOut() ? (
                        <>
                            {Boolean(canParkingUse(condition)) ? (
                                <BtnItem handler={handleParking} name={'LPNP_OPEN'} disabled={false} />
                            ) : Boolean(condition?.isMapBuilt) ? (
                                <BtnItem name={'LPNP_OPEN'} disabled={true} />
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        ''
                    )}

                    {getStudyStatus(status) == statusMap.STANDBY && lpnp_switch == 'OPEN' && !isNeedToOut() ? (
                        <>
                            {condition?.isMapBuilt ? (
                                <BtnItem handler={handleStartStudy} name={'LEARNING_RESTART'} />
                            ) : (
                                <BtnItem handler={handleStartStudy} name={'LEARNING_START'} />
                            )}
                        </>
                    ) : (
                        <></>
                    )}

                    {getStudyStatus(status) == statusMap.APA_SELECTING ? (
                        <>
                            <BtnItem
                                handler={handleAPAParking}
                                name={'APA_PARKING'}
                                disabled={!mviz_msg ? true : false}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
