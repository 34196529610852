const INIT_STATE = {
    tooltipMsg: "",
    flag: false,
};

export default function smartDrivingSettingMsg(state = INIT_STATE, action) {
    switch (action.type) {
        case 'setUpMsg':
            return {
                ...state,
                ...{
                    tooltipMsg: action.data.tooltipMsg,
                    flag: action.data.flag
                }
            }
        default:
            return state;
    }
}
