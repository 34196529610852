import axios from 'axios';
import axiosRetry from 'axios-retry';
import NativeHttp from './nativeHttp';
import { isNativeLoad } from './isEnv';

const FeiShuUrl = 'https://open.feishu.cn/open-apis/bot/v2/hook/25a720d2-785b-4214-82a8-6f84bf5734d2';
const TIMEOUT = 5000;
const instance = axios.create({
    timeout: TIMEOUT,
});

axiosRetry(instance, {
    // 重试次数
    retries: 3,
    // 函数判断发生错误时是否重试
    retryCondition: (error) => axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.response?.status !== 200,
    // 重试的时候是否重置超时时间
    shouldResetTimeout: false,
    // 每个请求之间的重试延迟时间
    retryDelay: (retryCount) => {
        return retryCount * 1000;
    },
});

const success = ({ data, status, statusText }) => {
    if (status >= 200 && status < 300) {
        return data;
    }
    return Promise.reject(new Error(statusText));
};

// 响应拦截器
instance.interceptors.response.use(success, (error) => {
    return Promise.reject(error);
});

// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

const http = {
    get(url, params) {
        return instance.get(url, { ...params });
    },
    post(url, params) {
        return instance.post(url, { ...params });
    },
    fsPush(params) {
        return instance.post(FeiShuUrl, { msg_type: 'text', content: { text: JSON.stringify(params) } });
    },
};

const nativeHttp = new NativeHttp({
    retryTimes: 3,
    connectTimeout: 5,
});
export default isNativeLoad ? nativeHttp.http : http;
