import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { cusToast } from '@/components/customToast';

export default function useMEBSettingCommon() {
    // 通知底层：MEB开关
    const MEBControl = async (meb_switch) => {
        FM.sendToHMIParking(
            ParkingSocketMap.meb_switch_control,
            {
                cmd: meb_switch,
            },
            'meb_switch_control_cb',
        );
    };

    return {
        MEBControl,
    };
}
