import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { modalQueue, modalType } from '@/utils/modalQueue';
import amap from '@/assets/settingComNew/activeSafety/amap.png';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
import { useTranslation } from 'react-i18next';

export function useAMAP() {
    const amap_notice = useSelector((state) => state?.featureModelParking?.alert?.data?.is_amap_warning);
    const { t: translate } = useTranslation();
    const timer = useRef(null);
    useEffect(() => {
        if (amap_notice) {
            clearTimeout(timer.current);
            // 语音播放逻辑
            AppFn.playLocalAudio(localAudioNames.AMAP_BREAKING, 'Important');
            // 弹窗展示逻辑
            modalQueue({ type: modalType.AMAP, modalData: { img: amap, text: translate('settings.AMAPPressBrake') } });
        } else {
            // 弹窗销毁逻辑
            timer.current = setTimeout(() => {
                modalQueue({ type: modalType.AMAP, modalData: null });
            }, 500);
        }
    }, [amap_notice]);
}
