const SocketMap = {
    // 获取版本信息
    getVersion: '17',
    operateDriveMode: 'operate_drive_mode',
    drivingControl: 'driving_control',
    hmiMsgSubscribe: 'hmi_msg_subscribe',
    transDriveMode: 'trans_drive_mode',
    carFollowControl: 'car_follow_control',
    operateLaneChangeMode: 'lane_change',
    adasControl: 'adas_control',
    drivingStyle: 'driving_style_control',
    laneChangeStyle: 'lane_change_style_control',
    adasSlifControl: 'adas_slif_control',
    adasAebControl: 'adas_aeb_control',
    adasFcwControl: 'adas_fcw_control',
    TALSwitchControl: 'tla_control',
    elkSwitchControl: 'elk_control',
    lcaBsdControl: 'lca_bsd_control',
    dowControl: 'dow_control',
    rcwControl: 'rcw_control',
    absmControl: 'absm_control',
    resumeControl: 'running_mode_resume_control',
    autoLaneChangeControl: 'cp_lane_change',
    handsOffDriveMode: 'hands_off_drive_mode',
    tts: 'heartbeat_control',
    laneChangeTipControl: 'lane_change_tip_control',
    cswControl: 'csw_control',
    essControl: 'ess_control',
    ihcControl: 'ihc_control',
    event_trigger: 'event_trigger',
    lpnp_switch_control: 'lpnp_switch_control',
    apa_switch_control: 'apa_switch_control',
    tfl_stop_control: 'tfl_stop_control',
    collision_alert_control: 'collision_alert_control',
    latestLaneChangeControl: 'latest_lane_change_control',
    autoFollowSlifSpeedControl: 'auto_follow_slif_speed_control',
    adas_amap_control: 'adas_amap_control',
    adas_nrp_control: 'adas_nrp_control',
    adas_isa_control: 'adas_isa_control',
    cp_warning_control: 'cp_warning_control',
};

const ParkingSocketMap = {
    pilot_kind_control: 'pilot_kind_control', // 切换场景
    lpnp_cmd: 'lpnp_control', // 用户状态切换指令
    lpnp_switch_control: 'lpnp_switch_control', // 切换LPNP开关
    meb_switch_control: 'meb_switch_control',
    apa_control: 'apa_control',
};

const LPNP_CMD_TYPE = {
    LEARNING_START: 'LEARNING_START',
    LEARNING_RESTART: 'LEARNING_RESTART',
    LEARNING_CANCEL: 'LEARNING_CANCEL',
    LEARNING_COMPLETE: 'LEARNING_COMPLETE',
    PARKING_START: 'PARKING_START',
};

// 驾驶/变道风格
/*// 驾驶/变道风格
const styleOptions=[{
  label: '正常',
  value: 'NORMAL'
}, {
  label: '激进',
  value: 'AGGRESIVE'
}, {
  label: '保守',
  value: 'CONSERVATIVE'
}];*/

export {
    SocketMap,
    ParkingSocketMap,
    LPNP_CMD_TYPE,
    // styleOptions
};
