import './index.less';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Collapse, Button, Empty } from 'antd-mobile';
import TaskTitle from '../TaskTitle';
import TaskDetail from '../TaskDetail';
import { getTaskList } from '../../api';
import dayjs from 'dayjs';
import localCache from '@/utils/localCache';
import { useIsParking } from '../../hook';

export default function TaskList({ className }) {
    const [taskInfo, setTaskInfo] = useState();
    const [taskDetailFlag, setTaskDetailFlag] = useState(false);
    const isParking = useIsParking();
    const box_id = useSelector((state) =>
        isParking ? state?.featureModelParking?.body?.boxId : state?.featureModelDriving?.baseData?.body?.boxid,
    );
    const [taskList, setTaskList] = useState([]);
    const [activeKey, setActiveKey] = useState(null);
    const firstFetch = useRef(true);

    useEffect(() => {
        if (taskDetailFlag || !box_id) return;
        let params = { box_id, date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') };
        getTaskList(params).then(({ code, data, message }) => {
            if (code === 200) {
                if (firstFetch.current) {
                    setActiveKey(data.index + '');
                    firstFetch.current = false;
                }
                setTaskList(data?.tasks || []);
            }
        });
    }, [box_id, taskDetailFlag]);
    const mounted = useRef(false);
    useEffect(() => {
        if (!mounted.current && Array.isArray(taskList) && taskList.length) {
            localCache.get('momenta_hmi_workbench_task', (task_id) => {
                const task = taskList.find((item) => item._id === task_id);
                if (task) openTaskDetail(task);
            });

            mounted.current = true;
        }
    }, [taskList]);

    function openTaskDetail(task) {
        setTaskInfo(task);
        setTaskDetailFlag(true);
        localCache.set('momenta_hmi_workbench_task', task?._id);
    }
    return (
        <div className={classnames(className, 'task-list-container')}>
            <h3 className="title">今日任务</h3>
            {taskList.length ? (
                <Collapse accordion className="task-list" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
                    {taskList.map((task, index) => (
                        <Collapse.Panel
                            title={
                                <TaskTitle
                                    taskName={task.task_name}
                                    taskType={task.task_form}
                                    startTime={task.estimate_start_time}
                                    endTime={task.estimate_end_time}
                                    taskStatus={task.real_task_status}
                                    deploy={task.software_status_str}
                                />
                            }
                            key={index}
                        >
                            <ul>
                                <li className="task-info-item">安全员：{task.driver.driver_name || '未分配'}</li>
                                <li className="task-info-item">所属功能：{task.task_function}</li>
                                <li className="task-info-item">所属模块：{task.task_module || '无'}</li>
                                <li className="task-info-item">任务城市：{task.city}</li>
                                <li className="task-info-item">软件包：{task.software_version || '无'}</li>
                                <li className="task-info-item">
                                    <Button
                                        color="primary"
                                        fill="outline"
                                        className="btn-entry"
                                        onClick={() => openTaskDetail(task)}
                                    >
                                        进入任务
                                    </Button>
                                </li>
                            </ul>
                        </Collapse.Panel>
                    ))}
                </Collapse>
            ) : (
                <Empty description="暂无数据" imageStyle={{ width: '2rem' }} className="tip" />
            )}

            <TaskDetail taskInfo={taskInfo} visbale={taskDetailFlag} onCancel={() => setTaskDetailFlag(false)} />
        </div>
    );
}
