/**
 * 锥形桶提醒
 * */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import localAudioNames from '@/constant/localAudioName';
import WidgetComponent from '@/components/widget/index';
import { widgetQueue, widgetType } from '@/utils/widgetQueue';
import { AppFn } from '@/utils/native';
import { useTranslation } from 'react-i18next';
import hnpErrorTip from '@/assets/hnp/hnp_error_tip.png';
import reportEventName from '@/constant/reportEventName';
import { batchReport } from '@/utils/report';
// import {cusToast} from '@/components/customToast';

const { playLocalAudio } = AppFn;

let timer = null; // widget 定时器句柄
let timer_auto_stop = null; // case1 定时器句柄
let timer_no_auto_stop = null; // case2 定时器句柄
export default function TaperedBarrel() {
    // 正在运行的智驾状态
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    // 1: 提示驾驶员注意交通环境   2: 提示驾驶员接管
    const cone_bucket_warn = useSelector(
        (state) => state?.featureModelDriving?.coneBucketStop?.notice?.data?.coneBucketWarnLevel,
    );

    // 国际化处理
    const { t: translate } = useTranslation();

    const dataBuriedPoint = (eventName, reportData) => {
        batchReport({
            eventName,
            timestamp: new Date().getTime(),
            reportData,
        });
    };

    useEffect(() => {
        // console.log(cone_bucket_warn, running_mode, "cone_bucket_warn, running_mode log")
        clearTimeout(timer);
        clearInterval(timer_auto_stop);
        clearInterval(timer_no_auto_stop);
        timer = null;
        timer_auto_stop = null;
        timer_no_auto_stop = null;
        if (!(running_mode === 'CP' || running_mode === 'HNP')) return;
        let text = '';
        // Case 1：锥桶在自车道，系统可自动刹停； Auto_brake_stop
        if (cone_bucket_warn === 1) {
            text = translate('WatchBarrierAhead');
            // 前方有障碍物，请小心驾驶 + bibibi混合音频
            playLocalAudio(localAudioNames.CONICAL_BARREL_AUTO_AVOID, 'Highest');
            // 埋点上报
            dataBuriedPoint(reportEventName.HMI_BARRIER_VIEW, {
                Type: 'Auto_brake_stop',
                Running_mode: running_mode,
            });
            // 循环播放音效
            let count = 0;
            let time_interval = 2700;
            timer_auto_stop = setInterval(fn, time_interval);
            function fn() {
                if (count === 0) {
                    clearInterval(timer_auto_stop);
                    time_interval = 1500;
                    timer_auto_stop = setInterval(fn, time_interval);
                }
                count += 1;
                playLocalAudio(localAudioNames.BIBIBI_WARNING, 'Highest');
            }
        }
        // Case2：锥桶在自车道，需人工介入；
        if (cone_bucket_warn === 2) {
            // 前方有障碍物，请立即接管 + bibibi混合音频
            playLocalAudio(localAudioNames.CONICAL_BARREL_NO_AVOID, 'Highest');
            // 埋点上报
            dataBuriedPoint(reportEventName.HMI_BARRIER_VIEW, {
                Type: 'Manual_brake',
                Running_mode: running_mode,
            });
            // 循环播放音效
            let count = 0;
            let time_interval = 2700;
            timer_no_auto_stop = setInterval(noAutoStop, time_interval);
            function noAutoStop() {
                if (count === 0) {
                    clearInterval(timer_auto_stop);
                    time_interval = 1500;
                    timer_auto_stop = setInterval(noAutoStop, time_interval);
                }
                count += 1;
                playLocalAudio(localAudioNames.BIBIBI_WARNING, 'Highest');
            }
        }

        if (text && text !== '' && running_mode === 'CP' && cone_bucket_warn !== 2) {
            widgetQueue({
                widgetNumber: 1,
                type: widgetType.TAPERED_BARREL_AUTO_STOP,
                widgetContent: {
                    content: text,
                },
            });
            timer = setTimeout(() => {
                widgetQueue({ widgetNumber: 1, type: widgetType.TAPERED_BARREL_AUTO_STOP, widgetContent: null });
            }, 3 * 1000);
        } else {
            widgetQueue({ widgetNumber: 1, type: widgetType.TAPERED_BARREL_AUTO_STOP, widgetContent: null });
        }
    }, [running_mode, cone_bucket_warn]);

    return (
        <React.Fragment>
            <WidgetComponent />
            {running_mode === 'CP' && cone_bucket_warn === 2 && (
                <div className="tipsItem">
                    <img src={hnpErrorTip}></img>
                    <p>{translate('takeOverTips')}</p>
                </div>
            )}
        </React.Fragment>
    );
}
