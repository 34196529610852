import { useSelector } from 'react-redux';
import Distance from './distance';
import Gear from './gear';
import Perception from './perception';
import Speed from './speed';
import './index.less';

export default function StatusInfo() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const robonodeReady = useSelector((state) => state?.robonode?.ready);
    return (
        <div className="p-status-info">
            {userMode == 'D' ? (
                <div className="p-status-info-wrap">
                    <Speed userMode={userMode} />
                    <Distance />
                    <Gear />
                    <Perception />
                </div>
            ) : (
                <>
                    <Speed />
                    <Distance />
                    <Gear />
                    <Perception />
                </>
            )}
        </div>
    );
}
