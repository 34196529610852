export const TASK_STATUS_MAP = {
  2: {
    text: '待分配司机',
    tagStyle: {
      '--background-color': 'rgba(244, 236, 255, 1)',
      '--border-color': 'rgba(205, 175, 244, 1)',
      '--text-color': 'rgba(205, 175, 244, 1)',
    },
  },
  3: {
    text: '待执行',
    tagStyle: {
      '--background-color': 'rgba(255, 241, 227, 1)',
      '--border-color': 'rgba(255, 154, 61, 1)',
      '--text-color': 'rgba(255, 154, 61, 1)',
    },
  },
  4: {
    text: '执行中',
    tagStyle: {
      '--background-color': 'rgba(230, 255, 251, 1)',
      '--border-color': 'rgba(0, 203, 182, 1)',
      '--text-color': 'rgba(0, 203, 182, 1)',
    },
  },
  5: {
    text: '结束',
    tagStyle: {
      '--background-color': 'rgba(237, 239, 243, 1)',
      '--border-color': 'rgba(154, 158, 170, 1)',
      '--text-color': 'rgba(154, 158, 170, 1)',
    },
  },
  default: {
    text: 'NONE',
    tagStyle: {
      '--background-color': 'rgba(217, 241, 255, 1)',
      '--border-color': 'rgba(28, 173, 255, 1)',
      '--text-color': 'rgba(28, 173, 255, 1)',
    },
  },
};

export const ROUTE_TYPE = [
  {
    value: 'freeRoute',
    label: '自由路线',
  },
  {
    value: 'presetRoute',
    label: '预置路线',
  },
  {
    value: 'customRoute',
    label: '自定义路线',
  },
  {
    value: 'customParking',
    label: '自定义停车场',
  },
];
