import { WIDGET_ACTION } from '../action/type';

const INIT_STATE_WIDGET1 = {
    level: null,
    list: []
};

export default function widget1Data(state = INIT_STATE_WIDGET1, action) {
    switch (action.type) {
        case WIDGET_ACTION.widget1Action:
            return { ...state, ...action.data };
        default:
            return state;
    }
}
