import React, {useEffect} from "react";
import {useSelector} from 'react-redux';
import {widgetPush, widgetType} from '@/utils/widgetPush';
import HB from "@/assets/hnp/adas/IHC/HB.png";
import LB from "@/assets/hnp/adas/IHC/LB.png";
import WARNING from "@/assets/hnp/adas/IHC/WARNING.png";
import './index.less';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

export default function IhcIcon() {
    // widget展示  LB近光 HB远光 WARNING故障  (还有两种对前端无用的状态: CLOSE/OPEN)
    const ihc_icon_status = useSelector(state => state?.featureModelDriving?.ihc?.state);
    const targetStatus = ["LB", "HB", "WARNING"]

    const showWidget = () => {
        if (targetStatus.includes(ihc_icon_status)) {
            /**
             * IHC icon为非常驻显示 icon，满足特定条件才显示
             * IHC功能关闭：不显示icon
             * IHC触发近光灯：显示绿色icon
             * IHC触发远光灯：显示蓝色icon
             * IHC功能故障：显示黄色icon
             * */
            const ImageMap = {LB, HB, WARNING}
            let contentText = `
                <div class="ess_icon_wrap">
                  <img class="img_wrap"  src=${ImageMap[ihc_icon_status]} alt="IHC"/>
                </div>
            `
            let content = {"content": contentText}
            widgetPush({widgetNumber: 3, type: widgetType.IHC, widgetContent: content});
        } else {
            widgetPush({widgetNumber: 3, type: widgetType.IHC, widgetContent: null});
        }
    }

    // 数据上报
    const reportDataFunc = () => {
        let result = ""
        if (ihc_icon_status === "LB") {
            result = 0
        }
        if (ihc_icon_status === "HB") {
            result = 1
        }
        if (ihc_icon_status === "WARNING") {
            result = 2
        }
        singelPointReport({
            eventName: reportEventName.HMI_IHC_VIEW,
            timestamp: new Date().getTime(),
            text: result,
        })
    }

    useEffect(() => {
        showWidget()
        targetStatus.includes(ihc_icon_status) && reportDataFunc()

    }, [ihc_icon_status])

    return (
        <></>
    )
}
