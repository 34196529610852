import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import uploadImg from '@/assets/workbench/upload.png';
import bg from '@/assets/workbench/recode_item_bg.png';
import { getFileList } from '../api';
import { Button, Modal, Mask } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import dayjs from 'dayjs';
import status from '@/assets/workbench/uploading.png';
import sound from '@/assets/workbench/sound.png';
import toNumber from 'lodash/toNumber';
import sound_play from '@/assets/workbench/sound_play.gif';
import BScroll from '@better-scroll/core';
import { Empty } from 'antd-mobile';
import { singelPointReport } from '@/utils/report';
import workBenchEventName from '@/constant/workBenchEventName';
import Toast from '@/components/workbench/Toast';
import closeImg from '@/assets/workbench/close_img.png';
import logSend from '@/utils/aLog';
import ProgressiveImg from './image';
import './index.less';
import { AppFn } from '@/utils/native';
import { useIsParking } from '../hook';

let isScrollButtom = false;
const { recordAudioFile, wbPlayAudioCallback } = AppFn;

export default function Report(props) {
    const isParking = useIsParking();
    const box_id = useSelector((state) =>
        isParking ? state?.featureModelParking?.body?.boxId : state?.featureModelDriving?.baseData?.body?.boxid,
    );
    const uploadFileList = useSelector((state) => state?.diskDataCollection?.uploadFileList);
    // hmi返回消息的时间戳
    const timestamp = useSelector((state) =>
        isParking ? state?.featureModelParking?.hmi?.timestamp : state?.featureModelDriving?.baseData?.hmi?.timestamp,
    );

    const workBenchRecording = useSelector((state) => state?.switchModeAction?.isRecording); // 工作台的录音状态
    const recordSetingTimes = useSelector((state) => state?.switchModeAction?.recordTimes);
    // 触发录包标识
    const filter_meta = useSelector((state) =>
        isParking ? state?.featureModelParking?.filterMeta : state?.featureModelDriving?.filterMeta,
    );
    const [list, setList] = useState([]);
    const [visible, setVisible] = useState(false);
    //const [newRecord,setNewRecord]  =  useState(filter_meta.time_stamp); // 触发录包

    const [modalVisible, setModalVisible] = useState(false);
    const [recordData, setRecordData] = useState(null); // 当前补录项
    const [currentRecording, setCurrentRecording] = useState(false); // 当前补录状态

    const [isLoading, setIsLoading] = useState(false);
    const [curUrl, setCurUrl] = useState('');
    const [isShowNoMore, setIsShowNoMore] = useState(false);
    const [num, setIndex] = useState(-1);
    // const [fileName,setFileName] = useState(undefined);
    const listRef = useRef([]);
    const myScroll = useRef();
    const container = useRef();
    const content = useRef();

    const fileNameRef = useRef();
    const timestampRef = useRef(); // 记录时间用
    const preAudioUrl = useRef(); //上一个点击的url
    const playTimer = useRef();
    const amendTimerRef = useRef(); //补录定时器
    let [recordTime, setRecordTime] = useState(0); // 记录录音时长

    const count = useRef(10);
    const playEffect = useRef(false);

    // 工作台关闭时录音播放中停止播放
    useEffect(() => {
        return () => {
            if (!playEffect.current) return;
            window?.momentaJsBridge?.dispatch(
                'playCloudAudio',
                null,
                JSON.stringify({
                    type: 'duplex',
                    loop: false,
                    action: 'stop',
                    data: preAudioUrl.current,
                }),
            );
        };
    }, []);

    const reset = (url) => {
        playEffect.current = false;
        setIndex(-1);
        clearInterval(playTimer.current);
        console.log('ssss', JSON.stringify({ type: 'duplex', loop: false, action: 'stop', data: preAudioUrl.current }));
        window?.momentaJsBridge?.dispatch(
            'playCloudAudio',
            null,
            JSON.stringify({
                type: 'duplex',
                loop: false,
                action: 'stop',
                data: url || preAudioUrl.current,
            }),
        );
    };

    const playAudio = (url, duration, i) => {
        count.current = duration || 10;
        playEffect.current = true;
        setIndex(i);
        preAudioUrl.current = url;
        console.log('ssss--222', i, JSON.stringify({ type: 'duplex', loop: false, action: 'start', data: url }));
        window?.momentaJsBridge?.dispatch(
            'playCloudAudio',
            null,
            JSON.stringify({ type: 'duplex', loop: false, action: 'start', data: url }),
        );
        // count.current = 7;
        playTimer.current = setInterval(() => {
            if (toNumber(count.current) === 1) {
                // count.current = duration || 10;
                // count.current = 7;

                return reset(url); //正常结束的给url
            }
            count.current = count.current - 1;
        }, 1000);
        singelPointReport({
            eventName: workBenchEventName.WB_record_play_click,
            timestamp: new Date().getTime(),
        });
    };

    const handleAudio = (url, duration, i) => {
        //处理未播放完毕的逻辑
        if (playEffect.current) {
            reset();
            //当重复点击时直接提前退出
            if (num === i) return;
        } else {
            console.log('ssss-重新赋值');
            preAudioUrl.current = url; //没有在播放，说明新的开始
        }
        playAudio(url, duration, i);
    };

    const openSomeImage = (url) => {
        setVisible(true);
        setCurUrl(url);
        singelPointReport({
            eventName: workBenchEventName.WB_picture_play_click,
            timestamp: new Date().getTime(),
        });
    };

    /*监控录音的状态，被打断之后直接关闭补录,filter_meta?.time_stamp,
  只要有录包信号发出就，就先直接结束录音*/
    useEffect(() => {
        if (currentRecording) {
            logSend({
                action: 'stopAmendRecord',
                data: {
                    type: 'stopAmendRecord',
                    file_name: fileNameRef.current, //xxxx
                    id: recordData?.id,
                    execTime: Date.now(),
                    meta_data: {
                        endType: -1, // 补录打断结束
                        duration: recordTime,
                        boxid: recordData?.device_box_id,
                        time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                    },
                },
            });
            // 补录直接被打断
            recordAudioFile(
                'stopAmendRecordCB',
                JSON.stringify({
                    type: 'stopAmendRecord',
                    file_name: fileNameRef.current, //xxxx
                    meta_data: {
                        endType: -1, // 补录打断结束
                        duration: recordTime,
                        boxid: recordData?.device_box_id,
                        id: recordData?.id,
                        time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                    },
                }),
            );
            clearInterval(amendTimerRef.current);
            setModalVisible(false);
            setRecordTime(0);
            props.workBenchClose();
        }
    }, [filter_meta?.timeStamp]);

    const getFileListAgain = async () => {
        if (!box_id) return;
        setIsLoading(true);
        let list = listRef?.current;
        let res = await getFileList({
            device_box_id: box_id,
            last_id: list[list?.length - 1]?.id,
            limit: 10,
        });
        if (res?.code == 200) {
            listRef.current = [...listRef.current, ...res?.data];
            setList(listRef.current);
            setIsShowNoMore(true);
            myScroll?.current?.refresh();
        }
        isScrollButtom = false;
        setIsLoading(false);
    };

    useEffect(async () => {
        if (!list?.length) return;
        await getFileListAgain();
    }, [uploadFileList?.length]);

    useEffect(async () => {
        if (!box_id) return;
        let res = await getFileList({
            device_box_id: box_id,
            last_id: 0,
            limit: 10,
        });
        if (res?.code == 200) {
            listRef.current = [...listRef.current, ...res?.data];
            setList(listRef.current);
            myScroll.current = new BScroll('#wrapper', {
                probeType: 3,
                wheel: true,
                scrollbar: true,
                click: true,
                pullUpLoad: true,
            });
            myScroll?.current?.on('scroll', async () => {
                if (
                    myScroll?.current?.y <= container?.current?.offsetHeight - content?.current?.offsetHeight &&
                    !isScrollButtom
                ) {
                    isScrollButtom = true;
                    return await getFileListAgain();
                }
            });
        }
    }, [box_id]);

    //触发补录
    const recordHandle = () => {
        // 手动结束录音
        if (currentRecording) {
            recordAudioFile(
                'stopAmendRecordCB',
                JSON.stringify({
                    type: 'stopAmendRecord',
                    file_name: fileNameRef.current, //xxxx
                    meta_data: {
                        endType: 1, // 补录结束
                        duration: recordTime,
                        boxid: recordData?.device_box_id,
                        id: recordData?.id,
                        time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                    },
                }),
            );

            clearInterval(amendTimerRef.current);
            //结束录制音效
            wbPlayAudioCallback(null, JSON.stringify({ type: 'duplex', data: 'end_record_reminder.mp3' }));
            setCurrentRecording(false); //结束状态
            setTimeout(() => {
                setRecordTime(0);
                setModalVisible(false);
            }, 500);

            // setInterval(() => {
            //   console.log('rrrrrr')
            //   getFileListAgain()
            // }, 3000);
        } else {
            wbPlayAudioCallback(null, JSON.stringify({ type: 'duplex', data: 'start_record_reminder.mp3' }));
            let listNames = recordData?.voice_content?.filename?.split('_') || [];
            let newTime = dayjs(timestamp * 1000).format('YYYYMMDD-HHmmss');
            timestampRef.current = timestamp;
            console.log('uploadFile--timestamp', timestampRef.current, timestamp);
            listNames[listNames.length - 1] = newTime;
            fileNameRef.current = listNames.join('_');
            setCurrentRecording(true);
            //播报完成后，执行录音动画并读秒
            setTimeout(() => {
                play_record_callback();
                setRecordTime(recordTime++);
                // console.log(99999,recordTime)
            }, 1000);
        }
    };

    //补录完成回调，重新刷新列表
    window.stopAmendRecordCB = (nres) => {
        console.log('mmmxxxxx', JSON.stringify(nres));
        getFileList({
            device_box_id: box_id,
            last_id: 0,
            limit: 10,
        }).then((res) => {
            if (res?.code == 200) {
                listRef.current = [...res?.data];
                setList(listRef.current);
                myScroll?.current?.refresh();
            }
        });
    };
    const play_record_callback = () => {
        //播放开始录音音效
        recordAudioFile(
            'stopAmendRecordCB',
            JSON.stringify({
                type: 'startAmendRecord',
                //时间取新生成。
                file_name: fileNameRef.current,
                meta_data: {
                    duration: recordTime,
                    id: recordData?.id, //xxxx
                    time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                },
            }),
        );
        console.log(
            'uploadFile--start',
            JSON.stringify({
                type: 'startAmendRecord',
                //时间取新生成。
                file_name: fileNameRef.current,
                meta_data: {
                    duration: recordTime,
                    id: recordData?.id, //xxxx
                    time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                },
            }),
        );

        amendTimerRef.current = setInterval(() => {
            //用户未主动触发结束,超过recordTimes自动结束
            if (recordTime > recordSetingTimes) {
                setRecordTime(0);
                clearInterval(amendTimerRef.current);
                setCurrentRecording(false);
                setModalVisible(false);

                const meta_data = {
                    duration: recordTime - 1, //此处要减掉1，因为要走秒，不然音频文件少1s
                    id: recordData?.id, //xxxx
                    boxid: recordData?.device_box_id,
                    time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                };
                meta_data.endType = 1; //录音结束
                recordAudioFile(
                    'stopAmendRecordCB',
                    JSON.stringify({
                        type: 'stopAmendRecord',
                        file_name: fileNameRef.current, //xxxx
                        meta_data,
                    }),
                );
                //结束录制音效
                wbPlayAudioCallback(null, JSON.stringify({ type: 'duplex', data: 'end_record_reminder.mp3' }));
            } else {
                console.log('xxxxxxx', recordTime);
                setRecordTime(recordTime++);
            }
        }, 1000);
    };

    const additionalBtn = (e, item) => {
        console.log('sss', e, item);
        e.stopPropagation();
        if (workBenchRecording) {
            Toast.info('工作台录音中,录音结束后补录。', 2000);
            return;
        }
        console.log('playEffect.current', playEffect.current);
        if (playEffect.current) {
            reset();
        }
        setModalVisible(true);
        setRecordData(item);
    };

    return (
        <div className="work-bench-base right">
            <div className="work-bench-base1">
                <h4>上报管理</h4>
                <div ref={container} className="work-bench-base-upload-content" id="wrapper">
                    <ul ref={content} className="work-bench-base-lists">
                        {uploadFileList?.length
                            ? uploadFileList?.map((item, index) => {
                                  return (
                                      <li key={index}>
                                          <div className="work-bench-base-lists-status">
                                              <img src={status} alt="status" />
                                          </div>
                                          <div className="work-bench-base-lists-title">
                                              <span className="">{item?.file_name}</span>
                                          </div>
                                          <div className="work-bench-base-lists-content">
                                              <img className="work-bench-img" src={uploadImg} alt="screen shot" />
                                              <img className="work-bench-audio" src={bg} alt="audio record" />
                                              <div className="work-bench-base-lists-effect">
                                                  <div className="work-bench-base-lists-effect-loading"></div>
                                              </div>
                                          </div>
                                      </li>
                                  );
                              })
                            : ''}
                        {list?.length
                            ? list?.map((item, index) => {
                                  return (
                                      <li key={index}>
                                          <div className="work-bench-base-lists-title">
                                              <span className="">
                                                  {dayjs(item?.created_at).format('YYYY-MM-DD HH:mm:ss')}
                                              </span>
                                          </div>
                                          <div className="work-bench-base-lists-content">
                                              <ProgressiveImg
                                                  className="work-bench-img"
                                                  openSomeImage={(url) => openSomeImage(url)}
                                                  src={item?.pic_content?.url}
                                              />
                                              <div
                                                  onClick={() =>
                                                      handleAudio(
                                                          item?.voice_content?.url,
                                                          item?.voice_content?.duration,
                                                          index,
                                                      )
                                                  }
                                                  className="right-content-img"
                                              >
                                                  <img className="work-bench-audio" src={bg} alt="audio record" />
                                                  <p className="voice-times">
                                                      {item?.voice_content?.duration}
                                                      <span>"</span>
                                                  </p>
                                                  <div className="work-bench-audio-icon">
                                                      {num !== index ? (
                                                          <img
                                                              // onClick={() =>
                                                              //   handleAudio(item?.voice_content?.url, item?.voice_content?.duration, index)
                                                              // }
                                                              src={sound}
                                                              alt="sound_icon"
                                                          />
                                                      ) : (
                                                          <img
                                                              src={sound_play}
                                                              onClick={() =>
                                                                  handleAudio(item?.voice_content?.url, index)
                                                              }
                                                              alt="sound_icon"
                                                          />
                                                      )}
                                                  </div>
                                                  {item?.voice_content?.end_type === -1 && (
                                                      <div className="additional-btn">
                                                          <Button
                                                              onClick={(e) => {
                                                                  additionalBtn(e, item);
                                                              }}
                                                              style={{
                                                                  '--background-color': '#F0F3FF',
                                                                  '--text-color': '#2A69E2',
                                                                  '--border-color': '#2A69E2',
                                                                  '--border-radius': '.05rem',
                                                                  fontSize: '.2rem',
                                                                  height: '.42rem',
                                                              }}
                                                              fill="outline"
                                                          >
                                                              点击补录
                                                          </Button>
                                                      </div>
                                                  )}
                                                  {item?.voice_content?.end_type === 1 && (
                                                      <div className="additional-btn">
                                                          <Button
                                                              disabled
                                                              style={{
                                                                  '--border-radius': '.05rem',
                                                                  fontSize: '.2rem',
                                                                  height: '.42rem',
                                                              }}
                                                          >
                                                              补录完成
                                                          </Button>
                                                      </div>
                                                  )}
                                              </div>
                                          </div>
                                      </li>
                                  );
                              })
                            : ''}
                        {!isLoading && list?.length && isShowNoMore ? (
                            <div className="list-no-more">没有更多数据～</div>
                        ) : (
                            ''
                        )}

                        {/* <Viewer
              visible={visible}
              onClose={() => setVisible(false)}
              images={[{ src: curUrl, alt: "" }]}
            /> */}
                    </ul>
                    {!uploadFileList?.length && !list?.length && !isLoading ? (
                        <Empty description="暂无数据" imageStyle={{ width: '2rem' }} className="tip" />
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <>
                {
                    <Mask visible={visible} onMaskClick={() => setVisible(false)}>
                        <div className="img-view-content">
                            <img src={curUrl} alt="图片加载失败" />
                            <div onClick={() => setVisible(false)} className="cricle-close">
                                <img src={closeImg} alt="图片" />
                            </div>
                        </div>
                    </Mask>
                }
            </>
            <Modal
                bodyClassName="workBench-modal-content"
                visible={modalVisible}
                content={
                    <div className="">
                        <CloseOutline
                            className="close-icon"
                            onClick={() => {
                                if (currentRecording) {
                                    Toast.info('语音补录中，请结束后再关闭', 1500);
                                    return;
                                }
                                setModalVisible(false);
                            }}
                        />

                        <p className="title"> {dayjs(recordData?.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                        <p className="title-two">补录时若被打断，需要重新补录</p>

                        <div className="cricle-outer" onClick={recordHandle}>
                            <div className={currentRecording ? 'cricle-inner active' : 'cricle-inner'}></div>
                        </div>
                        <div className="time-cutdown">
                            <span className="red-icon"></span>
                            <span className="cutdown-title">
                                0:00:{recordTime >= 10 ? recordTime : '0' + recordTime}
                            </span>
                        </div>
                    </div>
                }
                closeOnAction
            />
        </div>
    );
}
