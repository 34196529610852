import { useSelector } from 'react-redux';
import openLplp from '@/assets/parking/icons/icon_open_lpnp.png';
import apaStart from '@/assets/parking/icons/icon_apa_start.png';
import reStart from '@/assets/parking/icons/icon_apa_continue.png';
import './index.less';

const operate_icon_map = {
    LPNP_OPEN: {
        iconD: openLplp,
        icon: openLplp,
        txt: 'Start LPNP',
    },
    APA_PARKING: {
        iconD: apaStart,
        icon: apaStart,
        txt: 'Park In',
    },
    //@zhongning 暂停
    // PARKING_PAUSE: {
    //     iconD: reStart,
    //     icon: reStart,
    //     txt: 'Resume APA',
    // },
    LEARNING_START: {
        iconD: openLplp,
        icon: openLplp,
        txt: 'Start Route Learning',
    },
    LEARNING_RESTART: {
        class: 'restart',
        iconD: '',
        icon: '',
        txt: 'Route Update',
    },
    LEARNING_CANCEL: {
        iconD: '',
        icon: '',
        txt: 'Exit Route Learning',
    },
    RECOVER_LPNP: {
        iconD: openLplp,
        icon: openLplp,
        txt: 'LPNP Resume',
    },
};

export default function OperateBtns(props) {
    const userMode = useSelector((state) => state?.parking?.userMode);
    return (
        <>
            {props?.name ? (
                <button
                    disabled={props?.disabled ? 'disabled' : ''}
                    onClick={props?.handler}
                    className={`p-operate-btn ${userMode == 'D' ? 'd' : 'sr'} ${
                        props?.disabled ? 'disabled' : 'active'
                    } ${operate_icon_map[props?.name]?.icon ? 'highlight' : ''} ${
                        operate_icon_map[props?.name]?.class ? 'restart' : ''
                    }`}
                >
                    {operate_icon_map[props?.name]?.icon ? (
                        <img
                            src={
                                userMode == 'D'
                                    ? operate_icon_map[props?.name]?.iconD
                                    : operate_icon_map[props?.name]?.icon
                            }
                            alt="btn_icon"
                        />
                    ) : (
                        ''
                    )}
                    <span>{operate_icon_map[props?.name]?.txt}</span>
                </button>
            ) : (
                ''
            )}
        </>
    );
}
