import { AppFn } from '@/utils/native';
import store from '@/store';

export default function setHMIMode(userMode) {
    const isD = userMode === 'D';
    const state = store.getState();
    const status = state?.featureModelParking?.lpnp?.state;
    const getSRMode = (status) => {
        switch (status) {
            case 'LEARNING':
            case 'MAP_BEING_VERIFIED':
                return 'start';
            case 'APA_PARKING':
            case 'APA_SELECTING':
            case 'APA_PARKING_PAUSE':
            case 'PARKING':
                return 'apa';
            case 'CRUISING':
                return 'cruise';
            default:
                return 'end';
        }
    };
    AppFn.switchUserMode(isD ? 'mviz' : 'sr', getSRMode(status));
}
