import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import style from './index.module.less';
import { AppFn } from '@/utils/native';
import store from '@/store/index';
import { HOT_AREA_TYPE } from '@/store/action/type';

const { playTTSAudio, playLocalAudio } = AppFn;

const AudioMap = [
    {
        name: 'lpnp_start_learning.mp3',
        lebal: 'Start Route Learning',
    },
    {
        name: 'lpnp_start_cruising.mp3',
        lebal: 'Start LPNP',
    },
    {
        name: 'lpnp_start_parking.mp3',
        lebal: 'Start Park In',
    },
    {
        name: 'lpnp_map_build_succ.mp3',
        lebal: 'LPNP Mapping Succuss',
    },
    {
        name: 'lpnp_map_build_fail.mp3',
        lebal: 'Route Larning Failed',
    },
    {
        name: 'lpnp_exit.mp3',
        lebal: 'L P N P Exited',
    },
    {
        name: 'lpnp_parking_succ.mp3',
        lebal: 'L P N P Completed',
    },
    {
        name: 'lpnp_parking_fail.mp3',
        lebal: 'L P N P Failed',
    },
    {
        name: 'lpnp_approaching_destination.mp3',
        lebal: '即将到达您的车位',
    },
    {
        name: 'lpnp_cruising_turn_left.mp3',
        lebal: '前方即将左转',
    },
    {
        name: 'lpnp_cruising_turn_right.mp3',
        lebal: '前方即将右转',
    },
    {
        name: 'lpnp_space_occupied_unable_continue.mp3',
        lebal: '目标车位被占，无法继续泊车',
    },
    {
        name: 'lpnp_start_apa_selecting.mp3',
        lebal: '请选择车位后点击开始泊车',
    },
    {
        name: 'lpnp_start_apa_parking_2stips.mp3',
        lebal: '请您松开刹车',
    },
    {
        name: 'lpnp_start_apa_parking.mp3',
        lebal: '即将进入车位',
    },
    {
        name: 'lpnp_apa_parking_succ.mp3',
        lebal: '泊车完成',
    },
    {
        name: 'lpnp_apa_parking_fail.mp3',
        lebal: '泊车失败',
    },
];

export default function Native() {
    const isHotAll = useSelector((state) => state.hotArea.isHotAll);
    const handleClick = (data) => {
        console.log(data);
        playTTSAudio(data);
    };

    const handleAudioClick = (data) => {
        console.log('handleAudioClick', data);
        playLocalAudio(data);
    };
    // return (
    //     <div className={style.wrapper}>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('方向盘转角异常')}
    //         >
    //             方向盘转角异常
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('超出限速')}
    //         >
    //             超出限速
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('内部节点异常')}
    //         >
    //             内部节点异常
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('导航异常')}
    //         >
    //             导航异常
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('到达终点')}
    //         >
    //             到达终点
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('定位状态异常')}
    //         >
    //             定位状态异常
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('路线不可用')}
    //         >
    //             路线不可用
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('超出地图范围')}
    //         >
    //             超出地图范围
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('车道异常宽度')}
    //         >
    //             车道异常宽度
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('车身压线')}
    //         >
    //             车身压线
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('异常车道')}
    //         >
    //             异常车道
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('车道参考线消失')}
    //         >
    //             车道参考线消失
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('双侧车道线消失')}
    //         >
    //             双侧车道线消失
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('相对度超限')}
    //         >
    //             相对度超限
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('检测到刹车')}
    //         >
    //             检测到刹车
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('检测到转向')}
    //         >
    //             检测到转向
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('系统异常')}
    //         >
    //             系统异常
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('检测到踩油门时间过长')}
    //         >
    //             检测到踩油门时间过长
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('检测到长时间未手握方向盘')}
    //         >
    //             检测到长时间未手握方向盘
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('检测到前车横向偏移过大')}
    //         >
    //             检测到前车横向偏移过大
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('按键退出')}
    //         >
    //             按键退出
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('1000米后有闸机，H N P将退出')}
    //         >
    //             闸机-1000
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('200米后有闸机，准备接管')}
    //         >
    //             闸机-200
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('1000米后收费站，H N P将退出')}
    //         >
    //             收费站-1000
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('200米后有收费站，可以接管')}
    //         >
    //             收费站-200
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('1000米后出O D D，准备接管')}
    //         >
    //             odd-1000
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('200米后有闸机，准备接管')}
    //         >
    //             odd-200
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('1000米后有道路施工，H N P将退出')}
    //         >
    //             道路施工-1000
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('200米后有道路施工，准备接管')}
    //         >
    //             道路施工-200
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('1000米后将退出，准备接管')}
    //         >
    //             终点1000
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('200米后将到达，准备接管')}
    //         >
    //             终点-200
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('前方2000米进匝道')}
    //         >
    //             匝道-2000
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('前方1000米进匝道')}
    //         >
    //             匝道-1000
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('前方500米进匝道')}
    //         >
    //             匝道-500
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('前方200米下匝道，请尽快变道')}
    //         >
    //             匝道-200
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('即将进入匝道')}
    //         >
    //             匝道-0
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('即将汇入主路')}
    //         >
    //             即将汇入主路
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('降级到C P')}
    //         >
    //             降级到CP
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('降级到A C C')}
    //         >
    //             降级到ACC
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('已激活')}
    //         >
    //             智驾激活提示
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('H N P已退出')}
    //         >
    //             智驾退出警示
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('自主变道')}
    //         >
    //             自主变道
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('导航变道')}
    //         >
    //             导航变道
    //         </span>
    //         <span
    //             className={style.button}
    //             onClick={()=> handleClick('H N P已退出')}
    //         >
    //             HNP已退出
    //         </span>
    //     </div>
    // )
    useEffect(() => {
        store.dispatch({
            type: HOT_AREA_TYPE.isHotAll,
            data: true,
        });
    }, []);

    useEffect(() => {
        const HotArea = [];
        AppFn.setHotArea(HotArea, isHotAll);
    }, [isHotAll]);

    return (
        <div className={style.wrapper}>
            {AudioMap.map((item, index) => {
                return (
                    <span key={index} className={style.button} onClick={() => handleAudioClick(item?.name)}>
                        {item.lebal}
                    </span>
                );
            })}
        </div>
    );
}
