import React, {useEffect} from "react";
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import localAudioNames from '@/constant/localAudioName';
import {AppFn} from '@/utils/native';
import hardDiskWarn from "@/assets/hnp/hardDiskWarn.png";
import {widgetPush, widgetType} from '@/utils/widgetPush';
import {batchReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import './index.less';

let timer = null;   // 定时器句柄
export default function HardDisk() {
    // widget 展示
    const fleet_detail_status = useSelector(state => state?.featureModelDriving?.safetyDiagnose?.hardDisk?.fleet?.[0]?.["detailStatus"]);
    // notice_list 语音播放
    const hard_disk_not_in_place_notice_level = useSelector(state => state?.featureModelDriving?.safetyDiagnose?.notice?.level);

    const {t: translate} = useTranslation();

    // useEffect(() => {
    //     if (!hard_disk_not_in_place_notice_level) return
    //     AppFn.playLocalAudio(localAudioNames.DEMO_DATA_NOTINSERTED, 'Highest');
    // }, [hard_disk_not_in_place_notice_level])

    const showWidget = () => {
        if (fleet_detail_status) {
            let contentText = `
                <div class="hard_disk_wrap">
                  <img class="img_wrap"  src=${hardDiskWarn} alt="hardDisk"/>
                </div>
            `
            let content = {"content": contentText}
            widgetPush({widgetNumber: 3, type: widgetType.HARDDISK, widgetContent: content});
            // 播放数采盘未插好语音
            AppFn.playLocalAudio(localAudioNames.DEMO_DATA_NOTINSERTED, 'Highest');
            reportDataFunc("disk_notice_warning");
        } else {
            widgetPush({widgetNumber: 3, type: widgetType.HARDDISK, widgetContent: null});
            reportDataFunc("diskNotDisplay");
        }
    }

    useEffect(() => {
        clearTimeout(timer)
        if (fleet_detail_status) {
            timer = setTimeout(() => {
                showWidget()
            }, 30 * 1000)
        } else {
            widgetPush({widgetNumber: 3, type: widgetType.HARDDISK, widgetContent: null});
            reportDataFunc("diskNotDisplay");
        }
    }, [fleet_detail_status])

    // 数据上报
    const reportDataFunc = (value) =>{
        batchReport({
            eventName: reportEventName.HMI_DISKNOTICE_VIEW,
            timestamp: new Date().getTime(),
            text: value,
        })
    }

    return (
        <></>
    )
}
