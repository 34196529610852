import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppFn } from '@/utils/native';

export default function useSetSRMode() {
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const apaStatus = useSelector((state) => state?.featureModelParking?.apa?.state);
    const userMode = useSelector((state) => state?.parking?.userMode);

    const setAPASRMode = () => {
        // 研发模式不用设置
        if (userMode === 'D') return;
        // TODO: 独立APA C版本设置
    };

    const setLPNPSRMode = (t) => {
        // 研发模式不用设置
        if (userMode === 'D') return;
        // LPNP设置
        switch (t) {
            case 'LEARNING':
            case 'MAP_BEING_VERIFIED':
                AppFn.learnParking({ action: 'start' });
                break;
            case 'APA_PARKING':
            case 'APA_SELECTING':
            case 'APA_PARKING_PAUSE':
            case 'PARKING':
                AppFn.learnParking({ action: 'apa' });
                break;
            case 'CRUISING':
                AppFn.learnParking({ action: 'cruise' });
                break;
            default:
                AppFn.learnParking({ action: 'end' });
                break;
        }
    };

    useEffect(() => {
        setAPASRMode(apaStatus);
        setLPNPSRMode(status);
    }, [status, apaStatus, userMode]);
}
