import {useEffect, useState} from "react";
import { useSelector } from 'react-redux';
import {Switch} from 'antd-mobile';
import {SocketMap} from '@/constant/socketMap';
import FM from "@/utils/nativeSubAndPub";
import {useTranslation} from 'react-i18next';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

let timer = null;
export default function AccCpTakeoverSwitch() {
    // OPEN/CLOSE
    const acc_cp_switch = useSelector(state => state?.featureModelDriving?.switch?.driving?.collision_alert_control?.switch);
    const [accCpSwitch, setAccCpSwitch] = useState(acc_cp_switch === "OPEN");
    const {t: translate} = useTranslation();

    useEffect(() => {
        acc_cp_switch && setAccCpSwitch(acc_cp_switch === "OPEN");
        if (timer) {
            clearTimeout(timer)
            timer = null
        }
    }, [acc_cp_switch])

    const switchChange = async (v) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            setAccCpSwitch(acc_cp_switch === "OPEN")
        }, 2 * 1000)
        reportDataFunc(v ? "OPEN" : "CLOSE");
        await accCpSwitchControl(v)
    };

    // 通知底层
    const accCpSwitchControl = async (value) => {
        FM.sendToHMIDriving(SocketMap.collision_alert_control, {
            switch: value ? 'OPEN' : 'CLOSE',
        },'drive_node_res_fail_cb');
    }

    // 数据上报
    const reportDataFunc = (v) => {
        singelPointReport({
            eventName: reportEventName.HMI_ACC_CP_TAKEOVER_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                accCpTakeoverSwitch: v
            },
        })
    }

    return (
        <div className='select-group-item'>
            <div className='select-group-switch'>
                <Switch
                    checked={accCpSwitch}
                    onChange={(checked) => switchChange(checked)}
                    className='custom-switch'
                />
                <span className='switch-name'>{translate('settings.CpCollisionAlert')}</span>
            </div>
        </div>
    )
}

