const reportEventName = {
    // app初始化
    HMI_APP_INIT_VIEW: 'HMI_app_init_view',
    // 智驾开关事件名称
    HMI_POINT_SETTINGON_CLICK: 'HMI_pilot_settingon_click',
    // 起点导航
    HMI_TOROUTE_NAVI_CLICK: 'HMI_toroute_navi_click',
    // 变道状态
    HMI_LANE_CHANGE_VIEW: 'HMI_lane_change_view',
    // 分汇流打灯
    HMI_MERGE_SIGNAL_VIEW: 'HMI_merge_signal_view',
    // 分汇流  新增：20230116 trigger_reason_v3
    TURN_LIGHT_LANE_MERGE_SPLIT: 'HMI_light_lane_merge_split',
    // 匝道  新增：20230116 trigger_reason_v3
    TURN_LIGHT_ENTER_RAMP: 'HMI_light_enter_ramp',
    // 智驾监控
    HMI_NOTICE_VIEW: 'HMI_notice_view',
    // 智驾监控状态
    HMI_ICON_AUTO_STATUS_VIEW: 'HMI_Icon_autoStatus_view',
    // 路线应用成功
    HMI_HOME_ROUTEAPPLIED_CLICK: 'HMI_home_routeApplied_click',
    // 路测小助手点击
    HMI_HOME_CHECKCLINIC_CLICK: 'HMI_home_checkClinic_click',
    // 接管进自动提醒开关
    HMI_HOME_15SNOTICE_CLICK: 'HMI_home_15sNotice_click',
    // 取消算路
    HMI_HOME_UNROUTE_CLICK: 'HMI_home_unRoute_click',
    // Mviz刷新次数
    HMI_HOME_MVIZREFRESH_CLICK: 'HMI_home_mvizRefresh_click',
    // 用户模式开关
    HMI_SETTING_USERMODEL_CLICK: 'HMI_setting_userModel_click',
    // 布局切换
    HMI_SETTING_LAYOUTSWITCH_CLICK: 'HMI_setting_layoutSwitch_click',
    // APP热启动
    HMI_APP_RELIVE_VIEW: 'HMI_app_relive_view',
    // App连接心跳点
    HMI_APP_ALIVE_VIEW: 'HMI_app_alive_view',
    // 匝道语音播报
    HMI_RAMP_VIEW: 'HMI_ramp_view',
    // ODD播报语音播报时
    HMI_ODD_VIEW: 'HMI_ODD_view',
    // 匝道持续时长
    HMI_RAMP_ACTION: 'HMI_ramp_action',
    // ODD持续时长
    HMI_ODD_ACTION: 'HMI_odd_action',
    // 智驾状态间隔
    HMI_AD_STATUS_DURATION: 'HMI_AD_status_duration',
    // 路线类
    HMP_ROUTE_INFO: 'HMI_route_info',
    // 降级，退自动
    HMI_DOWNGRAD_VIEW: 'HMI_downgrad_view',
    // 降级至ACC
    HMI_LOWERTOACC_VIEW: 'HMI_lowerToAcc_view',
    // 升级
    HMI_UPGRADE_VIEW: 'HMI_upgrade_view',
    // 智驾进入退出开关点击
    HMI_ACTIVATE_CLICK: 'HMI_activate_click',
    // 跟车时距
    HMI_CARGAP_CLICK: 'HMI_cargap_click',
    // 智驾模式开关
    HMI_AD_MODE_CLICK: 'HMI_AD_mode_click',
    // CP主动变道开关
    HMI_SPEED_lANECHANGE_CLICK: 'HMI_speed_lanechange_click',
    // CP驾驶风格
    HMI_DREIVING_STYLE_CLICK: 'HMI_driving_style_click',
    // CP变道风格
    HMI_LANECHANGE_STYLE_CLICK: 'HMI_lanechange_style_click',
    // 转向灯回正语音提醒播报
    HMI_SIGNAL_TURN_OFF_AUDIO_VIEW: 'HMI_signal_turn_off_audio_view',
    // TFL开关
    HMI_TFL_SWITCH_CLICK: 'HMI_tfl_switch_click',
    // CpWarning开关
    HMI_CPWARNING_SWITCH_CLICK: 'HMI_cpwarning_switch_click',
    // ACC、CP碰撞风险提示按钮
    HMI_ACC_CP_TAKEOVER_CLICK: 'HMI_acc_cp_takeover_click',
    // 确认式变道开关
    HMI_LANE_CONFIRM_CLICK: 'HMI_lane_confirm_click',
    // 1. AEB 开关
    HMI_AEB_SWITCH_CLICK: 'HMI_AEB_switch_click',
    // 2. FCW  开关
    HMI_FCW_SWITCH_CLICK: 'HMI_FCW_switch_click',
    // 3. FCW灵敏度 开关
    HMI_FCW_SENSITIVITY_CLICK: 'HMI_FCW_sensitivity_click',
    // 4. RCW 开关
    HMI_RCW_SWITCH_CLICK: 'HMI_RCW_switch_click',
    // 5. BSD/LCA 开关
    HMI_BSD_SWITCH_CLICK: 'HMI_BSD_switch_click',
    // 6. DOW 开关
    HMI_DOW_SWITCH_CLICK: 'HMI_DOW_switch_click',
    // 7. TLA 开关
    HMI_TLA_SWITCH_CLICK: 'HMI_TLA_switch_click',
    // 8. SLIF 开关
    HMI_SLIF_SWITCH_CLICK: 'HMI_SLIF_switch_click',
    // 9. LDW 开关
    HMI_LDW_SWITCH_CLICK: 'HMI_LDW_switch_click',
    // 10. LDP 开关
    HMI_LDP_SWITCH_CLICK: 'HMI_LDP_switch_click',
    // 11. LDW Sensitivity 开关
    HMI_LDW_SENSITIVITY_CLICK: 'HMI_LDW_sensitivity_click',
    // 14. LDW Warning Type 开关
    HMI_LDW_WARNINF_TYPE_CLICK: 'HMI_LDW_Warning_Type_click',
    // 15. ELK 开关
    HMI_ELK_SWITCH_CLICK: 'HMI_ELK_switch_click',
    // AEB Warning
    HMI_AEB_VIEW: 'HMI_AEB_view',
    // FCW Warning
    HMI_FCW_VIEW: 'HMI_FCW_view',
    // RCW Warning
    HMI_RCW_VIEW: 'HMI_RCW_view',
    // LDP Warning
    HMI_LDP_VIEW: 'HMI_LDP_view',
    // SLIF Warning
    HMI_SLIF_VIEW: 'HMI_SLIF_view',
    HMI_SLIF_duration: 'HMI_SLIF_duration',
    // TSR View
    HMI_TSR_VIEW: 'HMI_TSR_view',
    // 数采盘状态
    HMI_DATADISK_ACTION: 'HMI_datadisk_action',

    // 开始点对点导航搜索
    HMI_SEARCH_POI_CLICK: 'HMI_search_poi_click',
    // 使用点对点导航算路
    HMI_POI_NAVI_CLICK: 'HMI_poi_navi_click',
    // 点对点算路结果
    HMI_POI_CAL_VIEW: 'HMI_poi_cal_view',
    // 锥桶提醒
    HMI_BARRIER_VIEW: 'HMI_Barrier_view',
    // LPNP开关
    HMI_LPNP_SWITCH_CLICK: 'HMI_parking_settingon_click',
    // 数采盘未插好语音报警
    HMI_DISKNOTICE_VIEW: 'HMI_disknotice_view',
    // CSW开关点击事件
    HMI_CSW_SWITCH_CLICK: 'HMI_CSW_switch_click',
    // CSW警报icon提示
    HMI_CSW_VIEW: 'HMI_CSW_view',
    // ADAS ESS 开关点击事件
    HMI_ESS_SWITCH_CLICK: 'HMI_ESS_switch_click ',
    // ADAS ESS Icon提示
    HMI_ESS_VIEW: 'HMI_ESS_view',
    // ADAS IHC 开关点击事件
    HMI_IHC_SWITCH_CLICK: 'HMI_IHC_switch_click ',
    // ADAS IHC Icon提示
    HMI_IHC_VIEW: 'HMI_IHC_view',
    // 用户长按Set +键持续2s，将巡航车速设定值提高至道路限速至
    HMI_INCREASE_TO_SPEED_LIMIT_CLICK: 'HMI_Increaseto_SpeedLimit_click',
    // 用户长按Set -键持续2s，将巡航车速设定值降低至道路限速至
    HMI_DECREASE_TO_SPEED_LIMIT_CLICK: 'HMI_Decreaseto_SpeedLimit_click',
    // 性能埋点
    HMI_PERFORMANCE_EVENT: 'HMI_performanceEvent',
    //MEB开关上报
    HMI_MEB_SWITCH_CLICK: 'HMI_MEB_switch_click',
    // AMAP开关上报
    HMI_AMAP_SWITCH_CLICK: 'HMI_AMAP_switch_click',
    // AMAP 警报触发上报
    HMI_AMAP_VIEW: 'HMI_AMAP_view',
    // HRP开关上报
    HMI_HRP_SWITCH_CLICK: 'HMI_HRP_switch_click',
    // HRP 警报触发上报
    HMI_HRP_VIEW: 'HMI_HRP_view',

    //parking进入按钮上报
    HMI_PARKING_ENTER_CLICK: 'HMI_enter_parking_click',
    //退出parking上报
    HMI_PARKING_EXIT_CLICK: 'HMI_exit_parking_click',
    //首次学习泊车路线
    HMI_LPNP_START_LEARNING_CLICK: 'HMI_lpnp_start_learning_click',
    //重新学习泊车路线
    HMI_LPNP_ROUTEUPDATE_CLICK: 'HMI_lpnp_routeUpdate_click',
    //重新学习路线确认
    HMI_LPNP_ROUTEUPDATE_CONFIRM_CLICK: 'HMI_lpnp_routeUpdate_Confirm_click',
    // 取消学习
    HMI_LPNP_CANCEL_LEARNING_CLICK: 'HMI_lpnp_learning_cancel_click',
    //启动APA
    HMI_LPNP_APA_START_CLICK: 'HMI_lpnp_apa_start_click',
    //开始LPNP
    HMI_LPNP_CRUISE_START_CLICK: 'HMI_lpnp_cruise_start_click',

    //[提示事件]MEB提示
    HMI_MEB_VIEW: 'HMI_MEB_view',
    //路线学习开始提示
    HMI_LPNP_START_LEARNING_VIEW: 'HMI_lpnp_start_learning_view',
    //即将进入车位
    HMI_LPNP_APA_START_VIEW: 'HMI_lpnp_apa_start_view',
    //泊车成功
    HMI_LPNP_APA_SUCC_VIEW: 'HMI_lpnp_apa_succ_view',
    //泊车失败
    HMI_LPNP_APA_FAIL_VIEW: 'HMI_lpnp_apa_fail_view',

    // 路线学习成功
    HMI_LPNP_MAP_BUILD__SUCC_VIEW: 'HMI_lpnp_map_build_succ_view',
    // 路线学习失败
    HMI_LPNP_MAP_BUILD_FAIL_VIEW: 'HMI_lpnp_map_build_fail_view',

    //APA状态 提示
    HMI_LPNP_APA_STATUS_VIEW: 'HMI_lpnp_apa_status_view',
    //路线学习中，经过减少带提醒
    HMI_LPNP_BUMP_VIEW: 'HMI_lpnp_bump_view',
    //LPNP智能巡航状态 提示
    HMI_LPNP_STATUS_VIEW: 'HMI_lpnp_status_view',
    //LPNP智能巡航启动 提示
    HMI_LPNP_START_CRUISE_VIEW: 'HMI_lpnp_start_cruise_view',
    //LPNP智能巡航中
    HMI_BRAKE_RELEASE_VIEW: 'HMI_brake_release_view',
    //LPNP巡航通过弯道提醒
    HMI_LPNP_TURNING_VIEW: 'HMI_lpnp_turning_view',
    //即将到达目标车位提醒
    HMI_LPNP_APP_DES_VIEW: 'HMI_lpnp_approaching_destination_view',
    //目标车位被占提示
    HMI_LPNP_SPACE_OCCUPIED_VIEW: 'HMI_lpnp_space_occupied_view',
    //智能泊入成功 提示
    HMI_LPNP_PARKING_SUCC_VIEW: 'HMI_lpnp_parking_succ_view',
    //智能泊入失败 提示
    HMI_LPNP_PARKING_FAIL_VIEW: 'HMI_lpnp_parking_fail_view',
    //智能巡航退出 提示
    HMI_LPNP_EXITED_VIEW: 'HMI_lpnp_exited_view',
    // TOR提醒 HandsOn提醒
    HMI_HANDSON_TOR_VIEW: 'HMI_HandsOn_TOR_view',
    // MRC提醒
    HMI_MRC_VIEW: 'HMI_MRC_view',
    // 点击高德"平行路"Button
    HMI_HOME_PARALLELROAD_CLICK: 'HMI_home_parallelRoad_click',
    // 点击高德"回到自身"Button
    HMI_HOME_SELFCAR_CLICK: 'HMI_home_selfCar_click',
    // 点击高德"车头向南"Button
    HMI_HOME_CARMODE_CLICK: 'HMI_home_carMode_click',
    // 点击高德"路径全览"Button
    HMI_HOME_OVERVIEW_CLICK: 'HMI_home_overview_click',
    // 点对点导航重新算路
    HMI_HOME_RECALCULATE_ROUTE: 'HMI_home_recalculate_route',
    // CP推荐变道语音
    HMI_SUGGESTED_LANECHANGE_VIEW: 'HMI_suggested_lanechange_view',
    // 智驾声音设置
    HMI_AD_ALERT_CLICK: 'HMI_AD_alert_click',

    // LPNP推荐使用提醒
    HMI_USE_LPNP_VIEW: 'HMI_use_lpnp_view',
    // LPNP推荐使用, 用户点击【是】或【否】
    HMI_USE_LPNP_CONFIRM_CLICK: 'HMI_use_lpnp_confirm_click',
    // 切换C版/D版 模式
    HMI_customer_develop_click: 'HMI_customer_develop_click',
    // APA开关切换
    HMI_parking_settingon_click: 'HMI_parking_settingon_click',
    // 路线学习失败
    HMI_lpnp_map_build_fail_view: 'HMI_lpnp_map_build_fail_view',
};

export default reportEventName;
