import React, {useEffect, useState} from 'react';
import './index.less';

let timer = null

export default function SystemTime() {
    const [currentTime, setCurrentTime] = useState("")

    const getTime = () => {
        const dt = new Date();
        let h = dt.getHours(); //获取时
        let m = dt.getMinutes(); //获取分
        h = h < 10 ? ("0" + h) : h
        m = m < 10 ? ("0" + m) : m
        const current = `${h}:${m}`
        setCurrentTime(current)
    }

    useEffect(() => {
        clearInterval(timer)
        timer = null
        getTime()
        timer = setInterval(() => {
            getTime()
        }, 1000)
    }, [])

    useEffect(() => {
        return () => {
            clearInterval(timer)
            timer = null
        }
    }, [])

    return (
        <div className={"system_time_wrap"}>{currentTime}</div>
    )
}
