import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { useSelector, useDispatch } from 'react-redux';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import mtour from '@/assets/settingComNew/otherSettings/mtour.png';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import localCache from '@/utils/localCache';

export default function MtourMode() {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    const switchUnpState = useSelector((state) => state?.switchModeAction?.switchUnpState);
    // const [unpSwitch, setUnpSwitch] = useState(JSON.parse(localStorage.getItem('unp_switch')) || false);

    //切换UNP开关
    const switchUnp = (value) => {
        // localStorage.setItem('unp_switch', value)
        localCache.set('unp_switch', value);
        dispatch({
            type: SWITH_MODE_ACTION.switchUnpState,
            data: value,
        });
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>Mtour</div>
                </div>
                <Switch
                    checked={switchUnpState}
                    onChange={(checked) => switchUnp(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = (
        <div className={'right_dom_wrap'}>
            <img src={mtour} />
        </div>
    );

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} />
        </>
    );
}
