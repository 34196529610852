import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//这是一个 i18next 语言检测插件，用于检测浏览器中的用户语言，
//详情请访问：https://github.com/i18next/i18next-browser-languageDetector
// import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import zh from './zh.json';
import localCache from '@/utils/localCache';

// app国际版本才会将语音类别放到ua中
const ua = window.navigator.userAgent;
const reg = /language\(([^\)]+)\)/;
let languageType = reg.exec(ua);

// 默认中文
if (languageType) {
    languageType = reg.exec(ua)[1];
} else {
    languageType = 'zh-CN';
    // languageType = 'en-US';
}

// 存储到local中
// window.localStorage.setItem('i18nLang', languageType);
localCache.set('i18nLang', languageType);

const resources = {
    'zh-CN': {
        'zh-CN': {
            translation: zh,
        },
    },
    'en-US': {
        'en-US': {
            translation: en,
        },
    },
};

i18n.use(initReactI18next).init({
    // 初始化
    resources: resources[languageType], // 本地多语言数据
    fallbackLng: languageType,
    useSuspense: false,
});

export default i18n;
