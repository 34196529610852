import React from 'react';
import { Routes, Route, HashRouter, Navigate } from 'react-router-dom';
import DrivingLayout from '../pages/drivingLayout';
import Native from '../pages/native';
import ParkingLayout from '../pages/parkingLayout/index';
import ApaDebug from '@/components/parkingContainer/debug/apa';
import isCN from '@/utils/isCN';

export default function Router() {
    return (
        <HashRouter>
            <React.StrictMode>
                <Routes>
                    <Route path="/home/index" element={<DrivingLayout />}></Route>
                    {isCN() && <Route path="/home/parking" element={<ParkingLayout />}></Route>}
                    <Route path="/home/debug" element={<ApaDebug />}></Route>
                    <Route path="native" element={<Native />}></Route>
                    <Route path="*" element={<Navigate to="/home/index" />}></Route>
                </Routes>
            </React.StrictMode>
        </HashRouter>
    );
}
