import store from '@/store';

/**
 * @description 判断是否parking场景，外层调用知道场景时传入参数减少重复获取store状态
 * @param {string | null} current_scene 当前场景，没有传自动从store获取。
 * @returns {boolean}
 */
export default function isParking(current_scene = null) {
    // 外部没有传入自动获取
    if (current_scene === null) {
        const state = store?.getState();
        current_scene = state?.scene?.current_scene;
    }
    // 本地开发没有联车时通过hash判断
    if (!current_scene && process.env.NODE_ENV === 'development') {
        return window.location.hash === '#/home/parking';
    }
    return current_scene === 'parking';
}
