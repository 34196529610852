import {
    PARKING_DATA,
    PARKING_NOTICE,
    PARKING_LPNP_EXIT,
    MVIZ_APA_PARKING_DATA,
    PARKING_SWITCH,
    PARKING_WIGET,
    USER_MODE,
} from '../action/parkingType';

const INIT_STATE = {
    currentScene: 'SR',
    isShowGuide: false, // 是否展示学习引导页
    parkingData: null,
    parkingNotice: null,
    parkingSwitch: null,
    parkingWiget: null,
    lpnpExit: false,
    mvizParkData: null,
    isShowDashboard: null,
    SetTab: '',
    userMode: 'D',
};

export default function parking(state = INIT_STATE, action) {
    switch (action.type) {
        case PARKING_DATA.parkingState:
            return { ...state, parkingData: action.data };
        case PARKING_NOTICE.parkingNotice:
            return { ...state, parkingNotice: action.data };
        case PARKING_LPNP_EXIT.lpnpExit:
            return { ...state, lpnpExit: action.data };
        case MVIZ_APA_PARKING_DATA.mvizData:
            return { ...state, mvizParkData: action.data };
        case PARKING_SWITCH.parkingSwitch:
            return { ...state, parkingSwitch: action.data };
        case PARKING_WIGET.parkingWiget:
            return { ...state, parkingWiget: action.data };
        case 'lpnpDashboard':
            return { ...state, isShowDashboard: action.data };
        case 'SetTab':
            return { ...state, SetTab: action.data };
        case 'ISSHOWGUIDE':
            return { ...state, isShowGuide: action.data };
        case USER_MODE:
            return { ...state, userMode: action.data };
        default:
            return state;
    }
}
