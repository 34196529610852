import { useSelector } from 'react-redux';
import MebWiget from './mebWiget';
import ADASModalComponent from '@/components/adasModal';
import { useAMAP, useNRP } from './hook';

export default function ADAS() {
    const lpnpMtour = useSelector((state) => state?.parking?.lpnpMtour);
    // 避障辅助驾驶[AMAP]
    useAMAP();
    // 窄路辅助[NRP]
    useNRP();

    return (
        <div>
            {!lpnpMtour && <MebWiget />}
            <ADASModalComponent />
        </div>
    );
}
