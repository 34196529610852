/**
 * 变道相关：
 * 错过最晚变道点
 * */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import localAudioNames from '@/constant/localAudioName';
import WidgetComponent from '@/components/widget/index';
import { widgetQueue, widgetType } from '@/utils/widgetQueue';
// import  {MathRoundFn} from '@/utils/common';
// import {AppFn} from '@/utils/native';
import { useTranslation } from 'react-i18next';
// const { playLocalAudio } = AppFn

export default function LatestLaneChangeComponet() {
    // let oldTimeStamp = useRef(0)  // 存储上次时间
    // let oldDistance = useRef(0)  // 存储上一次的值
    // widget展示的最晚变道点距离
    const widget_lastest_lane_change_distance = useSelector(
        (state) => state?.featureModelDriving?.latestLaneChange?.distance,
    );
    const hnp_ddld_switch = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.hnp_ddld_switch);
    const showTimer = useRef(null);
    const [count, setCount] = useState(0);
    // 国际化处理
    const { t: translate } = useTranslation();
    // 最晚变道点语音控制（有值就播放语音）
    // const audio_lastest_lane_change_distance = useSelector(state => state?.func?.currentState?.notice_list?.notice_latest_lane_change?.data?.distance);
    /**
     * 错过最晚变道点 M6.2逻辑修改，展示更换为widget，0-80一直显示直到信号消失，只播放一次语音
     * 0822改为0-200
     */
    // useEffect(() => {
    //     let contentText = '', localAudioFile = '', widget_lastest_lane_change_distance_fixed = '';
    //     if(widget_lastest_lane_change_distance > 0 && widget_lastest_lane_change_distance <= 200 ){
    //         widget_lastest_lane_change_distance_fixed = MathRoundFn(widget_lastest_lane_change_distance);
    //         const current = Number(Date.now())
    //         // 当偏航距离存在，且当前时间 - 上次的时间 < 1s时，不做任何处理(防抖)
    //         if (widget_lastest_lane_change_distance && current - oldTimeStamp.current < 1000) {
    //             widget_lastest_lane_change_distance_fixed = oldDistance.current
    //         } else {
    //             oldTimeStamp.current = current
    //             oldDistance.current = widget_lastest_lane_change_distance_fixed
    //         }
    //         contentText = {'icon': '', 'content': `${widget_lastest_lane_change_distance_fixed}米后将偏航，建议手动变道`};
    //     }
    //     else if (widget_lastest_lane_change_distance > 200) {
    //         return;
    //     }
    //     else {
    //         contentText = '';
    //     }
    //     widgetQueue({widgetNumber: 2, type: widgetType.YAW, widgetContent: contentText})

    //     // 语音由底层控制，收到就触发语音播报
    //     if (audio_lastest_lane_change_distance) {
    //         localAudioFile = localAudioNames.DEMO_DEVIATE_LANE_CHANGE_DISTANCE;
    //         playLocalAudio(localAudioFile, 'Highest');
    //     }

    // },[widget_lastest_lane_change_distance, audio_lastest_lane_change_distance]);

    /** 11.1新需求最晚变道点展示 */

    useEffect(() => {
        if (widget_lastest_lane_change_distance && !hnp_ddld_switch) {
            !showTimer.current &&
                (showTimer.current = setInterval(() => {
                    setCount((prev) => {
                        return prev + 1;
                    });
                }, 1000));
        } else {
            setCount(0);
            clearInterval(showTimer.current);
            showTimer.current = null;
            let contentText = '';
            widgetQueue({ widgetNumber: 1, type: widgetType.LAST_LANE_CHANGE, widgetContent: contentText });
        }
    }, [widget_lastest_lane_change_distance]);

    useEffect(() => {
        if (count === 3) {
            let widgetContent = translate('laneChange.lastLaneChangeContent');
            let contentText = { icon: 'last_lane_change', content: widgetContent };
            widgetQueue({ widgetNumber: 1, type: widgetType.LAST_LANE_CHANGE, widgetContent: contentText });
        }
    }, [count]);

    return <WidgetComponent />;
}
