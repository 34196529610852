import { MVIZ_URL, AudioLevel } from '../constant/config';
import store from '@/store';
import logSend from './aLog';
/**
 * 调用端的scheme协议，app实现拦截
 * @param {*} methodName
 * @param {*} data
 */
function commonScheme(methodName, data) {
    console.log('methodName, data', methodName, data);
    try {
        window.location.href = `jsbridge://${methodName}?${JSON.stringify(data)}`;
    } catch (err) {
        console.log('commonScheme', err);
    }
}

// 定义Bridge类型
// 1、AudioType->音频播放
const PlayAudio = 'MediaPlayBridge';
// 2、设置JS点击区域
const SetHotArea = 'GetJSClickArea';
// 3、设置刷新
const Refresh = 'RefreshBridge';

let playAudioLock = false;
let audioText = '';
let audioLevel = 0;
// 音频播放状态回调
window.playAudioCallback = function (v) {
    console.log('playAudioCallback', v);
    const { data } = v;
    logSend({
        action: 'playFinishCallback',
        data: v,
    });
    if (data === audioText) {
        playAudioLock = false;
    }
};

// 定义暴露使用的对象
export const AppFn = {
    /*setLocation: function (data) {
        window?.momentaJsBridge?.dispatch('GetLocation', null , JSON.stringify(data));
    },*/
    // 传给Native HNP是否可进
    setHnpState: function (data) {
        window?.momentaJsBridge?.dispatch('getHnpState', null, data);
    },
    // 传给Native 当前智驾状态
    getDrivingState: function (data) {
        window?.momentaJsBridge?.dispatch('getDrivingState', null, JSON.stringify(data));
    },
    // TTS For Parking语音播报
    playTTSForParkingAudio: function (data) {
        window?.momentaJsBridge?.dispatch('playOfflineTts', 'playAudioCallback', data);
    },
    // TTS语音播报
    playTTSAudio: function (data, level) {
        if ((playAudioLock && AudioLevel[level] < AudioLevel[audioLevel]) || (playAudioLock && data === audioText))
            return;
        playAudioLock = true;
        audioText = data;
        audioLevel = level;
        logSend({
            action: 'playTTSAudio',
            data: {
                audioText: data,
                audioLevel: level,
            },
        });
        window?.momentaJsBridge?.dispatch('playOfflineTts', 'playAudioCallback', data);
    },
    // 播放端上语音文件
    playLocalAudio: function (data, level, playType, type = 'simplex', loop = false, action = '') {
        console.log('playLocalAudio', data, level, playType);
        // 如果是混音，直接播放
        if (type === 'duplex') {
            window?.momentaJsBridge?.dispatch(
                'playLocalAudio',
                'playAudioCallback',
                JSON.stringify({ type, loop, action, data }),
            );
        }
        if (action === 'stop') {
            return;
        }
        // 智驾播报
        const switchDrivingBroadcast = store?.getState()?.switchModeAction?.switchDrivingBroadcast;
        // const lane_change_tip_switch = store?.getState()?.featureModelDriving?.switch?.driving?.lane_change?.lane_change_tip_switch;
        const lane_change_tip_switch = 'SOUND_EFFECTS';
        // 对于变道提示: laneChangePrompt === 'OFF' ---> 只播放音效，laneChangePrompt ==='ON' ----> 语音和音效均播放
        /*1、当变道提示为音效，且playType === voice时，不播放当前语音
        2、当智驾播报模式为简洁，且playType === voice时，不播放当前语音文件*/
        if (lane_change_tip_switch === 'SOUND_EFFECTS' && switchDrivingBroadcast === 'Alert' && playType === 'voice')
            return;
        if ((playAudioLock && AudioLevel[level] < AudioLevel[audioLevel]) || (playAudioLock && data === audioText))
            return;
        playAudioLock = true;
        audioText = data;
        audioLevel = level;
        logSend({
            action: 'playLocalAudio',
            data: {
                audioText: data,
                audioLevel: level,
            },
        });
        window?.momentaJsBridge?.dispatch('playLocalAudio', 'playAudioCallback', data);
    },
    // 音频播放
    playAudio: function (audio) {
        commonScheme(PlayAudio, {
            data: audio + '.wav',
        });
    },
    // 设置热区
    setHotArea: function (data, isAllHot) {
        if (isAllHot) {
            commonScheme(SetHotArea, {
                data: {
                    data: [],
                    isAllHot,
                },
            });
        } else {
            commonScheme(SetHotArea, {
                data: {
                    data,
                },
            });
        }
    },
    // 刷新容器
    refresh: function () {
        console.log('Refresh');
        commonScheme(Refresh, {
            data: {
                homeUrl: encodeURIComponent(window.location.href),
            },
        });
    },
    // 应用内打开设备默认浏览器
    openBrowser: function (data) {
        window?.momentaJsBridge?.dispatch('openBrowser', null, data);
    },

    // 获取设备是robocar还是devCar
    getCarType: function (data) {
        window?.momentaJsBridge?.dispatch('getCarType', 'setCarType', data);
    },

    //js按钮事件
    jsButtonCallBack: function (data) {
        window?.momentaJsBridge?.dispatch('jsButtonCallBack', null, data);
    },

    //获取默认导航设置
    getNaviSettings: function (callback) {
        window?.momentaJsBridge?.dispatch('getNaviSettings', callback, null);
    },

    // new 设置Mviz
    setMviz: function (data) {
        window.momentaJsBridge?.dispatch(
            'getMvizArea',
            null,
            JSON.stringify({
                ...data,
                mVizUrl: MVIZ_URL,
            }),
        );
    },
    // Mviz靠左靠右切换
    setChangeMviz: function (data) {
        window.momentaJsBridge?.dispatch('changeMvizLocation', null, data);
    },

    // 重启服务器/关机
    setServerSwitch: function (data) {
        console.log(data, '调取协议');
        window.momentaJsBridge?.dispatch('rebootOrShutdown', null, data);
    },

    // 页面状态上报
    webViewPageState: function (data) {
        window?.momentaJsBridge?.dispatch('webViewPageState', null, data);
    },

    // Js调用native上报告警埋点
    pushNoticeData: function (data) {
        window?.momentaJsBridge?.dispatch('pushNoticeData', null, data);
    },

    // 工作台录音
    recordAudioFile: function (callback, data) {
        window?.momentaJsBridge?.dispatch('recordAudioFile', callback, data);
    },
    //工作台播放
    wbPlayAudioCallback: function (callback, data) {
        window?.momentaJsBridge?.dispatch('playLocalAudio', callback, data);
    },

    /**
     * @description 切换C版/D版（sr/mviz）
     * @param {'sr' | 'mviz'} scenario 模式
     */
    switchUserMode(scenario, action) {
        if (!['sr', 'mviz'].includes(scenario)) {
            throw new Error('参数只能为sr或mviz');
        }
        if (scenario === 'mviz') {
            const params = {
                scenario,
            };
            return window?.momentaJsBridge?.dispatch('switchToScenario', null, JSON.stringify(params));
        }
        const params = {
            scenario,
            action,
        };
        window?.momentaJsBridge?.dispatch('switchToScenario', null, JSON.stringify(params));
    },

    learnParking(data) {
        let state = store.getState();
        let userMode = state?.parking?.userMode;
        if (userMode === 'D') return;
        window.momentaJsBridge?.dispatch('learnParking', null, JSON.stringify(data));
    },

    switchToScenario(mode) {
        let state = store.getState();
        let userMode = state?.parking?.userMode;
        console.log('switchToScenario', userMode, userMode === 'D');
        if (userMode === 'D') {
            window?.momentaJsBridge?.dispatch('switchToScenario', '', "{'scenario': 'mviz'}");
        } else {
            console.log('switchToScenario parmas', { scenario: 'sr', action: '${mode}' });
            window?.momentaJsBridge?.dispatch('switchToScenario', '', `{"scenario": "sr", "action": "${mode}"}`);
        }
    },
    showLPNP(data) {
        window.momentaJsBridge.dispatch('showLPNP', null, JSON.stringify(data));
    },
    // 传给Native HNP是否可进
    httpRquestApi: function (data) {
        window.momentaJsBridge.dispatch('callNetworkApi', 'httpRquestCallback', data);
    },
    // 网络请求
    callNetworkApi: function (callback, data) {
        window.momentaJsBridge?.dispatch('callNetworkApi', callback, data);
    },

    // 存储数据
    saveData: function (data) {
        window.momentaJsBridge?.dispatch('saveData', null, JSON.stringify({ ...data }));
    },

    // 删除数据
    removeData: function (data) {
        window.momentaJsBridge?.dispatch('removeData', null, data);
    },

    // 获取数据
    getData: function (callback, data) {
        window.momentaJsBridge?.dispatch('getData', callback, data);
    },
};
