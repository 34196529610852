import { useEffect, useState } from 'react';
import { getCookie } from '@/utils/tools';
import { useSelector } from 'react-redux';
import { getCarInfo } from '../api';
import { useIsParking } from '../hook';

export default function CarInfo() {
    const isParking = useIsParking();
    const box_id = useSelector((state) =>
        isParking ? state?.featureModelParking?.body?.boxId : state?.featureModelDriving?.baseData?.body?.boxid,
    );
    const ip = useSelector((state) =>
        isParking ? state?.featureModelParking?.body?.ip : state?.featureModelDriving?.baseData?.body?.ip,
    );
    const carType = useSelector((state) => state?.featureModelCommon?.carType) || 'Devcar';
    const [carPlate, setCarPlate] = useState('--');
    useEffect(async () => {
        if (!box_id) return;
        let params = {
            car_plate: box_id,
            page: 1,
            page_size: 10,
        };
        let res = await getCarInfo(params);
        if (res?.code == 200) {
            setCarPlate(res?.data?.vehicle_list[0]?.car_plate);
        }
    }, [box_id]);
    return (
        <div className="work-bench-base">
            <h4>车辆信息</h4>
            <div className="work-bench-base-content">
                <div className="work-bench-base-content-item">
                    <h5>车牌号</h5>
                    <p>{carPlate}</p>
                </div>
                <div className="work-bench-base-content-item">
                    <h5>车端HOST</h5>
                    <p>{box_id ? box_id : '--'}</p>
                </div>
                <div className="work-bench-base-content-item">
                    <h5>IP</h5>
                    <p>{carType === 'Devcar' && ip && ip !== 'Failed to get IP of vpn' ? ip : 'Searching IP...'}</p>
                </div>
            </div>
        </div>
    );
}
