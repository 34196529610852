import React, {useState, useEffect, useRef} from "react";
import {useSelector} from 'react-redux';
import {AppFn} from '@/utils/native';
import {cusToast} from '@/components/customToast';
import localAudioNames from '@/constant/localAudioName';
import {useTranslation} from 'react-i18next';
import {widgetQueue, widgetType} from '@/utils/widgetQueue';
import hnpErrorTip from '@/assets/hnp/hnp_error_tip.png';
import {MathRoundFn} from '@/utils/common';
import {batchReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import np_active from "@/assets/resume/np_active.png";
import  {useDebounceFn} from '@/utils/common';
import OddWidgetCom from './oddWidgetCom/index';
import './index.less';

const {playLocalAudio} = AppFn;

// odd类型
const noticeOddType = {
    // 0、50、100: 领航路段结束
    ODD_UNKNOWN: 'ODD_UNKNOWN',
    // 1: 道路施工
    ODD_ROAD_CONSTRUCTION: 'ODD_ROAD_CONSTRUCTION',
    // 2: 收费站
    ODD_TOLL: 'ODD_TOLL',
    // 3: 闸机
    ODD_GATE_MACHINE: 'ODD_GATE_MACHINE',
    // 4: 地图采集专用 1121新增
    ODD_MAP_COLLECT: 'ODD_MAP_COLLECT',
    // 5: 地图数据MPD问题 1121新增
    ODD_MAP_ERROR: 'ODD_MAP_ERROR',
    // 6: 非地图模块的其他模块问题 1121新增
    ODD_NONE_MAP_ERROR: 'ODD_NONE_MAP_ERROR',
    // 7: 动态事件 1121新增
    ODD_DYNAMIC_EVENT: 'ODD_DYNAMIC_EVENT',
    // 领航路段终点
    // ODD_DESTINATION: 'ODD_DESTINATION',
    // 进匝道（处理1000，500）
    ODD_RAMP_ENTER: 'ODD_RAMP_ENTER',
    // 出匝道
    ODD_RAMP_OUT: 'ODD_RAMP_OUT',
    // 101: SD未匹配的区域
    ODD_UNMATCH: 'ODD_UNMATCH',
    // 102: 路线终点
    ODD_TOUR_END: 'ODD_TOUR_END',
    // 103: 道路类型变化
    ODD_DDLD_ROAD_TYPE_CHANGE: 'ODD_DDLD_ROAD_TYPE_CHANGE',
}
// odd语音文件
// M9.1 1013 进出匝道不变，其余的只提示1000、200
const audioFileMap = {
    // 0、50、100: 领航路段结束
    ODD_UNKNOWN: {
        2000: '',
        // 1000: '1000米后到H N P运行终点，H N P将退出',
        1000: localAudioNames.DEMO_1000_EXIT_ODD,
        // 500: '500米后到H N P运行终点，H N P将退出',
        // 500: localAudioNames.DEMO_500_EXIT_ODD,
        500: '',
        // 200: '请提前接管，H N P要退出',
        200: localAudioNames.DEMO_200_EXIT_ODD,
        // 100: localAudioNames.DEMO_100_EXIT_ODD,
        // 0: localAudioNames.DEMO_0_EXIT_ODD
        0: ''
    },
    //  1: 道路变化
    ODD_ROAD_CONSTRUCTION: {
        2000: '',
        // 1000: '1000米后有道路施工，H N P将退出',
        1000: localAudioNames.DEMO_1000_ROAD_CONSTRUCTION,
        // 500: '500米后有道路施工，H N P将退出',
        // 500: localAudioNames.DEMO_500_ROAD_CONSTRUCTION,
        500: '',
        // 200: '请提前接管，H N P将退出',
        200: localAudioNames.DEMO_200_ROAD_CONSTRUCTION,
        // 100: localAudioNames.DEMO_100_ROAD_CONSTRUCTION,
        100: '',
        // 0: localAudioNames.DEMO_0_ROAD_CONSTRUCTION
        0: '',
    },
    // 2: 收费站
    ODD_TOLL: {
        2000: '',
        // 1000: '1000米后有收费站，H N P将退出',
        1000: localAudioNames.DEMO_1000_TOLL,
        // 500: '500米后有收费站，H N P将退出',
        // 500: localAudioNames.DEMO_500_TOLL,
        500: '',
        // 200: '请提前接管，H N P将退出',
        200: localAudioNames.DEMO_200_TOLL,
        // 100: localAudioNames.DEMO_100_TOLL,
        100: '',
        // 0: localAudioNames.DEMO_0_TOLL
        0: ''
    },
    // 3: 闸机
    ODD_GATE_MACHINE: {
        2000: '',
        // 1000: '1000米后有闸机，H N P将退出',
        1000: localAudioNames.DEMO_1000_GATE,
        // 500: '500米后有闸机，H N P将退出',
        // 500: localAudioNames.DEMO_500_GATE,
        500: '',
        // 200: '200米后有闸机，H N P将退出',
        200: localAudioNames.DEMO_200_GATE,
        // 100: localAudioNames.DEMO_100_GATE,
        100: '',
        // 0: localAudioNames.DEMO_0_GATE
        0: ''
    },
    // 4: 地图采集专用 1121新增
    ODD_MAP_COLLECT: {
        2000: '',
        1000: localAudioNames.DEMO_1000_EXIT_ODD, // 1000米后领航路段结束，NP将退出
        // 500: localAudioNames.DEMO_500_EXIT_ODD, // 500米后领航路段结束，NP将退出
        500: '', // 500米后领航路段结束，NP将退出
        200: localAudioNames.DEMO_200_EXIT_ODD, // 200米后领航路段结束，NP将退出
        // 100: localAudioNames.DEMO_100_EXIT_ODD, // 领航路段即将结束，NP将退出
        100: '', // 领航路段即将结束，NP将退出
        // 0: localAudioNames.DEMO_0_EXIT_ODD, // 领航路段结束，NP将退出
        0: '', // 领航路段结束，NP将退出
    },
    // 5: 地图数据MPD问题 1121新增
    ODD_MAP_ERROR: {
        2000: '',
        1000: localAudioNames.DEMO_1000_EXIT_ODD, // 1000米后领航路段结束，NP将退出
        // 500: localAudioNames.DEMO_500_EXIT_ODD, // 500米后领航路段结束，NP将退出
        500: '', // 500米后领航路段结束，NP将退出
        200: localAudioNames.DEMO_200_EXIT_ODD, // 200米后领航路段结束，NP将退出
        // 100: localAudioNames.DEMO_100_EXIT_ODD, // 领航路段即将结束，NP将退出
        100: '', // 领航路段即将结束，NP将退出
        // 0: localAudioNames.DEMO_0_EXIT_ODD, // 领航路段结束，NP将退出
        0: '', // 领航路段结束，NP将退出
    },
    // 6: 非地图模块的其他模块问题 1121新增
    ODD_NONE_MAP_ERROR: {
        2000: '',
        1000: localAudioNames.DEMO_1000_EXIT_ODD, // 1000米后领航路段结束，NP将退出
        // 500: localAudioNames.DEMO_500_EXIT_ODD, // 500米后领航路段结束，NP将退出
        500: '', // 500米后领航路段结束，NP将退出
        200: localAudioNames.DEMO_200_EXIT_ODD, // 200米后领航路段结束，NP将退出
        // 100: localAudioNames.DEMO_100_EXIT_ODD, // 领航路段即将结束，NP将退出
        100: '', // 领航路段即将结束，NP将退出
        // 0: localAudioNames.DEMO_0_EXIT_ODD, // 领航路段结束，NP将退出
        0: '', // 领航路段结束，NP将退出
    },
    // 7: 动态事件 1121新增
    ODD_DYNAMIC_EVENT: {
        2000: '',
        1000: localAudioNames.DEMO_1000_DYNAMIC_EVENT, // 前方有障碍物，1公里后NP将退出
        500: '',
        200: localAudioNames.DEMO_200_DYNAMIC_EVENT, // 接近障碍物，200米后NP将退出
        100: '',
        0: '',
    },
    //   终点
   /* ODD_DESTINATION: {
        2000: '',
        // 1000: '1000米后到H N P运行终点，H N P将退出',
        1000: localAudioNames.DEMO_1000_DESTINATION,
        // 500: '500米后到H N P运行终点，H N P将退出',
        500: localAudioNames.DEMO_500_DESTINATION,
        // 200: '请提前接管，H N P要退出',
        200: localAudioNames.DEMO_200_DESTINATION,
        100: localAudioNames.DEMO_100_DESTINATION,
        0: localAudioNames.DEMO_0_DESTINATION
    },*/
    //   匝道
    ODD_RAMP_ENTER: {
        // 2000: '还有2000米进匝道',
        2000: '',
        // 1000: '1000米后进匝道',
        1000: localAudioNames.DEMO_1000_RAMP,
        500: localAudioNames.DEMO_500_RAMP,
        200: '',
        // 0: '即将进入匝道，请注意路况',
        0: localAudioNames.DEMO_RAMP
    },
    //   匝道出口
    ODD_RAMP_OUT: {
        2000: '',
        1000: '',
        500: '',
        200: '',
        // 0: '即将汇入主路，请注意路况'
        0: localAudioNames.DEMO_RAMP_OUT
    },
    // 101: ODD_UNMATCH与SD未匹配的区域
    ODD_UNMATCH: {
        1000: localAudioNames.DEMO_1000_EXIT_ODD, // 1000米后领航路段结束，NP将退出
        // 500: localAudioNames.DEMO_500_EXIT_ODD, // 500米后领航路段结束，NP将退出
        500: '', // 500米后领航路段结束，NP将退出
        200: localAudioNames.DEMO_200_EXIT_ODD, // 200米后领航路段结束，NP将退出
        // 100: localAudioNames.DEMO_100_EXIT_ODD, // 领航路段即将结束，NP将退出
        100: '', // 领航路段即将结束，NP将退出
        // 0: localAudioNames.DEMO_0_EXIT_ODD, // 领航路段结束，NP将退出
        0: '', // 领航路段结束，NP将退出
    },
    // 102: 路线终点
    ODD_TOUR_END: {
        1000: localAudioNames.DEMO_1000_TOUR_END, // 1000米到达终点，NP将退出
        500: '', // 500米到达终点，NP将退出
        200: localAudioNames.DEMO_200_TOUR_END, // 200米到达终点，NP将退出
        100: '',
        0: ''
        // 100: localAudioNames.DEMO_100_DESTINATION, // 即将到达领航路段终点，NP将退出
        // 0: localAudioNames.DEMO_0_DESTINATION,   // 到达领航路段终点，NP将退出
    },
    // 103: 道路类型变化
    ODD_DDLD_ROAD_TYPE_CHANGE: {
        1000: localAudioNames.DEMO_1000_EXIT_ODD, // 1000米后领航路段结束，NP将退出
        // 500: localAudioNames.DEMO_500_EXIT_ODD, // 500米后领航路段结束，NP将退出
        500: '', // 500米后领航路段结束，NP将退出
        200: localAudioNames.DEMO_200_EXIT_ODD, // 200米后领航路段结束，NP将退出
        // 100: localAudioNames.DEMO_100_EXIT_ODD, // 领航路段即将结束，NP将退出
        100: '', // 领航路段即将结束，NP将退出
        // 0: localAudioNames.DEMO_0_EXIT_ODD, // 领航路段结束，NP将退出
        0: '', // 领航路段结束，NP将退出
    }
}

export default function OddComponent () {
    const hnp_state = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 正在运行状态
    const running_mode = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    // 智驾状态
    // const notice_driving_state = useSelector(state => state?.func?.drivingModeChange?.data?.state);
    // 智驾等级
    // const notice_driving_running_mode = useSelector(state => state?.func?.drivingModeChange?.data?.running_mode);
    // odd类型
    const notice_route_odd_type = useSelector(state => state?.featureModelDriving?.routeOdd?.notice?.data?.oddType);
    // odd距离
    const notice_route_odd_distance = useSelector(state => state?.featureModelDriving?.routeOdd?.notice?.data?.distance);
    // widget odd distance
    // const widget_route_info_odd_distance = useSelector(state => state?.func?.currentState?.widget?.route_info?.odd?.distance || state?.func?.currentState?.widget?.route_info?.ramp_info?.distance);
    // widget odd type
    // const widget_route_info_odd_type = useSelector(state => state?.func?.currentState?.widget?.route_info?.odd?.type || state?.func?.currentState?.widget?.route_info?.ramp_info?.type);
    // release_platform (MAIN HNP UNP CP)
    const release_platform = useSelector(state => state?.featureModelDriving?.baseData?.hmi?.release_platform);
    // unp抑制开关是否打开
    const switchUnpState = useSelector(state => state?.switchModeAction?.switchUnpState);
    // HNP路测采集开关是否打开
    const takeOverSwitch = useSelector(state => state?.warningAutomatic?.takeOverSwitchStatus);
    /*const [hnpTips, setHnpTips] = useState(false)*/
    const {t: translate} = useTranslation();

    /*const oddTextMap = {
        // 0、50、100: 领航路段结束
        ODD_UNKNOWN: translate("oddTextMap.exit_odd",{returnObjects: true }),
        // 1: 道路变化
        ODD_ROAD_CONSTRUCTION: translate("oddTextMap.road_construction",{returnObjects: true }),
        // 2: 收费站
        ODD_TOLL: translate("oddTextMap.toll_booth",{returnObjects: true }),
        // 3: 闸机
        ODD_GATE_MACHINE: translate("oddTextMap.gate",{returnObjects: true }),
        // 4: 地图采集专用 1121新增
        ODD_MAP_COLLECT: translate("oddTextMap.odd_un_match",{returnObjects: true }),
        // 5: 地图数据MPD问题 1121新增
        ODD_MAP_ERROR: translate("oddTextMap.odd_un_match",{returnObjects: true }),
        // 6: 非地图模块的其他模块问题 1121新增
        ODD_NONE_MAP_ERROR: translate("oddTextMap.odd_un_match",{returnObjects: true }),
        // 7: 动态事件 1121新增
        ODD_DYNAMIC_EVENT: translate("oddTextMap.odd_dynamic_event",{returnObjects: true }),
        //   终点
        // ODD_DESTINATION: translate("oddTextMap.destination",{returnObjects: true }),
        //   匝道
        ODD_RAMP_ENTER: translate("oddTextMap.ramp",{returnObjects: true }),
        //   匝道出口
        ODD_RAMP_OUT: translate("oddTextMap.ramp_out",{returnObjects: true }),
        // 101: ODD_UNMATCH 与SD未匹配的区域
        ODD_UNMATCH: translate("oddTextMap.odd_un_match",{returnObjects: true }),
        // 102: 路线终点
        ODD_TOUR_END: translate("oddTextMap.odd_tour_end",{returnObjects: true }),
    }*/

    let pre_route_odd_type = useRef(notice_route_odd_type)
    let pre_route_odd_distance = useRef(notice_route_odd_distance)
    let off_audio_2000 = useRef(true)
    let off_audio_1000 = useRef(true)
    let off_audio_500 = useRef(true)
    let off_audio_200 = useRef(true)
    // let off_audio_100 = useRef(true)
    // let off_audio_0 = useRef(true)
    const [oddReportList, setOddReportList] = useState([]);

    // 播语音上报
    const audioReport = (type, distance) => {
        let eventName = (type === noticeOddType.ODD_RAMP_ENTER) ? reportEventName.HMI_RAMP_VIEW : reportEventName.HMI_ODD_VIEW;
        batchReport({
            eventName,
            timestamp: Date.now(),
            reportData: {
                Type: type,
                Distance: distance
            }
        })
    }

    useEffect(() => {
        if (typeof(notice_route_odd_type) === 'undefined') {
            if (oddReportList.length !== 0) {
                let Type = oddReportList[0].type;
                let Duration = Number(((Date.now() - oddReportList[0].timestamp) / 1000).toFixed(1));
                batchReport({
                    eventName:
                        [
                            noticeOddType.ODD_RAMP_ENTER,
                            noticeOddType.ODD_RAMP_OUT
                        ].includes(Type)
                            ? reportEventName.HMI_RAMP_ACTION
                            : reportEventName.HMI_ODD_ACTION,
                    timestamp: new Date().getTime(),
                    reportData: {
                        Type,
                        Duration
                    }
                })
                setOddReportList([]);
            }
        }
        else {
            let oddList = [];
            oddList.push({
                type: notice_route_odd_type,
                timestamp: Date.now()
            })
            setOddReportList(oddList);
        }
    }, [notice_route_odd_type])

    useEffect(() => {
        let text = '', localAudio = '';
        if (typeof(notice_route_odd_type) === 'undefined' ||  !notice_route_odd_type) return
        // 1.9.0 unp抑制开关打开, 关闭匝道提醒
        if(switchUnpState && ([noticeOddType.ODD_RAMP_ENTER, noticeOddType.ODD_RAMP_OUT].includes(notice_route_odd_type))) return;
        /*1、HNP路测采集开关关闭：HNP、UNP、HNP_PLUS active的时候才播匝道语音
        2、HNP路测采集开关打开时：不论HNP还是CP都播匝道语音*/
        /**
         * 0907：和HMI_nonde确认：不确定是否返回HNP_PLUS
         * 前端加上比较保险
         */
        if((takeOverSwitch === 'OFF')
            && [noticeOddType.ODD_RAMP_ENTER, noticeOddType.ODD_RAMP_OUT].includes(notice_route_odd_type)
            && !((running_mode === 'HNP' || running_mode === 'UNP' || running_mode === 'HNP_PLUS') && hnp_state === 'ACTIVE')
        ) return;
        if (pre_route_odd_type.current !== notice_route_odd_type && pre_route_odd_distance.current !== notice_route_odd_distance) {
            // off_audio_0.current = true
            // off_audio_100.current = true
            off_audio_200.current = true
            off_audio_500.current = true
            off_audio_1000.current = true
            off_audio_2000.current = true
        }

        // 组装文案音频等数据
        const mapOddData = (oddDistance, type, unpState) => {
            if (!type || typeof(type) === 'undefined') return;
            if(oddDistance === 1000 && type === noticeOddType.ODD_RAMP_ENTER){
                localAudio = unpState ? '' : audioFileMap?.[type]?.[oddDistance];
            }
            else{
                localAudio = oddDistance ? audioFileMap?.[type]?.[oddDistance] : '';
            }
            // text = oddDistance ? oddTextMap?.[type]?.[oddDistance] : '';
        }

        if (notice_route_odd_distance >= 1600 &&  notice_route_odd_distance <= 2000 && off_audio_2000.current) {
            mapOddData(2000, notice_route_odd_type, switchUnpState)
            off_audio_2000.current = false
        } else if (notice_route_odd_distance > 950 && notice_route_odd_distance <= 1049 && off_audio_1000.current) {
            mapOddData(1000, notice_route_odd_type, switchUnpState)
            off_audio_1000.current = false
        } else if (notice_route_odd_distance > 450 && notice_route_odd_distance <= 549 && off_audio_500.current) {
            mapOddData(500, notice_route_odd_type, switchUnpState)
            off_audio_500.current = false
        } else if (notice_route_odd_distance > 150 && notice_route_odd_distance <= 249 && off_audio_200.current) {
            mapOddData(200, notice_route_odd_type, switchUnpState)
            off_audio_200.current = false
        }
        /*else if (notice_route_odd_distance >= 30 && notice_route_odd_distance <= 100 && off_audio_100.current) {
            // mapOddEvent(100)
            off_audio_100.current = false
        } else if (notice_route_odd_distance >= 0 && notice_route_odd_distance <= 20 && off_audio_0.current) {
            mapOddEvent(0)
            off_audio_0.current = false
        }*/

        if (localAudio !== '' && release_platform !== 'UNP') {
            playLocalAudio(localAudio, 'Normal');
            audioReport(notice_route_odd_type, notice_route_odd_distance);
        }
        /**
         * 改由app展示odd信息
         */
        // (text !== '' && typeof(text) !== 'undefined') && cusToast.show({
        //     content: text,
        //     duration: 2000,
        // });

        pre_route_odd_type.current = notice_route_odd_type
        pre_route_odd_distance.current = notice_route_odd_distance
    }, [ notice_route_odd_distance, notice_route_odd_type, hnp_state, running_mode, switchUnpState, release_platform]);

    // odd widget
    /*useEffect(() => {
        /!**
         * 0906
         *  if (running_mode === 'HNP' && hnp_state === 'ACTIVE')
         *  HMI_node只在HNP ACTIVE下发route_info，前端不需做HNP ACTIVE的判断、只需做距离控制
         *!/
        let data = {}
        if (widget_route_info_odd_distance > 200) return
        /!**
         * 0901产品需求下掉弹窗
         *!/
        // if (widget_route_info_odd_distance <= 100 && widget_route_info_odd_distance > 0) {
        //     setHnpTips(true)
        // } else {
        //     setHnpTips(false)
        // }
        if (widget_route_info_odd_distance <= 200 && widget_route_info_odd_distance > 0) {
            data.icon = 'odd_hnp_to_manual'
            data.content = `${translate("oddTextMap.odd_taker_over")}`
        } else {
            data = null;
        }
        widgetQueue({widgetNumber: 2, type: widgetType.ODD, widgetContent: data})
    }, [ widget_route_info_odd_distance, widget_route_info_odd_type ])*/

    // 因为ODD导致退自动特殊处理
    /**
     * 9.1需求增加为code处理升降级
     */
    // useEffect(() => {
    //     if ((notice_driving_state === 'UPPER' || notice_driving_state === 'ACTIVE') && notice_driving_running_mode === 'HNP') {
    //         // let oddText = '';
    //         let oddLocalAudio = '';
    //         // 未返回type不再执行
    //         if (!notice_driving_odd_type || typeof(notice_driving_odd_type) === 'undefined') return;
    //         // 100米距离特殊处理
    //         if (notice_driving_odd_distance >= 0 && notice_driving_odd_distance < 180) {
    //             oddLocalAudio = audioFileMap[notice_driving_odd_type][100];
    //             // oddText = oddTextMap[notice_driving_odd_type][100];
    //             oddLocalAudio !== '' && release_platform !== 'UNP' && setTimeout(() => {
    //                 playLocalAudio(oddLocalAudio, 'Normal');
    //                 audioReport(notice_driving_odd_type, notice_route_odd_distance);
    //             }, 2000)
    //             /**
    //              * 改由app展示odd信息
    //              */
    //             // oddText !== '' && typeof(oddText) !== 'undefined' && cusToast.show({
    //             //     content: oddText,
    //             //     duration: 2000,
    //             // });
    //         }
    //     }
    // }, [notice_driving_running_mode, notice_driving_state, notice_driving_odd_distance, notice_driving_odd_type, release_platform])

    return (
        <React.Fragment>
            {/*{hnpTips && <div className="odd_component_wrapper">
                <div className='tipsItem'>
                    <img src={ hnpErrorTip }></img>
                    <p>{translate("oddOverTips")}</p>
                </div>
            </div>}*/}

            {/*odd widget component*/}
            <OddWidgetCom></OddWidgetCom>
        </React.Fragment>
    )
}
