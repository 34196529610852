import {useSelector} from 'react-redux';
import React, {useEffect, memo} from 'react';
import SLOW from "@/assets/hnp/adas/slow.png";
import './index.less';
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

function CswShow() {
    // true/false
    const csw_notice = useSelector(state => state?.featureModelDriving?.csw?.notice?.data?.cswWarning);

    // 数据上报
    const reportDataFunc = (v) => {
        singelPointReport({
            eventName: reportEventName.HMI_CSW_VIEW,
            timestamp: new Date().getTime(),
            text: v,
        })
    }

    useEffect(() => {
        if (csw_notice) {
            reportDataFunc("true")
        }
    }, [csw_notice])

    return (
        <React.Fragment>
            {
                csw_notice &&
                <div className={"csw_wrap"}>
                    <img src={SLOW} alt="SLOW"/>
                </div>
            }
        </React.Fragment>
    )
}
export default memo(CswShow);
