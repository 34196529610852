import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { cusToast } from '@/components/customToast';
import { HOT_AREA_TYPE } from '@/store/action/type';
import store from '@/store/index';
import Modal from '../../../globalComponent/modal';
import Loading from '../../../globalComponent/loading';

export default function useCompleteHook() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const gear = useSelector((state) => state?.featureModelParking?.body?.gear);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const learning_distance = useSelector((state) => state?.featureModelParking?.lpnp?.statistic?.learningDistance);
    const dispatch = useDispatch();
    const curShow = useRef(null);
    const curAlert = useRef(null);
    const curDangRef = useRef(null);
    const prevStatusRef = useRef(null);

    window.lpnp_cmd_cb = (res) => {
        if (res?.data?.code !== 0) {
            curAlert.current = null;
            curShow.current = null;
            Loading.hide();
            return cusToast.show({
                content: 'End Route Learning Failed',
            });
        }
        curAlert.current = null;
        curShow.current = null;
    };

    const handleComplete = async () => {
        FM.sendToHMIParking(
            ParkingSocketMap.lpnp_cmd,
            {
                cmd: 'COMFIRM_LEARNING_COMPLETE',
            },
            'lpnp_cmd_cb',
        );
    };

    const saveStatus = (status) => {
        if (status !== prevStatusRef.current) {
            prevStatusRef.current = status;
        }
    };

    useEffect(() => {
        // TODO: 处理 APA-PARKING -> LEARNING 不处理
        if (prevStatusRef.current == 'APA_PARKING' && status === 'LEARNING') {
            return saveStatus(status);
        }
        saveStatus(status);

        // LEARNING状态扭转，隐藏loading
        if (prevStatusRef.current === 'LEARNING' && status !== 'LEARNING') {
            Loading.hide();
        }

        // P档状态复位，二次P档复位
        if (curDangRef.current !== 'P' && gear == 'P') {
            curShow.current = true;
            curAlert.current = false;
        }
        curDangRef.current = gear;

        if (learning_distance > 0 && gear == 'P' && status == 'LEARNING' && !curAlert.current && curShow.current) {
            curAlert.current = true;
            Modal.info({
                onOk: async () => {
                    Loading.show();
                    await handleComplete();
                },
                onCancel: () => {
                    curAlert.current = null;
                },
                title: 'LEARNING COMPLETE',
                okText: 'COMPLETE',
                cancelText: 'CONTINUE',
                content: 'Completing the route learning will save the route and end the process',
                centered: true,
                isD: userMode === 'D',
            });
        } else {
            curShow.current = null;
            // Modal.clear();
        }
    }, [learning_distance, gear, status]);

    // 模拟自测代码
    useEffect(() => {
        window.__updateState = (data) => {
            dispatch({
                type: 'parkingState',
                data: data,
            });
        };
        window.__updateNotice = (data) => {
            dispatch({
                type: 'parkingNotice',
                data: data,
            });
        };
    }, []);
}
