import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Icon from '@/assets/parking/loading.png';
import store from '@/store/index';
import { HOT_AREA_TYPE } from '@/store/action/type';
import './index.less';
let loadNode = [];
const removeLoadNode = () => {
    loadNode.forEach((node) => {
        try {
            React.isValidElement(node) && ReactDOM.unmountComponentAtNode(node);
            document.body.removeChild(node);
            store.dispatch({
                type: HOT_AREA_TYPE.isHotAll,
                data: false,
            });
        } catch (err) {}
    });
    loadNode = [];
};

function Loading() {
    useEffect(() => {
        store.dispatch({
            type: HOT_AREA_TYPE.isHotAll,
            data: true,
        });
    }, []);
    return (
        <div className="loading-wrap">
            <span onClick={removeLoadNode}></span>
            <img className="animate__animated animate__infinite animate__rotate" src={Icon} alt="Loading" />
        </div>
    );
}

const show = () => {
    removeLoadNode();
    const div_wrap = document.createElement('div');
    loadNode.push(div_wrap);
    document.body.appendChild(div_wrap);
    ReactDOM.render(<Loading />, div_wrap);
    return null;
};

Loading.show = () => {
    show();
};
Loading.hide = () => {
    store.dispatch({
        type: HOT_AREA_TYPE.isHotAll,
        data: false,
    });
    removeLoadNode();
};

export default Loading;
