import { useEffect,  useRef,  useState} from 'react';
import { useSelector } from 'react-redux';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
// import BSDLCALeft from '@/assets/hnp/adas/BSDLCADOWRCW/BSD_LCA_left.gif';
// import BSDLCARight from '@/assets/hnp/adas/BSDLCADOWRCW/BSD_LCA_right.gif';
// import BSDLCABoth from '@/assets/hnp/adas/BSDLCADOWRCW/BSD_LCA_both.gif';
// import DOWLeft from '@/assets/hnp/adas/BSDLCADOWRCW/DOW_left.gif';
// import DOWRight from '@/assets/hnp/adas/BSDLCADOWRCW/DOW_right.gif';
// import DOWBoth from '@/assets/hnp/adas/BSDLCADOWRCW/DOW_both.gif';
//  import RCWBehind from '@/assets/hnp/adas/BSDLCADOWRCW/RCW_behind.gif';
import BSDLCADOWBOTH from '@/assets/hnp/adas/BSDLCADOWRCW/BSD_LCA_DOW_BOTH.gif';
import BSDLCADOWLEFT from '@/assets/hnp/adas/BSDLCADOWRCW/BSD_LCA_DOW_LEFT.gif';
import BSDLCADOWRIGHT from '@/assets/hnp/adas/BSDLCADOWRCW/BSD_LCA_DOW_RIGHT.gif';

import BSDLCALeftImage from '@/assets/hnp/adas/BSDLCADOWRCW/BSD_LCA_left_image.png';
import BSDLCARightImage from '@/assets/hnp/adas/BSDLCADOWRCW/BSD_LCA_right_image.png';
import BSDLCABothImage from '@/assets/hnp/adas/BSDLCADOWRCW/BSD_LCA_both_image.png';
import DOWLeftImage from '@/assets/hnp/adas/BSDLCADOWRCW/DOW_left_image.png';
import DOWRightImage from '@/assets/hnp/adas/BSDLCADOWRCW/DOW_right_image.png';
import DOWBothImage from '@/assets/hnp/adas/BSDLCADOWRCW/DOW_both_image.png';
import RCWBehindImage from '@/assets/hnp/adas/BSDLCADOWRCW/RCW_behind_image.png';
import {useTranslation} from 'react-i18next';
import {widgetPush, widgetType} from '@/utils/widgetPush';
import {modalQueue, modalType} from '@/utils/modalQueue';
import AdasModal from '@/components/adasModal/index'
import {batchReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

import './index.less';
const { playLocalAudio } = AppFn
const iconGifList = {
    "BSD":{
        "LEFT":BSDLCADOWLEFT,
        "RIGHT":BSDLCADOWRIGHT,
        "BOTH":BSDLCADOWBOTH,
    },
    "LCA":{
        "LEFT":BSDLCADOWLEFT,
        "RIGHT":BSDLCADOWRIGHT,
        "BOTH":BSDLCADOWBOTH,
    },
    "DOW":{
        "LEFT":BSDLCADOWLEFT,
        "RIGHT":BSDLCADOWRIGHT,
        "BOTH":BSDLCADOWBOTH,
    }
}

const modalImageList = {
    "BSD":{
        "LEFT":BSDLCALeftImage,
        "RIGHT":BSDLCARightImage,
        "BOTH":BSDLCABothImage,
    },
    "LCA":{
        "LEFT":"",
        "RIGHT":"",
        "BOTH":"",
    },
    "DOW":{
        "LEFT":DOWLeftImage,
        "RIGHT":DOWRightImage,
        "BOTH": DOWBothImage,
    },
    "RCW":{
        'true':RCWBehindImage,
    },
}
/*const modalTextList = {
    "BSD":"请注意盲区风险",
    "LCA":"请注意并线风险",
    "DOW":"请注意开门风险",
    "RCW":"请注意后方车辆",
}*/
const audioList = {
    "BSD":{
        "LEFT":"",
        "RIGHT":"",
    },
    "LCA":{
        "LEFT":localAudioNames.BIBIBI_WARNING,
        "RIGHT":localAudioNames.BIBIBI_WARNING,
    },
    "DOW":{
        "LEFT":localAudioNames.BIBIBI_WARNING,
        "RIGHT":localAudioNames.BIBIBI_WARNING,
        "BOTH":localAudioNames.BIBIBI_WARNING,
    },
    "RCW":{
        // "true":localAudioNames.RCW_BEHIND,
        // "true":localAudioNames.LANE_CHANGE_WARNING,
        "true":localAudioNames.BIBIBI_WARNING,
    },
}
const audioLevelList = {
    highest: "Highest",
    important: "Important",
    normal: "Normal",
    low: "Low"
}

export default function BSDLCADOWRCWComponent() {
    // 方向
    // LEFT，RIGHT，BOTH, INVALID
    const blind_area_warning_light = useSelector(state => state?.featureModelDriving?.blindAreaWarning?.light);
    // LCA/BSD、DOW
    const blind_area_warning_light_type = useSelector(state => state?.featureModelDriving?.blindAreaWarning?.type);

    // notice_list
    // LEFT, RIGHT, BOTH,INVALID
    const blind_area_warning = useSelector(state => state?.featureModelDriving?.blindAreaWarning?.notice?.data?.blindDirection);
    //type: DOW/LCA
    const blind_area_warning_type = useSelector(state => state?.featureModelDriving?.blindAreaWarning?.notice?.data?.type);
    // level
    const blind_area_warning_level = useSelector(state => state?.featureModelDriving?.blindAreaWarning?.notice?.level);

    //RCW
    //rcw_warning: ture
    const rcw_warning = useSelector(state => state?.featureModelDriving?.rcw?.notice?.data?.rcwWarning);
    // level: highest
    const rcw_notice_level = useSelector(state => state?.featureModelDriving?.rcw?.notice?.level);
    let warningTimer = useRef(null);
    const {t: translate} = useTranslation();
    let modalTextList = translate('BSDLCADOWRCW.modalTextList',{returnObjects: true });

    // state 存储值做弹框延迟消失用
    // const [isShowDelay, setIsShowDelay] = useState(false);

   /* useEffect(()=>{
        let direction = "" ,type = "";
        if(rcw_warning){
            direction = rcw_warning;
            type = 'RCW';
        }else{
            direction = blind_area_warning;
            type = blind_area_warning_type;
        }
        let noticeImage = (type && direction) && modalImageList?.[type]?.[direction];
        let noticeText= type && modalTextList?.[type];

        if(noticeImage && noticeText){
            setIsShowDelay(true);
        }else{
            if(isShowDelay){
                setTimeout(()=>{
                    setIsShowDelay(false);
                },1500)
            }
        }
    },[rcw_warning, blind_area_warning, blind_area_warning_type]);*/

    useEffect(()=>{
       let audio = rcw_warning
           ? audioList?.["RCW"]?.[rcw_warning]
           :((blind_area_warning && blind_area_warning_type)
               && audioList?.[blind_area_warning_type]?.[blind_area_warning]);
       let level = ''
        if(rcw_warning){
            level =  audioLevelList?.[rcw_notice_level];
        }else if(blind_area_warning_type === 'LCA' || blind_area_warning_type === 'DOW'){
            level =  audioLevelList?.[blind_area_warning_level];
        }
        /**
         *0915 M7.2：改为音效提示
         */
        if(audio){
            audio && level && playLocalAudio(audio, level);
            (!warningTimer.current) && (warningTimer.current = setInterval(() => {
                audio && level && playLocalAudio(audio, level);
            }, 1000));
        }else{
            clearInterval(warningTimer.current);
            warningTimer.current = null;
            return;
        }
    },[blind_area_warning,
        blind_area_warning_type,
        blind_area_warning_level,
        rcw_warning,
        rcw_notice_level
    ])
    //widget: 图标闪烁（BSD/LCA、DOW），RCW没有图标闪烁
   /* const iconGifItem = (params) => {
        const {blind_area_warning_light, blind_area_warning_light_type} = params;
        let iconGif = (blind_area_warning_light_type && blind_area_warning_light)
            && iconGifList?.[blind_area_warning_light_type]?.[blind_area_warning_light];
        return (
            <>
                {
                    iconGif && (<div className='iconGif'>
                        <img src={ iconGif }  alt=""/>
                    </div>)
                }
            </>
        )
    }*/

    useEffect(()=>{
        let iconGif = (blind_area_warning_light_type && blind_area_warning_light)
            && iconGifList?.[blind_area_warning_light_type]?.[blind_area_warning_light];
        let content = {
            content: `<img src=${ iconGif }  alt="" class="gifImg"/>`
        };
        if(iconGif){
            widgetPush({widgetNumber: 3, type: widgetType.BSDLCADOW, widgetContent: content});
        }else{
            widgetPush({widgetNumber: 3, type: widgetType.BSDLCADOW, widgetContent: null});
        }
    },[blind_area_warning_light, blind_area_warning_light_type])

    /**
     *0914 M7.2：下掉BSD/LCA、DOW的弹窗
     * 只RCW进行弹窗提示
     */
    const noticeImageItem = (params) =>{
       const {direction, type} = params;
       let noticeImage = (type && direction) && modalImageList?.[type]?.[direction];
       let noticeText= type && modalTextList?.[type];
       if(rcw_warning){
           modalQueue({type: modalType.RCW, modalData:{"img":noticeImage, "text": noticeText} })
           reportDataFunc("RCWWarning");
       }else{
           modalQueue({type: modalType.RCW, modalData: null})
       }
    }
   useEffect(()=>{
       noticeImageItem({'direction':rcw_warning,'type':'RCW'})
   },[rcw_warning])

    // 数据上报
    const reportDataFunc = (value) =>{
        batchReport({
            eventName: reportEventName.HMI_RCW_VIEW,
            timestamp: new Date().getTime(),
            text: value,
        })
    }

    // notice_list blind_direction： 弹窗+声音（BSD/LCA、DOW）
    // notice_list rcw_notice： 弹窗+声音（RCW）
    /*const noticeImageItem = (params) =>{
        const {direction, type} = params;
        let noticeImage = (type && direction) && modalImageList?.[type]?.[direction];
        let noticeText= type && modalTextList?.[type];
        return (
            <>
                {
                    noticeImage && noticeText && (
                        <div className='tipsItemGifBSD'>
                            <p>{noticeText}</p>
                            {noticeImage && <img src={ noticeImage } alt=''></img>}
                        </div>
                    )
                }
            </>
        )
    }*/
    return(
        <>
            <AdasModal />
            {/*{iconGifItem({blind_area_warning_light, blind_area_warning_light_type})}*/}
            {/*{
                (rcw_warning
                    ?noticeImageItem({'direction':rcw_warning,'type':'RCW'})
                    :noticeImageItem({'direction':blind_area_warning,'type':blind_area_warning_type}))
            }*/}
        </>
    )

}
