import { NATIVE_ACTION } from '../action/type';

const INIT_STATE = {
  hnpChangeState: '',  // hnp 状态 => 进入：ACTIVE   退出：OFF
  naviState: '',  // 导航状态 => start: 开始导航    exit: 手动退出导航
};

export default function nativeAction(state = INIT_STATE, action) {
    switch (action.type) {
      case NATIVE_ACTION.hnpChangeState:
        return {...state, hnpChangeState: action.data};
      case NATIVE_ACTION.naviState:
        return {...state, naviState: action.data};
      default:
        return state;
    }
  }