import { useSelector } from 'react-redux';
import './index.less';

const build_status_map = ['LEARNING', 'APA_PARKING', 'APA_SELECTING', 'APA_PARKING_PAUSE'];
const parking_status_map = ['CRUISING'];

export default function Distance() {
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const statistic = useSelector((state) => state?.featureModelParking?.lpnp?.statistic);

    const isShow = (s) => {
        return build_status_map.concat(parking_status_map).indexOf(s) > -1;
    };

    const isBuildMap = (s) => {
        return build_status_map.indexOf(s) > -1;
    };

    const isParking = (s) => {
        return parking_status_map.indexOf(s) > -1;
    };

    return (
        <>
            {isShow(status) ? (
                <div className="p-info-distance">
                    {isBuildMap(status) ? (
                        <>
                            <span>Learning Distance</span>
                            <span className="p-info-distance-num">{parseInt(statistic?.learningDistance || 0)}</span>
                            <span className="p-info-distance-m">m</span>
                        </>
                    ) : (
                        ''
                    )}

                    {isParking(status) ? (
                        <>
                            <span>Remaining Distance</span>
                            <span className="p-info-distance-num">
                                {parseInt(statistic?.remainingCruisingDistance || 0)}
                            </span>
                            <span className="p-info-distance-m">m</span>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                ''
            )}
        </>
    );
}
