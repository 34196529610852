import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux'
import { AppFn } from '@/utils/native';
import {useTranslation} from 'react-i18next';

const { playTTSAudio } = AppFn

let speedAudioTimer = null
let speedAudioArr = []

export default function SetSpeedNotice() {
    const hnp_state = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    const running_mode = useSelector(state => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const notice_dirver_speed = useSelector(state => state?.func?.currentState?.notice_list?.notice_dirver_speed_limit_action?.data?.speed);
    const switchSpeedAudio = useSelector(state => state?.switchModeAction?.switchSpeedAudio);
    const notice_dirver_speed_pre = useRef(null)
    const {t: translate} = useTranslation();
    // 设定速度通知
    useEffect(() => {
        if (hnp_state === 'ACTIVE' && (running_mode === 'HNP' || running_mode === 'CP' || running_mode === 'ACC') && switchSpeedAudio === 'ON' && notice_dirver_speed && notice_dirver_speed !== notice_dirver_speed_pre.current) {
            speedAudioArr.push(notice_dirver_speed)
            if(speedAudioTimer) return
            speedAudioTimer = setTimeout(() =>{
                if (!speedAudioArr.length) return
                let tts = speedAudioArr.pop()
                typeof(tts) !== 'undefined'&& playTTSAudio(`${translate('settings.setTo')}${tts}`, 'Low')
                speedAudioArr = []
                speedAudioTimer = null
            }, 500)
            notice_dirver_speed_pre.current = notice_dirver_speed
        }
    }, [ notice_dirver_speed, hnp_state, running_mode, switchSpeedAudio ])
}
