import http from './http';
import LZString from 'lz-string';
import { REPORT_URL } from '@/constant/config';
import store from '@/store';
import { getReportEventAtt } from './reportEventAtt';
import { isReport, isOfflinePack } from '@/utils/isEnv';

// ua信息
const userAgent = window.navigator.userAgent;
// appVersion信息
const regVersion = /hmi_appVersion\(([^\)]+)\)/;
const AppVersion = regVersion.exec(userAgent) ? regVersion.exec(userAgent)[1] : '';
// 国际国内信息
const regLanguage = /language\(([^\)]+)\)/;
const languageType = regLanguage.exec(userAgent) ? regLanguage.exec(userAgent)[1] : 'zh-CN';
const appName = languageType !== 'en-US' ? 'domestic' : 'international';
// 设备信息
const regDevice = /hmi_uniqueId\(([^\)]+)\)/;
const deviceID = regDevice.exec(userAgent) ? regDevice.exec(userAgent)[1] : '';
// h5 version
const packageInfo = require('./../../package.json');
// 批量上报最大条数
const BATCH_REPORT_LENGTH = 50;
// 单点上报时间
const SINGELPOINT_REPORT_TIME = 5000;
// 单点上报数据存储
let singelReportData = [];
// 定时上报（只有触发单点数据存储才会开启计时器）
let singelTimer = null;
// 批量上报数据
let batchReportData = [];

// 上报基础字段
const baseParams = {
    transID: '',
    // required app透传版本号
    AppVersion,
    // required 闭环版本号
    MFVersion: '',
    hminodeVersion: '',
    // 国内国外版本
    appName,
    releasePlatform: '',
    // required
    platform: 'H5',
    userID: '',
    userPassport: '',
    // app透传的设备类型
    deviceID,
    boxID: '',
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    userAgent,
    timestamp: new Date().getTime(),
    eventLength: 1,
    isOfflinePack: isOfflinePack,
};

// 上报事件基础信息（每条）
const eventItem = {
    url: window.location.hash.replace('#', ''),
    pageVersion: packageInfo?.version || window.location.pathname.replaceAll('/', '') || '1.0.0',
    eventName: '',
    timestamp: new Date().getTime(),
    text: '',
    ex: {},
};

// 生成transId
const generatorTransID = () => {
    return new Date().valueOf() + '_' + (Math.random() * 10000).toString().replace('.', '');
};

/**
 * 单点上报（做存储定期上报）
 * @param {string} text 需要上报的信息
 * @param {string} eventName 上报事件名称
 * @param {number} timestamp 触发时的时间戳
 */
export const singelPointReport = (data) => {
    if (!isReport) return;
    const { text = '', reportData, eventName, timestamp = Date.now() } = data;
    const state = store?.getState();
    // baseParams.MFVersion = state?.featureModelDriving?.baseData?.hmi?.maf_version;
    baseParams.boxID =
        baseParams.boxID ||
        state?.featureModelDriving?.baseData?.body?.boxid ||
        state?.featureModelParking?.body?.boxId;
    baseParams.hminodeVersion = state?.featureModelDriving?.baseData?.hmi?.hmi_version || '';
    baseParams.releasePlatform = state?.featureModelDriving?.baseData?.hmi?.release_platform || '';
    const common = getReportEventAtt();
    let singelEventItem = {
        ...eventItem,
        ex: {
            common,
            ...reportData,
        },
        eventName,
        timestamp,
        text,
    };
    // 暂存数据
    singelReportData.push({ ...singelEventItem });
    // console.log('******baseParams', JSON.stringify(baseParams));
    // console.log('******singelReportData', singelReportData);

    if (singelTimer) {
        // 统一上报
        if (singelReportData.length === 10) {
            let singelReportParams = {
                ...baseParams,
                transID: generatorTransID(),
                events: [],
                eventLength: singelReportData.length,
            };
            // singelReportParams.transID = generatorTransID();
            // singelReportParams.events = [];
            // singelReportParams.eventLength = singelReportData.length;
            singelReportParams.events.push(...singelReportData);
            let compressParams = LZString.compressToBase64(JSON.stringify(singelReportParams));
            // console.log('******singelReportParams', JSON.stringify(singelReportParams));
            // console.log('******compresssingelReportParams', compressParams);
            http.post(REPORT_URL + '/trace', { data: compressParams });
            singelReportData.splice(0, 10);
        }
    } else {
        singelTimer = setInterval(() => {
            let singelReportParams = {
                ...baseParams,
                transID: generatorTransID(),
                events: [],
                eventLength: singelReportData.length,
            };
            // singelReportParams.transID = generatorTransID();
            // singelReportParams.events = [];
            // singelReportParams.eventLength = singelReportData.length;
            singelReportParams.events.push(...singelReportData);
            let compressParams = LZString.compressToBase64(JSON.stringify(singelReportParams));
            if (singelReportData.length !== 0) {
                // console.log('******singelReportParams', JSON.stringify(singelReportParams));
                // console.log('******compresssingelReportParams', compressParams);
                http.post(REPORT_URL + '/trace', { data: compressParams });
                singelReportData.splice(0, singelReportData.length);
            }
            clearInterval(singelTimer);
            singelTimer = null;
        }, SINGELPOINT_REPORT_TIME);
    }
};

// 批量上报（定量数据发送）
export const batchReport = (data) => {
    if (!isReport) return;
    const { eventName, timestamp, text = '', reportData } = data;
    // console.log(reportData);
    const state = store?.getState();
    // baseParams.MFVersion = state?.featureModelDriving?.baseData?.hmi?.maf_version;
    baseParams.boxID = baseParams.boxID || state?.featureModelDriving?.baseData?.body?.boxid;
    baseParams.hminodeVersion = state?.featureModelDriving?.baseData?.hmi?.hmi_version || '';
    baseParams.releasePlatform = state?.featureModelDriving?.baseData?.hmi?.release_platform || '';
    const common = getReportEventAtt();
    let batchEventItem = {
        ...eventItem,
        eventName,
        timestamp,
        text,
        ex: { common, ...reportData },
    };
    batchReportData.push({ ...batchEventItem });
    if (batchReportData.length === BATCH_REPORT_LENGTH) {
        let batchReportParams = {
            ...baseParams,
            transID: generatorTransID(),
            events: [],
            eventLength: BATCH_REPORT_LENGTH,
        };
        // batchReportParams.transID = generatorTransID();
        // batchReportParams.events = [];
        // batchReportParams.eventLength = BATCH_REPORT_LENGTH;
        batchReportParams.events.push(...batchReportData);
        let compressBatchReportParams = LZString.compressToBase64(JSON.stringify(batchReportParams));
        // console.log('******batchReportParams',JSON.stringify(batchReportParams));
        http.post(REPORT_URL + '/trace', { data: compressBatchReportParams });
        batchReportData.splice(0, BATCH_REPORT_LENGTH);
    }
};
