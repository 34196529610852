import reportEventName from '@/constant/reportEventName';

export const TipsMapConfig = {
    LEARNING_START: 'Start Route Learning',
    LEARNING: 'Please drive to the parking spot and park in',
    START_CRUSE_STOP: 'Please release the brake',
    START_CRUSE: 'Driving to your target parking spot',
    CRUISING: 'LPNP is working',
    PARKING: 'LPNP parking in', //'即将进入车位',
    APA_SELECTING: 'Select a pakring spot and click the “Park In”', //'已为您选好推荐车位，请确认后点击“开始泊车”',
    APA_PARKING: 'APA is working', //'正在泊入您的车位',
};

export const TOAST_CONFIG = {
    lpnp_start_learning: 'Start route learning here, drive to a parking spot and park in', //'我从这里开始学习了，请开往你的车位并泊入',
    lpnp_start_cruising: 'LPNP is activated', //'LPNP已开始',
    lpnp_start_parking: 'Park in is in progress', //'开始泊车',
    lpnp_map_build_succ: 'Route Learning Completed', //'路线学习完成', // 建图成功
    lpnp_map_build_fail: 'Route Learning Failed', //'路线学习失败',
    lpnp_exit: 'LPNP is deactivated', //'LPNP已退出'LPNP Crusing is Deactivated,不展示文字@zhongning
    lpnp_parking_succ: 'LPNP Cruising Complete', //'LPNP已完成',
    lpnp_parking_fail: 'LPNP is Failed',
    lpnp_approaching_destination: 'Arriving at the parking spot soon', //'即将到达您的车位',
    lpnp_cruising_turn: {
        lText: 'Going to turn left', //'前方即将左转',
        rText: 'Going to turn right', //'前方即将右转',
        // 即将通过路口
        straight: 'Going straight at the intersection ahead',
    },
    lpnp_space_occupied_unable_continue: 'LPNP deactivated for the target spot occupied', //'目标车位被占，无法继续泊车',
    lpnp_start_apa_selecting: 'Select a pakring spot and click the “Park In”', //'已为您选好推荐车位，请确认后点击“开始泊车”',
    lpnp_start_apa_parking_2stips: 'Please release the brake', //fe hack
    lpnp_start_apa_parking: 'Park in is in progress',
    lpnp_apa_parking_succ: 'APA is completed', //'泊车完成',
    lpnp_apa_parking_fail: 'APA is deactivated', //'泊车失败',
    lpnp_speed_bump_inc: 'Passing through a speed bump', //'经过减速带',
    lpnp_apa_selecting_without_parking_slot: 'No available direction to park out',
    lpnp_drive_to_map_starting_point: 'Driving to the start of the learned route', // 开往建图起点
    lpnp_ready: 'LPNP is available', //'LPNP可用了',LPNP Cruising is Available //不展示文字@zhongning
    lpnp_exit_park: 'Driving away from the parking lot',
    lpnp_interaction: {
        // 即将绕行前车
        avoid_vehicle: 'Avoid the vehicle ahead',
        // 等待前车行驶
        wait_vehicle: 'Waiting for the vehicle ahead to drive away',
        // 即将避让行人
        yield_to_pedestrian: 'Avoid the pedestrian ahead',
    },
};

export const AudioMap = {
    lpnp_start_learning: 'lpnp_start_learning.mp3',
    lpnp_start_cruising: 'lpnp_start_cruising.mp3',
    lpnp_start_parking: 'lpnp_start_parking.mp3',
    lpnp_map_build_succ: 'lpnp_map_build_succ.mp3',
    lpnp_map_build_fail: 'lpnp_map_build_fail.mp3',
    lpnp_exit: 'lpnp_exit.mp3',
    lpnp_parking_succ: 'lpnp_parking_succ.mp3',
    lpnp_parking_fail: 'lpnp_parking_fail.mp3',
    lpnp_approaching_destination: 'lpnp_approaching_destination.mp3',
    lpnp_cruising_turn_left: 'lpnp_cruising_turn_left.mp3',
    lpnp_cruising_turn_right: 'lpnp_cruising_turn_right.mp3',
    lpnp_cruising_turn_straight: 'lpnp_cruising_turn_straight.mp3',
    lpnp_space_occupied_unable_continue: 'lpnp_space_occupied_unable_continue.mp3',
    lpnp_start_apa_selecting: 'lpnp_start_apa_selecting.mp3',
    lpnp_start_apa_parking_2stips: 'lpnp_start_apa_parking_2stips.mp3',
    lpnp_start_apa_parking: 'lpnp_start_apa_parking.mp3',
    lpnp_apa_parking_succ: 'lpnp_apa_parking_succ.mp3',
    lpnp_apa_parking_fail: 'lpnp_apa_parking_fail.mp3',
    lpnp_speed_bump_inc: 'lpnp_speed_bump_inc.mp3', // 减速带提醒
    lpnp_apa_selecting_without_parking_slot: 'lpnp_apa_selecting_without_parking_slot.mp3', // 附近无可泊车位，请手动倒入
    lpnp_drive_to_map_starting_point: 'lpnp_drive_to_map_starting_point.mp3', // 开往建图起点
    lpnp_ready: 'lpnp_ready.mp3', // lpnp已就绪
    lpnp_exit_park: 'lpnp_exit_park.mp3', // 车辆驶出停车场
    // 即将绕行前车
    lpnp_interaction_avoid_vehicle: 'lpnp_interaction_avoid_vehicle.mp3',
    // 等待前车行驶
    lpnp_interaction_wait_vehicle: 'lpnp_interaction_wait_vehicle.mp3',
    // 即将避让行人
    lpnp_interaction_yield_to_pedestrian: 'lpnp_interaction_yield_to_pedestrian.mp3',
};

// 打点上报映射
export const ReportMap = {
    //路线学习阶段上报
    lpnp_start_learning: { eventName: reportEventName.HMI_LPNP_START_LEARNING_VIEW, text: 'lpnp_start_learning' },
    lpnp_speed_bump_inc: { eventName: reportEventName.HMI_LPNP_BUMP_VIEW, text: 'lpnp_speed_bump_inc' },
    lpnp_start_apa_parking: { eventName: reportEventName.HMI_LPNP_APA_START_VIEW, text: 'lpnp_start_apa_parking' },
    lpnp_apa_parking_succ: { eventName: reportEventName.HMI_LPNP_APA_SUCC_VIEW, text: 'lpnp_apa_parking_succ' },
    lpnp_apa_parking_fail: { eventName: reportEventName.HMI_LPNP_APA_FAIL_VIEW, text: 'lpnp_apa_parking_fail' },
    lpnp_map_build_fail: { eventName: reportEventName.HMI_LPNP_MAP_BUILD_FAIL_VIEW, text: 'lpnp_map_build_fail' },

    //智能巡航阶段上报
    lpnp_start_cruising: { eventName: reportEventName.HMI_LPNP_START_CRUISE_VIEW, text: 'lpnp_start_cruising' },
    lpnp_start_apa_parking_2stips: {
        eventName: reportEventName.HMI_BRAKE_RELEASE_VIEW,
        text: 'lpnp_start_apa_parking_2stips',
    },
    lpnp_approaching_destination: {
        eventName: reportEventName.HMI_LPNP_APP_DES_VIEW,
        text: 'lpnp_approaching_destination',
    },
    lpnp_space_occupied_unable_continue: {
        eventName: reportEventName.HMI_LPNP_SPACE_OCCUPIED_VIEW,
        text: 'lpnp_space_occupied_unable_continue',
    },
    lpnp_parking_fail: { eventName: reportEventName.HMI_LPNP_PARKING_FAIL_VIEW, text: 'lpnp_parking_fail' },
    lpnp_exit: { eventName: reportEventName.HMI_LPNP_EXITED_VIEW, text: 'lpnp_exit' },
};
