import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import FM from '@/utils/nativeSubAndPub';
// import close from "@/assets/parking/pnp_debug_close.png";
import { LPNP_PROJECT_CMD, LPNP_DEBUG_CMD } from './config';
import { cusToast } from '@/components/customToast';
import { HOT_AREA_TYPE } from '@/store/action/type';
import close from '@/assets/parking/d_new/cancel_d.png';
import './index.less';

export default function PNPDebug(props) {
    const operatorRef = useRef(null);
    const dispatch = useDispatch();
    const [curTab, setTab] = useState('');
    const operatorArea = operatorRef?.current?.getBoundingClientRect();

    window.lpnp_control_cb1 = (res) => {
        if (res?.data?.code === 0) {
            return cusToast.show({
                content: 'Exit LPNP Mode Succeeded!',
            });
        } else {
            return cusToast.show({
                content: 'Exit LPNP Mode Failed!',
            });
        }
    };
    window.lpnp_control_cb2 = (res) => {
        if (res?.data?.code === 0) {
            return cusToast.show({
                content: 'Enter LPNP Mode Succeeded!',
            });
        } else {
            return cusToast.show({
                content: 'Enter LPNP Mode Failed!',
            });
        }
    };
    const quit_mode = async () => {
        FM.sendToHMIParking(
            LPNP_PROJECT_CMD,
            {
                cmd: LPNP_DEBUG_CMD.EXIT_LPNP,
                debug: true,
            },
            'lpnp_control_cb1',
        );
    };

    const debug_mode = async () => {
        FM.sendToHMIParking(
            LPNP_PROJECT_CMD,
            {
                cmd: LPNP_DEBUG_CMD.LPNP_DEBUG,
                debug: true,
            },
            'lpnp_control_cb2',
        );
    };

    const showDashBoard = () => {
        setTab('dashbord');
        dispatch({
            type: 'lpnpDashboard',
            data: true,
        });
    };

    useEffect(() => {
        const operatorAreaInfo = `${operatorArea?.x}, ${operatorArea?.y}, ${operatorArea?.width}, ${operatorArea?.height}`;
        dispatch({
            type: HOT_AREA_TYPE.LPNPDebugOperatorArea,
            data: operatorAreaInfo,
        });
        return () => {
            dispatch({
                type: HOT_AREA_TYPE.LPNPDebugOperatorArea,
                data: '',
            });
        };
    }, [operatorArea?.x, operatorArea?.y, operatorArea?.width, operatorArea?.height]);

    return (
        <div ref={operatorRef} className="pnp-debug-wrap">
            <div className="pnp-debug-title">
                <div onClick={() => props?.close()} className="pnp-debug-close">
                    <img className="pnp-debug-close-icon" src={close} alt="close" />
                </div>
                {/* <p className="pnp-debug-name">工程模式</p> */}
            </div>
            <div onClick={() => quit_mode()} className={`pnp-debug-btn ${curTab == 'quit' ? 'active' : ''}`}>
                Exit LPNP Mode
            </div>
            <div onClick={() => debug_mode()} className={`pnp-debug-btn ${curTab == 'enter' ? 'active' : ''}`}>
                Enter LPNP Mode
            </div>
            <div onClick={() => showDashBoard()} className={`pnp-debug-btn ${curTab == 'dashbord' ? 'active' : ''}`}>
                LPNP Debug Info
            </div>
        </div>
    );
}
