import Router from './router/index';
import Record from '@/components/workbench/record';
import { useInitLocalCache } from './hook';
import { useSelector } from 'react-redux';

function App() {
    // 初始化本地缓存的状态，提交到redux
    useInitLocalCache();

    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);

    return (
        <>
            <Router></Router>
            {switchWorkBench && <Record />}
        </>
    );
}

export default App;
