/**
 * apaDebug: null,
  apa: null,
  lpnp: null,
  body: null,
  hmi: null,
  switch: null,
  meb: null,
  lpnpStateChange: null
 */
const INIT_STATE = {};

export default function FeatureModelParking(state = INIT_STATE, action) {
  switch (action.type) {
    case "featureModelParking":
      return {
        ...state,
        ...{
          ...action.data,
        },
      };
    default:
      return state;
  }
}
