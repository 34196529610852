import {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch} from 'react-redux'
import { HOT_AREA_TYPE } from '@/store/action/type';
import { AppFn } from '@/utils/native';
import './index.less';

const { jsButtonCallBack, getNaviSettings} = AppFn

/*let fontSize = {
   /!* 'height': '0.22rem',
    'width': '0.3rem',
    'lineHeight': '0.8rem',*!/
    'fontSize': '.2rem',
    /!*'display': 'inline-block',
    'textAlign': 'right',*!/
    'height': '0.22rem',
    'width': '0.3rem',
    'margin':'0 90%',
    'display': 'flex',
    'justifyContent': 'flex-end'
}*/

export default function SettingCom(props){
    const [checkedPlay, setCheckedPlay] = useState(null);
    const [naviYaw, setNaviYaw] = useState(null);
    const dispatch = useDispatch();
    // 热区相关
    const settingModalDom = useRef(null);
    const area = useSelector(state => state?.layout?.Area);
    const switchMvizState = useSelector(state => state?.switchModeAction?.switchMvizState);

    let playModeOptions=[{
            label: '详细播报',
            value: 'detailBroadcast',
        }, {
            label: '简洁播报',
            value: 'simpleBroadcast',
        }, {
            label: '静音',
            value: 'closeBroadcast',
        }];
    let naviYawOptions=[{
            label: '车头向上',
            value: 'carUp',
        }, {
            label: '北向上',
            value: 'northUp',
        }];
    useEffect(()=>{
        const settingModalArea = settingModalDom?.current?.getBoundingClientRect();
        const settingModalInfo = `${settingModalArea?.x}, ${settingModalArea?.y}, ${settingModalArea?.width}, ${settingModalArea?.height}`;
        typeof(settingModalArea) !== 'undefined' && dispatch({
            type: HOT_AREA_TYPE.settingModalDom,
            data: settingModalInfo
        });
    },[area?.isHorizontal, settingModalDom?.current?.getBoundingClientRect()?.width, switchMvizState])

    useEffect(()=>{
        //获取默认导航设置
        // window?.momentaJsBridge?.dispatch("getNaviSettings", 'naviSettingCallback', null)
        getNaviSettings('naviSettingCallback')
        window.naviSettingCallback = function (data) {
            const {broadcastMode, upMode} = data;
            let playArr=[],naviArr=[];
            playModeOptions.map(item=>{
                playArr.push(item.value);
            })
            naviYawOptions.map(item=>{
                naviArr.push(item.value);
            })
            if(playArr.includes(broadcastMode)){
                handleTap('playMode',broadcastMode);
            }
            if(naviArr.includes(upMode)){
                handleTap('naviYaw',upMode);
            }
        }
        // Toast(window?.momentaJsBridge?.dispatch("getNaviSettings",'naviSettingCallback',null), 0);
        // const data = JSON.parse(getNaviSettings('naviSettingCallback'))
        // console.log('navi data',data);
    },[])
    const handleCancel = () =>{
        props.onCancel('close');
        const settingModalArea = settingModalDom?.current?.getBoundingClientRect();
        typeof(settingModalArea) !== 'undefined' && dispatch({
            type: HOT_AREA_TYPE.settingModalDom,
            data: '',
        });
    }
    const handleTap = (flag,value) => {
        if((flag === 'playMode' && value === checkedPlay)
            || (flag === 'naviYaw' && value === naviYaw)){
            return
        }

        if(flag === 'playMode'){
            setCheckedPlay(value)
        }else{
            setNaviYaw(value)
        }
        jsButtonCallBack(value);
    }
    return(
        <div className='modalMask naviSet' ref={settingModalDom}>
            <div className='modalBox'>
                <div className='modalCom'>
                    <div className='iconfont close' onClick={() => handleCancel()}>{'\ue621'}</div>
                    <div className='maskContent'>
                        {/* 智驾区域*/}
                        <div className='select-group'>
                            <div className='select-group-item'>
                                <p className='select-group-item-name name'>播放模式</p>
                                <div className='select-box'>
                                    {
                                        playModeOptions.length && playModeOptions.map((item, index) => {
                                            return (<span key={index}
                                                          className={`select-box-item ${checkedPlay === item.value ? 'active' : ''}`}
                                                          onClick={() => handleTap('playMode', item.value)}>{item.label}</span>)
                                        })
                                    }
                                </div>
                            </div>
                            <div className='select-group-item'>
                                <p className='select-group-item-name name'>导航视角</p>
                                <div
                                    className={`select-box lane-change`}>
                                    {
                                        naviYawOptions.length && naviYawOptions.map((item, index) => {
                                            return (<span key={index}
                                                          className={`select-box-item ${naviYaw === item.value ? 'active' : ''}`}
                                                          onClick={() => handleTap('naviYaw', item.value)}>{item.label}</span>)
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
       

    )
}