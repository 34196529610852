import versionMap from '@/constant/versionMap';
import store from '@/store';
import {AppFn} from "@/utils/native"

const ua = window.navigator.userAgent
const reg = /hmi_appVersion\(([^\)]+)\)/
const app_version_exec = reg.exec(ua)

// hmi、h5、app 版本匹配
export function compareVersion () {
    const state = store?.getState();
    const v = state?.featureModelDriving?.baseData?.hmi?.hmi_version;
    if (app_version_exec && v) {
        let appVersion = app_version_exec[1]
        let match_hmi_arr = getMatchHmiKey(appVersion)
        if(!match_hmi_arr.includes(v)) {
            setTimeout(() => {
                window.location.href = `${window.location.origin}/index.html`
            }, 1000)
            AppFn.pushNoticeData(JSON.stringify({eventName: 'Web_CheckVersionMap_Failed', data: {
                h5: window.location.pathname.replace(/\//g,''),
                app: appVersion,
                new_hmiversion: v
            }}))
        }
    }
}

/**
 * 版本比较
 */
// 1: 大于、0：等于、-1：小于
function vCompare (a, b) {
    var sources = a.split('.');
    var dests = b.split('.');
    var maxL = Math.max(sources.length, dests.length);
    var result = 0;
    for (let i = 0; i < maxL; i++) {
        let preValue = sources.length >i ? sources[i]:0;
        let preNum = isNaN(Number(preValue)) ? preValue.charCodeAt() : Number(preValue);
        let lastValue = dests.length >i ? dests[i]:0;
        let lastNum =  isNaN(Number(lastValue)) ? lastValue.charCodeAt() : Number(lastValue);
        if (preNum < lastNum) {
            result = -1;
            break;
        } else if (preNum > lastNum) {
            result = 1;
            break;
        }
    }
    return result;
}
function getMatchHmiKey (app_v) {
    let arr = []
    for (let v in versionMap) {
      let o = versionMap[v].app
      let compare_a = vCompare(app_v, o[0]), compare_b = vCompare(app_v, o[1])
      if ((compare_a == 1 || compare_a == 0)&& compare_b == -1) {
        arr.push(v)
      }
    }
    return arr
}
