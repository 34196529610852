import { useSelector } from 'react-redux';
import InfoTips from '@/components/inforTips/index';
import './index.less';
import classNames from 'classnames';
export default function PNPInfoTips() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);
    return (
        <div className={classNames('pnp-info-tips', userMode === 'D' ? 'd' : 'sr', { 'show-record': switchWorkBench })}>
            <InfoTips />
        </div>
    );
}
