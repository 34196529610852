import React, {useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import WidgetComponent from "../../widget/index";
import {widgetQueue, widgetType} from '@/utils/widgetQueue';
import {useTranslation} from "react-i18next";

export default function CpTFL() {
    const {t: translate} = useTranslation();
    // bool  Planning是否收到红绿灯信号
    const has_traffic_light = useSelector(state => state?.featureModelDriving?.tflStopControlInfo?.hasTrafficLight);
    // bool  Planning结合红绿灯信息和司机交互信息的最终决策结果：true为通过，false为不通过
    const is_planning_decision_pass = useSelector(state => state?.featureModelDriving?.tflStopControlInfo?.isPlanningDecisionPass);
    // bool  是否需要用户确认
    const is_need_driver_comfirm_pass = useSelector(state => state?.featureModelDriving?.tflStopControlInfo?.isNeedDriverConfirmPass);
    // DDLD给Planning的停止线距离 -- 若该距离为负数，不展示该提示
    const distance_to_stopline = useSelector(state => state?.featureModelDriving?.tflStopControlInfo?.distanceToStopline);

    // 时间锁
    const durationLock = useRef(0);
    // 存储定时器句柄
    const timerHandle = useRef(null);

    const getDistanceInt = (speed) => {
        if (speed > 145 && speed <= 200) {
            return 200
        } else if (speed >= 95 && speed <= 145) {
            return 150
        } else if (speed >= 75 && speed < 95) {
            return 100
        } else if (speed >= 45 && speed < 75) {
            return 75
        } else if (speed >= 25 && speed < 45) {
            return 50
        } else if (speed >= 10 && speed < 25) {
            return 25
        } else if (speed >= 0 && speed < 10) {
            const number = Math.round(speed);
            return number
        } else {
            return null;
        }
    }

    useEffect(() => {
        let text = "";
        // planning检测到有红绿灯 且 planning决定停下且无需用户确认时
        if (has_traffic_light && !is_planning_decision_pass && !is_need_driver_comfirm_pass) {
            text = {
                icon: "cp_tfl",
                content: "",
                priority: "highest"
            }
            const getDistance = getDistanceInt(distance_to_stopline);
            // 0米时
            if (distance_to_stopline === 0 || getDistance === 0) {
                text.content = translate("cpTFL.lightParking")  // 红绿灯前停车
            } else {
                if (getDistance) {
                    // 前方红绿灯将在XXX米后停车
                    text.content = `
                        <div style="white-space: nowrap">${translate("cpTFL.wordsContent")} ${getDistance}${translate("cpTFL.wordsOther")}</div>`
                }
            }
        }

        // planning检测到有红绿灯 且 planning决定停下且需需要用户确认时
        if (has_traffic_light && !is_planning_decision_pass && is_need_driver_comfirm_pass) {
            text = {
                icon: "cp_tfl",
                content: "",
                priority: "highest"
            }
            const getDistance = getDistanceInt(distance_to_stopline);
            // 0米时
            if (distance_to_stopline === 0 || getDistance === 0) {
                text.content = `
                    <!--红绿灯前停车-->
                    <div class="custom_toast-title">${translate("cpTFL.lightParking")}</div>
                    <!--踩加速踏板继续行驶-->
                    <div class="custom_toast-subtitle">${translate("cpTFL.acceleratorContinue")}</div>
                `
            } else {
                if (getDistance) {
                    text.content = `
                    <!--前方红绿灯将在XXX米后停车-->
                    <div class="custom_toast-title">
                       ${translate("cpTFL.wordsContent")} ${getDistance}${translate("cpTFL.wordsOther")}
                    </div>
                    <!--踩加速踏板继续行驶-->
                    <div class="custom_toast-subtitle">${translate("cpTFL.acceleratorContinue")}</div>
                `
                }
            }
        }

        const isShowWidget = text !== "" && text?.content && text?.content !== "";

        if (isShowWidget) {
            const current_time = Date.now();
            if (current_time - durationLock.current >= 1000) {
                clearTimeout(timerHandle.current);
                timerHandle.current = null;
                widgetQueue({widgetNumber: 1, type: widgetType.TFL, widgetContent: text});
                durationLock.current = current_time;
            }
            else {
                clearTimeout(timerHandle.current);
                timerHandle.current = null;
                timerHandle.current = setTimeout(() => {
                    widgetQueue({widgetNumber: 1, type: widgetType.TFL, widgetContent: text});
                    const current_now = Date.now();
                    durationLock.current = current_now;
                }, 1000);
            }
        } else {
            clearTimeout(timerHandle.current);
            timerHandle.current = null;
            widgetQueue({widgetNumber: 1, type: widgetType.TFL, widgetContent: null})
        }

        // console.log(has_traffic_light, "log has_traffic_light")
        // console.log(is_planning_decision_pass, "log is_planning_decision_pass")
        // console.log(is_need_driver_comfirm_pass, "log is_need_driver_confirm_pass")
        // console.log(distance_to_stopline, "log distance_to_stopline ---------------------------")
    }, [
        has_traffic_light,
        is_planning_decision_pass,
        is_need_driver_comfirm_pass,
        distance_to_stopline,
    ])

    return (
        <React.Fragment>
            <WidgetComponent/>
        </React.Fragment>
    )
}

