import { Tag } from 'antd-mobile';
import dayjs from 'dayjs';
import { TASK_STATUS_MAP } from '../const';

export default function TaskTitle({ taskStatus, taskType, startTime, endTime, deploy, taskName }) {
  return (
    <div className="task-title">
      <div className="task-desc">
        <div className="task-name">
          <Tag
            fill="outline"
            style={(TASK_STATUS_MAP[taskStatus] || TASK_STATUS_MAP.default).tagStyle}
          >
            {(TASK_STATUS_MAP[taskStatus] || TASK_STATUS_MAP.default).text}
          </Tag>
          <span>{taskName}</span>
        </div>
        <div className="task-time">
          {taskType}｜{dayjs(startTime).format('HH:mm')}-{dayjs(endTime).format('HH:mm')}
        </div>
      </div>
      <div className="deploy-status">{deploy}</div>
    </div>
  );
}
