import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SceneChange() { 
    const current_scene = useSelector(state => state?.scene?.current_scene);
    const navigate = useNavigate();
    useEffect(() => {
        switch(current_scene){
            case "driving":
                navigate("/home/index")
                break;
            case "parking":
                navigate("/home/parking")
                break;
            default:
                break;
        }
    }, [current_scene]);
}


