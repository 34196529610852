import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import close from "@/assets/parking/pnp_debug_close.png";
import { carIpMap, carType } from '@/constant/config';
import './index.less';

export default function LPNPDashboard() {
    const isShowDashboard = useSelector((state) => state?.parking?.isShowDashboard);
    const dashboardRef = useRef(null);
    const dispatch = useDispatch();
    const [val, setVal] = useState();

    const dashboardArea = dashboardRef?.current?.getBoundingClientRect();

    const close = () => {
        dispatch({
            type: 'lpnpDashboard',
            data: false,
        });
    };

    useEffect(() => {
        const dashboardAreaInfo = `${dashboardArea?.x}, ${dashboardArea?.y}, ${dashboardArea?.width}, ${dashboardArea?.height}`;
        dispatch({
            type: 'LPNPDashboardArea',
            data: dashboardAreaInfo,
        });

        return () => {
            dispatch({
                type: 'LPNPDashboardArea',
                data: '',
            });
        };
    }, [isShowDashboard, dashboardArea?.x, dashboardArea?.y, dashboardArea?.width, dashboardArea?.height]);

    const getLPNP = () => {
        let ip = carIpMap[carType] || '192.168.1.10';
        return `http://${ip}:3001/charts?server=${ip}:8085`;
    };

    return (
        <div ref={dashboardRef} className="lpnp-dashboard">
            <div onClick={() => close()} className="lpnp-dashboard-mask"></div>
            <div className="lpnp-dashboard-mviz">
                <iframe src={getLPNP()} frameBorder="0"></iframe>
            </div>
        </div>
    );
}
