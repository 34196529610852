import {Toast} from "antd-mobile";
import store from '@/store';
import './index.css';

/**
 * custom_toast_error: error
 * custom_toast_warn: warn
 * custom_toast_normal: normal
 * */

export const customToast = ({className, text, duration = 3000}) => {
    const element = document.getElementsByClassName(className);
    const area = store?.getState()?.layout?.Area;
    const switch_mviz_layout = store?.getState()?.switchModeAction?.switchMvizState;
    if (!element || element.length < 1) {
        const create_div = document.createElement('div')
        create_div.setAttribute('class', className)
        document.body.appendChild(create_div)
    }

    return Toast.show({
        getContainer: document.getElementsByClassName(className)[0],
        content: <p style={{fontSize: '0.28rem', padding: '0 0.2rem'}}>{text}</p>,
        duration: duration,
        maskClassName: area.isHorizontal+ ' ' + switch_mviz_layout,
    })
}
