import { AppFn } from '@/utils/native';
import store from '@/store';
import { ParkingLayoutConfig } from './config';
import localCache from '@/utils/localCache';

export const getMtour = () =>
    localCache.get('lpnp_mtour', (data) => {
        store.dispatch({
            type: 'lpnpMtour',
            data,
        });
    });

export const setParkingLayout = () => AppFn.setMviz(ParkingLayoutConfig);
