import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { TOAST_CONFIG } from './alertMap';
import { isShowTips, getTipsTxt, getToastAndAudioText, reportHandle, reportHandleHack, alertAudioPlay } from './fn';
import { TOAST_TIME } from '@/components/parkingContainer/config';

import './index.less';

export default function ParkingTips() {
    const status = useSelector((state) => state?.featureModelParking.lpnp?.state);
    const switch_mode = useSelector((state) => state?.featureModelParking?.switch?.lpnpSwitch);
    const isShowGuide = useSelector((state) => state?.parking?.isShowGuide);
    const userMode = useSelector((state) => state?.parking?.userMode);

    const robonodeReady = useSelector((state) => state?.robonode?.ready);
    const noticeData = useSelector((state) => state?.featureModelParking?.alert?.data);
    const noticeType = useSelector((state) => state?.featureModelParking?.alert?.type);
    const noticeLevel = useSelector((state) => state?.featureModelParking?.alert?.level);

    const speed_bumps_count = useSelector((state) => state?.featureModelParking.lpnp?.statistic?.speedBumpsCount);
    const mviz_msg = useSelector((state) => state?.parking?.mvizParkData);
    const apa_available_parking_slots_count = useSelector(
        (state) => state?.featureModelParking.lpnp?.statistic?.apaAvailableParkingSlotsCount,
    );
    const [isShowToast, setShowToast] = useState(false);
    const [Toast, setToast] = useState('');
    const timerRef = useRef();

    const toastHandler = (txt) => {
        setShowToast(true);
        txt && setToast(txt);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setToast('');
            setShowToast(false);
        }, TOAST_TIME);
    };

    const notNeedToast = () => !TOAST_CONFIG[noticeType];

    const checkVaildAlert = () => getToastAndAudioText(noticeType, noticeData);

    const handleTipsAndAudio = (noticeType, noticeData) => {
        // alert通用打点
        reportHandle(noticeType);

        // 不需要toast
        if (notNeedToast()) return;

        // 非config中alert忽略
        if (!checkVaildAlert()) return;

        // 获取toast文案和音频名称
        let { txt, audioName } = getToastAndAudioText(noticeType, noticeData);

        // alert 左右转向打点
        reportHandleHack(audioName);

        // 处理toast
        toastHandler(txt);

        // 处理语音播报
        alertAudioPlay({ audioName, noticeType, noticeLevel, speed_bumps_count });
    };
    // StartParkingNoticeHack(handleTipsAndAudio);
    // window.lpnp_apa_parking_exit_cb = async (res) => {
    //     logSend({
    //         action: 'lpnp_apa_parking_exit_cb',
    //         data: {
    //             res,
    //         },
    //     });
    // };
    useEffect(() => {
        console.log('noticeData', noticeType, noticeData);
        // 非独立apa中暂不处理
        // if(noticeType==='lpnp_apa_parking_succ' || noticeType==='lpnp_apa_parking_fail'){
        //     FM.sendToHMIParking(
        //         ParkingSocketMap.apa_control,
        //         {
        //             cmd: 'EXIT_APA',
        //         },
        //         'lpnp_apa_parking_exit_cb',
        //     );
        // }
        // 在学习引导页中，抑制所有播报
        if (!isShowGuide) {
            handleTipsAndAudio(noticeType, noticeData);
        }
    }, [noticeType, noticeData?.direction, noticeData?.info]);

    return (
        <>
            {
                //@zhongning取消这个的展示，移到按钮处
                switch_mode == 'CLOSE' && robonodeReady == true ? (
                    <div className={classNames(['parking-tips', { sr: userMode === 'C' }])}>
                        <div className="wrap">
                            <p>{'Please turn on the LPNP switch first'}</p>
                        </div>
                    </div>
                ) : (
                    ''
                )
            }
            {switch_mode == 'OPEN' &&
            isShowTips(status) &&
            (!Toast || !isShowToast) &&
            getTipsTxt({ status, track_id: mviz_msg?.track_id, count: apa_available_parking_slots_count }) ? (
                <div className={classNames(['parking-tips', { sr: userMode === 'C' }])}>
                    <div className="wrap">
                        <p>{getTipsTxt(status, mviz_msg?.track_id, apa_available_parking_slots_count)}</p>
                    </div>
                </div>
            ) : (
                ''
            )}
            {/* {
            switch_mode == "OPEN" && isShowProcess(status) && !isShowToast ? <RouteSaveProcess /> : ""
        } */}
            {switch_mode == 'OPEN' && Toast && isShowToast ? (
                <div className={classNames(['parking-tips', { sr: userMode === 'C' }])}>
                    <p className="parking-toast">{Toast}</p>
                </div>
            ) : (
                ''
            )}
        </>
    );
}
