/**
 * 存储featureModel公共字段
 * type: null,
 * carType: null,
 */
const INIT_STATE = {};

export default function FeatureModelCommon(state = INIT_STATE, action) {
    switch (action.type) {
        case 'featureModelCommon':
            return {
                // ...state,
                ...{
                    ...action.data,
                },
            };
        default:
            return state;
    }
}
