//防抖
import { useRef } from "react";
export const useDebounceFn = (func, wait, immediate) => {
    let timeout = useRef(), context, result;
    const resDebounced = (...args) => {
        context = this;
        if (timeout.current) clearTimeout(timeout.current);
        if (immediate) {
            const callNow = !timeout.current;
            timeout.current = setTimeout(()=> {
                timeout.current = null;
            }, wait);
            if (callNow) result = func.apply(context, args);
        } else {
            timeout.current = setTimeout(()=> {
                timeout.current = null;
                result = func.apply(context, args);
            }, wait);
        };
        return result;
    };
    resDebounced.cancal = () => {
        clearTimeout(timeout.current);
        timeout.current = null;
    };
    return resDebounced;
};

// 对ODD、弯道、最晚变道点进行数字四舍五入处理
export const MathRoundFn = (distance) => {
    return Math.round(distance / 10) * 10;
}