import React, {useState, useEffect} from "react";
import {useTranslation} from 'react-i18next';
import {Switch} from "antd-mobile";
import CardLayout from "../../../components/cardLayout";
import ldp from '@/assets/settingComNew/activeSafety/ldp.png';
import {useSelector} from "react-redux";
import useLKASettingCommon from "../LKASettingCommon";
import "@/components/slider/smartDrivingSetting/components/cardStyle/index.less";

let timer = null;
export default function LdpCard() {
    const {t: translate} = useTranslation();
    const ldp_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.lka_switch?.ldp_switch);
    const [LDPSwitch, setLDPSwitch] = useState(ldp_switch === 'OPEN');
    const [timer, setTimer] = useState(null)  // 存放定时器句柄

    const {switchChange} = useLKASettingCommon({timer: timer, setTimer: setTimer, setLoading: null});

    useEffect(() => {
        ldp_switch && setLDPSwitch(ldp_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
    }, [ldp_switch])

    const leftDom = (
        <div className={"left_dom_wrap"}>
            <div className={"switch_wrap"}>
                <div className={"content_wrap"}>
                    <div className={"text_wrap"}>LDP</div>
                    <div className={"text_wrap_detail"} style={{whiteSpace: "pre-wrap"}}>
                        {translate('LKASetting.LDPSwitch')}
                    </div>
                </div>
                <Switch
                    checked={LDPSwitch}
                    onChange={(checked) => switchChange('LDP', checked, setLDPSwitch)}
                    className={"customSwitch"}
                />
            </div>
        </div>
    )

    const rightDom = (
        <div className={"right_dom_wrap"}>
            <img src={ldp}/>
        </div>
    )

    return (
        <>
            <CardLayout
                leftDom={leftDom}
                rightDom={rightDom}
            />
        </>
    )
}
