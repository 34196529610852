import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from 'react-i18next';
import {Switch} from "antd-mobile";
import CardLayout from "../../../components/cardLayout";
import fcw from '@/assets/settingComNew/activeSafety/fcw.png';
import {useSelector} from "react-redux";
import useFcwSettingCommon from "../FCWSettingCommon";
import "@/components/slider/smartDrivingSetting/components/cardStyle/index.less";
import {singelPointReport} from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

let timer = null;
export default function FcwCard() {
    const {t: translate} = useTranslation();
    const fcw_switch = useSelector(state => state?.featureModelDriving?.switch?.adas?.fcw?.fcw_switch);
    const [FCWSwitch, setAFCWSwitch] = useState(fcw_switch === 'OPEN');
    const fcw_sensitive_level = useSelector(state => state?.featureModelDriving?.switch?.adas?.fcw?.fcw_sensitive_level);
    const [loading, setLoading] = useState(false)

    const {FCWControl} = useFcwSettingCommon()

    useEffect(() => {
        fcw_switch && setAFCWSwitch(fcw_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer)
            timer = null
        }
        setLoading(false)
    }, [fcw_switch])

    const switchFCWChange = (value, sensiValue) => {
        clearTimeout(timer)
        setLoading(true)
        timer = setTimeout(() => {
            setLoading(false)
            setAFCWSwitch(fcw_switch === 'OPEN')
        }, 2 * 1000)
        const result_fcw_level = fcw_sensitive_level ? fcw_sensitive_level : "2"
        FCWControl({
            'fcw_switch': value,
            'fcw_sensitive_level': sensiValue ? sensiValue : result_fcw_level,
        });
        reportDataFunc(value, sensiValue ? sensiValue : result_fcw_level)
    }
    // 数据上报
    const reportDataFunc = (value, value2) =>{
        singelPointReport({
            eventName: reportEventName.HMI_FCW_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                FCW: value ? 'OPEN' : 'CLOSE',
            },
        })
        singelPointReport({
            eventName: reportEventName.HMI_FCW_SENSITIVITY_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                fcw_sensitive_level: value2,
            },
        })
    }


    const leftDom = (
        <div className={"left_dom_wrap"}>
            <div className={"switch_wrap"}>
                <div className={"content_wrap"}>
                    <div className={"text_wrap"}>FCW</div>
                    <div className={"text_wrap_detail"}>{translate('AEBFCW.FCWSwitch')}</div>
                </div>
                <Switch
                    checked={FCWSwitch}
                    loading={loading}
                    onChange={(checked) => switchFCWChange(checked)}
                    className={"customSwitch"}
                />
            </div>
        </div>
    )

    const rightDom = (
        <div className={"right_dom_wrap"}>
            <img src={fcw}/>
        </div>
    )

    return (
        <>
            <CardLayout
                leftDom={leftDom}
                rightDom={rightDom}
            />
        </>
    )
}
