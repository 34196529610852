
import AEB from "../aebAndFcwComponent/aeb";
import FCW from "../aebAndFcwComponent/fcw";
import "./index.less";
import React from "react";
import {useSelector} from "react-redux";

export default function AebAndFcwLayout(){
    return (
        <div className="aeb-fcw-layout">
           <React.Fragment>
               <FCW />
               <AEB/>
            </React.Fragment>
        </div>
    )
}
