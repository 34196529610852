import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import backD from '@/assets/parking/d_new/back_d.png';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { cusToast } from '@/components/customToast';
import './index.less';

export default function BackToDriving() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const backBtnRef = useRef(null);
    const backBtn = backBtnRef?.current?.getBoundingClientRect();
    window.pilot_kind_control_cb_driving = (res) => {
        if (res?.data?.code === 0) {
            //退出parking点击
            singelPointReport({
                eventName: reportEventName.HMI_PARKING_EXIT_CLICK,
                timestamp: new Date().getTime(),
                text: 'parking exit',
            });
            // return navigate(`/${"home/index"}`);
        }
    };
    const isRecording = useSelector((state) => state?.switchModeAction?.isRecording);
    const handleBack = async () => {
        if (isRecording) {
            cusToast.show({
                content: 'Wait for recording to complete',
                duration: 3000,
            });
            return;
        }
        FM.sendToHMIParking(
            ParkingSocketMap.pilot_kind_control,
            {
                cmd: 'DRIVING',
            },
            'pilot_kind_control_cb_driving',
        );
    };
    useEffect(() => {
        const backBtnInfo = `${backBtn?.x}, ${backBtn?.y}, ${backBtn?.width}, ${backBtn?.height}`;
        console.log('parkingBtnInfo', backBtnInfo);
        dispatch({
            type: HOT_AREA_TYPE.backBtn,
            data: backBtnInfo,
        });
    }, [status, backBtn?.x, backBtn?.y, backBtn?.width, backBtn?.height]);

    return (
        <>
            {['NOT_IN', 'STANDBY', 'PASSIVE'].indexOf(status) != -1 ? (
                <div
                    ref={backBtnRef}
                    onClick={() => handleBack()}
                    className={`p-back-to-driving ${userMode == 'D' ? 'd' : 'sr'}`}
                >
                    {userMode == 'D' ? (
                        <img className="p-back-icon" src={backD} alt="Back" />
                    ) : (
                        <span className="icon-back"></span>
                    )}
                </div>
            ) : (
                ''
            )}
        </>
    );
}
