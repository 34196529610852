import { useSelector } from 'react-redux';
// import openLplp from "@/assets/parking/icons/icon_open_lpnp.png";
import stepSuccess from '@/assets/parking/icons/step_success.png';

import './index.less';

const stepList = {
    step1: {
        text: 'Driving out to the ground', //'驶出停车场，回到地面',
        step: '01',
        success: stepSuccess,
    },
    step2: {
        text: 'Driving back to the parking lot', //'请返回地下停车场',
        step: '02',
        success: stepSuccess,
    },
    step3: {
        text: 'Driving to start of the learned route', //'请开往路线学习起点',
        step: '03',
        success: stepSuccess,
    },
};

export default function GuideStep(props) {
    const currentStep = stepList?.[props.step];
    return (
        <div className={`guide-list ${props?.running}`}>
            <div className={`l-icon  ${props?.status} ${props?.running}`}>
                {props?.status === 'success' ? (
                    <img src={currentStep.success} alt="success" />
                ) : (
                    <span className={'step-title'}>{currentStep?.step}</span>
                )}
            </div>
            <div className={`r-desc ${props?.status}`}>
                <span className={'desc-title'}>{currentStep.text}</span>
            </div>
        </div>
    );
}
