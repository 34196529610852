const INIT_STATE = {
    router_name: "",
    router_city: "",
    router_type: "",
};

export default function routerInfo(state = INIT_STATE, action) {
    switch (action.type) {
        case 'changeRouterInfo':
            return {
                ...state,
                ...{
                    router_name: action.data?.router_name,
                    router_city: action.data?.router_city,
                    router_type: action.data?.router_type,
                }
            }
        default:
            return state;
    }
}
