// ua信息
const userAgent = window.navigator.userAgent;
// 设备信息:用来判断是pad加载的
const regDevice = /hmi_uniqueId\(([^\)]+)\)/;
const deviceID = regDevice.exec(userAgent) ? regDevice.exec(userAgent)[1] : '';
const isNativeLoad = !!deviceID;

// 离线包：不是http开头的是离线包
const { origin } = window.location;
const isOfflinePack = !origin.startsWith('http');

// app中release包才进行埋点数据上报
const isReport = /hmi_buildType\(release\)/.test(userAgent);

export { isNativeLoad, isOfflinePack, isReport };
