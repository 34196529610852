import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { lightColorLeft, lightColorMid, lightColorRight } from '@/constant/TLALight';
import INVALID from '@/assets/TLA/INVALID.png';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import './index.less';

export default function TLALights() {
    const lightsLeft = useSelector((state) => state?.featureModelDriving?.tla?.lights?.left?.color);
    const lightsRight = useSelector((state) => state?.featureModelDriving?.tla?.lights?.right?.color);
    const lightsMid = useSelector((state) => state?.featureModelDriving?.tla?.lights?.mid?.color);
    const TLA_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.tla_switch);

    // useEffect(() => {
    //     console.log(lightsLeft, lightsMid, lightsRight, TLA_switch, "--lightsLeft, lightsRight, lightsMid, TLA_switch--")
    // }, [lightsLeft, lightsMid, lightsRight, TLA_switch]);

    useEffect(() => {
        if (TLA_switch && TLA_switch !== 'ACTIVE') {
            FM.sendToHMIDriving(
                SocketMap.TALSwitchControl,
                {
                    tal_switch: 'ACTIVE',
                },
                'drive_node_res_fail_cb',
            );
        }
    }, [TLA_switch]);

    const showTlaLight =
        (lightsLeft && lightsLeft !== 'INVALID') ||
        (lightsMid && lightsMid !== 'INVALID') ||
        (lightsRight && lightsRight !== 'INVALID');

    return (
        <React.Fragment>
            {showTlaLight && (
                <div className="TLA_wrap">
                    <img alt="" src={lightColorLeft[lightsLeft] || INVALID} />
                    <img alt="" src={lightColorMid[lightsMid] || INVALID} />
                    <img alt="" src={lightColorRight[lightsRight] || INVALID} />
                </div>
            )}
        </React.Fragment>
    );
}
